import React, {useState} from 'react';
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {Link, useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input';
import {signup} from 'redux/actions';
import '../login/phone.css';

const useStyles = makeStyles(({palette, ...theme}) => ({
  cardHolder: {
    background: '#1A2038',
  },
  card: {
    maxWidth: 650,
    borderRadius: 12,
    margin: '1rem',
  },
  logoImg: {
    width: '5rem',
    [theme.breakpoints.down('sm')]: {
      width: '4rem',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  socialButton: {
    width: '100%',
    '& img': {
      margin: '0 8px',
    },
  },
  labelLink: {
    color: palette.primary.main,
    textDecoration: 'underline',
  },
}));

const Register = () => {
  const [state, setState] = useState({});
  const classes = useStyles();
  const [passwordError, setPasswordError] = useState('');
  const [passwordError2, setPasswordError2] = useState('');
  const [mobileError, setMobileError] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState('');
  const [agreementError, setAgreementError] = useState(false);

  const ipData = useSelector((state) => state.ipData);
  const signupData = useSelector((state) => state.signupData, shallowEqual);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  // check pin validity and password
  React.useEffect(() => {
    if (!ValidatorForm.hasValidationRule('passwordValid')) {
      ValidatorForm.addValidationRule('passwordValid', (value) => {
        if (value.length >= 1 && value.length < 8) {
          setPasswordError('Mininum 8 characters');
          return false;
        }
        if (value.length > 32) {
          setPasswordError('Maximum 32 characters');
          return false;
        }
        return true;
      });
    }

    if (!ValidatorForm.hasValidationRule('isMatch')) {
      ValidatorForm.addValidationRule('isMatch', (value) => {
        if (value !== '' && value !== state.password) {
          setPasswordError2('Password do not Match');
          return false;
        }
        return true;
      });
    }

    return () => {
      if (ValidatorForm.hasValidationRule('passwordValid')) {
        ValidatorForm.removeValidationRule('passwordValid');
      }
      if (ValidatorForm.hasValidationRule('isMatch')) {
        ValidatorForm.removeValidationRule('isMatch');
      }
    };
  }, [state.password, state.password2]);

  const handleChange = ({target: {name, value}}) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleFormSubmit = () => {
    const data = {
      ...state,
      mobile,
      orgId: orgInfo.orgId,
    };
    if (mobile === '') {
      setMobileError('This field is required');
      return;
    }
    if (!agreement) setAgreementError(true);
    if (!isValidPhoneNumber(mobile)) return;
    dispatch(signup(data, redirectToSignin));
  };

  const redirectToSignin = () => {
    history.push('/');
  };

  let {email, password, agreement, lastName, firstName, password2} = state;

  React.useEffect(() => {
    if (mobile && !isValidPhoneNumber(mobile)) {
      setMobileError('Number is not valid');
    } else setMobileError('');
  }, [mobile]);

  React.useEffect(() => {
    if (agreement) setAgreementError(false);
  }, [agreement]);
  return (
    <div
      className={clsx(
        'flex justify-center items-center  min-h-full-screen',
        classes.cardHolder
      )}
    >
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg md sm xs={12}>
            <div
              className="px-8 pt-8 flex items-center justify-between"
              style={{columnGap: '4rem'}}
            >
              <img
                src={orgInfo?.logo}
                alt=""
                className={`${classes.logoImg}`}
              />
              <span>
                <h6 className="text-primary">Sign up to</h6>
                <h3 className="text-primary">CHURCHPAD</h3>
              </span>
            </div>
            <p
              className="subtitle-1 text-center relative"
              style={{top: '1.5rem'}}
            >
              {' '}
              Enter details below to sign up
            </p>
            <div className="p-8 h-full">
              <ValidatorForm onSubmit={handleFormSubmit}>
                <TextValidator
                  className="mb-6 w-full"
                  variant="outlined"
                  size="small"
                  label="First Name"
                  onChange={handleChange}
                  type="text"
                  name="firstName"
                  value={firstName || ''}
                  validators={['required']}
                  errorMessages={[
                    'this field is required',
                    'Name is not valid',
                  ]}
                />
                <TextValidator
                  className="mb-6 w-full"
                  variant="outlined"
                  size="small"
                  label="Last Name"
                  onChange={handleChange}
                  type="text"
                  name="lastName"
                  value={lastName || ''}
                  validators={['required']}
                  errorMessages={[
                    'this field is required',
                    'Name is not valid',
                  ]}
                />
                <TextValidator
                  className="mb-6 w-full"
                  variant="outlined"
                  size="small"
                  label="Email"
                  onChange={handleChange}
                  type="email"
                  name="email"
                  value={email || ''}
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'this field is required',
                    'email is not valid',
                  ]}
                />
                <div className="relative">
                  <PhoneInput
                    international={
                      ipData && ipData ? ipData.country_code : null
                    }
                    withCountryCallingCode
                    countryCallingCodeEditable={false}
                    defaultCountry={ipData ? ipData.country_code : null}
                    placeholder="Mobile"
                    id="loginMobile"
                    value={mobile}
                    onChange={setMobile}
                    className={`mb-5 px-3 mobile`}
                    name="mobile"
                  />
                  {mobile && isValidPhoneNumber(mobile) && (
                    <div className="check"></div>
                  )}
                  {mobile && !isValidPhoneNumber(mobile) && (
                    <span className="x">X</span>
                  )}
                  {mobileError && (
                    <p className="text-error my-auto errorMessage">
                      {mobileError}
                    </p>
                  )}
                </div>
                <TextValidator
                  className="mb-6 w-full"
                  label="Password"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  name="password"
                  type="password"
                  value={password}
                  validators={[
                    'required',
                    'passwordValid',
                    'matchRegexp:[@#$%!]',
                    'matchRegexp:[0-9]',
                    'matchRegexp:[A-Z]',
                    'matchRegexp:[a-z]',
                  ]}
                  errorMessages={[
                    'this field is required',
                    `${passwordError}`,
                    'Must contain at least one special character',
                    'Must contain at least one number',
                    'Must contain at least one uppercase character',
                    'Must contain at least one lowercase character',
                  ]}
                />
                <TextValidator
                  className="mb-6 w-full"
                  label="Confirm Password"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  name="password2"
                  type="password"
                  value={password2}
                  validators={['required', 'isMatch']}
                  errorMessages={[
                    'this field is required',
                    `${passwordError2}`,
                  ]}
                />
                <FormControlLabel
                  className="mb-4"
                  name="agreement"
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: 'agreement',
                        value: e.target.checked,
                      },
                    })
                  }
                  control={
                    <Checkbox size="small" checked={agreement || false} />
                  }
                  label={
                    <>
                      I have read and agree to the{' '}
                      <a
                        href={orgInfo?.termsOfService}
                        target="_blank"
                        className={classes.labelLink}
                        rel="noopener noreferrer"
                      >
                        terms of service.
                      </a>
                    </>
                  }
                />
                {agreementError && (
                  <p className="text-error">This field is required</p>
                )}
                <div
                  className="flex items-center justify-between"
                  style={{columnGap: '2rem'}}
                >
                  <div className="relative w-full">
                    <Button
                      className="w-full"
                      variant="contained"
                      color="primary"
                      disabled={
                        (signupData && signupData.loading) || !agreement
                      }
                      type="submit"
                    >
                      Sign Up
                    </Button>
                    {signupData && signupData.loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>

                  <Link to="/" className="w-full">
                    <Button variant="outlined" className="capitalize w-full">
                      Sign In
                    </Button>
                  </Link>
                </div>
              </ValidatorForm>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Register;
