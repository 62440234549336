import {themes} from '../MatxTheme/initThemes';

// UPDATE BELOW CODE
// DOC http://demos.ui-lib.com/matx-react-doc/layout.html

export const themeOptions = {
  lightMode: {
    mode: 'lightMode',
    theme: {
      activeLayout: 'layout1',
      activeTheme: 'customTheme',
      layout1Settings: {
        leftSidebar: {
          show: true,
          mode: 'full',
          theme: 'whiteBlue',
          bgOpacity: 0.28,
          bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
        },
        topbar: {
          theme: 'customTheme',
          fixed: true,
          show: true,
        },
      },
      footer: {
        theme: 'customTheme',
      },
    },
  },
  darkMode: {
    mode: 'darkMode',
    theme: {
      activeLayout: 'layout1',
      activeTheme: 'customThemeDark',
      layout1Settings: {
        leftSidebar: {
          show: true,
          mode: 'full',
          theme: 'slateDark1',
          bgOpacity: 0.92,
          bgImgURL: '/assets/images/sidebar/sidebar-bg-dark.jpg',
        },
        topbar: {
          theme: 'customThemeDark',
          fixed: true,
          show: true,
        },
      },
      footer: {
        theme: 'customThemeDark',
      },
    },
  },
};

const preferLightTheme =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: light)').matches;
const newTheme = JSON.parse(localStorage.getItem('currentTheme'));
let usetheme = themeOptions.darkMode.theme;

if (preferLightTheme && !newTheme) {
  usetheme = themeOptions.lightMode.theme;
}
if (newTheme && newTheme) {
  if (newTheme && newTheme === 'lightMode') {
    usetheme = themeOptions.lightMode.theme;
  } else {
    usetheme = themeOptions.darkMode.theme;
  }
}

export const MatxLayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2
  activeTheme: usetheme.activeTheme, // View all valid theme colors inside MatxTheme/themeColors.js
  perfectScrollbar: false,

  themes: themes,
  layout1Settings: usetheme.layout1Settings, // open Layout1/Layout1Settings.js
  footer: {
    show: true,
    fixed: false,
    theme: usetheme.footer, // View all valid theme colors inside MatxTheme/themeColors.js
  },
};
