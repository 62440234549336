import {
  GET_NOTIFICATION,
  DELETE_NOTIFICATION,
} from '../actions/NotificationActions';

const initialState = {
  message: null,
};

const NotificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATION: {
      return {
        ...state,
        message: action.payload,
      };
    }
    case DELETE_NOTIFICATION: {
      return {...initialState};
    }
    default: {
      return {...state};
    }
  }
};

export default NotificationReducer;
