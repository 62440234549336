import { actionTypes } from "../actionTypes";

export const contactGroupsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTACT_LIST_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.CONTACT_LIST_GROUP_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addContactGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_LIST_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_CONTACT_LIST_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteContactGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CONTACT_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_CONTACT_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const renameContactGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RENAME_CONTACT_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.RENAME_CONTACT_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const addUsersToContactGroupAnalyze = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_USER_TO_GROUP_ANALYZE_SUCCESS:
      return action.payload;
    case actionTypes.ADD_USER_TO_GROUP_ANALYZE_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addUsersToContactGroupProcess = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_USER_TO_GROUP_PROCESS_SUCCESS:
      return action.payload;
    case actionTypes.ADD_USER_TO_GROUP_PROCESS_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const contactGroupUsers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTACT_GROUP_USERS_SUCCESS:
      return action.payload;
    case actionTypes.GET_CONTACT_GROUP_USERS_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteUsersFromContactGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CONTACT_GROUP_USERS_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_CONTACT_GROUP_USERS_FAILURE:
      return action.payload;
    case actionTypes.CONTACT_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONTACT_USERS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateContactGroupUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTACT_GROUP_USERS_SUCCESS:
      return action.payload;
    case actionTypes.UPDATE_CONTACT_GROUP_USERS_FAILURE:
      return action.payload;
    case actionTypes.CONTACT_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONTACT_USERS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const smartKeywordsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SMART_KEYWORDS_SUCCESS:
      return action.payload;
    case actionTypes.SMART_KEYWORDS_FAILURE:
      return action.payload;
    case actionTypes.SMART_KEYWORDS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SMART_KEYWORDS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteSmartKeyword = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SMART_KEYWORDS_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_SMART_KEYWORDS_FAILURE:
      return action.payload;
    case actionTypes.SMART_KEYWORDS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SMART_KEYWORDS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addNewSmartKeyword = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_SMARTKEY_WORDS_SUCCESS:
      return action.payload;
    case actionTypes.ADD_SMARTKEY_WORDS_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const editSmartKeyword = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_SMARTKEY_WORDS_SUCCESS:
      return action.payload;
    case actionTypes.EDIT_SMARTKEY_WORDS_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const smartKeywordConsumers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SMART_KEYWORD_CONSUMER_SUCCESS:
      return action.payload;
    case actionTypes.SMART_KEYWORD_CONSUMER_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const fetchCommunicationGroupsByCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FECTH_COMMUNICATION_GROUP_BY_CATEGORY_SUCCESS:
      return action.payload;
    case actionTypes.FECTH_COMMUNICATION_GROUP_BY_CATEGORY_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        // ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_FETCH_CATEGORY_DATA:
      return {};
    default:
      return state;
  }
};

export const uCMessageLogs = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UCMESSAGE_SUCCESS:
      return action.payload;
    case actionTypes.UCMESSAGE_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const unifiedCommunicationSendEngineAnalyze = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UNIFIED_COMMUNICATION_SEND_ENGINE_ANALYZE_SUCCESS:
      return action.payload;
    case actionTypes.UNIFIED_COMMUNICATION_SEND_ENGINE_ANALYZE_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const unifiedCommunicationSendEngineProcess = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UNIFIED_COMMUNICATION_SEND_ENGINE_PROCESS_SUCCESS:
      return action.payload;
    case actionTypes.UNIFIED_COMMUNICATION_SEND_ENGINE_PROCESS_FAILURE:
      return action.payload;
    case actionTypes.CREATE_CONTACT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_CONTACT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const doNotCallTextLoading = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DONOTCALLTEXT_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DONOTCALLTEXT_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const doNotTextCallEmailListingticketListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DONOTCALLTEXT_LISTING_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DONOTCALLTEXT_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DONOTCALLTEXT_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchDoNotTextCallEmail = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DONOTCALLTEXT_SEARCH_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DONOTCALLTEXT_SEARCH_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DONOTCALLTEXT_SEARCH_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const uCScheduledMessages = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHEDULE_MESSAGE_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_SCHEDULE_MESSAGE_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export const uCMessageLogsGetDetails = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHEDULE_MESSAGE_VIEW_LOG_STOP:
      return { loading: false, ...action.payload };
    case actionTypes.GET_SCHEDULE_MESSAGE_VIEW_LOG_START:
      return {
        ...state,
        loading: true,
      };
      case actionTypes.RESET_UC_LOGS_DATA:
        return {};
    default:
      return state;
  }
};

export const uCScheduledMessageGetDetails = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_SCHEDULE_MESSAGE_VIEW_DATA_STOP:
      return { loading: false, ...action.payload };
    case actionTypes.GET_SCHEDULE_MESSAGE_VIEW_DATA_START:
      return {
        ...state,
        loading: true,
      };
      case actionTypes.RESET_UC_SCHEDULE_DATA:
        return {};
    default:
      return state;
  }
};

