import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import GlobalGivingEbrochure from ".";
import GlobalGivingEbrochureSuccess from "./SuccessPage";

const GlobalGivingEbrochureRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          component={GlobalGivingEbrochure}
          path={"/global-giving-ebrochure"}
        />
        <Route
          component={GlobalGivingEbrochureSuccess}
          path={"/global-giving-ebrochure/success"}
          exact
        />
        <Redirect to="/global-giving-ebrochure" />
      </Switch>
    </Router>
  );
};

export default GlobalGivingEbrochureRoutes;
