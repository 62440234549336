export const actionTypes = {
  GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
  GET_CART_LIST: "GET_CART_LIST",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  GET_RATING_LIST: "GET_RATING_LIST",
  GET_BRAND_LIST: "GET_BRAND_LIST",
  ADD_PRODUCT_TO_CART: "ADD_PRODUCT_TO_CART",
  DELETE_PRODUCT_FROM_CART: "DELETE_PRODUCT_FROM_CART",
  UPDATE_CART_AMOUNT: "UPDATE_CART_AMOUNT",
  GET_IPDATA: "GET_IPDATA",
  FETCH_ORG_INFO: "FETCH_ORG_INFO",
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  START_PAGE_LOADING: "START_PAGE_LOADING",
  STOP_PAGE_LOADING: "STOP_PAGE_LOADING",
  RESET_ENDPOINT_DATA: "RESET_ENDPOINT_DATA",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FORGOT_PIN_SUCCESS: "FORGOT_PIN_SUCCESS",
  FORGOT_PIN_FAILURE: "FORGOT_PIN_FAILURE",
  VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS:
    "VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS",
  VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE:
    "VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE",
  RESET_PASSWORD_OR_PIN_SUCCESS: "RESET_PASSWORD_OR_PIN_SUCCESS",
  RESET_PASSWORD_OR_PIN_FAILURE: "RESET_PASSWORD_OR_PIN_FAILURE",
  GET_ORG_INFO_LIST: "GET_ORG_INFO_LIST",
  UPDATE_USER_RECORD_SUCCESS: "UPDATE_USER_RECORD_SUCCESS",
  UPDATE_USER_RECORD_FAILURE: "UPDATE_USER_RECORD_FAILURE",
  ORG_INFO_LIST: "ORG_INFO_LIST",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE: "GET_USER_PROFILE_FAILURE",
  FETCH_SECOND_ORG_INFO: "FETCH_SECOND_ORG_INFO",
  FETCH_PEOPLE: "FETCH_PEOPLE",
  FETCH_USER_PROFILE: "FETCH_USER_PROFILE",
  CLEAR_PEOPLE: "CLEAR_PEOPLE",
  GET_MEMBERSHIP: "GET_MEMBERSHIP",
  ADD_MEMBERSHIP: "ADD_MEMBERSHIP",
  CHANGE_MEMBERSHIP_NAME: "CHANGE_MEMBERSHIP_NAME",
  DELETE_MEMBERSHIP_NAME: "DELETE_MEMBERSHIP_NAME",
  CHANGE_MEMBERSHIP_POSITION: "CHANGE_MEMBERSHIP_POSITION",
  UPDATE_ADMIN_PROFILE: "UPDATE_ADMIN_PROFILE",
  ADD_NEW_MEMBER: "ADD_NEW_MEMBER",
  GENERATE_RANDOM_PASSWORD: "GENERATE_RANDOM_PASSWORD",
  SEARCHED_USERS: "SEARCHED_USERS",
  PRAYERWALLS_SUCCESS: "PRAYERWALLS_SUCCESS",
  PRAYERWALLS_FAILURE: "PRAYERWALLS_FAILURE",
  PRAYERWALLS_APPROVE_SUCCESS: "PRAYERWALLS_APPROVE_SUCCESS",
  PRAYERWALLS_APPROVE_FAILURE: "PRAYERWALLS_APPROVE_FAILURE",
  FELLOWSHIP_CATEGORY_WITH_POSITION_SUCCESS:
    "FELLOWSHIP_CATEGORY_WITH_POSITION_SUCCESS",
  FELLOWSHIP_CATEGORY_WITH_POSITION_FAILURE:
    "FELLOWSHIP_CATEGORY_WITH_POSITION_FAILURE",
  CHANGE_FELLOWSHIP_POSITION_SUCCESS: "CHANGE_FELLOWSHIP_POSITION_SUCCESS",
  CHANGE_FELLOWSHIP_POSITION_FAILURE: "CHANGE_FELLOWSHIP_POSITION_FAILURE",
  ADD_FELLOWSHIP_CATEGORY_SUCCESS: "ADD_FELLOWSHIP_CATEGORY_SUCCESS",
  ADD_FELLOWSHIP_CATEGORY_FAILURE: "ADD_FELLOWSHIP_CATEGORY_FAILURE",
  DELETE_FELLOWSHIP_CATEGORY_SUCCESS: "DELETE_FELLOWSHIP_CATEGORY_SUCCESS",
  DELETE_FELLOWSHIP_CATEGORY_FAILURE: "DELETE_FELLOWSHIP_CATEGORY_FAILURE",
  EDIT_FELLOWSHIP_CATEGORY_SUCCESS: "EDIT_FELLOWSHIP_CATEGORY_SUCCESS",
  EDIT_FELLOWSHIP_CATEGORY_FAILURE: "EDIT_FELLOWSHIP_CATEGORY_FAILURE",
  UPDATE_GENERAL_SETTINGS: "UPDATE_GENERAL_SETTINGS",
  UPDATE_SOCIALMEDIA: "UPDATE_SOCIALMEDIA",
  UPDATE_LOGO: "UPDATE_LOGO",
  GENEROSITY_DASHBOARD_SUCCESS: "GENEROSITY_DASHBOARD_SUCCESS",
  GENEROSITY_DASHBOARD_FAILURE: "GENEROSITY_DASHBOARD_FAILURE",
  GET_CONTRIBUTIONS_NAMES_AND_CODES_FAILURE:
    "GET_CONTRIBUTIONS_NAMES_AND_CODES_FAILURE",
  GET_CONTRIBUTIONS_NAMES_AND_CODES_SUCCESS:
    "GET_CONTRIBUTIONS_NAMES_AND_CODES_SUCCESS",
  CHANGE_CONTRIBUTION_NAMES_POSITION_SUCCESS:
    "CHANGE_CONTRIBUTION_NAMES_POSITION_SUCCESS",
  CHANGE_CONTRIBUTION_NAMES_POSITION_FAILURE:
    "CHANGE_CONTRIBUTION_NAMES_POSITION_FAILURE",
  ADMIN_RECENT_CONTRIBUTIONS_SUCCESS: "ADMIN_RECENT_CONTRIBUTIONS_SUCCESS",
  ADMIN_RECENT_CONTRIBUTIONS_FAILURE: "ADMIN_RECENT_CONTRIBUTIONS_FAILURE",
  FELLOWSHIP_PREQUEST_DASHBOARD_SUCCESS:
    "FELLOWSHIP_PREQUEST_DASHBOARD_SUCCESS",
  FELLOWSHIP_PREQUEST_DASHBOARD_FAILURE:
    "FELLOWSHIP_PREQUEST_DASHBOARD_FAILURE",
  FELLOWSHIP_PREPORT_DASHBOARD_SUCCESS: "FELLOWSHIP_PREPORT_DASHBOARD_SUCCESS ",
  FELLOWSHIP_PREPORT_DASHBOARD_FAILURE: "FELLOWSHIP_PREPORT_DASHBOARD_FAILURE",
  ORG_MESSAGE: "ORG_MESSAGE",
  UPDATE_TEXT_MESSAGING: "UPDATE_TEXT_MESSAGING",
  CHILDREN_LISTING_SUCCESS: "CHILDREN_LISTING_SUCCESS",
  CHILDREN_LISTING_FAILURE: "CHILDREN_LISTING_FAILURE",
  GET_CHILD: "GET_CHILD",
  GET_ONE_CHILD_SUCCESS: "GET_ONE_CHILD_SUCCESS",
  GET_ONE_CHILD_FAILURE: "GET_ONE_CHILD_FAILURE",
  UPDATE_ORRG_COLOR_SUCCESS: "UPDATE_ORRG_COLOR_SUCCESS",
  DELETE_A_CHILD_SUCCESS: "DELETE_A_CHILD_SUCCESS",
  DELETE_A_CHILD_FAILURE: "DELETE_A_CHILD_FAILURE",
  UPDATE_A_CHILD_DATA_SUCCESS: "UPDATE_A_CHILD_DATA_SUCCESS",
  UPDATE_A_CHILD_DATA_FAILURE: "UPDATE_A_CHILD_DATA_FAILURE",
  DELETE_CONTRIBUTION_NAMES_SUCCESS: "DELETE_CONTRIBUTION_NAMES_SUCCESS",
  DELETE_CONTRIBUTION_NAMES_FAILURE: "DELETE_CONTRIBUTION_NAMES_FAILURE",
  GET_AVAILABLE_CONTRIBUTION_CODES_SUCCESS:
    "GET_AVAILABLE_CONTRIBUTION_CODES_SUCCESS",
  GET_AVAILABLE_CONTRIBUTION_CODES_FAILURE:
    "GET_AVAILABLE_CONTRIBUTION_CODES_FAILURE",
  ADD_CONTRIBUTION_NAME_SUCCESS: "ADD_CONTRIBUTION_NAME_SUCCESS",
  ADD_CONTRIBUTION_NAME_FAILURE: "ADD_CONTRIBUTION_NAME_FAILURE",
  CHANGE_CONTRIBUTION_NAME_SUCCESS: "CHANGE_CONTRIBUTION_NAME_SUCCESS",
  CHANGE_CONTRIBUTION_NAME_FAILURE: "CHANGE_CONTRIBUTION_NAME_FAILURE",
  GET_ALL_CLASSROOM_CATEGORIES_SUCCESS: "GET_ALL_CLASSROOM_CATEGORIES_SUCCESS",
  GET_ALL_CLASSROOM_CATEGORIES_FAILURE: "GET_ALL_CLASSROOM_CATEGORIES_FAILURE",
  ADD_CLASSROOM_CATEGORIES_SUCCESS: "ADD_CLASSROOM_CATEGORIES_SUCCESS",
  ADD_CLASSROOM_CATEGORIES_FAILURE: "ADD_CLASSROOM_CATEGORIES_FAILURE",
  CHANGE_CLASSROOM_CATEGORIES_POSITION_SUCCESS:
    "CHANGE_CLASSROOM_CATEGORIES_POSITION_SUCCESS",
  CHANGE_CLASSROOM_CATEGORIES_POSITION_FAILURE:
    "CHANGE_CLASSROOM_CATEGORIES_POSITION_FAILURE",
  DELETE_CLASSROOM_CATEGORIES_SUCCESS: "DELETE_CLASSROOM_CATEGORIES_SUCCESS",
  DELETE_CLASSROOM_CATEGORIES_FAILURE: "DELETE_CLASSROOM_CATEGORIES_FAILURE",
  EDIT_CLASSROOM_CATEGORIES_SUCCESS: "EDIT_CLASSROOM_CATEGORIES_SUCCESS",
  EDIT_CLASSROOM_CATEGORIES_FAILURE: "EDIT_CLASSROOM_CATEGORIES_FAILURE",
  UPDATE_EMAIL_TEMPLATE_SUCCESS: "UPDATE_EMAIL_TEMPLATE_SUCCESS",
  TEACHER_LISTING_SUCCESS: "TEACHER_LISTING_SUCCESS",
  TEACHER_LISTING_FAILURE: "TEACHER_LISTING_FAILURE",
  SEARCH_TEACHER_SUCCESS: "SEARCH_TEACHER_SUCCESS",
  SEARCH_TEACHER_FAILURE: "SEARCH_TEACHER_FAILURE",
  DELETE_TEACHER_SUCCESS: "DELETE_TEACHER_SUCCESS",
  DELETE_TEACHER_FAILURE: "DELETE_TEACHER_FAILURE",
  AVAILABLE_CLASSROOM_CATEGORIES_SUCCESS:
    "AVAILABLE_CLASSROOM_CATEGORIES_SUCCESS",
  AVAILABLE_CLASSROOM_CATEGORIES_FAILURE:
    "AVAILABLE_CLASSROOM_CATEGORIES_FAILURE",
  ADD_TEACHER_SUCCESS: "ADD_TEACHER_SUCCESS",
  ADD_TEACHER_FAILURE: "ADD_TEACHER_FAILURE",
  EDIT_TEACHER_SUCCESS: "EDIT_TEACHER_SUCCESS",
  EDIT_TEACHER_FAILURE: "EDIT_TEACHER_FAILURE",
  GLOBAL_SYSTEM_SETTINGS_SUCCESS: "GLOBAL_SYSTEM_SETTINGS_SUCCESS",
  GLOBAL_SYSTEM_SETTINGS_FAILURE: "GLOBAL_SYSTEM_SETTINGS_FAILURE",
  GET_ROLESPERMISSIONSLIST: "GET_ROLESPERMISSIONSLIST",
  GET_ROLESPERMISSIONSLIST_SUCCESS: "GET_ROLESPERMISSIONSLIST_SUCESS",
  GET_ROLESPERMISSIONSLIST_FAIL: "GET_ROLESPERMISSIONSLIST_FAIL",
  GET_USERS_WITH_ROLES: "GET_USERS_WITH_ROLES",
  GET_USERS_WITH_ROLES_SUCCESS: "GET_USERS_WITH_ROLES_SUCCESS",
  GET_USERS_WITH_ROLES_FAIL: "GET_USERS_WITH_ROLES_FAIL",
  GET_USER_ROLE_PERM: "GET_USER_ROLE_PERM",
  GET_USER_ROLE_PERM_SUCCESS: "GET_USER_ROLE_PERM_SUCCESS",
  GET_USER_ROLE_PERM_FAIL: "GET_USER_ROLE_PERM_FAIL",
  UPDATE_ROLES_PERM_SUCCESS: "UPDATE_ROLES_PERM_SUCCESS",
  UPDATE_ROLES_PERM: "UPDATE_ROLES_PERM",
  UPDATE_ROLES_PERM_FAIL: "UPDATE_ROLES_PERM_FAIL",
  DELETE_ROLES_PERM: "DELETE_ROLES_PERM",
  DELETE_ROLES_PERM_SUCCESS: "DELETE_ROLES_PERM_SUCCESS",
  DELETE_ROLES_PERM_FAIL: "DELETE_ROLES_PERM_FAIL",
  UPDATE_GLOBAL_SYSTEM_SETTING_SUCCESS: "UPDATE_GLOBAL_SYSTEM_SETTING_SUCCESS",
  UPDATE_GLOBAL_SYSTEM_SETTING_FAILURE: "UPDATE_GLOBAL_SYSTEM_SETTING_FAILURE",
  CHILDREN_GRID_LISTING_SUCCESS: "CHILDREN_GRID_LISTING_SUCCESS",
  CHILDREN_GRID_LISTING_FAILURE: "CHILDREN_GRID_LISTING_FAILURE",
  CHILDREN_MODULE_SUCCESS: "CHILDREN_MODULE_SUCCESS",
  CHILDREN_MODULE_FAILURE: "CHILDREN_MODULE_FAILURE",
  GET_CHILD_CHECKED_IN_TOKEN_SUCCESS: "GET_CHILD_CHECKED_IN_TOKEN_SUCCESS",
  GET_CHILD_CHECKED_IN_TOKEN_FAILURE: "GET_CHILD_CHECKED_IN_TOKEN_FAILURE",
  GET_CHILD_CHECKED_IN_TOKEN_START: "GET_CHILD_TOKEN_START",
  GET_CHILD_CHECKED_IN_TOKEN_STOP: "GET_CHILD_CHECKED_IN_TOKEN_STOP",
  CHILDREN_CHECKIN_SUCCESS: "CHILDREN_CHECKIN_SUCCESS",
  CHILDREN_CHECKIN_FAILURE: "CHILDREN_CHECKIN_FAILURE",
  SEARCH_USERS_ROLES_PERM: "SEARCH_USERS_ROLES_PERM",
  SEARCH_USERS_ROLES_PERM_SUCCESS: "SEARCH_USERS_ROLES_PERM_SUCCESS",
  SEARCH_USERS_ROLES_PERM_FAIL: "SEARCH_USERS_ROLES_PERM_FAIL",
  CHECK_USER_ROLE_PERM: "CHECK_USER_ROLE_PERM",
  CHECK_USER_ROLE_PERM_SUCCESS: "CHECK_USER_ROLE_PERM_SUCCESS",
  CHECK_USER_ROLE_PERM_FAIL: "CHECK_USER_ROLE_PERM_FAIL",
  ADD_ROLES_PERM_NEW_USER: "ADD_ROLES_PERM_NEW_USER",
  ADD_ROLES_PERM_NEW_USER_SUCCESS: "ADD_ROLES_PERM_NEW_USER_SUCCESS",
  ADD_ROLES_PERM_NEW_USER_FAIL: "ADD_ROLES_PERM_NEW_USER_FAIL",
  CLEAR_SEARCH_USERS_ROLES_PERM: "CLEAR_SEARCH_USERS_ROLES_PERM",
  GENEROSITY_MODULE_SPECIFIC_INFO_SUCCESS:
    "GENEROSITY_MODULE_SPECIFIC_INFO_SUCCESS",
  GENEROSITY_MODULE_SPECIFIC_INFO_FAILURE:
    "GENEROSITY_MODULE_SPECIFIC_INFO_FAILURE",
  CHANGE_TRANSACTION_CONTRIBUTION_TYPE_SUCCESS:
    "CHANGE_TRANSACTION_CONTRIBUTION_TYPE_SUCCESS",
  CHANGE_TRANSACTION_CONTRIBUTION_TYPE_FAILURE:
    "CHANGE_TRANSACTION_CONTRIBUTION_TYPE_FAILURE",
  SEND_CHILDREN_COMMUNICATION_SUCCESS: "SEND_CHILDREN_COMMUNICATION_SUCCESS",
  SEND_CHILDREN_COMMUNICATION_FAILURE: "SEND_CHILDREN_COMMUNICATION_FAILURE",
  MEDIA_VAULT_LISTING_START: "MEDIA_VAULT_LISTING_START",
  MEDIA_VAULT_LISTING_STOP: "MEDIA_VAULT_LISTING_STOP",
  MEDIA_VAULT_LISTING_SUCCESS: "MEDIA_VAULT_LISTING_SUCCESS",
  MEDIA_VAULT_LISTING_FAILURE: "MEDIA_VAULT_LISTING_FAILURE",
  MEDIA_VAULT_IMAGE_DELETE_SUCCESS: "MEDIA_VAULT_IMAGE_DELETE_SUCCESS",
  MEDIA_VALUT_IMAGE_DELETE_FAILURE: "MEDIA_VALUT_IMAGE_DELETE_FAILURE",
  MEDIA_VALUT_UPLOAD_START: "MEDIA_VALUT_UPLOAD_START",
  MEDIA_VALUT_UPLOAD_STOP: "MEDIA_VALUT_UPLOAD_STOP",
  MEDIA_VALUT_UPLOAD_SUCCESS: "MEDIA_VALUT_UPLOAD_SUCCESS",
  MEDIA_VALUT_UPLOAD_FAILURE: "MEDIA_VALUT_UPLOAD_FAILURE",
  CONTRIBUTIONS_REPORTING_SUCCESS: "CONTRIBUTIONS_REPORTING_SUCCESS",
  CONTRIBUTIONS_REPORTING_FAILURE: "CONTRIBUTIONS_REPORTING_FAILURE",
  WEEKLY_DATES_GENERATOR_SUCCESS: "WEEKLY_DATES_GENERATOR_SUCCESS",
  START_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS:
    "START_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS",
  END_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS:
    "END_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS",
  WEEKLY_DATES_GENERATOR_FAILURE: "WEEKLY_DATES_GENERATOR_FAILURE",
  CHILDREN_DASHBOARD_SUCCESS: "CHILDREN_DASHBOARD_SUCCESS",
  CHILDREN_DASHBOARD_FAILURE: "CHILDREN_DASHBOARD_FAILURE",
  PEOPLE_DASHBOARD_SUCCESS: "PEOPLE_DASHBOARD_SUCCESS",
  PEOPLE_DASHBOARD_FAILURE: "PEOPLE_DASHBOARD_FAILURE",
  CONTACT_LIST_GROUP_SUCCESS: "CONTACT_LIST_GROUP_SUCCESS",
  CONTACT_LIST_GROUP_FAILURE: "CONTACT_LIST_GROUP_FAILURE",
  CREATE_CONTACT_LIST_GROUP_SUCCESS: "CREATE_CONTACT_LIST_GROUP_SUCCESS",
  CREATE_CONTACT_LIST_GROUP_FAILURE: "CREATE_CONTACT_LIST_GROUP_FAILURE",
  CREATE_CONTACT_GROUP_START: "CREATE_CONTACT_GROUP_START",
  CREATE_CONTACT_GROUP_STOP: "CREATE_CONTACT_GROUP_STOP",
  DELETE_CONTACT_GROUP_SUCCESS: "DELETE_CONTACT_GROUP_SUCCESS",
  DELETE_CONTACT_GROUP_FAILURE: "DELETE_CONTACT_GROUP_FAILURE",
  RENAME_CONTACT_GROUP_FAILURE: "RENAME_CONTACT_GROUP_FAILURE",
  RENAME_CONTACT_GROUP_SUCCESS: "RENAME_CONTACT_GROUP_SUCCESS",
  ADD_USER_TO_GROUP_ANALYZE_SUCCESS: "ADD_USER_TO_GROUP_ANALYZE_SUCCESS",
  ADD_USER_TO_GROUP_PROCESS_SUCCESS: "ADD_USER_TO_GROUP_PROCESS_SUCCESS",
  ADD_USER_TO_GROUP_ANALYZE_FAILURE: "ADD_USER_TO_GROUP_ANALYZE_FAILURE",
  ADD_USER_TO_GROUP_PROCESS_FAILURE: "ADD_USER_TO_GROUP_PROCESS_FAILURE",
  GET_CONTACT_GROUP_USERS_SUCCESS: "GET_CONTACT_GROUP_USERS_SUCCESS",
  GET_CONTACT_GROUP_USERS_FAILURE: "GET_CONTACT_GROUP_USERS_FAILURE",
  CONTACT_USERS_START: "CONTACT_USERS_START",
  CONTACT_USERS_STOP: "CONTACT_USERS_STOP",
  UPDATE_CONTACT_GROUP_USERS_SUCCESS: "UPDATE_CONTACT_GROUP_USERS_SUCCESS",
  DELETE_CONTACT_GROUP_USERS_SUCCESS: "DELETE_CONTACT_GROUP_USERS_SUCCESS",
  UPDATE_CONTACT_GROUP_USERS_FAILURE: "UPDATE_CONTACT_GROUP_USERS_FAILURE",
  DELETE_CONTACT_GROUP_USERS_FAILURE: "DELETE_CONTACT_GROUP_USERS_FAILURE",
  SMART_KEYWORDS_START: "SMART_KEYWORDS_START",
  SMART_KEYWORDS_STOP: "SMART_KEYWORDS_STOP",
  SMART_KEYWORDS_SUCCESS: "SMART_KEYWORDS_SUCCESS",
  SMART_KEYWORDS_FAILURE: "SMART_KEYWORDS_FAILURE",
  DELETE_SMART_KEYWORDS_SUCCESS: "DELETE_SMART_KEYWORDS_SUCCESS",
  DELETE_SMART_KEYWORDS_FAILURE: "DELETE_SMART_KEYWORDS_FAILURE",
  DEPARTMENTS_LIST_SUCCESS: "DEPARTMENTS_LIST_SUCCESS",
  DEPARTMENTS_LIST_FAIL: "DEPARTMENTS_LIST_FAIL",
  CREATE_DEPARTMENT_LIST_GROUP_SUCCESS: "CREATE_DEPARTMENT_LIST_GROUP_SUCCESS",
  CREATE_DEPARTMENT_LIST_GROUP_FAILURE: "CREATE_DEPARTMENT_LIST_GROUP_FAILURE",
  CREATE_DEPARTMENT_GROUP_START: "CREATE_DEPARTMENT_GROUP_START",
  CREATE_DEPARTMENT_GROUP_STOP: "CREATE_DEPARTMENT_GROUP_STOP",
  DELETE_DEPARTMENT_GROUP_SUCCESS: "DELETE_DEPARTMENT_GROUP_SUCCESS",
  DELETE_DEPARTMENT_GROUP_FAILURE: "DELETE_DEPARTMENT_GROUP_FAILURE",
  RENAME_DEPARTMENT_GROUP_SUCCESS: "RENAME_DEPARTMENT_GROUP_SUCCESS",
  RENAME_DEPARTMENT_GROUP_FAILURE: "RENAME_DEPARTMENT_GROUP_FAILURE",
  GET_DEPARTMENT_GROUP_USERS_SUCCESS: "GET_DEPARTMENT_GROUP_USERS_SUCCESS",
  GET_DEPARTMENT_GROUP_USERS_SUCCESS_RESET:
    "GET_DEPARTMENT_GROUP_USERS_SUCCESS_RESET",
  GET_DEPARTMENT_GROUP_USERS_FAILURE: "GET_DEPARTMENT_GROUP_USERS_FAILURE",
  DELETE_DEPARTMENT_GROUP_USERS_SUCCESS:
    "DELETE_DEPARTMENT_GROUP_USERS_SUCCESS",
  DELETE_DEPARTMENT_GROUP_USERS_FAILURE:
    "DELETE_DEPARTMENT_GROUP_USERS_FAILURE",
  DEPARTMENT_USERS_START: "DEPARTMENT_USERS_START",
  DEPARTMENT_USERS_STOP: "DEPARTMENT_USERS_STOP",
  POSITIONS_LIST_SUCCESS: "POSITIONS_LIST_SUCCESS",
  POSITIONS_LIST_FAIL: "POSITIONS_LIST_FAIL",
  SEARCH_USER_TO_JOIN_SUCCESS: "SEARCH_USER_TO_JOIN_SUCCESS",
  SEARCH_USER_TO_JOIN_START: "SEARCH_USER_TO_JOIN_START",
  SEARCH_USER_TO_JOIN_STOP: "SEARCH_USER_TO_JOIN_STOP",
  CLEAR_SEARCH_USERS_TO_JOIN: "CLEAR_SEARCH_USERS_TO_JOIN",
  ADD_USER_TO_DEPARTMENT_FAIL: "ADD_USER_TO_DEPARTMENT_FAIL",
  ADD_USER_TO_DEPARTMENT_SUCCESS: "ADD_USER_TO_DEPARTMENT_SUCCESS",
  ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS: "ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS",
  ADD_REMOVE_USER_ADMIN_ACCESS_FAIL: "ADD_REMOVE_USER_ADMIN_ACCESS_FAIL",
  ADD_SMARTKEY_WORDS_SUCCESS: "ADD_SMARTKEY_WORDS_SUCCESS",
  ADD_SMARTKEY_WORDS_FAILURE: "ADD_SMARTKEY_WORDS_FAILURE",
  EDIT_SMARTKEY_WORDS_SUCCESS: "EDIT_SMARTKEY_WORDS_SUCCESS",
  EDIT_SMARTKEY_WORDS_FAILURE: "EDIT_SMARTKEY_WORDS_FAILURE",
  SMART_KEYWORD_CONSUMER_SUCCESS: "SMART_KEYWORD_CONSUMER_SUCCESS",
  SMART_KEYWORD_CONSUMER_FAILURE: "SMART_KEYWORD_CONSUMER_FAILURE",
  CREATE_POSITION_START: "CREATE_POSITION_START",
  CREATE_POSITION_STOP: "CREATE_POSITION_STOP",
  CREATE_POSITION_SUCCESS: "CREATE_POSITION_SUCCESS",
  CREATE_POSITION_FAIL: "CREATE_POSITION_FAIL",
  DELETE_POSITION_SUCCESS: "DELETE_POSITION_SUCCESS",
  DELETE_POSITION_FAIL: "DELETE_POSITION_FAIL",
  RENAME_POSITION_SUCCESS: "RENAME_POSITION_SUCCESS",
  RENAME_POSITION_FAIL: "RENAME_POSITION_FAIL",
  GET_POSITION_USERS_SUCCESS: "GET_POSITION_USERS_SUCCESS",
  GET_POSITION_USERS_FAIL: "GET_POSITION_USERS_FAIL",
  SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS:
    "SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS",
  SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE:
    "SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE",
  SALUTATIONS_LIST_SUCCESS: "SALUTATIONS_LIST_SUCCESS",
  SALUTATIONS_LIST_FAIL: "SALUTATIONS_LIST_FAIL",
  SALUTAION_LOADING_START: "SALUTAION_LOADING_START",
  SALUTAION_LOADING_STOP: "SALUTAION_LOADING_STOP",
  ADD_SALUTATION_SUCCESS: "ADD_SALUTATION_SUCCESS",
  ADD_SALUTATION_FAIL: "ADD_SALUTATION_FAIL",
  DELETE_SALUTATION_SUCCESS: "DELETE_SALUTATION_SUCCESS",
  DELETE_SALUTATION_FAIL: "DELETE_SALUTATION_FAIL",
  RENAME_SALUTATION_SUCCESS: "RENAME_SALUTATION_SUCCESS",
  RENAME_SALUTATION_FAIL: "RENAME_SALUTATION_FAIL",
  GET_SALUTATION_USERS_SUCCESS: "GET_SALUTATION_USERS_SUCCESS",
  GET_SALUTATION_USERS_FAIL: "GET_SALUTATION_USERS_FAIL",
  LEADERSHIP_LOADING_STOP: "LEADERSHIP_LOADING_STOP",
  LEADERSHIP_LOADING_START: "LEADERSHIP_LOADING_START",
  LEADERSHIP_LIST_SUCCESS: "LEADERSHIP_LIST_SUCCESS",
  LEADERSHIP_LIST_FAIL: "LEADERSHIP_LIST_FAIL",
  ADD_LEADERSHIP_SUCCESS: "ADD_LEADERSHIP_SUCCESS",
  ADD_LEADERSHIP_FAIL: "ADD_LEADERSHIP_FAIL",
  DELETE_LEADERSHIP_SUCCESS: "DELETE_LEADERSHIP_SUCCESS",
  DELETE_LEADERSHIP_FAIL: "DELETE_LEADERSHIP_FAIL",
  CHANGE_LEADERSHIP_SUCCESS: "CHANGE_LEADERSHIP_SUCCESS",
  CHANGE_LEADERSHIP_FAIL: "CHANGE_LEADERSHIP_FAIL",
  SEARCH_USER_TO_JOIN_LEADERSHIP_SUCCESS:
    "SEARCH_USER_TO_JOIN_LEADERSHIP_SUCCESS",
  SEARCH_USER_TO_JOIN_LEADERSHIP_STOP: "SEARCH_USER_TO_JOIN_LEADERSHIP_STOP",
  SEARCH_USER_TO_JOIN_LEADERSHIP_START: "SEARCH_USER_TO_JOIN_LEADERSHIP_START",
  CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP:
    "CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP",
  SEARCH_PARENTS_TO_CHECKIN_SUCCESS: "SEARCH_PARENTS_TO_CHECKIN_SUCCESS",
  SEARCH_PARENTS_TO_CHECKIN_FAILURE: "SEARCH_PARENTS_TO_CHECKIN_FAILURE",
  CHECKIN_START: "CHECKIN_START",
  CHECKIN_STOP: "CHECKIN_STOP",
  GET_CHILDREN_WITH_CHECKIN_STATUS_SUCCESS:
    "GET_CHILDREN_WITH_CHECKIN_STATUS_SUCCESS",
  GET_CHILDREN_WITH_CHECKIN_STATUS_FAILURE:
    "GET_CHILDREN_WITH_CHECKIN_STATUS_FAILURE",
  CHECKIN_CHILDREN_SUCCESS: "CHECKIN_CHILDREN_SUCCESS",
  CHECKIN_CHILDREN_FAILURE: "CHECKIN_CHILDREN_FAILURE",
  FECTH_COMMUNICATION_GROUP_BY_CATEGORY_SUCCESS:
    "FECTH_COMMUNICATION_GROUP_BY_CATEGORY_SUCCESS",
  FECTH_COMMUNICATION_GROUP_BY_CATEGORY_FAILURE:
    "FECTH_COMMUNICATION_GROUP_BY_CATEGORY_FAILURE",
  GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS:
    "GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS",
  GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE:
    "GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE",
  UPLOAD_MEDIA_SUCCESS: "UPLOAD_MEDIA_SUCCESS",
  UPLOAD_MEDIA_FAILURE: "UPLOAD_MEDIA_FAILURE",
  UPDATE_FOLLOW_UP_SUCCESS: "UPDATE_FOLLOW_UP_SUCCESS",
  UPDATE_FOLLOW_UP_FAILURE: "UPDATE_FOLLOW_UP_FAILURE",
  NEW_FOLLOW_UP_FORM_SUBMISSION_SUCCESS:
    "NEW_FOLLOW_UP_FORM_SUBMISSION_SUCCESS",
  NEW_FOLLOW_UP_FORM_SUBMISSION_FAILURE:
    "NEW_FOLLOW_UP_FORM_SUBMISSION_FAILURE",
  ASSIGNED_FOLLOW_UP_MEMBERS_SUCCESS: "ASSIGNED_FOLLOW_UP_MEMBERS_SUCCESS",
  ASSIGNED_FOLLOW_UP_MEMBERS_FAILURE: "ASSIGNED_FOLLOW_UP_MEMBERS_FAILURE",
  GET_ACTIVITY_LOG_SUCCESS: "GET_ACTIVITY_LOG_SUCCESS",
  GET_ACTIVITY_LOG_FAILURE: "GET_ACTIVITY_LOG_FAILURE",
  ASSIGN_GUEST_SUCCESS: "ASSIGN_GUEST_SUCCESS",
  ASSIGN_GUEST_FAILURE: "ASSIGN_GUEST_FAILURE",
  CREATE_ACTIVITY_LOG_SUCCESS: "CREATE_ACTIVITY_LOG_SUCCESS",
  CREATE_ACTIVITY_LOG_FAILURE: "CREATE_ACTIVITY_LOG_FAILURE",
  ENABLE_USER_ACCOUNT_LAODING_START: "ENABLE_USER_ACCOUNT_LAODING_START",
  ENABLE_USER_ACCOUNT_LAODING_STOP: "ENABLE_USER_ACCOUNT_LAODING_STOP",
  ENABLE_USER_ACCOUNT_SUCCESS: "ENABLE_USER_ACCOUNT_SUCCESS",
  ENABLE_USER_ACCOUNT_FAIL: "ENABLE_USER_ACCOUNT_FAIL",
  SEARCH_SPOUSE_SUCCESS: "SEARCH_SPOUSE_SUCCESS",
  SEARCH_SPOUSE_START: "SEARCH_SPOUSE_START",
  SEARCH_SPOUSE_STOP: "SEARCH_SPOUSE_STOP",
  CLEAR_SEARCHED_SPOUSE: "CLEAR_SEARCHED_SPOUSE",
  UPDATE_SPOUSE_SUCCESS: "UPDATE_SPOUSE_SUCCESS",
  UPDATE_SPOUSE_FAIL: "UPDATE_SPOUSE_FAIL",
  UPDATE_SPOUSE_START: "UPDATE_SPOUSE_START",
  UPDATE_SPOUSE_STOP: "UPDATE_SPOUSE_STOP",
  CHANGE_PWD_SUCCESS: "CHANGE_PWD_SUCCESS",
  CHANGE_PWD_FAIL: "CHANGE_PWD_FAIL",
  CHANGE_PWD_START: "CHANGE_PWD_START",
  CHANGE_PWD_STOP: "CHANGE_PWD_STOP",
  ADMIN_CHILD_MANAGER_SUCCESS: "ADMIN_CHILD_MANAGER_SUCCESS",
  ADMIN_CHILD_MANAGER_FAIL: "ADMIN_CHILD_MANAGER_FAIL",
  ADMIN_CHILD_MANAGER_START: "ADMIN_CHILD_MANAGER_START",
  ADMIN_CHILD_MANAGER_STOP: "ADMIN_CHILD_MANAGER_STOP",
  USER_DEPT_SERV_SUCCESS: "USER_DEPT_SERV_SUCCESS",
  USER_DEPT_SERV_FAIL: "USER_DEPT_SERV_FAIL",
  UPDATE_ACTIVITY_LOG_SUCCESS: "UPDATE_ACTIVITY_LOG_SUCCESS",
  UPDATE_ACTIVITY_LOG_FAILURE: "UPDATE_ACTIVITY_LOG_FAILURE",
  RESET_MEDIA_DATA: "RESET_MEDIA_DATA",
  UCMESSAGE_SUCCESS: "UCMESSAGE_SUCCESS",
  UCMESSAGE_FAILURE: "UCMESSAGE_FAILURE",
  DELETE_USER__START: "DELETE_USER__START",
  DELETE_USER__STOP: "DELETE_USER__STOP",
  DELETE_USER__SUCCESS: "DELETE_USER__SUCCESS",
  DELETE_USER__FAIL: "DELETE_USER__FAIL",
  GET_ORG_SERMONS_LOADING_START: "GET_ORG_SERMONS_LOADING_START",
  GET_ORG_SERMONS_SUCCESS: "GET_ORG_SERMONS_SUCCESS",
  GET_ORG_SERMONS_FAIL: "GET_ORG_SERMONS_FAIL",
  GET_ORG_SERMONS_LOADING_STOP: "GET_ORG_SERMONS_LOADING_STOP",
  CREATE_SERMON_LOADING: "CREATE_SERMON_LOADING",
  CREATE_SERMON_STOP: "CREATE_SERMON_STOP",
  CREATE_SERMON_SUCCESS: "CREATE_SERMON_SUCCESS",
  CREATE_SERMON_FAIL: "CREATE_SERMON_FAIL",
  DELETE_SERMON_START: "DELETE_SERMON_START",
  DELETE_SERMON_SUCCESS: "DELETE_SERMON_SUCCESS",
  DELETE_SERMON_FAIL: "DELETE_SERMON_FAIL",
  DELETE_SERMON_STOP: "DELETE_SERMON_STOP",
  UPDATE_SERMON_SUCCESS: "UPDATE_SERMON_SUCCESS",
  UPDATE_SERMON_FAIL: "UPDATE_SERMON_FAIL",
  UPDATE_SERMON_STOP: "UPDATE_SERMON_STOP",
  UPDATE_SERMON_LOADING: "UPDATE_SERMON_LOADING",
  GET_ORG_SERMONS_LIST_LOADING_STOP: "GET_ORG_SERMONS_LIST_LOADING_STOP",
  GET_ORG_SERMONS_LIST_FAIL: "GET_ORG_SERMONS_LIST_FAIL",
  GET_ORG_SERMONS_LIST_SUCCESS: "GET_ORG_SERMONS_LIST_SUCCESS",
  GET_ORG_SERMONS_LIST_LOADING_START: "GET_ORG_SERMONS_LIST_LOADING_START",
  CREATE_SERMON_GROUP_LOADING: "CREATE_SERMON_GROUP_LOADING",
  CREATE_SERMON_GROUP_STOP: "CREATE_SERMON_GROUP_STOP",
  CREATE_SERMON_GROUP_SUCCESS: "CREATE_SERMON_GROUP_SUCCESS",
  CREATE_SERMON_GROUP_FAIL: "CREATE_SERMON_GROUP_FAIL",
  UPDATE_SERMON_GROUP_SUCCESS: "UPDATE_SERMON_GROUP_SUCCESS",
  UPDATE_SERMON_GROUP_FAIL: "UPDATE_SERMON_GROUP_FAIL",
  UPDATE_SERMON_GROUP_LOADING: "UPDATE_SERMON_GROUP_LOADING",
  UPDATE_SERMON_GROUP_STOP: "UPDATE_SERMON_GROUP_STOP",
  GET_ORG_SERMON_LIST_LOADING_START: "GET_ORG_SERMON_LIST_LOADING_START",
  GET_ORG_SERMON_LIST_SUCCESS: "GET_ORG_SERMON_LIST_SUCCESS",
  GET_ORG_SERMON_LIST_FAIL: "GET_ORG_SERMON_LIST_FAIL",
  GET_ORG_SERMON_LIST_LOADING_STOP: "GET_ORG_SERMON_LIST_LOADING_STOP",
  DELETE_SERMON_GROUP_SUCCESS: "DELETE_SERMON_GROUP_SUCCESS",
  DELETE_SERMON_GROUP_FAIL: "DELETE_SERMON_GROUP_FAIL",
  DELETE_SERMON_GROUP_START: "DELETE_SERMON_GROUP_START",
  DELETE_SERMON_GROUP_STOP: "DELETE_SERMON_GROUP_STOP",
  SEARCH_SERMON_START: "SEARCH_SERMON_START",
  SEARCH_SERMON_SUCCESS: "SEARCH_SERMON_SUCCESS",
  SEARCH_SERMON_FAIL: "SEARCH_SERMON_FAIL",
  SEARCH_SERMON_STOP: "SEARCH_SERMON_STOP",
  ADD_SERMONS_TO_GROUP_LOADING: "ADD_SERMONS_TO_GROUP_LOADING",
  ADD_SERMONS_TO_GROUP_STOP: "ADD_SERMONS_TO_GROUP_STOP",
  ADD_SERMONS_TO_GROUP_SUCCESS: "ADD_SERMONS_TO_GROUP_SUCCESS",
  ADD_SERMONS_TO_GROUP_FAIL: "ADD_SERMONS_TO_GROUP_FAIL",
  DELETE_SERMONS_FROM_GROUP_START: "DELETE_SERMONS_FROM_GROUP_START",
  DELETE_SERMONS_FROM_GROUP_SUCCESS: "DELETE_SERMONS_FROM_GROUP_SUCCESS",
  DELETE_SERMONS_FROM_GROUP_FAIL: "DELETE_SERMONS_FROM_GROUP_FAIL",
  DELETE_SERMONS_FROM_GROUP_STOP: "DELETE_SERMONS_FROM_GROUP_STOP",
  GET_EVENT_SUCCESS: "GET_EVENT_SUCCESS",
  GET_EVENT_FAILURE: "GET_EVENT_FAILURE",
  GET_FOLLOW_UP_FORM_SUCCESS: "GET_FOLLOW_UP_FORM_SUCCESS",
  GET_FOLLOW_UP_FORM_FAILURE: "GET_FOLLOW_UP_FORM_FAILURE",
  CREATE_EVENT_SUCCESS: "CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILURE: "CREATE_EVENT_FAILURE",
  DELETE_SERMONS_SERIES_STOP: "DELETE_SERMONS_SERIES_STOP",
  DELETE_SERMONS_SERIES_FAIL: "DELETE_SERMONS_SERIES_FAIL",
  DELETE_SERMONS_SERIES_SUCCESS: "DELETE_SERMONS_SERIES_SUCCESS",
  DELETE_SERMONS_SERIES_START: "DELETE_SERMONS_SERIES_START",
  UPDATE_ORG_SERMON_SERIES_LOADING_STOP:
    "UPDATE_ORG_SERMON_SERIES_LOADING_STOP",
  UPDATE_ORG_SERMON_SERIES_FAIL: "UPDATE_ORG_SERMON_SERIES_FAIL",
  UPDATE_ORG_SERMON_SERIES_SUCCESS: "UPDATE_ORG_SERMON_SERIES_SUCCESS",
  UPDATE_ORG_SERMON_SERIES_LOADING_START:
    "UPDATE_ORG_SERMON_SERIES_LOADING_START",
  CREATE_ORG_SERMON_SERIES_LOADING_START:
    "CREATE_ORG_SERMON_SERIES_LOADING_START",
  CREATE_ORG_SERMON_SERIES_SUCCESS: "CREATE_ORG_SERMON_SERIES_SUCCESS",
  CREATE_ORG_SERMON_SERIES_FAIL: "CREATE_ORG_SERMON_SERIES_FAIL",
  CREATE_ORG_SERMON_SERIES_LOADING_STOP:
    "CREATE_ORG_SERMON_SERIES_LOADING_STOP",
  GET_ORG_SERMON_SERIES_LOADING_STOP: "GET_ORG_SERMON_SERIES_LOADING_STOP",
  GET_ORG_SERMON_SERIES_FAIL: "GET_ORG_SERMON_SERIES_FAIL",
  GET_ORG_SERMON_SERIES_SUCCESS: "GET_ORG_SERMON_SERIES_SUCCESS",
  GET_ORG_SERMON_SERIES_LOADING_START: "GET_ORG_SERMON_SERIES_LOADING_START",
  UNIFIED_COMMUNICATION_SEND_ENGINE_ANALYZE_SUCCESS:
    "UNIFIED_COMMUNICATION_SEND_ENGINE_ANALYZE_SUCCESS",
  UNIFIED_COMMUNICATION_SEND_ENGINE_ANALYZE_FAILURE:
    "UNIFIED_COMMUNICATION_SEND_ENGINE_ANALYZE_FAILURE",
  START_EVENT: "START_EVENT",
  STOP_EVENT: "STOP_EVENT",
  CREATE_SERMON_PREACHER_SUCCESS: "CREATE_SERMON_PREACHER_SUCCESS",
  CREATE_SERMON_PREACHER_FAIL: "CREATE_SERMON_PREACHER_FAIL",
  CREATE_SERMON_PREACHER_LOADING: "CREATE_SERMON_PREACHER_LOADING",
  CREATE_SERMON_PREACHER_STOP: "CREATE_SERMON_PREACHER_STOP",
  UPDATE_SERMON_PREACHER_SUCCESS: "UPDATE_SERMON_PREACHER_SUCCESS",
  UPDATE_SERMON_PREACHER_FAIL: "UPDATE_SERMON_PREACHER_FAIL",
  UPDATE_SERMON_PREACHER_LOADING: "UPDATE_SERMON_PREACHER_LOADING",
  UPDATE_SERMON_PREACHER_STOP: "UPDATE_SERMON_PREACHER_STOP",
  GET_ORG_SERMON_PREACHER_SUCCESS: "GET_ORG_SERMON_PREACHER_SUCCESS",
  GET_ORG_SERMON_PREACHER_FAIL: "GET_ORG_SERMON_PREACHER_FAIL",
  GET_ORG_SERMON_PREACHER_LOADING_START:
    "GET_ORG_SERMON_PREACHER_LOADING_START",
  GET_ORG_SERMON_PREACHER_LOADING_STOP: "GET_ORG_SERMON_PREACHER_LOADING_STOP",
  DELETE_SERMON_PREACHER_SUCCESS: "DELETE_SERMON_PREACHER_SUCCESS",
  DELETE_SERMON_PREACHER_FAIL: "DELETE_SERMON_PREACHER_FAIL",
  DELETE_SERMON_PREACHER_START: "DELETE_SERMON_PREACHER_START",
  DELETE_SERMON_PREACHER_STOP: "DELETE_SERMON_PREACHER_STOP",
  SEARCH_PREACHER_START: "SEARCH_PREACHER_START",
  SEARCH_PREACHER_SUCCESS: "SEARCH_PREACHER_SUCCESS",
  SEARCH_PREACHER_FAIL: "SEARCH_PREACHER_FAIL",
  SEARCH_PREACHER_STOP: "SEARCH_PREACHER_STOP",
  UNIFIED_COMMUNICATION_SEND_ENGINE_PROCESS_SUCCESS:
    "UNIFIED_COMMUNICATION_SEND_ENGINE_PROCESS_SUCCESS",
  UNIFIED_COMMUNICATION_SEND_ENGINE_PROCESS_FAILURE:
    "UNIFIED_COMMUNICATION_SEND_ENGINE_PROCESS_FAILURE",
  GET_GROUPS_SUCCESS: "GET_GROUPS_SUCCESS",
  GET_GROUPS_FAIL: "GET_GROUPS_FAIL",
  GET_GROUPS_LOADING_START: "GET_GROUPS_LOADING_START",
  GET_GROUPS_STOP: "GET_GROUPS_STOP",
  CREATE_GROUPS_SUCCESS: "CREATE_GROUPS_SUCCESS",
  CREATE_GROUPS_FAIL: "CREATE_GROUPS_FAIL",
  CREATE_GROUPS_LOADING_START: "CREATE_GROUPS_LOADING_START",
  CREATE_GROUPS_STOP: "CREATE_GROUPS_STOP",
  UPDATE_GROUPS_SUCCESS: "UPDATE_GROUPS_SUCCESS",
  UPDATE_GROUPS_FAIL: "UPDATE_GROUPS_FAIL",
  UPDATE_GROUPS_LOADING_START: "UPDATE_GROUPS_LOADING_START",
  UPDATE_GROUPS_STOP: "UPDATE_GROUPS_STOP",
  DELETE_GROUPS_SUCCESS: "DELETE_GROUPS_SUCCESS",
  DELETE_GROUPS_FAIL: "DELETE_GROUPS_FAIL",
  DELETE_GROUPS_LOADING_START: "DELETE_GROUPS_LOADING_START",
  DELETE_GROUPS_STOP: "DELETE_GROUPS_STOP",
  APPROVE_USER_SUCCESS: "APPROVE_USER_SUCCESS",
  APPROVE_USER_FAIL: "APPROVE_USER_FAIL",
  APPROVE_USER_LOADING_START: "APPROVE_USER_LOADING_START",
  APPROVE_USER_STOP: "APPROVE_USER_STOP",
  REMOVE_USER_SUCCESS: "REMOVE_USER_SUCCESS",
  REMOVE_USER_FAIL: "REMOVE_USER_FAIL",
  REMOVE_USER_LOADING_START: "REMOVE_USER_LOADING_START",
  REMOVE_USER_STOP: "REMOVE_USER_STOP",
  GET_GROUPS_USERS_LOADING_START: "GET_GROUPS_USERS_LOADING_START",
  GET_GROUPS_USERS_SUCCESS: "GET_GROUPS_USERS_SUCCESS",
  GET_GROUPS_USERS_FAIL: "GET_GROUPS_USERS_FAIL",
  GET_GROUPS_USERS_STOP: "GET_GROUPS_USERS_STOP",
  RESET_FETCH_CATEGORY_DATA: "RESET_FETCH_CATEGORY_DATA",
  LIST_NOTE_OF_CHILD_SUCCESS: "LIST_NOTE_OF_CHILD_SUCCESS",
  LIST_NOTE_OF_CHILD_FAILURE: "LIST_NOTE_OF_CHILD_FAILURE",
  ADD_CHILD_NOTE_SUCCESS: "ADD_CHILD_NOTE_SUCCESS",
  ADD_CHILD_NOTE_FAILURE: "ADD_CHILD_NOTE_FAILURE",
  GET_COMMENT_FOR_A_CHILD_SUCCESS: "GET_COMMENT_FOR_A_CHILD_SUCCESS",
  GET_COMMENT_FOR_A_CHILD_FAILURE: "GET_COMMENT_FOR_A_CHILD_FAILURE",
  COMMENT_START: "COMMENT_START",
  COMMENT_STOP: "COMMENT_STOP",
  ADD_COMMENT_TO_CHILD_NOTE_SUCCESS: "ADD_COMMENT_TO_CHILD_NOTE_SUCCESS",
  ADD_COMMENT_TO_CHILD_NOTE_FAILURE: "ADD_COMMENT_TO_CHILD_NOTE_FAILURE",
  EDIT_CHILD_NOTE_SUCCESS: "EDIT_CHILD_NOTE_SUCCESS",
  EDIT_CHILD_NOTE_FAILURE: "EDIT_CHILD_NOTE_FAILURE",
  DELETE_CHILD_NOTE_SUCCESS: "DELETE_CHILD_NOTE_SUCCESS",
  DELETE_CHILD_NOTE_FAILURE: "DELETE_CHILD_NOTE_FAILURE",
  EDIT_COMMENT_TO_CHILD_NOTE_SUCCESS: "EDIT_COMMENT_TO_CHILD_NOTE_SUCCESS",
  EDIT_COMMENT_TO_CHILD_NOTE_FAILURE: "EDIT_COMMENT_TO_CHILD_NOTE_FAILURE",
  DELETE_COMMENT_TO_CHILD_NOTE_FAILURE: "DELETE_COMMENT_TO_CHILD_NOTE_FAILURE",
  DELETE_COMMENT_TO_CHILD_NOTE_SUCCESS: "DELETE_COMMENT_TO_CHILD_NOTE_SUCCESS",
  GET_ORG_SERMONS_LIST_CLEAR: "GET_ORG_SERMONS_LIST_CLEAR",
  MERGE_USER_LOADING_START: "MERGE_USER_LOADING_START",
  MERGE_USER_SUCCESS: "MERGE_USER_SUCCESS",
  MERGE_USER_FAIL: "MERGE_USER_FAIL",
  MERGE_USER_LOADING_STOP: "MERGE_USER_LOADING_STOP",
  GET_ORG_SHOP_START: "GET_ORG_SHOP_START",
  GET_ORG_SHOP_SUCCESS: "GET_ORG_SHOP_SUCCESS",
  GET_ORG_SHOP_FAIL: "GET_ORG_SHOP_FAIL",
  GET_ORG_SHOP_STOP: "GET_ORG_SHOP_STOP",
  LIST_CHECKIN_OF_CHILD_SUCCESS: "LIST_CHECKIN_OF_CHILD_SUCCESS",
  LIST_CHECKIN_OF_CHILD_FAILURE: "LIST_CHECKIN_OF_CHILD_FAILURE",
  CHILDREN_CHECKIN_LISTING_SUCCESS: "CHILDREN_CHECKIN_LISTING_SUCCESS",
  CHILDREN_CHECKIN_LISTING_FAILURE: "CHILDREN_CHECKIN_LISTING_FAILURE",
  UPDATE_SHOP_START: "UPDATE_SHOP_START",
  UPDATE_SHOP_SUCCESS: "UPDATE_SHOP_SUCCESS",
  UPDATE_SHOP_FAIL: "UPDATE_SHOP_FAIL",
  UPDATE_SHOP_STOP: "UPDATE_SHOP_STOP",
  CHILDREN_CHECKIN_REPORTING_SUCCESS: "CHILDREN_CHECKIN_REPORTING_SUCCESS",
  CHILDREN_CHECKIN_REPORTING_FAILURE: "CHILDREN_CHECKIN_REPORTING_FAILURE",
  CHILDREN_CHECKIN_REPORTING_RESET: "CHILDREN_CHECKIN_REPORTING_RESET",
  GET_ORG_TAG_START: "GET_ORG_TAG_START",
  GET_ORG_TAG_SUCCESS: "GET_ORG_TAG_SUCCESS",
  GET_ORG_TAG_FAIL: "GET_ORG_TAG_FAIL",
  GET_ORG_TAG_STOP: "GET_ORG_TAG_STOP",
  CREATE_SHOP_TAG_START: "CREATE_SHOP_TAG_START",
  CREATE_SHOP_TAG_STOP: "CREATE_SHOP_TAG_STOP",
  UPDATE_SHOP_TAG_START: "UPDATE_SHOP_TAG_START",
  UPDATE_SHOP_TAG_STOP: "UPDATE_SHOP_TAG_STOP",
  DELETE_SHOP_TAG_START: "DELETE_SHOP_TAG_START",
  DELETE_SHOP_TAG_STOP: "DELETE_SHOP_TAG_STOP",
  STOP_ORG_COMM_LOADING: "STOP_ORG_COMM_LOADING",
  START_ORG_COMM_LOADING: "START_ORG_COMM_LOADING",
  FOLLOW_UP_MAIN_DASHBOARD_SUCCESS: "FOLLOW_UP_MAIN_DASHBOARD_SUCCESS",
  FOLLOW_UP_MAIN_DASHBOARD_FAILURE: "FOLLOW_UP_MAIN_DASHBOARD_FAILURE",
  GET_ORG_DISCOUNT_START: "GET_ORG_DISCOUNT_START",
  GET_ORG_DISCOUNT_SUCCESS: "GET_ORG_DISCOUNT_SUCCESS",
  GET_ORG_DISCOUNT_FAIL: "GET_ORG_DISCOUNT_FAIL",
  GET_ORG_DISCOUNT_STOP: "GET_ORG_DISCOUNT_STOP",
  CREATE_DISCOUNT_START: "CREATE_DISCOUNT_START",
  CREATE_DISCOUNT_STOP: "CREATE_DISCOUNT_STOP",
  UPDATE_DISCOUNT_START: "UPDATE_DISCOUNT_START",
  UPDATE_DISCOUNT_STOP: "UPDATE_DISCOUNT_STOP",
  DELETE_DISCOUNT_START: "DELETE_DISCOUNT_START",
  DELETE_DISCOUNT_STOP: "DELETE_DISCOUNT_STOP",
  SEARCH_DISCOUNT_START: "SEARCH_DISCOUNT_START",
  SEARCH_DISCOUNT_SUCCESS: "SEARCH_DISCOUNT_SUCCESS",
  SEARCH_DISCOUNT_FAIL: "SEARCH_DISCOUNT_FAIL",
  SEARCH_DISCOUNT_STOP: "SEARCH_DISCOUNT_STOP",
  GET_ORG_PRODUCTS_START: "GET_ORG_PRODUCTS_START",
  GET_ORG_PRODUCTS_SUCCESS: "GET_ORG_PRODUCTS_SUCCESS",
  GET_ORG_PRODUCTS_FAIL: "GET_ORG_PRODUCTS_FAIL",
  GET_ORG_PRODUCTS_STOP: "GET_ORG_PRODUCTS_STOP",
  CREATE_PRODUCT_START: "CREATE_PRODUCT_START",
  CREATE_PRODUCT_STOP: "CREATE_PRODUCT_STOP",
  UPDATE_PRODUCT_START: "UPDATE_PRODUCT_START",
  DELETE_PRODUCT_START: "DELETE_PRODUCT_START",
  DELETE_PRODUCT_STOP: "DELETE_PRODUCT_STOP",
  GET_ORG_ADDRESSES_SUCCESS: "GET_ORG_ADDRESSES_SUCCESS",
  GET_ORG_ADDRESSES_FAIL: "GET_ORG_ADDRESSES_FAIL",
  GET_ORG_ADDRESSES_START: "GET_ORG_ADDRESSES_START",
  GET_ORG_ADDRESSES_STOP: "GET_ORG_ADDRESSES_STOP",
  CREATE_ORG_ADDRESSES_START: "CREATE_ORG_ADDRESSES_START",
  CREATE_ORG_ADDRESSES_STOP: "CREATE_ORG_ADDRESSES_STOP",
  UPDATE_ORG_ADDRESSES_START: "UPDATE_ORG_ADDRESSES_START",
  UPDATE_ORG_ADDRESSES_STOP: "UPDATE_ORG_ADDRESSES_STOP",
  DELETE_ORG_ADDRESSES_START: "DELETE_ORG_ADDRESSES_START",
  DELETE_ORG_ADDRESSES_STOP: "DELETE_ORG_ADDRESSES_STOP",
  SEARCH_ADDRESS_SUCCESS: "SEARCH_ADDRESS_SUCCESS",
  SEARCH_ADDRESS_FAIL: "SEARCH_ADDRESS_FAIL",
  SEARCH_ADDRESS_START: "SEARCH_ADDRESS_START",
  SEARCH_ADDRESS_STOP: "SEARCH_ADDRESS_STOP",
  UPDATE_PRODUCT_STOP: "UPDATE_PRODUCT_STOP",
  SEARCH_ADDRESS_EVENT_SUCCESS: "SEARCH_ADDRESS_EVENT_SUCCESS",
  SEARCH_ADDRESS_FAILURE: "SEARCH_ADDRESS_FAILURE",
  GET_ORGANISATION_ADDRESS_SUCCESS: "GET_ORGANISATION_ADDRESS_SUCCESS",
  GET_ORGANISATION_ADDRESS_FAILURE: "GET_ORGANISATION_ADDRESS_FAILURE",
  CLOSED_OUT_FORMS_SUCCESS: "CLOSED_OUT_FORMS_SUCCESS",
  CLOSED_OUT_FORMS_FAILURE: "CLOSED_OUT_FORMS_FAILURE",
  TICKET_LOADING_START: "TICKET_LOADING_START",
  TICKET_INFO_SUCCESS: "TICKET_INFO_SUCCESS",
  TICKET_LOADING_STOP: "TICKET_LOADING_STOP",
  TICKET_LISTING_SUCCESS: "TICKET_LISTING_SUCCESS",
  DONOTCALLTEXT_LOADING_START: "DONOTCALLTEXT_LOADING_START",
  DONOTCALLTEXT_LOADING_STOP: "DONOTCALLTEXT_LOADING_STOP",
  DONOTCALLTEXT_LISTING_SUCCESS: "DONOTCALLTEXT_LISTING_SUCCESS",
  DONOTCALLTEXT_SEARCH_SUCCESS: "DONOTCALLTEXT_SEARCH_SUCCESS",
  DONOTCALLTEXT_SEARCH_LOADING_START: "DONOTCALLTEXT_SEARCH_LOADING_START",
  DONOTCALLTEXT_SEARCH_LOADING_STOP: "DONOTCALLTEXT_SEARCH_LOADING_STOP",
  ACCESS_SECURITY_LOADING_START: "ACCESS_SECURITY_LOADING_START",
  ACCESS_SECURITY_LOADING_STOP: "ACCESS_SECURITY_LOADING_STOP",
  ACCESS_SECURITY_LISTING_SUCCESS: "ACCESS_SECURITY_LISTING_SUCCESS",
  ACCESS_SECURITY_SEARCH_LOADING_START: "ACCESS_SECURITY_SEARCH_LOADING_START",
  ACCESS_SECURITY_SEARCH_LOADING_STOP: "ACCESS_SECURITY_SEARCH_LOADING_STOP",
  ACCESS_SECURITY_SEARCH_SUCCESS: "ACCESS_SECURITY_SEARCH_SUCCESS",
  GET_DISTRICTS_DATA_SUCCESS: "GET_DISTRICTS_DATA_SUCCESS",
  GET_DISTRICTS_DATA_FAILURE: "GET_DISTRICTS_DATA_FAILURE",
  GET_ZONES_DATA_SUCCESS: "GET_ZONES_DATA_SUCCESS",
  GET_ZONES_DATA_FAILURE: "GET_ZONES_DATA_FAILURE",
  GET_HOUSEFELLOWSHIP_DATA_SUCCESS: "GET_HOUSEFELLOWSHIP_DATA_SUCCESS",
  GET_HOUSEFELLOWSHIP_DATA_FAILURE: "GET_HOUSEFELLOWSHIP_DATA_FAILURE",
  CELEBRATION_LOADING_START: "CELEBRATION_LOADING_START",
  CELEBRATION_LOADING_STOP: "CELEBRATION_LOADING_STOP",
  CELEBRATION_LISTING_SUCCESS: "CELEBRATION_LISTING_SUCCESS",
  BIRTHDAY_WEDDINGS_LISTING_SUCCESS: "BIRTHDAY_WEDDINGS_LISTING_SUCCESS",
  BIRTHDAY_WEDDINGS_LOADING_START: "BIRTHDAY_WEDDINGS_LOADING_START",
  BIRTHDAY_WEDDINGS_LOADING_STOP: "BIRTHDAY_WEDDINGS_LOADING_STOP",
  IMPORT_PEOPLE_START: "IMPORT_PEOPLE_START",
  IMPORT_PEOPLE_STOP: "IMPORT_PEOPLE_STOP",
  FOLLOWUP_START_LOADING: "FOLLOWUP_START_LOADING",
  FOLLOWUP_STOP_LOADING: "FOLLOWUP_STOP_LOADING",
  FOLLOWUP_REPORTING_SUCCESS: "FOLLOWUP_REPORTING_SUCCESS",
  FOLLOWUP_REPORTING_FAILURE: "FOLLOWUP_REPORTING_FAILURE",
  EXPORT_STOP: "EXPORT_STOP",
  EXPORT_SUCCESS: "EXPORT_SUCCESS",
  EXPORT_THESE_SUCCESS: "EXPORT_THESE_SUCCESS",
  EXPORT_START: "EXPORT_START",
  UPDATE_USER_PHONE_NUMBER_START: "UPDATE_USER_PHONE_NUMBER_START",
  UPDATE_USER_PHONE_NUMBER_STOP: "UPDATE_USER_PHONE_NUMBER_STOP",
  CHANGE_GROUP_BANNER_SUCCESS: "CHANGE_GROUP_BANNER_SUCCESS",
  CHANGE_GROUP_BANNER_FAILURE: "CHANGE_GROUP_BANNER_FAILURE",
  MOBILE_APP_CUSTOMIZATION_SUCCESS: "MOBILE_APP_CUSTOMIZATION_SUCCESS",
  MOBILE_APP_CUSTOMIZATION_FAILURE: "MOBILE_APP_CUSTOMIZATION_FAILURE",
  MOBILE_APP_CUSTOMIZATION_START: "MOBILE_APP_CUSTOMIZATION_START",
  MOBILE_APP_CUSTOMIZATION_STOP: "MOBILE_APP_CUSTOMIZATION_STOP",
  USER_CONTRIBUTION_SUCCESS: "USER_CONTRIBUTION_SUCCESS",
  USER_CONTRIBUTION_START: "USER_CONTRIBUTION_START",
  USER_CONTRIBUTION_STOP: "USER_CONTRIBUTION_STOP",
  USER_CONTRIBUTION_STATEMENT_START: "USER_CONTRIBUTION_STATEMENT_START",
  USER_CONTRIBUTION_STATEMENT_SUCCESS: "USER_CONTRIBUTION_STATEMENT_SUCCESS",
  USER_CONTRIBUTION_STATEMENT_STOP: "USER_CONTRIBUTION_STATEMENT_STOP",
  FOLLOWUP_REPORTING_DATA_SUCCESS: "FOLLOWUP_REPORTING_DATA_SUCCESS",
  FOLLOWUP_REPORTING_DATA_FAILURE: "FOLLOWUP_REPORTING_DATA_FAILURE",
  ADD_MANUAL_CONTRIBUTION_START: "ADD_MANUAL_CONTRIBUTION_START",
  ADD_MANUAL_CONTRIBUTION_STOP: "ADD_MANUAL_CONTRIBUTION_STOP",
  GET_QR_CODES_ADMIN_START: "GET_QR_CODES_ADMIN_START",
  GET_QR_CODES_ADMIN_SUCCESS: "GET_QR_CODES_ADMIN_SUCCESS",
  GET_QR_CODES_ADMIN_STOP: "GET_QR_CODES_ADMIN_STOP",
  GET_MESSAGE_CREDITS_LISTING_START: "GET_MESSAGE_CREDITS_LISTING_START",
  GET_MESSAGE_CREDITS_LISTING_SUCCESS: "GET_MESSAGE_CREDITS_LISTING_SUCCESS",
  GET_MESSAGE_CREDITS_LISTING_STOP: "GET_MESSAGE_CREDITS_LISTING_STOP",
  ORG_PAYMENT_METHOD_SUCCESS: "ORG_PAYMENT_METHOD_SUCCESS",
  ORG_PAYMENT_METHOD_START: "ORG_PAYMENT_METHOD_START",
  ORG_PAYMENT_METHOD_STOP: "ORG_PAYMENT_METHOD_STOP",
  BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_START:
    "BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_START",
  BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_STOP:
    "BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_STOP",
  BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_START:
    "BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_START",
  BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_STOP:
    "BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_STOP",
  MSG_CREDIT_PURCHASED_HISTORY_START: "MSG_CREDIT_PURCHASED_HISTORY_START",
  MSG_CREDIT_PURCHASED_HISTORY_STOP: "MSG_CREDIT_PURCHASED_HISTORY_STOP",
  MSG_CREDIT_PURCHASED_HISTORY_SUCCESS: "MSG_CREDIT_PURCHASED_HISTORY_SUCCESS",
  ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_START:
    "ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_START",
  ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP:
    "ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP",
  CLEAR_TABLE_PEOPLE: "CLEAR_TABLE_PEOPLE",
  RESET_TO_DEFAULT_ADMIN_TABLE_COL_VIEW_START:
    "RESET_TO_DEFAULT_ADMIN_TABLE_COL_VIEW_START",
  RESET_TO_DEFAULT_ADMIN_TABLE_COL_VIEW_STOP:
    "RESET_TO_DEFAULT_ADMIN_TABLE_COL_VIEW_STOP",
  SEARCH_USER_LOADING_START: "SEARCH_USER_LOADING_START",
  SEARCH_USER_LOADING_STOP: "SEARCH_USER_LOADING_STOP",
  PEOPLE_LOADING_STOP: "PEOPLE_LOADING_STOP",
  PEOPLE_LOADING_START: "PEOPLE_LOADING_START",
  IMPORT_CONTRIBUTION_COLUMNS: "IMPORT_CONTRIBUTION_COLUMNS",
  IMPORT_CONTRIBUTION_COLUMNS_SUCCESS: "IMPORT_CONTRIBUTION_COLUMNS_SUCCESS",
  IMPORT_CONTRIBUTION_COLUMNS_FAILURE: "IMPORT_CONTRIBUTION_COLUMNS_FAILURE",
  IMPORT_CONTRIBUTION_ANALYZE: "IMPORT_CONTRIBUTION_ANALYZE",
  IMPORT_CONTRIBUTION_ANALYZE_SUCCESS: "IMPORT_CONTRIBUTION_ANALYZE_SUCCESS",
  IMPORT_CONTRIBUTION_ANALYZE_FAILURE: "IMPORT_CONTRIBUTION_ANALYZE_FAILURE",
  IMPORT_CONTRIBUTION_PROCESS: "IMPORT_CONTRIBUTION_PROCESS",
  IMPORT_CONTRIBUTION_PROCESS_FAILURE: "IMPORT_CONTRIBUTION_PROCESS_FAILURE",
  IMPORT_CONTRIBUTION_PROCESS_SUCCESS: "IMPORT_CONTRIBUTION_PROCESS_SUCCESS",
  LIVESTREAM_STATUS_SUCCESS: "LIVESTREAM_STATUS_SUCCESS",
  LIVESTREAM_STATUS_FAILURE: "LIVESTREAM_STATUS_FAILURE",
  RESET_LIVESTREAM_STATUS_SUCCESS: "RESET_LIVESTREAM_STATUS_SUCCESS",
  RESETLIVESTREAM_STATUS_FAILURE: "RESETLIVESTREAM_STATUS_FAILURE",
  EVENT_REGISTRATION_SUCCESS: "EVENT_REGISTRATION_SUCCESS",
  EVENT_REGISTRATION_FAILURE: "EVENT_REGISTRATION_FAILURE",
  EVENT_LIST_FAILURE: "EVENT_LIST_FAILURE",
  EVENT_LIST_SUCCESS: "EVENT_LIST_SUCCESS",
  EVENT_REGISTRATION_START: "EVENT_REGISTRATION_START",
  EVENT_REGISTRATION_STOP: "EVENT_REGISTRATION_STOP",
  UPGRADE_START: "UPGRADE_START",
  UPGRADE_STOP: "UPGRADE_STOP",
  UPGRADE_SUCCESS: "UPGRADE_SUCCESS",
  SYSTEM_TOOLTIP_MODAL_COMMENTS_SUCCESS:
    "SYSTEM_TOOLTIP_MODAL_COMMENTS_SUCCESS",
  SYSTEM_TOOLTIP_MODAL_COMMENTS_FAILURE:
    "SYSTEM_TOOLTIP_MODAL_COMMENTS_FAILURE",
  UPDATE_SERMON_PLAYLIST_LOADING: "UPDATE_SERMON_PLAYLIST_LOADING",
  UPDATE_SERMON_PLAYLIST_STOP: "UPDATE_SERMON_PLAYLIST_STOP",
  GET_ORG_MEDIA_SUCCESS: "GET_ORG_MEDIA_SUCCESS",
  GET_ORG_MEDIA_FAIL: "GET_ORG_MEDIA_FAIL",
  GET_ORG_MEDIA_LOADING_START: "GET_ORG_MEDIA_LOADING_START",
  GET_ORG_MEDIA_LOADING_STOP: "GET_ORG_MEDIA_LOADING_STOP",
  CREATE_MEDIA_SUCCESS: "CREATE_MEDIA_SUCCESS",
  CREATE_MEDIA_FAIL: "CREATE_MEDIA_FAIL",
  CREATE_MEDIA_LOADING: "CREATE_MEDIA_LOADING",
  CREATE_MEDIA_STOP: "CREATE_MEDIA_STOP",
  DELETE_MEDIA_SUCCESS: "DELETE_MEDIA_SUCCESS",
  DELETE_MEDIA_FAIL: "DELETE_MEDIA_FAIL",
  DELETE_MEDIA_START: "DELETE_MEDIA_START",
  DELETE_MEDIA_STOP: "DELETE_MEDIA_STOP",
  UPDATE_MEDIA_SUCCESS: "UPDATE_MEDIA_SUCCESS",
  UPDATE_MEDIA_FAIL: "UPDATE_MEDIA_FAIL",
  UPDATE_MEDIA_LOADING: "UPDATE_MEDIA_LOADING",
  UPDATE_MEDIA_STOP: "UPDATE_MEDIA_STOP",
  GET_ORG_MEDIA_LIST_SUCCESS: "GET_ORG_MEDIA_LIST_SUCCESS",
  GET_ORG_MEDIA_LIST_FAIL: "GET_ORG_MEDIA_LIST_FAIL",
  GET_ORG_MEDIA_LIST_LOADING_START: "GET_ORG_MEDIA_LIST_LOADING_START",
  GET_ORG_MEDIA_LIST_LOADING_STOP: "GET_ORG_MEDIA_LIST_LOADING_STOP",
  CREATE_MEDIA_GROUP_SUCCESS: "CREATE_MEDIA_GROUP_SUCCESS",
  CREATE_MEDIA_GROUP_FAIL: "CREATE_MEDIA_GROUP_FAIL",
  CREATE_MEDIA_GROUP_LOADING: "CREATE_MEDIA_GROUP_LOADING",
  CREATE_MEDIA_GROUP_STOP: "CREATE_MEDIA_GROUP_STOP",
  UPDATE_MEDIA_GROUP_SUCCESS: "UPDATE_MEDIA_GROUP_SUCCESS",
  UPDATE_MEDIA_GROUP_FAIL: "UPDATE_MEDIA_GROUP_FAIL",
  UPDATE_MEDIA_GROUP_LOADING: "UPDATE_MEDIA_GROUP_LOADING",
  UPDATE_MEDIA_GROUP_STOP: "UPDATE_MEDIA_GROUP_STOP",
  GET_ORG_MEDIAS_LIST_LOADING_START: "GET_ORG_MEDIAS_LIST_LOADING_START",
  GET_ORG_MEDIAS_LIST_SUCCESS: "GET_ORG_MEDIAS_LIST_SUCCESS",
  GET_ORG_MEDIAS_LIST_FAIL: "GET_ORG_MEDIAS_LIST_FAIL",
  GET_ORG_MEDIAS_LIST_LOADING_STOP: "GET_ORG_MEDIAS_LIST_LOADING_STOP",
  GET_ORG_MEDIA_LIST_CLEAR: "GET_ORG_MEDIA_LIST_CLEAR",
  DELETE_MEDIA_GROUP_START: "DELETE_MEDIA_GROUP_START",
  DELETE_MEDIA_GROUP_SUCCESS: "DELETE_MEDIA_GROUP_SUCCESS",
  DELETE_MEDIA_GROUP_FAIL: "DELETE_MEDIA_GROUP_FAIL",
  DELETE_MEDIA_GROUP_STOP: "DELETE_MEDIA_GROUP_STOP",
  SEARCH_MEDIA_SUCCESS: "SEARCH_MEDIA_SUCCESS",
  SEARCH_MEDIA_START: "SEARCH_MEDIA_START",
  SEARCH_MEDIA_FAIL: "SEARCH_MEDIA_FAIL",
  SEARCH_MEDIA_STOP: "SEARCH_MEDIA_STOP",
  ADD_MEDIA_TO_GROUP_SUCCESS: "ADD_MEDIA_TO_GROUP_SUCCESS",
  ADD_MEDIA_TO_GROUP_FAIL: "ADD_MEDIA_TO_GROUP_FAIL",
  ADD_MEDIA_TO_GROUP_LOADING: "ADD_MEDIA_TO_GROUP_LOADING",
  ADD_MEDIA_TO_GROUP_STOP: "ADD_MEDIA_TO_GROUP_STOP",
  DELETE_MEDIA_FROM_GROUP_SUCCESS: "DELETE_MEDIA_FROM_GROUP_SUCCESS",
  DELETE_MEDIA_FROM_GROUP_FAIL: "DELETE_MEDIA_FROM_GROUP_FAIL",
  DELETE_MEDIA_FROM_GROUP_START: "DELETE_MEDIA_FROM_GROUP_START",
  DELETE_MEDIA_FROM_GROUP_STOP: "DELETE_MEDIA_FROM_GROUP_STOP",
  UPDATE_MEDIA_PLAYLIST_LOADING: "UPDATE_MEDIA_PLAYLIST_LOADING",
  UPDATE_MEDIA_PLAYLIST_STOP: "UPDATE_MEDIA_PLAYLIST_STOP",
  GET_ORG_MEDIA_SERIES_LOADING_START: "GET_ORG_MEDIA_SERIES_LOADING_START",
  GET_ORG_MEDIA_SERIES_SUCCESS: "GET_ORG_MEDIA_SERIES_SUCCESS",
  GET_ORG_MEDIA_SERIES_FAIL: "GET_ORG_MEDIA_SERIES_FAIL",
  GET_ORG_MEDIA_SERIES_LOADING_STOP: "GET_ORG_MEDIA_SERIES_LOADING_STOP",
  CREATE_ORG_MEDIA_SERIES_LOADING_START:
    "CREATE_ORG_MEDIA_SERIES_LOADING_START",
  CREATE_ORG_MEDIA_SERIES_SUCCESS: "CREATE_ORG_MEDIA_SERIES_SUCCESS",
  CREATE_ORG_MEDIA_SERIES_FAIL: "CREATE_ORG_MEDIA_SERIES_FAIL",
  CREATE_ORG_MEDIA_SERIES_LOADING_STOP: "CREATE_ORG_MEDIA_SERIES_LOADING_STOP",
  UPDATE_ORG_MEDIA_SERIES_LOADING_START:
    "UPDATE_ORG_MEDIA_SERIES_LOADING_START",
  UPDATE_ORG_MEDIA_SERIES_SUCCESS: "UPDATE_ORG_MEDIA_SERIES_SUCCESS",
  UPDATE_ORG_MEDIA_SERIES_FAIL: "UPDATE_ORG_MEDIA_SERIES_FAIL",
  UPDATE_ORG_MEDIA_SERIES_LOADING_STOP: "UPDATE_ORG_MEDIA_SERIES_LOADING_STOP",
  DELETE_MEDIA_SERIES_START: "DELETE_MEDIA_SERIES_START",
  DELETE_MEDIA_SERIES_SUCCESS: "DELETE_MEDIA_SERIES_SUCCESS",
  DELETE_MEDIA_SERIES_FAIL: "DELETE_MEDIA_SERIES_FAIL",
  DELETE_MEDIA_SERIES_STOP: "DELETE_MEDIA_SERIES_STOP",
  CREATE_MEDIA_SPEAKER_LOADING: "CREATE_MEDIA_SPEAKER_LOADING",
  CREATE_MEDIA_SPEAKER_STOP: "CREATE_MEDIA_SPEAKER_STOP",
  CREATE_MEDIA_SPEAKER_SUCCESS: "CREATE_MEDIA_SPEAKER_SUCCESS",
  CREATE_MEDIA_SPEAKER_FAIL: "CREATE_MEDIA_SPEAKER_FAIL",
  UPDATE_MEDIA_SPEAKER_LOADING: "UPDATE_MEDIA_SPEAKER_LOADING",
  UPDATE_MEDIA_SPEAKER_STOP: "UPDATE_MEDIA_SPEAKER_STOP",
  UPDATE_MEDIA_SPEAKER_SUCCESS: "UPDATE_MEDIA_SPEAKER_SUCCESS",
  UPDATE_MEDIA_SPEAKER_FAIL: "UPDATE_MEDIA_SPEAKER_FAIL",
  GET_ORG_MEDIA_SPEAKER_LOADING_START: "GET_ORG_MEDIA_SPEAKER_LOADING_START",
  GET_ORG_MEDIA_SPEAKER_SUCCESS: "GET_ORG_MEDIA_SPEAKER_SUCCESS",
  GET_ORG_MEDIA_SPEAKER_FAIL: "GET_ORG_MEDIA_SPEAKER_FAIL",
  GET_ORG_MEDIA_SPEAKER_LOADING_STOP: "GET_ORG_MEDIA_SPEAKER_LOADING_STOP",
  DELETE_MEDIA_SPEAKER_START: "DELETE_MEDIA_SPEAKER_START",
  DELETE_MEDIA_SPEAKER_SUCCESS: "DELETE_MEDIA_SPEAKER_SUCCESS",
  DELETE_MEDIA_SPEAKER_FAIL: "DELETE_MEDIA_SPEAKER_FAIL",
  DELETE_MEDIA_SPEAKER_STOP: "DELETE_MEDIA_SPEAKER_STOP",
  SEARCH_SPEAKER_START: "SEARCH_SPEAKER_START",
  SEARCH_SPEAKER_SUCCESS: "SEARCH_SPEAKER_SUCCESS",
  SEARCH_SPEAKER_FAIL: "SEARCH_SPEAKER_FAIL",
  SEARCH_SPEAKER_STOP: "SEARCH_SPEAKER_STOP",
  GET_INVOICE_LISTING_START: "GET_INVOICE_LISTING_START",
  GET_INVOICE_LISTING_STOP: "GET_INVOICE_LISTING_STOP",
  GET_INVOICE_LISTING_SUCCESS: "GET_INVOICE_LISTING_SUCCESS",
  GET_ORG_CARD_CHECK_STORED_TOKENS_START:
    "GET_ORG_CARD_CHECK_STORED_TOKENS_START",
  GET_ORG_CARD_CHECK_STORED_TOKENS_STOP:
    "GET_ORG_CARD_CHECK_STORED_TOKENS_STOP",
  GET_ORG_CARD_CHECK_STORED_TOKENS_SUCCESS:
    "GET_ORG_CARD_CHECK_STORED_TOKENS_SUCCESS",
  DELETE_CARD_CHECK_START: "DELETE_CARD_CHECK_START",
  DELETE_CARD_CHECK_STOP: "DELETE_CARD_CHECK_STOP",
  PAY_WITH_EXISTING_START: "PAY_WITH_EXISTING_START",
  PAY_WITH_EXISTING_STOP: "PAY_WITH_EXISTING_STOP",
  PAY_WITH_NEW_CARD_CHECK_START: "PAY_WITH_NEW_CARD_CHECK_START",
  PAY_WITH_NEW_CARD_CHECK_STOP: "PAY_WITH_NEW_CARD_CHECK_STOP",
  GET_INVOICE_PDF_START: "GET_INVOICE_PDF_START",
  GET_INVOICE_PDF_STOP: "GET_INVOICE_PDF_STOP",
  FOLLOWUP_DYNAMIC_FORM_SUCCESS: "FOLLOWUP_DYNAMIC_FORM_SUCCESS",
  FOLLOWUP_DYNAMIC_FORM_FAILURE: "FOLLOWUP_DYNAMIC_FORM_FAILURE",
  UPGRADE_DOWNGRADE_SERVICE_START: "UPGRADE_DOWNGRADE_SERVICE_START",
  UPGRADE_DOWNGRADE_SERVICE_STOP: "UPGRADE_DOWNGRADE_SERVICE_STOP",
  CANCEL_SERVICE_INFO_START: "CANCEL_SERVICE_INFO_START",
  CANCEL_SERVICE_INFO_STOP: "CANCEL_SERVICE_INFO_STOP",
  CANCEL_SERVICE_INFO_SUCCESS: "CANCEL_SERVICE_INFO_SUCCESS",
  CANCEL_SERVICE_REQUEST_START: "CANCEL_SERVICE_REQUEST_START",
  CANCEL_SERVICE_REQUEST_STOP: "CANCEL_SERVICE_REQUEST_STOP",
  FREE_TRIAL_EXT_INFO_START: "FREE_TRIAL_EXT_INFO_START",
  FREE_TRIAL_EXT_INFO_STOP: "FREE_TRIAL_EXT_INFO_STOP",
  FREE_TRIAL_EXT_INFO_SUCCESS: "FREE_TRIAL_EXT_INFO_SUCCESS",
  FREE_TRIAL_EXT_REQUEST_START: "FREE_TRIAL_EXT_REQUEST_START",
  FREE_TRIAL_EXT_REQUEST_STOP: "FREE_TRIAL_EXT_REQUEST_STOP",
  LIST_AUTOMATED_GREETINGS_START: "LIST_AUTOMATED_GREETINGS_START",
  LIST_AUTOMATED_GREETINGS_SUCCESS: "LIST_AUTOMATED_GREETINGS_SUCCESS",
  LIST_AUTOMATED_GREETINGS_STOP: "LIST_AUTOMATED_GREETINGS_STOP",
  ADD_AUTOMATED_GREETINGS_START: "ADD_AUTOMATED_GREETINGS_START",
  ADD_AUTOMATED_GREETINGS_STOP: "ADD_AUTOMATED_GREETINGS_STOP",
  FETCH_AUTOMATED_GREETINGS_START: "FETCH_AUTOMATED_GREETINGS_START",
  FETCH_AUTOMATED_GREETINGS_SUCCESS: "FETCH_AUTOMATED_GREETINGS_SUCCESS",
  FETCH_AUTOMATED_GREETINGS_STOP: "FETCH_AUTOMATED_GREETINGS_STOP",
  UPDATE_AUTOMATED_GREETINGS_START: "UPDATE_AUTOMATED_GREETINGS_START",
  UPDATE_AUTOMATED_GREETINGS_STOP: "UPDATE_AUTOMATED_GREETINGS_STOP",
  DELETE_AUTOMATED_GREETINGS_START: "DELETE_AUTOMATED_GREETINGS_START",
  DELETE_AUTOMATED_GREETINGS_STOP: "DELETE_AUTOMATED_GREETINGS_STOP",
  TEST_AUTOMATED_GREETINGS_START: "TEST_AUTOMATED_GREETINGS_START",
  TEST_AUTOMATED_GREETINGS_STOP: "TEST_AUTOMATED_GREETINGS_STOP",
  GET_USERS_GROUP_SUCCESS: "GET_USERS_GROUP_SUCCESS",
  GET_USERS_GROUP_FAILURE: "GET_USERS_GROUP_FAILURE",
  GET_USERS_GROUP_PENDING_START: "GET_USERS_GROUP_PENDING_START",
  GET_USERS_GROUP_PENDING_STOP: "GET_USERS_GROUP_PENDING_STOP",
  GET_SCHEDULE_MESSAGE_SUCCESS: "GET_SCHEDULE_MESSAGE_SUCCESS",
  GET_SCHEDULE_MESSAGE_START: "GET_SCHEDULE_MESSAGE_START",
  GET_SCHEDULE_MESSAGE_VIEW_LOG_START: "GET_SCHEDULE_MESSAGE_VIEW_LOG_START",
  GET_SCHEDULE_MESSAGE_VIEW_LOG_STOP: "GET_SCHEDULE_MESSAGE_VIEW_LOG_STOP",
  GET_SCHEDULE_MESSAGE_VIEW_DATA_START: "GET_SCHEDULE_MESSAGE_VIEW_DATA_START",
  GET_SCHEDULE_MESSAGE_VIEW_DATA_STOP: "GET_SCHEDULE_MESSAGE_VIEW_DATA_STOP",
  CONTRIBUTIONS_REPORTING_BY_DONOR_START:
    "CONTRIBUTIONS_REPORTING_BY_DONOR_START",
  CONTRIBUTIONS_REPORTING_BY_DONOR_SUCCESS:
    "CONTRIBUTIONS_REPORTING_BY_DONOR_SUCCESS",
  CONTRIBUTIONS_REPORTING_BY_DONOR_FAIL:
    "CONTRIBUTIONS_REPORTING_BY_DONOR_FAIL",
  LOGIN_BY_TOKEN_START: "LOGIN_BY_TOKEN_START",
  LOGIN_BY_TOKEN_STOP: "LOGIN_BY_TOKEN_STOP",
  CONTRIBUTIONS_STATEMENT_START: "CONTRIBUTIONS_STATEMENT_START",
  CONTRIBUTIONS_STATEMENT_SUCCESS: "CONTRIBUTIONS_STATEMENT_SUCCESS",
  CONTRIBUTIONS_STATEMENT_FAIL: "CONTRIBUTIONS_STATEMENT_FAIL",
  CONTRIBUTIONS_STATEMENT_ACTION_START: "CONTRIBUTIONS_STATEMENT_ACTION_START",
  CONTRIBUTIONS_STATEMENT_ACTION_FAIL: "CONTRIBUTIONS_STATEMENT_ACTION_FAIL",
  CHILDREN_GRID_AND_LISTING_SUCCESS: "CHILDREN_GRID_AND_LISTING_SUCCESS",
  CHILDREN_GRID_AND_LISTING_FAILURE: "CHILDREN_GRID_AND_LISTING_FAILURE",
  RESET_UC_LOGS_DATA: "RESET_UC_LOGS_DATA",
  RESET_UC_SCHEDULE_DATA: "RESET_UC_SCHEDULE_DATA",
  GET_ORG_ALBUMS_SUCCESS: "GET_ORG_ALBUMS_SUCCESS",
  GET_ORG_ALBUMS_FAIL: "GET_ORG_ALBUMS_FAIL",
  GET_ORG_ALBUMS_LOADING_START: "GET_ORG_ALBUMS_LOADING_START",
  GET_ORG_ALBUMS_LOADING_STOP: "GET_ORG_ALBUMS_LOADING_STOP",
  CREATE_ALBUM_SUCCESS: "CREATE_ALBUM_SUCCESS",
  CREATE_ALBUM_FAIL: "CREATE_ALBUM_FAIL",
  CREATE_ALBUM_LOADING: "CREATE_ALBUM_LOADING",
  CREATE_ALBUM_STOP: "CREATE_ALBUM_STOP",
  DELETE_ALBUM_SUCCESS: "DELETE_ALBUM_SUCCESS",
  DELETE_ALBUM_FAIL: "DELETE_ALBUM_FAIL",
  DELETE_ALBUM_START: "DELETE_ALBUM_START",
  DELETE_ALBUM_STOP: "DELETE_ALBUM_STOP",
  UPDATE_ALBUM_SUCCESS: "UPDATE_ALBUM_SUCCESS",
  UPDATE_ALBUM_FAIL: "UPDATE_ALBUM_FAIL",
  UPDATE_ALBUM_LOADING: "UPDATE_ALBUM_LOADING",
  UPDATE_ALBUM_STOP: "UPDATE_ALBUM_STOP",
  CREATE_EPISODE_SUCCESS: "CREATE_EPISODE_SUCCESS",
  CREATE_EPISODE_FAIL: "CREATE_EPISODE_FAIL",
  CREATE_EPISODE_LOADING: "CREATE_EPISODE_LOADING",
  CREATE_EPISODE_STOP: "CREATE_EPISODE_STOP",
  DELETE_EPISODE_SUCCESS: "DELETE_EPISODE_SUCCESS",
  DELETE_EPISODE_FAIL: "DELETE_EPISODE_FAIL",
  DELETE_EPISODE_START: "DELETE_EPISODE_START",
  DELETE_EPISODE_STOP: "DELETE_EPISODE_STOP",
  UPDATE_EPISODE_SUCCESS: "UPDATE_EPISODE_SUCCESS",
  UPDATE_EPISODE_FAIL: "UPDATE_EPISODE_FAIL",
  UPDATE_EPISODE_LOADING: "UPDATE_EPISODE_LOADING",
  UPDATE_EPISODE_STOP: "UPDATE_EPISODE_STOP",
  GET_ORG_ALBUM_SUCCESS: "GET_ORG_ALBUM_SUCCESS",
  GET_ORG_ALBUM_FAIL: "GET_ORG_ALBUM_FAIL",
  GET_ORG_ALBUM_LOADING_START: "GET_ORG_ALBUM_LOADING_START",
  GET_ORG_ALBUM_LOADING_STOP: "GET_ORG_ALBUM_LOADING_STOP",
  UPLOAD_EPISODE_FILE_LOADING: "UPLOAD_EPISODE_FILE_LOADING",
  UPLOAD_EPISODE_FILE_STOP: "UPLOAD_EPISODE_FILE_STOP",
  CREATE_ALBUM_CATEGORY_SUCCESS: "CREATE_ALBUM_CATEGORY_SUCCESS",
  CREATE_ALBUM_CATEGORY_FAIL: "CREATE_ALBUM_CATEGORY_FAIL",
  CREATE_ALBUM_CATEGORY_LOADING: "CREATE_ALBUM_CATEGORY_LOADING",
  CREATE_ALBUM_CATEGORY_STOP: "CREATE_ALBUM_CATEGORY_STOP",
  GET_ALBUM_CATEGORIES_SUCCESS: "GET_ALBUM_CATEGORIES_SUCCESS",
  GET_ALBUM_CATEGORIES_FAIL: "GET_ALBUM_CATEGORIES_FAIL",
  GET_ALBUM_CATEGORIES_LOADING: "GET_ALBUM_CATEGORIES_LOADING",
  GET_ALBUM_CATEGORIES_STOP: "GET_ALBUM_CATEGORIES_STOP",
  DELETE_ALBUM_CATEGORY_START: "DELETE_ALBUM_CATEGORY_START",
  DELETE_ALBUM_CATEGORY_STOP: "DELETE_ALBUM_CATEGORY_STOP",
  UPDATE_ALBUM_CATEGORY_START: "UPDATE_ALBUM_CATEGORY_START",
  UPDATE_ALBUM_CATEGORY_STOP: "UPDATE_ALBUM_CATEGORY_STOP",
  GET_ORG_ALBUM_CATEGORY_SUCCESS: "GET_ORG_ALBUM_CATEGORY_SUCCESS",
  GET_ORG_ALBUM_CATEGORY_FAIL: "GET_ORG_ALBUM_CATEGORY_FAIL",
  GET_ORG_ALBUM_CATEGORY_LOADING_START: "GET_ORG_ALBUM_CATEGORY_LOADING_START",
  GET_ORG_ALBUM_CATEGORY_STOP: "GET_ORG_ALBUM_CATEGORY_STOP",
  SEARCH_ALBUMS_SUCCESS: "SEARCH_ALBUMS_SUCCESS",
  SEARCH_ALBUMS_FAIL: "SEARCH_ALBUMS_FAIL",
  SEARCH_ALBUMS_START: "SEARCH_ALBUMS_START",
  SEARCH_ALBUMS_STOP: "SEARCH_ALBUMS_STOP",
  SEARCH_ALBUMS_RESET: "SEARCH_ALBUMS_RESET",
  ADD_ALBUMS_TO_CATEGORY_LOADING: "ADD_ALBUMS_TO_CATEGORY_LOADING",
  ADD_ALBUMS_TO__CATEGORY_STOP: "ADD_ALBUMS_TO__CATEGORY_STOP",
  DELETE_ALBUM_FROM_CATEGORY_START: "DELETE_ALBUM_FROM_CATEGORY_START",
  DELETE_ALBUM_FROM_CATEGORY_STOP: "DELETE_ALBUM_FROM_CATEGORY_STOP",
  REORDER_ALBUMS_STOP: "REORDER_ALBUMS_STOP",
  REORDER_ALBUMS_LOADING: "REORDER_ALBUMS_LOADING",
  REORDER_CATEGORIES_LOADING: "REORDER_CATEGORIES_LOADING",
  REORDER_CATEGORIES_STOP: "REORDER_CATEGORIES_STOP",
  REORDER_ALBUM_EPISODES_LOADING: "REORDER_ALBUM_EPISODES_LOADING",
  REORDER_ALBUM_EPISODES_STOP: "REORDER_ALBUM_EPISODES_STOP",
  CONTRIBUTIONS_STATEMENT_ACTION_MULTIPLE_FAIL:
    "CONTRIBUTIONS_STATEMENT_ACTION_MULTIPLE_FAIL",
  CONTRIBUTIONS_STATEMENT_ACTION_MULTIPLE_START:
    "CONTRIBUTIONS_STATEMENT_ACTION_MULTIPLE_START",
  IMPORT_CHILDREN_START: "IMPORT_CHILDREN_START",
  IMPORT_CHILDREN_STOP: "IMPORT_CHILDREN_STOP",
  DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS:
    "DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS",
  DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE:
    "DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE",
  DYNAMIC_FORMS_SUBMITTED_SUCCESS: "DYNAMIC_FORMS_SUBMITTED_SUCCESS",
  DYNAMIC_FORMS_SUBMITTED_FAILURE: "DYNAMIC_FORMS_SUBMITTED_FAILURE",
  START_LOADING_FOLLOWUP: "START_LOADING_FOLLOWUP",
  STOP_LOADING_FOLLOWUP: "STOP_LOADING_FOLLOWUP",
  FOLLOWUP_GLOBAL_SEARCH_SUCCESS: "FOLLOWUP_GLOBAL_SEARCH_SUCCESS",
  FOLLOWUP_GLOBAL_SEARCH_FAILURE: "FOLLOWUP_GLOBAL_SEARCH_FAILURE",
  UPDATE_DYNAMIC_FORM_STATUS_LOADING_START:
    "UPDATE_DYNAMIC_FORM_STATUS_LOADING_START",
  UPDATE_DYNAMIC_FORM_STATUS_LOADING_STOP:
    "UPDATE_DYNAMIC_FORM_STATUS_LOADING_STOP",
  DYNAMIC_FORMS_SUBMITTED_LOADING: "DYNAMIC_FORMS_SUBMITTED_LOADING",
  GET_CHECKIN_ATT_LIST_START: "GET_CHECKIN_ATT_LIST_START",
  GET_CHECKIN_ATT_LIST_SUCCESS: "GET_CHECKIN_ATT_LIST_SUCCESS",
  GET_CHECKIN_ATT_LIST_STOP: "GET_CHECKIN_ATT_LIST_STOP",
  ADD_CHECK_IN_STOP: "ADD_CHECK_IN_STOP",
  ADD_CHECK_IN_START: "ADD_CHECK_IN_START",
  EDIT_CHECK_IN_START: "EDIT_CHECK_IN_START",
  EDIT_CHECK_IN_STOP: "EDIT_CHECK_IN_STOP",
  DELETE_CHECK_IN_START: "DELETE_CHECK_IN_START",
  DELETE_CHECK_IN_STOP: "DELETE_CHECK_IN_STOP",
  GET_RECENT_ATT_START: "GET_RECENT_ATT_START",
  GET_RECENT_ATT_SUCCESS: "GET_RECENT_ATT_SUCCESS",
  GET_RECENT_ATT_STOP: "GET_RECENT_ATT_STOP",
  GET_ATT_REPORTING_START: "GET_ATT_REPORTING_START",
  GET_ATT_REPORTING_SUCCESS: "GET_ATT_REPORTING_SUCCESS",
  GET_ATT_REPORTING_STOP: "GET_ATT_REPORTING_STOP",
  GET_ATT_INFO_START: "GET_ATT_INFO_START",
  GET_ATT_INFO_STOP: "GET_ATT_INFO_STOP",
  GET_FOODALLERGY: "GET_FOODALLERGY",
  ADD_FOODALLERGY: "ADD_FOODALLERGY",
  CHANGE_FOODALLERGY_NAME: "CHANGE_FOODALLERGY_NAME",
  DELETE_FOODALLERGY_NAME: "DELETE_FOODALLERGY_NAME",
  CHANGE_FOODALLERGY_POSITION: "CHANGE_FOODALLERGY_POSITION",
  MOBILE_APP_SLIDER_LOADING_START: "MOBILE_APP_SLIDER_LOADING_START",
  MOBILE_APP_SLIDER_LOADING_STOP: "MOBILE_APP_SLIDER_LOADING_STOP",
  GET_FOLLOWUP_FORM_SUCCESS: "GET_FOLLOWUP_FORM_SUCCESS",
  GET_FOLLOWUP_FORM_FAILURE: "GET_FOLLOWUP_FORM_FAILURE",
  VERIFY_ROUTING_NUMBER_LOADING_START: "VERIFY_ROUTING_NUMBER_LOADING_START",
  VERIFY_ROUTING_NUMBER_LOADING_STOP: "VERIFY_ROUTING_NUMBER_LOADING_STOP",
  ONBOARD_CUSTOMER_START: "ONBOARD_CUSTOMER_START",
  ONBOARD_CUSTOMER_STOP: "ONBOARD_CUSTOMER_STOP",
  FOOD_ALLERGY_LIST_SUCCESS: "FOOD_ALLERGY_LIST_SUCCESS",
  GIVE_WITH_EXISTING_TOKEN_START: "GIVE_WITH_EXISTING_TOKEN_START",
  GIVE_WITH_EXISTING_TOKEN_STOP: "GIVE_WITH_EXISTING_TOKEN_STOP",
  GIVE_WITH_NEW_CARD_ADMIN_START: "GIVE_WITH_NEW_CARD_ADMIN_START",
  GIVE_WITH_NEW_CARD_ADMIN_STOP: "GIVE_WITH_NEW_CARD_ADMIN_STOP",
  GIVE_WITH_NEW_CHECK_ADMIN_START: "GIVE_WITH_NEW_CHECK_ADMIN_START",
  GIVE_WITH_NEW_CHECK_ADMIN_STOP: "GIVE_WITH_NEW_CHECK_ADMIN_STOP",
  RESET_ENDPOINT_DATA_ALLERGY: "RESET_ENDPOINT_DATA_ALLERGY",
  MEMBERS_EXPIRY_CARD_SUCCESS: "MEMBERS_EXPIRY_CARD_SUCCESS",
  MEMBERS_EXPIRY_CARD_FAILURE: "MEMBERS_EXPIRY_CARD_FAILURE",
  MEMBERS_EXPIRY_CARD_DELETE_SUCCESS: "MEMBERS_EXPIRY_CARD_DELETE_SUCCESS",
  MEMBERS_EXPIRY_CARD_DELETE_FAILURE: "MEMBERS_EXPIRY_CARD_DELETE_FAILURE",
  SUBMIT_PROPOSAL_START: "SUBMIT_PROPOSAL_START",
  SUBMIT_PROPOSAL_STOP: "SUBMIT_PROPOSAL_STOP",
  GET_ORG_STREAMS_SUCCESS: "GET_ORG_STREAMS_SUCCESS",
  GET_ORG_STREAMS_FAIL: "GET_ORG_STREAMS_FAIL",
  GET_ORG_STREAMS_LOADING_START: "GET_ORG_STREAMS_LOADING_START",
  GET_ORG_STREAMS_LOADING_STOP: "GET_ORG_STREAMS_LOADING_STOP",
  GET_FEATURED_STREAM_SUCCESS: "GET_FEATURED_STREAM_SUCCESS",
  GET_FEATURED_STREAM_FAIL: "GET_FEATURED_STREAM_FAIL",
  GET_FEATURED_STREAM_LOADING_START: "GET_FEATURED_STREAM_LOADING_START",
  GET_FEATURED_STREAM_STOP: "GET_FEATURED_STREAM_STOP",
  SEARCH_PLAYLIST_SUCCESS: "SEARCH_PLAYLIST_SUCCESS",
  SEARCH_PLAYLIST_FAIL: "SEARCH_PLAYLIST_FAIL",
  SEARCH_PLAYLIST_START: "SEARCH_PLAYLIST_START",
  SEARCH_PLAYLIST_STOP: "SEARCH_PLAYLIST_STOP",
  UPDATE_FEATURED_STREAM_LOADING: "UPDATE_FEATURED_STREAM_LOADING",
  UPDATE_FEATURED_STREAM_STOP: "UPDATE_FEATURED_STREAM_STOP",
  SEARCH_CATEGORIES_START: "SEARCH_CATEGORIES_START",
  SEARCH_CATEGORIES_STOP: "SEARCH_CATEGORIES_STOP",
  SEARCH_CATEGORIES_SUCCESS: "SEARCH_CATEGORIES_SUCCESS",
  UPDATE_WALT_THRU_ASSET_START: "UPDATE_WALT_THRU_ASSET_START",
  UPDATE_WALT_THRU_ASSET_STOP: "UPDATE_WALT_THRU_ASSET_STOP",
  DELETE_GROUP_USER_START: "DELETE_GROUP_USER_START",
  DELETE_GROUP_USER_STOP: "DELETE_GROUP_USER_STOP",
  GIVE_METHODS_LOADING_STOP: "GIVE_METHODS_LOADING_STOP",
  GIVE_METHODS_LOADING_START: "GIVE_METHODS_LOADING_START",
  GET_GIVE_METHODS_SUCCESS: "GET_GIVE_METHODS_SUCCESS",
  GLOBAL_GIVING_STOP: "GLOBAL_GIVING_STOP",
  GLOBAL_GIVING_START: "GLOBAL_GIVING_START",
  GET_GROUP_COORDINATORS_START: "GET_GROUP_COORDINATORS_START",
  GET_GROUP_COORDINATORS_STOP: "GET_GROUP_COORDINATORS_STOP",
  GET_GROUP_COORDINATORS_SUCCESS: "GET_GROUP_COORDINATORS_SUCCESS",
  CREATE_FORMS_REQUEST : "CREATE_FORMS_REQUEST",
  CREATE_FORMS_RESPONSE : "CREATE_FORMS_RESPONSE",
  GET_FORMS_RESPONSE : "GET_FORMS_RESPONSE",
  VIEW_SUBMITTED_FORMS_REQUEST: "VIEW_SUBMITTED_FORMS_REQUEST",
  VIEW_SUBMITTED_FORMS_RESPONSE: "VIEW_SUBMITTED_FORMS_RESPONSE",
  REQUEST_OTP_ONBOARDING_START: "REQUEST_OTP_ONBOARDING_START",
  REQUEST_OTP_ONBOARDING_STOP: "REQUEST_OTP_ONBOARDING_STOP",
};
