import { Checkbox, Icon, Tooltip } from "@material-ui/core";
import StatCards1 from "components/StatCard/StatCard";
import { useState } from "react";

const StepTwo = ({
  classes,
  setSelected,
  selected,
  products,
  data,
  //   isChecked,
  //   handleToggle,
  //   showSub,
  getPrice,
  //   handleIconClick,
}) => {
  const [showSub, setShowSub] = useState(null);

  const isChecked = (id) => {
    return selected.some((item) => item.name === id);
  };

  const handleIconClick = (e, i) => {
    e.stopPropagation();
    if (showSub === i + 1) return setShowSub(null);
    return setShowSub(i + 1);
  };

  const handleToggle = (id, i, checkBox = false) => {
    if (showSub === i + 1 && !checkBox) return setShowSub(null);
    const currentIndex = selected.indexOf(id);
    const newChecked = [...selected];

    if (currentIndex === -1) {
      newChecked.push(id);
      if (selected.length >= 5) {
        return setSelected(products);
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelected(newChecked);
  };

  return (
    <div>
      <div className={` mb-3 d-flex items-center ${classes.clear}`}>
        <div className="hide-on-mobile"></div>
        <div
          className="d-flex items-center flex-grow top-icons"
          style={{ columnGap: "1.5rem" }}
        >
          <span className="d-flex items-center">
            <Tooltip title="Select all" placement="top">
              <Icon
                onClick={() => setSelected(products)}
                disabled={selected.length === products.length}
              >
                check
              </Icon>
            </Tooltip>
            <p
              className="primary-color my-0 ml-1 cursor"
              onClick={() => setSelected(products)}
            >
              Select All
            </p>
          </span>
          <span className="d-flex items-center">
            <Tooltip title="Clear all" placement="top">
              <Icon
                onClick={() => setSelected([])}
                disabled={selected.length === 0}
              >
                close
              </Icon>
            </Tooltip>
            <p
              className="primary-color my-0 ml-1 cursor"
              onClick={() => setSelected([])}
            >
              Deselect All
            </p>
          </span>
        </div>
        <span style={{ textAlign: "end" }} className="primary-color">
          Selected Package: {data?.church_size.label}
        </span>
      </div>
      <div className="mb-5">
        {products.map((item, i) => (
          <div className={classes.productCard} key={item.name}>
            <div>
              <Checkbox
                color="primary"
                checked={isChecked(item.name)}
                onChange={() => handleToggle(item, i, true)}
              />
            </div>
            <div>
              <StatCards1
                item={item}
                handleClick={() => handleToggle(item, i)}
                handleIconClick={(e) => handleIconClick(e, i)}
                showSub={showSub === i + 1}
              />
            </div>
          </div>
        ))}

        <h5 className="primary-color text-center mt-3">
          Total:{" "}
          <span className="font-bold">
            {getPrice()} {data.church_size.currency}
          </span>
        </h5>
      </div>
    </div>
  );
};

export default StepTwo;
