import { actionTypes } from "../actionTypes";

export const getAlbums = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_ALBUMS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ALBUMS_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ALBUMS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_ALBUMS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createAlbum = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ALBUM_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_ALBUM_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_ALBUM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_ALBUM_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteAlbum = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ALBUM_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_ALBUM_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_ALBUM_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_ALBUM_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateAlbum = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ALBUM_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_ALBUM_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_ALBUM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_ALBUM_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getAlbum = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_ALBUM_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ALBUM_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ALBUM_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_ALBUM_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchAlbums = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ALBUMS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_ALBUMS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_ALBUMS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_ALBUMS_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SEARCH_ALBUMS_RESET:
      return { state: {} };
    default:
      return state;
  }
};

//   Album Episodes

export const createEpisode = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EPISODE_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_EPISODE_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_EPISODE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_EPISODE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteEpisode = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_EPISODE_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_EPISODE_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_EPISODE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_EPISODE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateEpisode = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_EPISODE_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_EPISODE_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_EPISODE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_EPISODE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const uploadFile = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_EPISODE_FILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPLOAD_EPISODE_FILE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// ALBUM CATEGORIES
export const createAlbumCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ALBUM_CATEGORY_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_ALBUM_CATEGORY_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_ALBUM_CATEGORY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_ALBUM_CATEGORY_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getAlbumCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ALBUM_CATEGORIES_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ALBUM_CATEGORIES_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ALBUM_CATEGORIES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALBUM_CATEGORIES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteAlbumCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ALBUM_CATEGORY_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_ALBUM_CATEGORY_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateAlbumCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ALBUM_CATEGORY_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_ALBUM_CATEGORY_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getAlbumCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_ALBUM_CATEGORY_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ALBUM_CATEGORY_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ALBUM_CATEGORY_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_ALBUM_CATEGORY_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const reorderAlbumsInCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REORDER_ALBUMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REORDER_ALBUMS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_CATEGORIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_CATEGORIES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_CATEGORIES_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.SEARCH_CATEGORIES_RESET:
      return { state: {} };
    default:
      return state;
  }
};
