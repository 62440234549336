import React, {useState} from 'react';
import {Card, Grid} from '@material-ui/core';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import { useThemeMode } from '../../utils/utils';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import  {isValidPhoneNumber} from 'react-phone-number-input';
import {
  resetPasswordOrPin,
  verifyForgotPassword,
} from 'redux/actions';
import CustomButton from 'components/Button';
import {getQueryString} from 'utils/utils';
import {Gif} from 'components/Suspense/GifSuspense';

const useStyles = makeStyles(({palette, ...theme}) => ({
  cardHolder: {
    background: '#1A2038',
  },
  card: {
    maxWidth: 800,
    borderRadius: 12,
    margin: '1rem',
  },
  logoImg: {
    width: '5rem',
    [theme.breakpoints.down('sm')]: {
      width: '4rem',
    },
  },
}));

const Reset = ({location}) => {
  const initialState = {
    password: '',
    pin: '',
  };

  const [userInfo, setUserInfo] = useState(initialState);
  const [state, setState] = useState({});
  const classes = useStyles();
  const forgotPasswordData = useSelector(
    (state) => state.forgotPasswordData,
    shallowEqual
  );
  const forgotPinData = useSelector(
    (state) => state.forgotPinData,
    shallowEqual
  );

  const [passwordError, setPasswordError] = useState('');
  const [passwordError2, setPasswordError2] = useState('');
  const [tokenType, setTokenType] = useState('password');
  const [isMobile, setisMobile] = useState(false);
  const [pinError, setPinError] = useState({});
  const [pinError2, setPinError2] = useState('');
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const MAX_LENGTH = 4;

  const verifyForgotPasswordOrPinData = useSelector(
    (state) => state.verifyForgotPasswordOrPinData,
    shallowEqual
  );
  const { themeColor } = useThemeMode();
  const {message} = useSelector((state) => state.notifications, shallowEqual);
  const [mobile, setMobile] = useState('');
  const [mobileError, setMobileError] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const token = getQueryString('t');

  React.useEffect(() => {
    if (!token) history.push('/');
    if (window.location.pathname === '/reset_pin') {
      setTokenType('pin');
      setisMobile(true);
    }
  }, []);

  React.useEffect(() => {
    if (orgInfo?.orgId)
      dispatch(
        verifyForgotPassword({token, orgId: orgInfo?.orgId}, redirectToSignin)
      );
  }, [orgInfo?.orgId]);

  const handleChange = ({target: {name, value}}) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  let {password, password2, pin} = state;

  React.useEffect(() => {
    if (!ValidatorForm.hasValidationRule('passwordValid')) {
      ValidatorForm.addValidationRule('passwordValid', (value) => {
        if (value.length >= 1 && value.length < 8) {
          setPasswordError('Mininum 8 characters');
          return false;
        }
        if (value.length > 32) {
          setPasswordError('Maximum 32 characters');
          return false;
        }
        return true;
      });
    }

    if (!ValidatorForm.hasValidationRule('isMatch')) {
      ValidatorForm.addValidationRule('isMatch', (value) => {
        if (value !== '' && value !== state.password) {
          setPasswordError2('Password do not Match');
          return false;
        }
        return true;
      });
    }
    if (!ValidatorForm.hasValidationRule('isMatchPin')) {
      ValidatorForm.addValidationRule('isMatchPin', (value) => {
        if (value !== '' && value !== state.pin) {
          setPasswordError2('Pin do not Match');
          return false;
        }
        return true;
      });
    }

    return () => {
      if (ValidatorForm.hasValidationRule('passwordValid')) {
        ValidatorForm.removeValidationRule('passwordValid');
      }
      if (ValidatorForm.hasValidationRule('isMatch')) {
        ValidatorForm.removeValidationRule('isMatch');
      }
      if (ValidatorForm.hasValidationRule('isMatchPin')) {
        ValidatorForm.removeValidationRule('isMatchPin');
      }
    };
  }, [state.password, state.password2]);

  const redirectToSignin = () => {
    history.push('/');
  };

  // const {settings} = useSettings();
  // const brightImg = '/assets/images/logos/unnamed.jpeg';
  // const darkImg =
  //   'https://dev.churchpad.com/xMasterPortalLogosLoc/app_pdf_logos/app_250_1606573199_w3sruo__cropped.png';

  // let input = isMobile ? mobile : password;

  const handleFormSubmit = (event) => {
    let data = isMobile
      ? {pin, token, orgId: orgInfo?.orgId, tokenType}
      : {password, token, orgId: orgInfo?.orgId, tokenType};
    dispatch(resetPasswordOrPin(data, redirectToSignin));
  };

  const onPinChange = (e) => {
    const pinInput = e.target.value.replace(/\D/g, '').slice(0, 4);
    const letters = /^[A-Za-z]+$/;
    if (e.target.value.match(letters))
      return setPinError({
        ...pinError,
        msg: 'Pin must be a number',
        status: true,
      });
    if (pinInput.length < 4)
      setPinError({
        ...pinError,
        msg: 'Pin must not be less than 4 numbers',
        status: true,
      });
    if (pinInput.length >= 4)
      setPinError({...pinError, msg: '', status: false});
    setUserInfo({...userInfo, pin: pinInput});
  };

  // Error message for phone
  React.useEffect(() => {
    if (mobile && !isValidPhoneNumber(mobile)) {
      setMobileError('Number is not valid');
    } else setMobileError('');
  }, [mobile]);

  return (
    <>
      {verifyForgotPasswordOrPinData?.responseCode === '00' ? (
        <div
          className={clsx(
            'flex justify-center items-center  min-h-full-screen',
            classes.cardHolder
          )}
        >
          <Card className={classes.card} style={{width: '22rem'}}>
            <Grid container>
              {/* <Grid item lg={5} md={5} sm={5} xs={12}>
            <div className="p-8 flex justify-center items-center h-full">
              <img
                className="w-full"
                src="/assets/images/illustrations/dreamer.svg"
                alt=""
              />
            </div>
          </Grid> */}
              <Grid item xs>
                <div
                  className="px-8 pt-8 flex items-center justify-between"
                  style={{columnGap: '4rem'}}
                >
                  <img
                    src={orgInfo?.logo}
                    alt=""
                    className={`${classes.logoImg}`}
                  />
                  <span>
                    <h3 style={{color: `${themeColor}`}}>Reset</h3>
                    <h3 style={{color: `${themeColor}`}} >
                      {isMobile ? 'Pin' : 'Password'}
                    </h3>
                  </span>
                </div>
                <div className="p-8 h-full bg-light-gray relative">
                  <span>
                    <p className=" mb-5 text-center">
                      Enter your new password and confirm password
                    </p>
                  </span>
                  <ValidatorForm onSubmit={handleFormSubmit}>
                    {isMobile ? (
                      <div className="relative">
                        <TextValidator
                          className="mb-3 w-full"
                          label="Pin"
                          placeholder="Enter Pin"
                          variant="outlined"
                          size="small"
                          onChange={onPinChange}
                          maxLength={MAX_LENGTH}
                          name="pin"
                          inputMode="numeric"
                          type="password"
                          value={userInfo.pin}
                          validators={['validPin', 'required']}
                          errorMessages={[
                            'Pin must be four digits',
                            'this field is required',
                          ]}
                        />
                        <TextValidator
                          className="mb-3 w-full"
                          label="Pin"
                          placeholder="Confirm Pin"
                          variant="outlined"
                          size="small"
                          onChange={onPinChange}
                          maxLength={MAX_LENGTH}
                          name="pin2"
                          inputMode="numeric"
                          type="password"
                          value={userInfo.pin2}
                          validators={['isMatchPin', 'required']}
                          errorMessages={[
                            `${pinError2}`,
                            'this field is required',
                          ]}
                        />
                      </div>
                    ) : (
                      <>
                        <TextValidator
                          className="mb-6 w-full"
                          label="Password"
                          variant="outlined"
                          size="small"
                          onChange={handleChange}
                          name="password"
                          type="password"
                          value={password}
                          validators={[
                            'required',
                            'passwordValid',
                            'matchRegexp:[@#$%!]',
                            'matchRegexp:[0-9]',
                            'matchRegexp:[A-Z]',
                            'matchRegexp:[a-z]',
                          ]}
                          errorMessages={[
                            'this field is required',
                            `${passwordError}`,
                            'Must contain at least one special character',
                            'Must contain at least one number',
                            'Must contain at least one uppercase character',
                            'Must contain at least one lowercase character',
                          ]}
                        />
                        <TextValidator
                          className="mb-6 w-full"
                          label="Confirm Password"
                          variant="outlined"
                          size="small"
                          onChange={handleChange}
                          name="password2"
                          type="password"
                          value={password2}
                          validators={['required', 'isMatch']}
                          errorMessages={[
                            'this field is required',
                            `${passwordError2}`,
                          ]}
                        />
                      </>
                    )}
                    {message && (
                      <p className="text-error my-auto mb-3">{message}</p>
                    )}
                    <div
                      className="flex items-center justify-between"
                      style={{columnGap: '2rem'}}
                    >
                      <CustomButton
                        label="Send Request"
                        className="w-full"
                        loading={
                          isMobile
                            ? forgotPinData && forgotPinData.loading
                            : forgotPasswordData && forgotPasswordData.loading
                        }
                        disabled={
                          isMobile
                            ? forgotPinData && forgotPinData.loading
                            : forgotPasswordData && forgotPasswordData.loading
                        }
                        type='submit'
                      />

                      <Link to="/" className="w-full">
                        {/* <Button className="capitalize">Sign in</Button> */}
                        <CustomButton
                          variant="outlined"
                          label="Sign In"
                          className="capitalize w-full"
                        />
                      </Link>
                    </div>
                  </ValidatorForm>
                  {/* <MatxDivider className="mt-3" text="Or" />
                  <div className="px-5 pt-5 read-more-text">
                    <div style={{color: `${themeColor}`}} className="mx-auto displayed-text" style={{maxWidth: '350px'}}>
                      <h5 style={{color: `${themeColor}`}} className="font-weight-bold text-center mb-4"><i className="fas fa-lock font-size-h3 mr-2"></i>{orgInfo?.privacySecurityStuff?.securityNoteLabel}</h5>
                      <div className="text-center">
                        <ReactReadMoreReadLess
                          charLimit={0}
                          ellipsis=""
                          readMoreText={"read more ▼"}
                          readLessText={"collapse ▲"}
                          readMoreClassName="show-more"
                          readLessClassName="show-more"
                          readLessStyle={{color: `${themeColor}`, cursor: 'pointer'}}
                          readMoreStyle={{color: `${themeColor}`, cursor: 'pointer'}}
                        >
                          {`${orgInfo?.privacySecurityStuff?.securityNoteContent} ${ipData?.ip}`}
                        </ReactReadMoreReadLess>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      ) : (
        <Gif />
      )}
    </>
  );
};

export default Reset;
