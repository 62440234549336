import axios from "axios";
import NotificationMesage from "components/Suspense/NotificationMessage";
import { toast } from "react-toastify";
import { logout } from "utils/authHelpers";

const instance = axios.create({});

const lastActiveTime = localStorage.getItem("lastActiveTime");
const LOGOUT_TIME = 30 * 60 * 1000;

instance.interceptors.request.use(
  (config) => {
    if (lastActiveTime && Date.now() - lastActiveTime > LOGOUT_TIME) {
      logout();
      return toast.error(
        <NotificationMesage responseStatus={"01"} message="Session Timeout " />
      );
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
