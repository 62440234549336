import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router";

const MobileProposalSuccess = () => {
  const { state: pdf } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!pdf) history.push("/mobile-app-proposal");
  }, []);

  return (
    <div
      className="h-full-screen flex flex-column justify-center items-center"
      style={{ rowGap: "5rem" }}
    >
      <div className="thank-you">
        <h2>Thank You!!!</h2>
      </div>
      <div className="items-center flex flex-column  justify-center">
        <a href={`${pdf}`} download={pdf} target="_blank" rel="noreferrer">
          <img
            style={{ height: "5rem" }}
            className={`cursor-pointer`}
            src={"https://repo.churchpad.com/cdn/images/a/pdf-icon.svg"}
            alt=""
          />
        </a>
        <p className="mt-1">Click on icon to view and download PDF</p>
      </div>
    </div>
  );
};

export default MobileProposalSuccess;
