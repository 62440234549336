import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { actionTypes } from "../actionTypes";
import { asyncMethod, apiUrl, defaultAction } from "../../utils/constants";
import NotificationMesage from "../../components/Suspense/NotificationMessage";

export const onBoardCustommer = (payload, cb) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ONBOARD_CUSTOMER_START,
    });
    const url = apiUrl.onBoardCustommer();
    try {
      const response = await axios({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response.data;
      if (data) {
        dispatch({
          type: actionTypes.ONBOARD_CUSTOMER_STOP,
        });
        toast.success(
          <NotificationMesage responseStatus={"00"} message={data.message} />
        );
        cb && cb(data);
        return dispatch(defaultAction(actionTypes.ONBOARD_CUSTOMER_STOP));
      } else {
        return dispatch(defaultAction(actionTypes.ONBOARD_CUSTOMER_STOP));
      }
    } catch (error) {
      console.log(error?.response);
      if (error?.response.status === 500) {
        return toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      } else
        Object.values(error?.response?.data.data).map((value) => {
          return toast.error(
            <NotificationMesage responseStatus={"01"} message={value[0]} />
          );
        });

      return dispatch(defaultAction(actionTypes.ONBOARD_CUSTOMER_STOP));
    }
  };
};

export const fetchChurchSize = (cb) => {
  return async () => {
    const url = apiUrl.fetchChurchSize();
    try {
      const response = await axios({
        method: asyncMethod.GET,
        url,
      });
      const data = await response.data;
      if (data) {
        return cb && cb(data.data);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};
export const fetchChurchProducts = (cb) => {
  return async () => {
    const url = apiUrl.fetchChurchProducts();
    try {
      const response = await axios({
        method: asyncMethod.GET,
        url,
      });
      const data = await response.data;
      if (data) {
        return cb && cb(data.data);
      }
    } catch (error) {
      console.log(error, "error");
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const checkShortName = (params, cb) => {
  return async () => {
    const url = apiUrl.checkShortName();
    try {
      const response = await axios({
        method: asyncMethod.GET,
        url,
        params,
      });
      const res = await response.data;
      if (res) {
        if (!res.data.available) {
          toast.error(
            <NotificationMesage responseStatus={"01"} message={res?.message} />
          );
        }
        return cb && cb(res);
      }
    } catch (error) {
      console.log(error.response, "error");
      if (!error.response) return;
      if (error?.response.status === 500) {
        return toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      } else
        Object.values(error?.response?.data.data).map((value) => {
          return toast.error(
            <NotificationMesage responseStatus={"01"} message={value[0]} />
          );
        });

      return cb && cb(error?.response);
    }
  };
};

// Mobile App

export const submitProposal = (payload, cb) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SUBMIT_PROPOSAL_START,
    });
    const url = apiUrl.submitProposal();
    try {
      const response = await axios({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response.data;
      if (data) {
        dispatch({
          type: actionTypes.SUBMIT_PROPOSAL_STOP,
        });
        toast.success(
          <NotificationMesage responseStatus={"00"} message={data.message} />
        );
        cb(data);
        return dispatch(defaultAction(actionTypes.SUBMIT_PROPOSAL_STOP));
      } else {
        return dispatch(defaultAction(actionTypes.SUBMIT_PROPOSAL_STOP));
      }
    } catch (error) {
      console.log(error?.response);
      if (error?.response.status === 500) {
        return toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      } else
        Object.values(error?.response?.data.data).map((value) => {
          return toast.error(
            <NotificationMesage responseStatus={"01"} message={value[0]} />
          );
        });

      return dispatch(defaultAction(actionTypes.SUBMIT_PROPOSAL_STOP));
    }
  };
};


// Global Giving

export const submitGlobalGiving = (payload, cb) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.GLOBAL_GIVING_START,
    });
    const url = apiUrl.globalGiving();
    try {
      const response = await axios({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response.data;
      if (data) {
        dispatch({
          type: actionTypes.GLOBAL_GIVING_STOP,
        });
        toast.success(
          <NotificationMesage responseStatus={"00"} message={data.message} />
        );
        cb(data);
        return dispatch(defaultAction(actionTypes.GLOBAL_GIVING_STOP));
      } else {
        return dispatch(defaultAction(actionTypes.GLOBAL_GIVING_STOP));
      }
    } catch (error) {
      console.log(error?.response);
      if (error?.response.status === 500) {
        return toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      } else
        Object.values(error?.response?.data.data).map((value) => {
          return toast.error(
            <NotificationMesage responseStatus={"01"} message={value[0]} />
          );
        });

      return dispatch(defaultAction(actionTypes.GLOBAL_GIVING_STOP));
    }
  };
};


export const requestOTPOnboarding = (payload, cb) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_OTP_ONBOARDING_START,
    });
    const url = apiUrl.requestOTPOnboarding();
    try {
      const response = await axios({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response.data;
      if (data) {
        dispatch({
          type: actionTypes.REQUEST_OTP_ONBOARDING_STOP,
        });
        // toast.success(
        //   <NotificationMesage responseStatus={"00"} message={data.message} />
        // );
        cb && cb(data);
        return dispatch(defaultAction(actionTypes.REQUEST_OTP_ONBOARDING_STOP));
      } else {
        return dispatch(defaultAction(actionTypes.REQUEST_OTP_ONBOARDING_STOP));
      }
    } catch (error) {
      console.log(error?.response);
      if (error?.response.status === 500) {
        return toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      } else
        Object.values(error?.response?.data.data).map((value) => {
          return toast.error(
            <NotificationMesage responseStatus={"01"} message={value[0]} />
          );
        });

      return dispatch(defaultAction(actionTypes.REQUEST_OTP_ONBOARDING_STOP));
    }
  };
};