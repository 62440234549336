import axios from 'axios'
import { checkNodeEnvNextGen, logout } from './authHelpers';
import { nextgenUrl, mserviceUrl, onBoardUrl } from './constants';



const AxiosInstance = axios.create({
    baseURL: checkNodeEnvNextGen(nextgenUrl.live, nextgenUrl.staging),
});
export const AxiosMserviceInstance = axios.create({
    baseURL: checkNodeEnvNextGen(mserviceUrl.live, mserviceUrl.staging),
});

export const AxiosInstanceCP = axios.create({
  baseURL: checkNodeEnvNextGen(onBoardUrl.live, onBoardUrl.staging),
});



export const setupAxiosInterceptors = () => {
    const onRequestSuccess = async (config) => {
      let authToken = localStorage.getItem('mservice-token') || '';

      if (!authToken) {
        logout()
      }
      
      if (authToken) {
        config.headers['Authorization'] = 'Bearer ' + authToken;
      }

      return config;
    };
    const onRequestSuccessMservice = async (config) => {
      let authToken = localStorage.getItem('mservice-token') || '';

      if (!authToken) {
        logout()
      }
      
      if (authToken) {
        config.headers['Authorization'] = 'Bearer ' + authToken;
      }

      return config;
    };
  
    const onRequestError = (err) => {

  
        return Promise.reject(err);
    };
  
    const onResponseSuccess = (response) => response;
    const onResponseError = (err) => {
      //console.log(err)


      if (err.response && err.response.status === 401) {

        logout()
      }
      // if (err.response && err.response.status === 400) {

      //   throw new Error(err)
      // }
      // if (err.response && err.response.status === 404) {

      //   throw new Error(err)
      // }
      // if (err.response && err.response.status === 500) {

      //   throw new Error(err)
      // }

      return Promise.reject(err);
    };
  
    AxiosInstance.interceptors.request.use(onRequestSuccess, onRequestError);
    AxiosInstanceCP.interceptors.request.use(onRequestSuccess, onRequestError);
    AxiosMserviceInstance.interceptors.request.use(onRequestSuccessMservice, onRequestError);
    AxiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
    AxiosInstanceCP.interceptors.response.use(onResponseSuccess, onResponseError);
    AxiosMserviceInstance.interceptors.response.use(onResponseSuccess, onResponseError);

  };

  export default AxiosInstance