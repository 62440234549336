import { useEffect } from "react";
import { isValidURL } from "utils/utils";

const useValidationHooks = (ValidatorForm, data) => {
  useEffect(() => {
    if (!ValidatorForm.hasValidationRule("isValidUrl")) {
      ValidatorForm.addValidationRule("isValidUrl", (value) => {
        const isValid = isValidURL(value);
        if (value !== "" && isValid) {
          return true;
        } else if (value === "" && !isValid) {
          return true;
        } else return false;
      });
    }
    if (!ValidatorForm.hasValidationRule("isLetter")) {
      ValidatorForm.addValidationRule("isLetter", (value) => {
        const isValid = /^[a-zA-Z\s]*$/.test(value);
        if (value !== "" && isValid) {
          return true;
        } else if (value === "" && !isValid) {
          return true;
        } else return false;
      });
    }

    return () => {
      if (ValidatorForm.hasValidationRule("isValidUrl")) {
        ValidatorForm.removeValidationRule("isValidUrl");
      }
      if (ValidatorForm.hasValidationRule("isLetter")) {
        ValidatorForm.removeValidationRule("isLetter");
      }
    };
  }, [data, ValidatorForm]);
};

export default useValidationHooks;
