import {
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import CustomTextField from "./CustomTextField";
import React, { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { isValidURL } from "utils/utils";
import InfoIcon from "@material-ui/icons/Info";
import { useDispatch } from "react-redux";
import { checkShortName } from "redux/actions/OnBoarding";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PhoneInputField from "components/PhoneTextField/PhoneInput";

const StepThree = ({
  data,
  handleChange,
  isTermsAgreed,
  setIsTermsAgreed,
  handleChangePhone,
  classes,
  mobileErrors,
  inputRef,
  shortNameCheck,
  setShortNameCheck
}) => {
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (!ValidatorForm.hasValidationRule("isValidUrl")) {
      ValidatorForm.addValidationRule("isValidUrl", (value) => {
        const isValid = isValidURL(value);
        if (value !== "" && isValid) {
          return true;
        } else if (value === "" && !isValid) {
          return true;
        } else return false;
      });
    }
    if (!ValidatorForm.hasValidationRule("isLetter")) {
      ValidatorForm.addValidationRule("isLetter", (value) => {
        const isValid = /^[a-zA-Z\s]*$/.test(value);
        if (value !== "" && isValid) {
          return true;
        } else if (value === "" && !isValid) {
          return true;
        } else return false;
      });
    }
    if (!ValidatorForm.hasValidationRule("hasError")) {
      ValidatorForm.addValidationRule("hasError", (value) => {
        if (value && shortNameCheck.error?.length) return false;
        return true;
      });
    }

    if (!ValidatorForm.hasValidationRule("passwordValid")) {
      ValidatorForm.addValidationRule("passwordValid", (value) => {
        if (value.length >= 1 && value.length < 8) {
          setPasswordError("Mininum 8 characters");
          return false;
        }
        if (value.length > 32) {
          setPasswordError("Maximum 32 characters");
          return false;
        }
        return true;
      });
    }

    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      return value === data.admin_password;
    });

    return () => {
      if (ValidatorForm.hasValidationRule("isValidUrl")) {
        ValidatorForm.removeValidationRule("isValidUrl");
      }
      if (ValidatorForm.hasValidationRule("passwordValid")) {
        ValidatorForm.removeValidationRule("passwordValid");
      }
      if (ValidatorForm.hasValidationRule("isLetter")) {
        ValidatorForm.removeValidationRule("isLetter");
      }
      ValidatorForm.removeValidationRule("isPasswordMatch");
    };
  }, [data]);

  const handleBlur = () => {
    if (data?.church_short_name && data?.church_short_name.length >= 4) {
      setShortNameCheck({ ...shortNameCheck, loading: true });
      dispatch(
        checkShortName({ short_name: data?.church_short_name }, (res) => {
          if (res?.status === 400) {
            return setShortNameCheck({
              loading: false,
              error: res.data.data.short_name[0],
            });
          }
          return setShortNameCheck({
            loading: false,
            error: "",
          });
        })
      );
    }
  };

  const handleShortNameFocus = () => {
    if (!shortNameCheck.loading)
      setShortNameCheck({
        loading: false,
        error: "",
      });
  };

  const handleVisibility = () => setVisible(!visible);

  return (
    <React.Fragment>
      <div className="form-description mb-3">
        <p className="center mb--1 primary-color">
          Fill out this form to get started.
        </p>
        <p className="text-11 center mt-1">
          All fields marked (
          <span style={{ color: "red" }} className="mx-1">
            *
          </span>
          ) are required
        </p>
      </div>

      <Grid container spacing={3}>
        {/* Church */}
        <Grid item xs={12} sm={6}>
          <CustomTextField
            label="Church Name"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type="text"
            value={data?.church_name}
            name="church_name"
            inputProps={{
              maxLength: 100,
            }}
            required
          />
          <div className="flex w-full relative">
            <CustomTextField
              label="Church Short Name"
              onChange={handleChange}
              fullWidth
              className="w-full mb-5"
              variant="outlined"
              size="small"
              type="text"
              value={data?.church_short_name}
              name="church_short_name"
              inputProps={{
                maxLength: 20,
                minLength: 4,
              }}
              validators={["matchRegexp:^[A-Za-z]{4,20}$"]}
              errorMessages={["Must be 4 to 20 letters only"]}
              placeholder="example THC"
              // validators={["isLetter"]}
              // errorMessages={["Must contain only letters"]}
              istextvalidator
              required
              onBlur={handleBlur}
              isValid={shortNameCheck?.error === ""}
              helperText={data?.church_short_name ? shortNameCheck?.error : ""}
              FormHelperTextProps={{
                className: shortNameCheck?.error?.length ? "error-text" : "",
              }}
              onFocus={handleShortNameFocus}
            />
            {shortNameCheck?.loading ? (
              <div className="relative">
                <div className={classes.loader}>
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="default"
                  />
                </div>
              </div>
            ) : (
              <CustomTooltip
                title={
                  <span className={classes.customTooltip}>
                    Your Church Short Name is the abbreviation or acronym of
                    your church name.
                    <br />
                    Example: The Way Global Ministry New York is TWGMNY or The
                    Redeemed Christian Church Of God, House Of Favor is RCCGHOF.
                    <br />
                    We use it to create a unique ChurchPad link for your church.
                    Example: twgmny.churchpad.com or rccghof.churchpad.com
                  </span>
                }
                placement="top"
              >
                <div className={classes.loader}>
                  <InfoIcon
                    className=""
                    size="small"
                    style={{
                      fontSize: "26px",
                      color: "rgb(49, 49, 49)",
                    }}
                  />
                </div>
              </CustomTooltip>
            )}
          </div>
          <CustomTextField
            label="Church Website"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type="text"
            value={data?.church_website}
            name="church_website"
            inputProps={{
              maxLength: 100,
            }}
            validators={["isValidUrl"]}
            errorMessages={["URL is not valid"]}
            istextvalidator
          />

          <CustomTextField
            fullWidth
            color="secondary"
            variant="outlined"
            inputRef={inputRef}
            id="autocomplete"
            size="small"
            label="Church Address"
            className="w-full mb-5"
            validators={["required"]}
            errorMessages={["this field is required"]}
            required
          />

          <CustomTextField
            label="Church Email"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5 "
            variant="outlined"
            size="small"
            type="email"
            value={data?.church_email}
            validators={["isEmail"]}
            name="church_email"
            inputProps={{
              maxLength: 100,
            }}
            istextvalidator
            errorMessages={["email is not valid"]}
            required
          />

          <div className="mb-5">
            <label htmlFor="" className="phone_label">
              Church Phone Number
            </label>
            <PhoneInputField
              mobile={data?.church_phone_number}
              id="church_phone_number"
              name="church_phone_number"
              setMobile={(e) => handleChangePhone(e, "church_phone_number")}
              mobileError={mobileErrors?.church_phone_number}
              classBox={`${
                mobileErrors?.admin_phone_number && "mb-5"
              } phone-border`}
              marginClass=""
            />
          </div>
        </Grid>

        {/* Admin */}
        <Grid item xs={12} sm={6}>
          <CustomTextField
            label="Admin First Name"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type="text"
            value={data?.admin_firstname}
            name="admin_firstname"
            inputProps={{
              maxLength: 100,
            }}
            required
          />
          <CustomTextField
            label="Admin Last Name"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type="text"
            value={data?.admin_lastname}
            name="admin_lastname"
            inputProps={{
              maxLength: 100,
            }}
            required
          />
          <CustomTextField
            label="Admin Email"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type="email"
            value={data?.admin_email}
            name="admin_email"
            inputProps={{
              maxLength: 100,
            }}
            validators={["required", "isEmail"]}
            required
            istextvalidator
            errorMessages={["this field is required", "email is not valid"]}
          />
          <CustomTextField
            label="Admin Password"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type={visible ? "text" : "password"}
            value={data?.admin_password}
            name="admin_password"
            inputProps={{
              maxLength: 100,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleVisibility}
                    aria-label="Toggle password visibility"
                  >
                    {!visible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            required
            istextvalidator
            validators={[
              "required",
              "passwordValid",
              "matchRegexp:[@#$%!]",
              "matchRegexp:[0-9]",
              "matchRegexp:[A-Z]",
              "matchRegexp:[a-z]",
            ]}
            errorMessages={[
              "this field is required",
              `${passwordError}`,
              "Must contain at least one special character",
              "Must contain at least one number",
              "Must contain at least one uppercase character",
              "Must contain at least one lowercase character",
            ]}
          />

          <CustomTextField
            label="Confirm Password"
            onChange={handleChange}
            fullWidth
            className="w-full mb-5"
            variant="outlined"
            size="small"
            type={visible ? "text" : "password"}
            value={data?.confirm_password}
            name="confirm_password"
            inputProps={{
              maxLength: 100,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleVisibility}
                    aria-label="Toggle password visibility"
                  >
                    {!visible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            istextvalidator
            validators={["required", "isPasswordMatch"]}
            errorMessages={["this field is required", "Passwords do not match"]}
            required
          />

          <div className="mb-5">
            <label htmlFor="" className="phone_label">
              Admin Phone Number
            </label>
            <PhoneInputField
              mobile={data?.admin_phone_number}
              name="admin_phone_number"
              setMobile={(e) => handleChangePhone(e, "admin_phone_number")}
              id="admin_phone_number"
              mobileError={mobileErrors.admin_phone_number}
              classBox={`${
                mobileErrors?.admin_phone_number && "mb-5"
              } phone-border`}
              marginClass=""
            />
          </div>
        </Grid>
      </Grid>

      <div className="flex items-center ">
        <Checkbox
          className="mr--1"
          color="primary"
          // checked={listOfInvoices.some(
          //   (item) => item.id === allValues.id
          // )}
          onChange={() => setIsTermsAgreed(!isTermsAgreed)}
          style={{ height: "5px" }}
          checked={isTermsAgreed}
        />

        <p>
          By checking this box you are agreeing to{" "}
          <a
            href="https://churchpad.com/terms-of-service/"
            target="_blank"
            rel="noreferrer"
            className="a-tag"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://churchpad.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
            className="a-tag"
          >
            Privacy Policy
          </a>
          .
        </p>
      </div>
    </React.Fragment>
  );
};

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgb(49, 49, 49)", // Set the background color of the tooltip pop-up
    opacity: 1, // Set the opacity to 1 to make it fully opaque
  },
}))(Tooltip);

export default StepThree;
