import Flyer from "assests/global-app-ebrochure.jpg";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import Logo from "assests/Onboard/Icon.png";
import PhoneInputField from "components/PhoneTextField/PhoneInput";
import "./style.css";
import CustomTextField from "Pages/Onboarding/CustomTextField";
import { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import useValidationHooks from "utils/hooks/useValidationHook";
import { isValidPhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "libphonenumber-js";
import CustomButton from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import StyledRadio from "components/StyledRadio";
import { submitGlobalGiving } from "redux/actions/OnBoarding";

const positions = [
  { label: "Pastor", value: "Pastor" },
  { label: "Ministry Leader", value: "Ministry Leader" },
  { label: "Member", value: "Member" },
  { label: "Other", value: "Other" },
];

const GlobalGivingEbrochure = () => {
  const initialData = {
    email: "",
    church_website: "",
    phone_number: "",
    church_name: "",
    first_name: "",
    last_name: "",
    role: "",
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState(initialData);
  const [mobileError, setMobileError] = useState("");
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);

  const loading = useSelector((state) => state.globalGiving?.loading);

  useValidationHooks(ValidatorForm, data);

  const handleChange = ({ target: { name, value } }) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
  };

  const handleChangePhone = (value, name) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
  };

  const handleFormSubmit = () => {
    let ip = localStorage.getItem("ipAddress");
    const payload = { ...data, ip: JSON.parse(ip) };
    if (payload.phone_number === "") {
      return setMobileError("This field is required");
    }
    if (!isValidPhoneNumber(payload?.phone_number))
      return setMobileError("This number is invalid");
    setMobileError("");
    if (data?.phone_number) {
      const parsedPhoneNumber = parsePhoneNumber(data?.phone_number);
      payload.phone_number = parsedPhoneNumber.number;
    }
    dispatch(submitGlobalGiving(payload, cb));
  };

  const cb = (res) => {
    const pdf = res.data.brochureUrl;
    history.push({
      pathname: `/global-giving-ebrochure/success`,
      state: pdf,
    });
  };

  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
      id="mobile-proposal"
    >
      <Card className={classes.card}>
        <Grid container spacing={2} className={`p-4 ${classes.gridContainer}`}>
          <Grid item lg={6} md={6} sm={5} xs={12}>
            <div
              className={clsx({
                "h-full": true,
                [classes.cardLeft]: true,
              })}
            >
              <img
                src={Flyer}
                alt="flyer"
                style={{ height: "auto", width: "auto" }}
              />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={7} xs={12} className="right-col">
            <div
              className={`flex flex-column items-center ${classes.logoTitle}`}
            >
              <img src={Logo} alt="" className={`church-logo mx-auto`} />
              <h4 className="mt-3">Global Giving Ebrochure</h4>
            </div>
            <div className="w-full">
              <ValidatorForm onSubmit={handleFormSubmit}>
                <div className="flex col-gap-1">
                  <CustomTextField
                    label="First Name"
                    onChange={handleChange}
                    fullWidth
                    className="w-full mb-5"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={data?.first_name}
                    name="first_name"
                    inputProps={{
                      maxLength: 100,
                    }}
                    required
                  />
                  <CustomTextField
                    label="Last Name"
                    onChange={handleChange}
                    fullWidth
                    className="w-full mb-5"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={data?.last_name}
                    name="last_name"
                    inputProps={{
                      maxLength: 100,
                    }}
                    required
                  />
                </div>

                <div className="flex col-gap-1">
                  <div className="w-full">
                    <label htmlFor="" className="phone_label">
                      Phone Number
                    </label>
                    <PhoneInputField
                      mobile={data?.phone_number}
                      name="phone_number"
                      setMobile={(e) => handleChangePhone(e, "phone_number")}
                      id="phone_number"
                      mobileError={mobileError}
                      classBox={`${
                        mobileError && "mb-5"
                      } phone-border phone_input`}
                    />
                  </div>
                  <div className="w-full phone_col">
                    <CustomTextField
                      label="Email"
                      onChange={handleChange}
                      fullWidth
                      className="w-full mb-5"
                      variant="outlined"
                      size="small"
                      type="email"
                      value={data?.email}
                      name="email"
                      inputProps={{
                        maxLength: 100,
                      }}
                      validators={["required", "isEmail"]}
                      required
                      istextvalidator
                      errorMessages={[
                        "this field is required",
                        "email is not valid",
                      ]}
                    />
                  </div>
                </div>

                <div className="flex col-gap-1">
                  <div className="w-full">
                    <CustomTextField
                      label="Church Website"
                      onChange={handleChange}
                      fullWidth
                      className="w-full"
                      variant="outlined"
                      size="small"
                      type="text"
                      value={data?.church_website}
                      name="church_website"
                      inputProps={{
                        maxLength: 100,
                      }}
                      validators={["isValidUrl"]}
                      errorMessages={["URL is not valid"]}
                      istextvalidator
                    />
                  </div>
                  <CustomTextField
                    label="Church Name"
                    onChange={handleChange}
                    fullWidth
                    className="w-full mb-5"
                    variant="outlined"
                    size="small"
                    type="text"
                    value={data?.church_name}
                    name="church_name"
                    inputProps={{
                      maxLength: 100,
                    }}
                    required
                  />
                </div>

                <div className="mb-3" style={{ columnGap: "3rem" }}>
                  <FormControl required>
                    <FormLabel style={{ color: "#304fff" }} component="legend">
                      Role
                    </FormLabel>
                    <RadioGroup
                      className="mt-1"
                      value={data?.role}
                      name="role"
                      onChange={handleChange}
                      row
                    >
                      {positions.map(({ label, value }, i) => {
                        return (
                          <FormControlLabel
                            value={value}
                            control={<StyledRadio required />}
                            label={label}
                            labelPlacement="end"
                            key={value}
                            style={{ marginLeft: "unset" }}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="flex items-center ">
                  <Checkbox
                    className="mr--1"
                    color="primary"
                    onChange={() => setIsTermsAgreed(!isTermsAgreed)}
                    style={{ height: "5px" }}
                  />

                  <p className="text-12">
                    By checking this box you are agreeing to{" "}
                    <a
                      href="https://churchpad.com/terms-of-service/"
                      target="_blank"
                      rel="noreferrer"
                      className="a-tag"
                    >
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://churchpad.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                      className="a-tag"
                    >
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>

                <CustomButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="mx-auto w-full"
                  label="Submit"
                  loading={loading}
                  disabled={loading || !isTermsAgreed}
                />
              </ValidatorForm>
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "#1A2038",
  },
  card: {
    maxWidth: 800,
    margin: "1rem",
  },
  cardLeft: {
    background: "#161c37 url(/assets/images/bg-3.png) no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
  },
  gridContainer: {
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  logoTitle: {
    [theme.breakpoints.only("xs")]: {
      marginBottom: "1.25rem",
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 26,
      lineHeight: 1.3,
      fontWeight: 800,
    },
  },
  mainTitle: {
    fontSize: 18,
    lineHeight: 1.3,
    marginBottom: 24,
  },
  features: {
    "& .item": {
      position: "relative",
      marginBottom: 12,
      paddingLeft: 20,
      "&::after": {
        position: "absolute",
        content: '""',
        width: 4,
        height: 4,
        borderRadius: 4,
        left: 4,
        top: 7,
        backgroundColor: palette.error.main,
      },
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  socialButton: {
    width: "100%",
    "& img": {
      margin: "0 8px",
    },
  },
}));

export default GlobalGivingEbrochure;
