import { checkNodeEnvNextGen, checkNodeEnvOnboarding } from "./authHelpers";

export const URL = {
  ipDataUrl: `https://api.ipdata.co?api-key=${process.env.REACT_APP_IPDATA_API_KEY}`,
  prodOrgUrl: "https://live-api.churchpad.com/v2/org-info-by-domain-name-web/",
  devOrgUrl: "https://test-api.churchpad.com/v2/org-info-by-domain-name-web/",
  testDomain: "democpadmin.churchpad.com",
};

// asyncMethod needed as payload for axios calls
export const asyncMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

// api endpoint url
export const apiUrl = {
  orgInfo: (origin, params) => {
    if (process.env.REACT_APP_ENV === "local")
      return `${origin}?orgDomainName=${URL.testDomain}`;
    else return `${origin}?orgDomainName=${params}`;
  },
  orgInfo2: (domain) =>
    `https://${domain}.churchpad.com/v2/org-info-by-id-web/`,
  forgotPassword: (domain) =>
    `https://${domain}.churchpad.com/v2/forgotpassword/`,
  forgotPin: (domain) => `https://${domain}.churchpad.com/v2/forgotpin/`,
  verifyForgotPasswordorPin: (domain) =>
    `https://${domain}.churchpad.com/v2/verifytokenforgotpasswordorpin/`,
  resetPassword: (domain) =>
    `https://${domain}.churchpad.com/v2/resetpasswordorpinbytoken/`,
  login: (domain) => `https://${domain}.churchpad.com/v2/admin-login/`,
  signup: (domain) => `https://${domain}.churchpad.com/v2/signup/`,
  updateUserRecordsListPerPage: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-records-list-per-page/`,
  orgInfoList: (domain) =>
    `https://${domain}.churchpad.com/v2/org-info-additional-data-web/`,
  updateInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-profile/`,
  church411: (domain) =>
    `https://${domain}.churchpad.com/v2/church411-directory/`,
  addAChildurl: (domain) =>
    `https://${domain}.churchpad.com/v2/child-profile-manager/`,
  userProfileUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-profile/`,
  prayerWalls: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-fellowship-walls/`,
  prayerWallsLikeOrPray: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-walls-like-or-pray/`,
  addPrayerWalls: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-fellowship-walls/`,
  orgOtherList: (domain) =>
    `https://${domain}.churchpad.com/v2/org-info-additional-data-web/`,
  archivePrayerUrl: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-walls-archive/`,
  answeredPrayerUrl: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/mark-answered-share-testimony/`,
  getUserProfile: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-fetch-user-profile/`,
  userContribution: (domain) =>
    `https://${domain}.churchpad.com/v2/user-contributions-history/`,
  // userImageUrl: domain => `https://${domain}.churchpad.com/v2/media-upload-api/`,
  giveWithCard: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-card/`,
  giveWithCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-check/`,
  updatePasswordAndEmail: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-email-password-pin/`,
  cardsOrCheckingProfiles: (domain) =>
    `https://${domain}.churchpad.com/v2/cards-checking-profiles-on-file-portal/`,
  giveWithANewCard: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-a-new-card-portal/`,
  giveWithANewCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-a-new-checking-portal/`,
  existingRecurringProfilesPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/existing-recurring-profiles-portal/`,
  deleteCardOrCheckingPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-card-or-checking-portal/`,
  giveWithAnExistingCardCheckingPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-an-existing-card-checking-portal/`,
  setAsDefaultCardChecking: (domain) =>
    `https://${domain}.churchpad.com/v2/set-as-default-card-checking/`,
  cancelARecurring: (domain) =>
    `https://${domain}.churchpad.com/v2/cancel-a-recurring/`,
  setupANewRecurringPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/setup-a-new-recurring-portal/`,
  contributionPdf: (domain) =>
    `https://${domain}.churchpad.com/v2/generate-pdf-documents/`,
  setOrUnset: (domain) =>
    `https://${domain}.churchpad.com/v2/set-or-unset-spouse/`,
  searchForSpouse: (domain) =>
    `https://${domain}.churchpad.com/v2/search-for-a-spouse/`,
  deleteChildurl: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/child-profile-manager/`,
  updateARecurringPortal: (domain) =>
    `https://${domain}.churchpad.com/v2/update-a-recurring-portal/`,
  updateUser2FA: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-2fa/`,
  verify2FA: (domain) => `https://${domain}.churchpad.com/v2/verify-2fa/`,
  fetchPeople: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-people-membership/`,
  fetchUserProfile: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-fetch-user-profile/`,
  getMembership: (domain) =>
    `https://${domain}.churchpad.com/v2/membership-types/`,
  addMembershipType: (domain) =>
    `https://${domain}.churchpad.com/v2/add-membership-type/`,
  changeMembershipName: (domain) =>
    `https://${domain}.churchpad.com/v2/change-membership-type-name/`,
  deleteMembership: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-membership-type/`,
  changeMembershipPosition: (domain) =>
    `https://${domain}.churchpad.com/v2/change-membership-types-position/`,
  updateUserProfileAdmin: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/admin-update-user-profile/`,

  addNewMember: (domain) => `https://${domain}.churchpad.com/v2/add-new-user/`,
  generateRandmPassword: (domain) =>
    `https://${domain}.churchpad.com/v2/generate-random-password/`,
  generateEnvelopeId: (domain) =>
    `https://${domain}.churchpad.com/v2/generate-envelope-contribution-id/`,
  doSearchUser: (domain) => `https://${domain}.churchpad.com/v2/search-user/`,
  doSearchUserAdvance: (domain) =>
    `https://${domain}.churchpad.com/v2/search-user-advanced/`,
  userImageUrl: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/admin-update-user-profile/`,
  changeContributionNamesPosition: (domain) =>
    `https://${domain}.churchpad.com/v2/change-contribution-names-position/`,
  adminApproveDisDeleteFellowship: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-approve-dis-delete-fellowship/`,
  fellowshipCategoryTypesWithPositions: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-category-types/`,
  changeFellowshipCategoryPosition: (domain) =>
    `https://${domain}.churchpad.com/v2/change-fellowship-category-position/`,
  addFellowshipCategory: (domain) =>
    `https://${domain}.churchpad.com/v2/add-fellowship-category/`,
  deleteFellowshipCategory: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-fellowship-category/`,
  changeFellowshipCategoryName: (domain) =>
    `https://${domain}.churchpad.com/v2/change-fellowship-category-name/`,
  updateOrgGeneral: (domain) =>
    `https://${domain}.churchpad.com/v2/update-org-general/`,
  updateOrgSocialMedia: (domain) =>
    `https://${domain}.churchpad.com/v2/update-org-social-media/`,
  updateOrgLogo: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/update-org-logos/`,
  generosityDashboard: (domain) =>
    `https://${domain}.churchpad.com/v2/generosity-dashboard/`,
  fellowshipPrequestDashboard: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-prequest-dashboard/`,
  fellowshipPreportDashboard: (domain) =>
    `https://${domain}.churchpad.com/v2/fellowship-preport-dashboard/`,
  getOrgMessage: (domain) =>
    `https://${domain}.churchpad.com/v2/org-messaging-data-web/`,
  updateOrgTextmessaging: (domain) =>
    `https://${domain}.churchpad.com/v2/update-org-messaging-sms-templates/`,
  childrenListing: (domain) =>
    `https://${domain}.churchpad.com/v2/children-listing/`,
  fetchChildProfile: (domain) =>
    `https://${domain}.churchpad.com/v2/fetch-child-profile/`,
  updateOrgColors: (domain) =>
    `https://${domain}.churchpad.com/v2/update-org-color-themes/`,
  adminRecentContributions: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-recent-contributions/`,
  contributionNamesAndCodes: (domain) =>
    `https://${domain}.churchpad.com/v2/contribution-names-codes/`,
  adminChildDeleteOrUpdate: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/admin-child-profile-manager/`,
  deleteContributionName: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-contribution-name/`,
  availableContributionCodes: (domain) =>
    `https://${domain}.churchpad.com/v2/available-contribution-codes/`,
  addContributionName: (domain) =>
    `https://${domain}.churchpad.com/v2/add-contribution-name/`,
  changeContributionName: (domain) =>
    `https://${domain}.churchpad.com/v2/change-contribution-name//`,
  classroomCategories: (domain) =>
    `https://${domain}.churchpad.com/v2/classroom-categories/`,
  addClassroomCategory: (domain) =>
    `https://${domain}.churchpad.com/v2/add-classroom-category/`,
  changeClassroomCategoryPosition: (domain) =>
    `https://${domain}.churchpad.com/v2/change-classroom-category-position/`,
  deleteClassroomCategory: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-classroom-category/`,
  changeClassroomCategory: (domain) =>
    `https://${domain}.churchpad.com/v2/change-classroom-category/`,
  updateEmailTemplate: (domain) =>
    `https://${domain}.churchpad.com/v2/update-org-msg-email-templates/`,
  teachersListing: (domain) =>
    `https://${domain}.churchpad.com/v2/teachers-listing/`,
  searchTeacher: (domain) =>
    `https://${domain}.churchpad.com/v2/search-teachers/`,
  deleteTeacher: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-teacher/`,
  availableClassroomThisTeacher: (domain) =>
    `https://${domain}.churchpad.com/v2/available-classroom-this-teacher/`,
  addTeacher: (domain) => `https://${domain}.churchpad.com/v2/add-teacher/`,
  editTeacher: (domain) => `https://${domain}.churchpad.com/v2/edit-teacher/`,
  globalSystemSettings: (domain) =>
    `https://${domain}.churchpad.com/v2/global-system-settings-web/`,
  getRolesPermissionList: (domain) =>
    `https://${domain}.churchpad.com/v2/roles-permissions-listing/`,
  getUserWithRoles: (domain) =>
    `https://${domain}.churchpad.com/v2/users-with-roles-permissions/`,
  getUserRolePerm: (domain) =>
    `https://${domain}.churchpad.com/v2/user-roles-permissions/`,
  doUpdateRolesPerm: (domain) =>
    `https://${domain}.churchpad.com/v2/update-user-roles-permissions/`,
  doDeleteRolesPerm: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-user-roles-permissions/`,
  updateGlobalSystemSettings: (domain) =>
    `https://${domain}.churchpad.com/v2/update-global-system-settings-web/`,
  childrenListingGrid: (domain) =>
    `https://${domain}.churchpad.com/v2/children-listing-grid/`,
  childrenGridAndListing: (domain) =>
    `https://${domain}.churchpad.com/v2/children-grid-and-listing-web/`,
  childrenModuleSpecificInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/children-module-specific-info/`,
  getChildCheckedInToken: (domain) =>
    `https://${domain}.churchpad.com/v2/get-child-checked-in-token/`,
  childrenCheckinCheckout: (domain) =>
    `https://${domain}.churchpad.com/v2/children-checkin-checkout/`,
  doSearchUserRoles: (domain) =>
    `https://${domain}.churchpad.com/v2/search-users-roles-and-permissions/`,
  checkRolePerm: (domain) =>
    `https://${domain}.churchpad.com/v2/get-user-current-roles-permissions/`,
  addRolesPermNew: (domain) =>
    `https://${domain}.churchpad.com/v2/add-roles-permissions-new-user/`,
  generosityModuleSpecificInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/generosity-module-specific-info/`,
  changeTransactionContributionType: (domain) =>
    `https://${domain}.churchpad.com/v2/change-transaction-contribution-type/`,
  sendCommunicationChildren: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-parents-analyze/`,
  mediaVaultListing: (domain) =>
    `https://${domain}.churchpad.com/v2/media-vault-listing/`,
  deleteMediaVault: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-media-vault/`,
  uploadMediaVault: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/upload-media-vault/`,
  contributionsReporting: (domain) =>
    `https://${domain}.churchpad.com/v2/contributions-reporting/`,
  weeklyDatesGenerator: (domain) =>
    `https://${domain}.churchpad.com/v2/weekly-dates-generator/`,
  childrenDashboard: (domain) =>
    `https://${domain}.churchpad.com/v2/children-dashboard/`,
  peopleDashboard: (domain) =>
    `https://${domain}.churchpad.com/v2/people-dashboard/`,
  contactGroupsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/contact-groups-listing/`,
  addContactGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/add-contact-group/`,
  deleteContactGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-contact-group/`,
  renameContactGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/rename-contact-group/`,
  verifyTwoFA: (domain) =>
    `https://${domain}.churchpad.com/v2/verify-2fa-admin/`,
  contactGroupUsers: (domain) =>
    `https://${domain}.churchpad.com/v2/contact-group-users/`,
  addUsersToContactGroupAnalyze: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-users-to-contact-group-analyze/`,
  addUsersToContactGroupProcess: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-users-to-contact-group-process/`,
  updateContactGroupUser: (domain) =>
    `https://${domain}.churchpad.com/v2/update-contact-group-user/`,
  deleteUsersFromContactGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-users-from-contact-group/`,
  smartKeywordsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/smart-keywords-listing/`,
  deleteSmartKeyword: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-smart-keyword/`,
  departmentsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/departments-listing/`,
  addDepartment: (domain) =>
    `https://${domain}.churchpad.com/v2/add-new-department/`,
  deleteDepartmentGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-department/`,
  renameDepartmentGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/rename-department/`,
  deleteUsersFromDepartmentGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-users-from-department/`,
  departmentGroupUsers: (domain) =>
    `https://${domain}.churchpad.com/v2/list-members-department/`,
  addUsersToDepartment: (domain) =>
    `https://${domain}.churchpad.com/v2/add-user-to-department/`,
  fetchUserDeptServ: (domain) =>
    `https://${domain}.churchpad.com/v2/fetch-user-depts-service-units/`,
  positionsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/positions-listing/`,
  doSearchUserToJoinDept: (domain) =>
    `https://${domain}.churchpad.com/v2/search-user-to-join-department/`,
  addNewSmartKeyword: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-new-smart-keyword/`,
  editSmartKeyword: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/edit-smart-keyword/`,
  smartKeywordConsumers: (domain) =>
    `https://${domain}.churchpad.com/v2/smart-keyword-consumers/`,
  addRemoveUserAdminAccess: (domain) =>
    `https://${domain}.churchpad.com/v2/add-remove-dept-admin-permission/`,
  addPosition: (domain) =>
    `https://${domain}.churchpad.com/v2/add-new-position/`,
  renamePosition: (domain) =>
    `https://${domain}.churchpad.com/v2/rename-position/`,
  deletePosition: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-position/`,
  positionMembers: (domain) =>
    `https://${domain}.churchpad.com/v2/list-members-position/`,
  sendCommunicationParentsProcess: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-parents-process/`,
  salutationsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/titles-salutations-listing/`,
  addSalutation: (domain) =>
    `https://${domain}.churchpad.com/v2/add-new-title-salutation/`,
  deleteSalutation: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-title-salutation/`,
  renameSalutation: (domain) =>
    `https://${domain}.churchpad.com/v2/rename-title-salutation/`,
  salutationMembers: (domain) =>
    `https://${domain}.churchpad.com/v2/list-members-title-salutation/`,
  leadershipsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/leadership-listing/`,
  addUpdateleadership: (domain) =>
    `https://${domain}.churchpad.com/v2/add-update-user-leadership/`,
  deleteleadership: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-user-from-leadership/`,
  changeleadership: (domain) =>
    `https://${domain}.churchpad.com/v2/change-leadership-view-position/`,
  doSearchUserToJoinLeadership: (domain) =>
    `https://${domain}.churchpad.com/v2/search-user-to-join-leadership/`,
  searchParentGuardianToCheckIn: (domain) =>
    `https://${domain}.churchpad.com/v2/search-parent-guardian-to-checkin/`,
  getChildrenWithCheckInStatus: (domain) =>
    `https://${domain}.churchpad.com/v2/get-children-with-check-in-status/`,
  checkInTheseChildren: (domain) =>
    `https://${domain}.churchpad.com/v2/check-in-these-children/`,
  fetchCommunicationGroupsByCategory: (domain) =>
    `https://${domain}.churchpad.com/v2/fetch-communication-groups-by-category/`,
  enableDisabledUserAccount: (domain) =>
    `https://${domain}.churchpad.com/v2/enable-disable-user-account/`,
  doSearchSpouse: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-search-spouse/`,
  updateSpouse: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-set-or-unset-spouse/`,
  adminChangePwd: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-change-user-pwd/`,
  adminChildManager: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/admin-child-profile-manager/`,
  getFollowUpByOrganizationID: (id) =>
    `${domainNextGen}/api/follow-ups/organization/${id}`,
  uploadMediaCdn: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/upload-media-cdn/`,
  deleteMediaCdn: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-media-cdn/`,
  updateFollowUp: (id) => `${domainNextGen}/api/follow-ups/${id}`,
  newFollowUpFormSubmission: (orgId, startDate, endDate) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/form-submissions?start=${startDate}&end=${endDate}`,
  assignedFollowUpMembers: (orgId, startDate, endDate, page, size) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/guest-assignments?start=${startDate}&end=${endDate}&page=${page}&size=${size}`,
  getClosedOutForms: (orgId) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/closed-form-submissions`,
  getActivityLog: (orgId) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/activity-log`,
  assignGuest: (orgId) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/assign-guest`,
  createActivityLog: (orgId) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/activity-log`,
  activityLogUrl: (orgId) =>
    `${domainNextGen}/api/follow-ups/organization/${orgId}/activity-log`,
  jwtAccessToken: (domain) => `${domain}/api/authenticate`,
  uCMessageLogs: (domain) =>
    `https://${domain}.churchpad.com/v2/uc-message-logs/`,
  deleteUser: (domain) => `https://${domain}.churchpad.com/v2/delete-user/`,
  getSermons: (id) => `${domainNextGen}/api/sermons/organization/${id}/list`,
  createSermon: () => `${domainNextGen}/api/sermons`,
  deleteSermon: (id) => `${domainNextGen}/api/sermons/${id}`,
  updateSermon: (id) => `${domainNextGen}/api/sermons/${id}`,
  getSermonsList: (id) =>
    `${domainNextGen}/api/streams/organization/${id}/play-lists`,
  createSermonGroup: () => `${domainNextGen}/api/sermonGroups`,
  updateSermonGroup: (id) => `${domainNextGen}/api/sermonGroups/${id}`,
  getSermonGroup: (id) => `${domainNextGen}/api/streams/play-lists/${id}`,
  deleteSermonGroup: (id) => `${domainNextGen}/api/sermonGroups/${id}`,
  searchSermons: (id) =>
    `${domainNextGen}/api/streams/organization/${id}/search/`,
  addSermonsToGroup: (id) => `${domainNextGen}/api/sermonGroups/${id}/sermons`,
  deleteSermonsToGroup: (id) =>
    `${domainNextGen}/api/sermonGroups/${id}/sermons`,
  updateSermontPlaylistDisplayOrder: (id) =>
    `${domainNextGen}/api/sermonGroups/organization/${id}/display-order`,
  eventUrl: (orgId) =>
    `${domainNextGen}/api/events/calendar/organization/${orgId}`,
  createEventUrl: () => `${domainNextGen}/api/events`,
  getFollowUpFormDetails: (payload) => {
    // console.log(payload, "payload2323");
    return `${domainNextGen}/api/forms/${payload.formId}/organization/${payload.orgId}/answers?legacy-user-id=${payload.legacyUserId}`;
  },
  getSermonsBySeries: (id) =>
    `${domainNextGen}/api/sermon-seriess/organization/${id}`,
  updateSermonSeries: (id) => ` ${domainNextGen}/api/sermon-seriess/${id}`,
  createSeries: (id) =>
    ` ${domainNextGen}/api/sermon-seriess/organization/${id}`,
  deleteSermonSeries: (id) => ` ${domainNextGen}/api/sermon-seriess/${id}`,
  unifiedCommunicationSendEngineAnalyze: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/unified-communication-send-engine-analyze/`,
  createPreacher: (id) => `${domainNextGen}/api/preachers/organization/${id}`,
  updateSermonPreacher: (id) => `${domainNextGen}/api/preachers/${id}`,
  getSermonPreacher: (id) =>
    `${domainNextGen}/api/preachers/organization/${id}`,
  deleteSermonPreacher: (id) => ` ${domainNextGen}/api/preachers/${id}`,
  searchPreachers: (id) =>
    `${domainNextGen}/api/preachers/organization/${id}/search?`,
  unifiedCommunicationSendEngineProcess: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/unified-communication-send-engine-process/`,
  getGroups: (id, group) =>
    `${domainNextGen}/api/groups/organization/${id}/type/${group}/list`,
  createGroup: (id) => `${domainNextGen}/api/groups/organization/${id}`,
  updateGroup: (id) => `${domainNextGen}/api/groups/${id}`,
  deleteGroup: (id) => `${domainNextGen}/api/groups/${id}`,
  approveUser: (id, groupId) =>
    `${domainNextGen}/api/groups/${groupId}/users/${id}/approve`,
  removeUser: (groupd, userId) =>
    `${domainNextGen}/api/groups/${groupd}/users/${userId}`,
  getUsersInGroup: (groupd) => `${domainNextGen}/api/groups/${groupd}/users`,
  listNotesOfChild: (domain) =>
    `https://${domain}.churchpad.com/v2/list-notes-of-child/`,
  addNoteToChild: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-note-child/`,
  listCommentsOfThisChildNote: (domain) =>
    `https://${domain}.churchpad.com/v2/list-comments-of-this-child-note/`,
  addCommentToThisChildNote: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-comment-to-this-child-note/`,
  deleteNoteOfChild: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-note-of-child/`,
  editNoteOfChild: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/edit-note-of-child/`,
  deleteCommentOfThisChildNote: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-comment-of-this-child-note/`,
  editCommentOfThisChildNote: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/edit-comment-of-this-child-note/`,
  mergeAccounts: (domain) =>
    `https://${domain}.churchpad.com/v2/merge-these-accounts/`,
  getShop: (orgId) => `${domainNextGen}/api/shops/organization/${orgId}`,
  listCheckInsOfChild: (domain) =>
    `https://${domain}.churchpad.com/v2/list-check-ins-of-child/`,
  childrenCheckInsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/children-checkins-listing/`,
  updateShop: (id) => ` ${domainNextGen}/api/shops/${id}`,
  childrenCheckinsReporting: (domain) =>
    `https://${domain}.churchpad.com/v2/children-checkins-reporting/`,
  createTag: (orgId) =>
    `${domainNextGen}/api/category-type-attributes/organization/${orgId}`,
  getTags: (orgId) =>
    `${domainNextGen}/api/category-type-attributes/organiation/${orgId}`,
  updateTag: (categoryAttributeId) =>
    `${domainNextGen}/api/category-type-attributes/${categoryAttributeId}`,
  deleteTag: (categoryAttributeId) =>
    `${domainNextGen}/api/category-type-attributes/${categoryAttributeId}`,
  sendCommunicationOrg: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-celebration-messaging-template/`,
  followUpDashboard: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-dashboard/`,
  discountUrl: (orgId) =>
    `${domainNextGen}/api/discounts/organization/${orgId}`,
  discountUrlupdateDelete: (orgId) => `${domainNextGen}/api/discounts/${orgId}`,
  searchDiscounts: (orgId) =>
    `${domainNextGen}/api/discounts/organization/${orgId}/search?`,
  getProducts: (orgId) => `${domainNextGen}/api/items/organization/${orgId}`,
  updateProduct: (orgId) => `${domainNextGen}/api/items/${orgId}`,
  createGetAddress: (orgId) =>
    `${domainNextGen}/api/addresss/organization/${orgId}`,
  updateDeleteAddress: (orgId) => `${domainNextGen}/api/addresss/${orgId}`,
  searchAddresses: (orgId) =>
    `${domainNextGen}/api/addresss/organization/${orgId}/search?`,
  searchAddress: (orgId, word) =>
    `${domainNextGen}/api/addresss/organization/${orgId}/search?q=${word}`,
  getOrganisationAddress: (orgId) =>
    `${domainNextGen}/api/addresss/organization/${orgId}?page=0&size=100`,
  deleteEvent: (id) => `${domainNextGen}/api/events/${id}`,
  closeOutForm: (
    orgId,
    id
  ) => `${domainNextGen}/api/follow-ups/organization/${orgId}/user/${id}/form-submissions/close-out
  `,
  ticketListing: (domain) =>
    `https://${domain}.churchpad.com/v2/tickets-listing/`,
  ticketInfo: (domain) =>
    `https://${domain}.churchpad.com/v2/ticket-information/`,
  createTicket: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/new-ticket/`,
  updateTicket: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/update-ticket/`,
  closeTicket: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/close-ticket/`,
  deleteTicket: (domain) => `https://${domain}.churchpad.com/v2/delete-ticket/`,
  doNotTextCallEmailListing: (domain) =>
    `https://${domain}.churchpad.com/v2/do-not-text-call-email-listing/`,
  addDoNotTextCall: (domain) =>
    `https://${domain}.churchpad.com/v2/add-do-not-text-call/`,
  addDoNotEmail: (domain) =>
    `https://${domain}.churchpad.com/v2/add-do-not-email/`,
  deleteDoNotTextCall: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-do-not-text-call/`,
  deleteDoNotEmail: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-do-not-email/`,
  searchDoNotTextCallEmail: (domain) =>
    `https://${domain}.churchpad.com/v2/search-do-not-text-call-email/`,
  accessSecurityAuditListing: (domain) =>
    `https://${domain}.churchpad.com/v2/access-security-audit-listing/`,
  searchAccessSecurityAuditByUserId: (domain) =>
    `https://${domain}.churchpad.com/v2/search-access-security-audit-by-userid/`,
  getDistricts: (orgId) =>
    `${domainNextGen}/api/house-fellowship-districts/organization/${orgId}?page=0&size=10`,
  deleteDistrict: (id) =>
    `${domainNextGen}/api/house-fellowship-districts/${id}`,
  createDistrict: (id) =>
    `${domainNextGen}/api/house-fellowship-districts/organization/${id}`,
  updateDistrict: (id) =>
    `${domainNextGen}/api/house-fellowship-districts/${id}`,
  getZones: (id) =>
    `${domainNextGen}/api/house-fellowship-zones/organization/${id}?page=0&size=10`,
  deleteZone: (id) => `${domainNextGen}/api/house-fellowship-zones/${id}`,
  updateZone: (id) => `${domainNextGen}/api/house-fellowship-zones/${id}`,
  createZone: (id) =>
    `${domainNextGen}/api/house-fellowship-zones/organization/${id}`,
  getHouseFellowship: (orgId) =>
    `${domainNextGen}/api/house-fellowships/organization/${orgId}?page=0&size=10`,
  deleteHouseFellowship: (id) => `${domainNextGen}/api/house-fellowships/${id}`,
  createFellowship: (orgId) =>
    `${domainNextGen}/api/house-fellowships/organization/${orgId}`,
  updateCommunicationOrg: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/edit-celebration-messaging-template/`,
  celebrationListing: (domain) =>
    `https://${domain}.churchpad.com/v2/celebration-messaging-templates-listing/`,
  birthdaysWeddingsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/birthdays-weddings-listing/`,
  adminUpdateUserProfileExpress: (domain) =>
    `https://${domain}.churchpad.com/v2/admin-update-user-profile-express/`,
  deleteCom: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-celebration-messaging-template/`,
  followUpListings: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-listings/`,
  guestSubmittedForm: (domain) =>
    `https://${domain}.churchpad.com/v2/guest-submitted-form/`,
  followUpMembersList: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-members-list/`,
  assignOrReassignFollowUp: (domain) =>
    `https://${domain}.churchpad.com/v2/assign-or-reassign-follow-up/`,
  deleteFollowUp: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-follow-up/`,
  guestActivityLog: (domain) =>
    `https://${domain}.churchpad.com/v2/guest-activity-log/`,
  followUpLogActivity: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/follow-up-log-activity/`,
  deleteFellowship: (id) => `${domainNextGen}/api/house-fellowships/${id}`,
  updateHouseFellowship: (id) => `${domainNextGen}/api/house-fellowships/${id}`,
  addInterestServiceGroup: (domain) =>
    `https://${domain}.churchpad.com/v2/add-interest-service-group/`,
  // editInterestServiceGroup: (domain) =>
  //   `https://${domain}.churchpad.com/v2/edit-interest-service-group/`,
  editInterestServiceGroup: () => `groups/edit-interest-service-group/`,
  importPeople: (domain) => `https://${domain}.churchpad.com/v2/import-people/`,
  getExportableData: (domain) =>
    `https://${domain}.churchpad.com/v2/get-exportable-data/`,
  exportTheseData: (domain) =>
    `https://${domain}.churchpad.com/v2/export-these-data/`,
  deletePhoneNumberAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-phone-number-admin/`,
  addPhoneNumberAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/add-phone-number-admin/`,
  followUpReporting: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-reporting/`,
  changeGroupBanner: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/change-group-banner/`,
  mobileAppCustomizationListing: (domain) =>
    `https://${domain}.churchpad.com/v2/mobile-app-customization-listing/`,
  updateAppAssetInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/update-app-asset-information/`,
  changeAppAssetPositions: (domain) =>
    `https://${domain}.churchpad.com/v2/change-app-asset-positions/`,
  enableOrDisableAppDashboardModule: (domain) =>
    `https://${domain}.churchpad.com/v2/enable-or-disable-app-dashboard-module/`,
  changeAppAssetImageOrVideo: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/change-app-asset-image-or-video/`,
  userContributionsHistory: (domain) =>
    `https://${domain}.churchpad.com/v2/user-contributions-history-admin/`,
  getUserContributionStatement: (domain) =>
    `https://${domain}.churchpad.com/v2/yearly-contribution-statements-admin/`,
  addNewAppSliderAsset: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-new-app-asset/`,
  followUpReportingViewData: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-reporting-view-data/`,
  addManualContribution: (domain) =>
    `https://${domain}.churchpad.com/v2/add-manual-contributions/`,
  qrCodesAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/qr-codes-admin/`,
  messagingCreditsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/messaging-credits-listing/`,
  orgPaymentMethod: (domain) =>
    `https://${domain}.churchpad.com/v2/organization-payment-methods/`,
  buyMsgCreditWithExistingToken: (domain) =>
    `https://${domain}.churchpad.com/v2/buy-msg-credit-with-existing-token/`,
  buyMsgCreditWithNewCardOrCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/buy-msg-credit-with-new-card-or-check/`,
  msgCreditPurchasedHistory: (domain) =>
    `https://${domain}.churchpad.com/v2/msg-credits-purchased-history/`,
  enableOrDisableMsgCreditsAutoRecharge: (domain) =>
    `https://${domain}.churchpad.com/v2/enable-or-disable-msg-credits-auto-recharge/`,
  updateAdminTableColumnsView: (domain) =>
    `https://${domain}.churchpad.com/v2/update-admin-table-columns-view/`,
  resetToDefaultAdminTableColView: (domain) =>
    `https://${domain}.churchpad.com/v2/reset-to-default-admin-tbl-col-view/`,
  imNewHereSubmission: (domain) =>
    `https://${domain}.churchpad.com/v2/im-new-here-submission-web/`,
  importContributionsColumns: (domain) =>
    `https://${domain}.churchpad.com/v2/import-contributions-columns/`,
  importContributionsAnalyze: (domain) =>
    `https://${domain}.churchpad.com/v2/import-contributions-analyze/`,
  importContributionsProcess: (domain) =>
    `https://${domain}.churchpad.com/v2/import-contributions-process/`,
  liveStreamStatus: (id) =>
    `${domainNextGen}/api/sermons/organization/${id}/live-stream-status`,
  resetLiveStreamStatus: (id) =>
    `${domainNextGen}/api/sermons/organization/${id}/refresh-live-stream`,
  listOfEvent: (id) =>
    `${domainNextGen}/api/events/organization/${id}/list?unique=true`,
  eventRegistration: (id) => `${domainNextGen}/api/events/${id}/registrations`,
  getPricing: (domain) =>
    `https://${domain}.churchpad.com/v2/upgrade-or-downgrade-service/`,
  systemTooltipsModalComments: (domain) =>
    `https://${domain}.churchpad.com/v2/system-tooltips-modal-comments-web/`,
  getMedias: (id) => `${domainNextGen}/api/media/organization/${id}/list`,
  createMedia: () => `${domainNextGen}/api/media`,
  deleteMedia: (id) => `${domainNextGen}/api/media/${id}`,
  updateMedia: (id) => `${domainNextGen}/api/media/${id}`,
  getMediaList: (id) => `${domainNextGen}/api/mediaGroups/organization/${id}`,
  createMediaGroup: () => `${domainNextGen}/api/mediaGroups`,
  updateMediaGroup: (id) => `${domainNextGen}/api/mediaGroups/${id}`,
  getMediaGroup: (id) => `${domainNextGen}/api/mediaGroups/${id}`,
  deleteMediaGroup: (id) => `${domainNextGen}/api/mediaGroups/${id}`,
  searchMedia: (id) => `${domainNextGen}/api/media/organization/${id}/search`,
  addMediaToGroup: (id) => `${domainNextGen}/api/mediaGroups/${id}/media`,
  deleteMediaFromGroup: (id) => `${domainNextGen}/api/mediaGroups/${id}/media`,
  updateMediatPlaylistDisplayOrder: (id) =>
    `${domainNextGen}/api/mediaGroups/organization/${id}/display-order`,
  getMediaBySeries: (id) =>
    `${domainNextGen}/api/media-seriess/organization/${id}`,
  updateMediaSeries: (id) => ` ${domainNextGen}/api/media-seriess/${id}`,
  createSeriesMedia: (id) =>
    ` ${domainNextGen}/api/media-seriess/organization/${id}`,
  deleteMediaSeries: (id) => ` ${domainNextGen}/api/media-seriess/${id}`,
  createSpeaker: (id) => `${domainNextGen}/api/speakers/organization/${id}`,
  updateMediaSpeaker: (id, type) => `${domainNextGen}/api/speakers/${id}`,
  getMediaSpeaker: (id, type) =>
    `${domainNextGen}/api/speakers/organization/${id}`,
  getSpeakerUrl: (id) => `${domainNextGen}/api/speakers/organization/${id}`,
  deleteMediaSpeaker: (id) => ` ${domainNextGen}/api/speakers/${id}`,
  searchSpeakers: (id) =>
    `${domainNextGen}/api/speakers/organization/${id}/search?`,
  resetToDefaultAppAssets: (domain) =>
    `https://${domain}.churchpad.com/v2/reset-to-default-app-assets/`,
  deleteAppAsset: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-app-asset/`,
  orgInvoicesListing: (domain) =>
    `https://${domain}.churchpad.com/v2/org-invoices-listing/`,
  orgCardCheckStoredTokens: (domain) =>
    `https://${domain}.churchpad.com/v2/org-card-check-stored-tokens/`,
  orgDeleteCardCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/org-delete-card-check/`,
  orgPayWithExistingCardCheckToken: (domain) =>
    `https://${domain}.churchpad.com/v2/org-pay-with-existing-card-check-token/`,
  orgPayWithNewCardOrCheck: (domain) =>
    `https://${domain}.churchpad.com/v2/org-pay-with-new-card-or-check/`,
  orgGetThisInvoicePdf: (domain) =>
    `https://${domain}.churchpad.com/v2/org-get-this-invoice-pdf/`,
  followUpDynamicForm: (domain) =>
    `https://${domain}.churchpad.com/v2/follow-up-dynamic-form-web/`,
  cancelServiceRequest: (domain) =>
    `https://${domain}.churchpad.com/v2/cancel-service-request/`,
  cancelServiceInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/cancel-service-information/`,
  freeTrialExtensionRequest: (domain) =>
    `https://${domain}.churchpad.com/v2/free-trial-extension-request/`,
  freeTrialExtensionInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/free-trial-extension-information/`,
  upgradeOrDowngradeServiceRequest: (domain) =>
    `https://${domain}.churchpad.com/v2/upgrade-or-downgrade-service-request/`,
  addAutomatedGreetings: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-automated-greetings/`,
  automatedGreetingsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/automated-greetings-listing/`,
  getAutomatedGreeting: (domain) =>
    `https://${domain}.churchpad.com/v2/get-automated-greeting/`,
  contributionsReportingByDonor: (domain) =>
    `https://${domain}.churchpad.com/v2/contributions-reporting-by-donors/`,
  updateAutomatedGreeting: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/update-automated-greeting/`,
  deleteAutomatedGreeting: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-automated-greeting/`,
  testAutomatedGreeting: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/test-automated-greeting/`,
  groupUsers: (groupd, status) =>
    `${domainNextGen}/api/groups/${groupd}/users?page=0&size=50&status=${status}`,
  groupUsersPending: (orgId, status, type) =>
    `${domainNextGen}/api/groups/organization/${orgId}/users?page=0&size=50&status=${status}&type=${type}`,
  uCScheduledMessages: (domain) =>
    `https://${domain}.churchpad.com/v2/uc-scheduled-messages/`,
  uCDeleteScheduledMessage: (domain) =>
    `https://${domain}.churchpad.com/v2/uc-delete-scheduled-message/`,
  uCUpdateScheduledMessage: (domain) =>
    `https://${domain}.churchpad.com/v2/uc-update-scheduled-message/`,
  uCMessageLogsGetDetails: (domain) =>
    `https://${domain}.churchpad.com/v2/uc-message-logs-get-details/`,
  uCScheduledMessageGetDetails: (domain) =>
    `https://${domain}.churchpad.com/v2/uc-scheduled-message-get-details/`,
  loginByToken: (domain) => `https://${domain}.churchpad.com/v2/admin-login/`,
  actionContributionStatementPdf: (domain) =>
    `https://${domain}.churchpad.com/v2/action-contribution-statement-pdf/`,
  contributionStatementMultiple: (domain) =>
    `https://${domain}.churchpad.com/v2/group-process-contribution-statements-pdf/`,
  contributionStatementsAccounting: (domain) =>
    `https://${domain}.churchpad.com/v2/contribution-statements-accounting/`,
  sendCommunicationToSpecialGroupAnalyze: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-to-special-group-analyze/`,
  sendCommunicationToSpecialGroupProcess: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/send-communication-to-special-group-process/`,
  addOrGetAlbum: (orgId) =>
    `${domainNextGen}/api/podcastAlbums/organization/${orgId}`,
  getOrUpdateOrDeleteAlbum: (id) => `${domainNextGen}/api/podcastAlbums/${id}`,
  albumEpisodes: (albumId) =>
    `${domainNextGen}/api/podcastAlbums/${albumId}/episodes`,
  searchAlbums: (episodeId) =>
    `${domainNextGen}/api/podcastAlbums/organization/${episodeId}/search`,
  searchCategories: (orgId) =>
    `${domainNextGen}/api/podcastCategories/organization/${orgId}/search`,
  createOrGetAlbumCategory: (orgId) =>
    `${domainNextGen}/api/podcastCategories/organization/${orgId}`,
  getOrUpdateOrDeleteAlbumCategories: (id) =>
    `${domainNextGen}/api/podcastCategories/${id}`,
  uploadFile: (episodeId) =>
    `${domainNextGen}/api/podcastEpisodes/${episodeId}/upload-media-file`,
  addOrDeleteAlbumFromCategory: (episodeId) =>
    `${domainNextGen}/api/podcastCategories/${episodeId}/albums`,
  reorderAlbumsInCategory: (categoryId) =>
    `${domainNextGen}/api/podcastCategories/${categoryId}/albums/display-order`,
  reorderCategory: (orgId) =>
    `${domainNextGen}/api/podcastCategories/organization/${orgId}/display-order`,
  reorderAlbumEpisodes: (id) =>
    `${domainNextGen}/api//podcastAlbums/${id}/episodes/display-order`,
  updateFollowUpGuestInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/update-follow-up-guest-information-web/`,
  importChildren: (domain) =>
    `https://${domain}.churchpad.com/v2/import-children/`,
  searchFollowUp: (domain) =>
    `https://${domain}.churchpad.com/v2/search-follow-up/`,
  getOrganizationForms: (orgId) => `forms?org_id=${orgId}`,
  createOrganizationForms: (orgId) =>
    `${domainNextGen}/api/formSchemas/organization/${orgId}`,
  deleteOrganizationForms: (formId, orgId) =>`forms/${formId}?org_id=${orgId}`,
  updateOrganizationForm: (formId) =>
    `${domainNextGen}/api/formSchemas/${formId}`,
  getAllFormSubmission: (orgId) =>
    `${domainNextGen}/api/formSubmissions/organization/${orgId}?page=0&size=20`,
  formSubmissionStatus: (Id) =>
    `${domainNextGen}/api/formSubmissions/${Id}/update-status`,
  videoUploadDynamicForms: (orgId) =>
    `${domainNextGen}/api/formSchemas/upload-media-file`,
  voidTransactionProcess: (domain) =>
    `https://${domain}.churchpad.com/v2/void-transaction-process/`,
  voidTransactionInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/void-transaction-information/`,
  checkInAttendanceListingAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/checkin-attendance-listing-admin/`,
  addCheckInAttendance: (domain) =>
    `https://${domain}.churchpad.com/v2/add-checkin-attendance/`,
  editCheckInAttendance: (domain) =>
    `https://${domain}.churchpad.com/v2/edit-checkin-attendance/`,
  deleteCheckInAttendance: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-checkin-attendance/`,
  checkInAttendanceRecent: (domain) =>
    `https://${domain}.churchpad.com/v2/checkin-attendance-recent/`,
  checkInAttendeesInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/checkin-attendees-information/`,
  checkInAttendeesReporting: (domain) =>
    `https://${domain}.churchpad.com/v2/checkin-attendees-reporting/`,
  getFoodAllergy: (domain) =>
    `https://${domain}.churchpad.com/v2/food-allergy-types/`,
  addFoodAllergyType: (domain) =>
    `https://${domain}.churchpad.com/v2/add-food-allergy-type/`,
  changeFoodAllergyName: (domain) =>
    `https://${domain}.churchpad.com/v2/change-food-allergy-type-name/`,
  deleteFoodAllergy: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-food-allergy-type/`,
  changeFoodAllergyPosition: (domain) =>
    `https://${domain}.churchpad.com/v2/change-food-allergy-types-position/`,
  changeMobileAppImageSliders: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/change-mobile-app-image-sliders/`,
  addMobileAppImageSliders: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/add-mobile-app-image-sliders/`,
  deleteMobileAppImageSliders: (domain) =>
    `https://dev.churchpad.com/${domain}/v2/delete-mobile-app-image-sliders/`,
  verifyRouting: (domain) =>
    `https://${domain}.churchpad.com/v2/verify-usa-bank-routing-number/`,
  onBoardCustommer: () => `${onBoardingDomain}/onboard`,
  submitProposal: () => `${onBoardingDomain}/church/app-proposal`,
  fetchChurchSize: () => `${onBoardingDomain}/church/sizes`,
  fetchChurchProducts: () => `${onBoardingDomain}/church/products`,
  checkShortName: () => `${onBoardingDomain}/church/short-names`,
  imNewHereDynamicForm: (domain) =>
    `https://${domain}.churchpad.com/v2/im-new-here-dynamic-form-web/`,
  childrenCheckInsPresentOrAbsentList: (domain) =>
    `https://${domain}.churchpad.com/v2/children-checkins-present-or-absent-list/`,
  foodAllergyListChildren: (domain) =>
    `https://${domain}.churchpad.com/v2/food-allergy-list-children/`,
  cardsCheckingTokensAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/cards-checking-tokens-admin/`,
  giveWithAnExistingCardCheckingAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-an-existing-card-checking-admin/`,
  giveWithANewCardAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-a-new-card-admin/`,
  giveWithANewCheckingAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/give-with-a-new-checking-admin/`,
  membersExpiringCardsListing: (domain) =>
    `https://${domain}.churchpad.com/v2/members-expiring-cards-listing/`,
  updateAppWalkThruAssetInformation: (domain) =>
    `https://${domain}.churchpad.com/v2/update-app-walkthru-asset-information/`,
  deleteExpiringCardAdmin: (domain) =>
    `https://${domain}.churchpad.com/v2/delete-expiring-card-admin/`,
  fetchStreams: (id) => `${domainNextGen}/api/streams/organization/${id}`,
  getFeaturedStreams: (id) =>
    `${domainNextGen}/api/streams/organization/${id}/featured`,
  updateFeaturedStream: (id) =>
    `${domainNextGen}/api/streams/organization/${id}/featured`,
  searchPlaylist: (id) =>
    `${domainNextGen}/api/streams/organization/${id}/play-lists/search/`,
  mserviceLogin: (domain) => `${domain}api/auth/`,
  deleteGroupLeader: (id) => `groups/remove_leader/${id}/`,
  giveMethods: () => `give/methods`,
  raiseTheMoneyUrl1: () => `${onBoardingDomain}/give/raise-the-money/register`,
  globalGiving: () => `${onBoardingDomain}/church/global-giving`,
  groupCoordinators: (uuid) => `groups/${uuid}`,
  createForms: () => 'forms',
  updateForms: (id) => `forms/${id}`,
  getFormByIdUrl: (id, orgId) => `forms/${id}?org_id=${orgId}`,
  submittedFormsUrl: (id, orgId) => `forms/${id}/submissions?org_id=${orgId}`,
  requestOTPOnboarding: () => `${onBoardingDomain}/onboard/request-code`,
};

// basicAuthPayload payload needed for axios calls
export const basicAuthPayload = {
  username: process.env.REACT_APP_BASIC_AUTH_USERNAME,
  password: process.env.REACT_APP_BASIC_AUTH_PASSWORD,
};

export const defaultAction = (type, payload) => ({ type, payload });

const isOnBoarding =
  window.location.hostname === "onboarding.churchpad.com" ||
  window.location.hostname === "onboarding-test.churchpad.com";
let path = window.location.pathname;
let parts = path.split("/");
let churchName = parts[1];

// grabs the domain name from browser URL like www.example.com
export const domainName = isOnBoarding
  ? `${window.location.hostname}/${churchName}`
  : window.location.hostname;
// export const domainName1 = 'democpadmin1.churchpad.com';

export const nodeEnv = {
  production: "production",
  development: "development",
  test: "test",
};

export const repeatNumbers = [
  "0000",
  "1111",
  "2222",
  "3333",
  "4444",
  "5555",
  "6666",
  "7777",
  "8888",
  "9999",
];

export const months = [
  { id: "01", value: "January" },
  { id: "02", value: "February" },
  { id: "03", value: "March" },
  { id: "04", value: "April" },
  { id: "05", value: "May" },
  { id: "06", value: "June" },
  { id: "07", value: "July" },
  { id: "08", value: "August" },
  { id: "09", value: "September" },
  { id: "10", value: "October" },
  { id: "11", value: "November" },
  { id: "12", value: "December" },
];

export const generateYears = () => {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 100))
    .fill("")
    .map((v, idx) => now - idx);

  return years;
};

export const generateArrayOfYears = () => {
  const min = new Date().getFullYear();
  const max = min + 21;
  const years = [];

  for (let i = min; i < max; i++) {
    years.push(i.toString());
  }
  return years;
};

export const dateFormatter = (day, mon, year) => {
  const fDay = day.length === 1 ? `-0${day}` : `-${day}`;

  const fYear = year !== "" ? `${year}-` : "0000-";

  return fYear + mon + fDay;
};

export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const addDays = (n) => {
  const t = new Date();
  t.setDate(t.getDate() + n);
  let month = "0" + (t.getMonth() + 1);
  let date = "0" + t.getDate();
  month = month.slice(-2);
  date = date.slice(-2);
  const newDate = date + "/" + month + "/" + t.getFullYear();
  return newDate;
};

export const nextgenUrl = {
  live: "https://apix.churchpad.com/nextgen",
  staging: "https://apps1.churchpad.com/nextgen",
};

export const mserviceUrl = {
  live: "https://mservice.churchpad.com/api/",
  staging: "https://mservice-test.churchpad.com/api/",
};

export const onBoardUrl = {
  live: "https://cp-live.churchpad.com/api",
  staging: "https://cp-test.churchpad.com/api",
};

export const domainNextGen = checkNodeEnvNextGen(
  nextgenUrl.live,
  nextgenUrl.staging
);
export const onBoardingDomain = checkNodeEnvNextGen(
  onBoardUrl.live,
  onBoardUrl.staging
);

export const iconTypes = {
  jpeg: "image",
  png: "image",
  jpg: "image",
  svg: "image",
  doc: "document",
  docx: "document",
  xlsx: "excel",
  xls: "excel",
  pdf: "pdf",
  csv: "csv",
  ppt: "powerPoint",
  pptx: "powerPoint",
};

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
