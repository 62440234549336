import { actionTypes } from "../actionTypes";

export const importContributionsColumns = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.IMPORT_CONTRIBUTION_COLUMNS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.IMPORT_CONTRIBUTION_COLUMNS_FAILURE:
    case actionTypes.IMPORT_CONTRIBUTION_COLUMNS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const importContributionsAnalyze = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.IMPORT_CONTRIBUTION_ANALYZE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.IMPORT_CONTRIBUTION_ANALYZE_FAILURE:
    case actionTypes.IMPORT_CONTRIBUTION_ANALYZE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const importContributionsProcess = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.IMPORT_CONTRIBUTION_PROCESS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.IMPORT_CONTRIBUTION_PROCESS_FAILURE:
    case actionTypes.IMPORT_CONTRIBUTION_PROCESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const contributionsReportingByDonor = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTRIBUTIONS_REPORTING_BY_DONOR_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONTRIBUTIONS_REPORTING_BY_DONOR_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.CONTRIBUTIONS_REPORTING_BY_DONOR_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const contributionStatementsAccounting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTRIBUTIONS_STATEMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONTRIBUTIONS_STATEMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.CONTRIBUTIONS_STATEMENT_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const actionContributionStatementPdf = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTRIBUTIONS_STATEMENT_ACTION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONTRIBUTIONS_STATEMENT_ACTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const contributionStatementMultiple = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTRIBUTIONS_STATEMENT_ACTION_MULTIPLE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONTRIBUTIONS_STATEMENT_ACTION_MULTIPLE_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const membersExpiringCardsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MEMBERS_EXPIRY_CARD_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.MEMBERS_EXPIRY_CARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteExpiringCardAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MEMBERS_EXPIRY_CARD_DELETE_SUCCESS:
      return {
        ...state,
        ...action.payload
        // loading: true,
      };
    case actionTypes.MEMBERS_EXPIRY_CARD_DELETE_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
