import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { loginByToken } from "redux/actions";

export default function LoginByToken() {
  const classes = useStyles();
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const productList = useSelector(
    (state) => state.secondOrgInfo?.responseData?.products,
    shallowEqual
  );

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const loginData = useSelector((state) => state.loginData, shallowEqual);
  const query = new URLSearchParams(search).get("t");
  const redirectToDashboard = () => {
    history.push("/dashboard/default");
  };


  const setProductList = () => {
    // localStorage.setItem("productList", JSON.stringify(productList));
  };
  React.useEffect(() => {
    if (query) {
      dispatch(
        loginByToken(
          {
            orgId: orgInfo.orgId,
            userToken: query,
          },
          setProductList
        )
      );
    }
  }, [query]);

  React.useEffect(() => {
    if (loginData.responseCode === "00") {
      redirectToDashboard();
    }
  }, [loginData.responseCode, loginData]);

  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
    ></div>
  );
}

const useStyles = makeStyles(({ palette }) => ({
  cardHolder: {
    background: "#1A2038",
  },
}));
