import React, { useState } from "react";
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  Switch,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles } from "@material-ui/core/styles";
import { useThemeMode } from "../../../utils/utils";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import MatxDivider from "_Matx/MatxDivider/MatxDivider";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { login } from "redux/actions";
import { useTheme } from "@material-ui/core";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "./phone.css";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import PinInputComponent from "components/PinInput";
import { verifyTwoFA } from "redux/actions/Auth";
import StyledRadio from "components/StyledRadio";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "#1A2038",
  },
  card: {
    maxWidth: 800,
    margin: "1rem",
  },
  logoImg: {
    width: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
    },
  },
  cardLeft: {
    background: "#161c37 url(/assets/images/bg-3.png) no-repeat",
    backgroundSize: "cover",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    "& span": {
      fontSize: 26,
      lineHeight: 1.3,
      fontWeight: 800,
    },
  },
  mainTitle: {
    fontSize: 18,
    lineHeight: 1.3,
    marginBottom: 24,
  },
  features: {
    "& .item": {
      position: "relative",
      marginBottom: 12,
      paddingLeft: 20,
      "&::after": {
        position: "absolute",
        content: '""',
        width: 4,
        height: 4,
        borderRadius: 4,
        left: 4,
        top: 7,
        backgroundColor: palette.error.main,
      },
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  socialButton: {
    width: "100%",
    "& img": {
      margin: "0 8px",
    },
  },
}));

const Login = () => {
  const initialState = {
    email: "",
    password: "",
    pin: "",
    mobile: "",
  };
  const [userInfo, setUserInfo] = useState(initialState);
  const [mobile, setMobile] = useState("");
  const [pinError, setPinError] = useState({});
  const [passwordError, setPasswordError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [showMobile, setShowMobile] = useState('email');
  const [twoFA, setTwoFA] = useState(false);
  const [visible, setVisible] = useState(false);
  const [twoFALoading, setTwoFALoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const ipData = useSelector((state) => state.ipData);
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const history = useHistory();
  const loginData = useSelector((state) => state.loginData, shallowEqual);
  const productList = useSelector(
    (state) => state.secondOrgInfo?.responseData?.products,
    shallowEqual
  );
  const { message } = useSelector((state) => state.notifications, shallowEqual);
  const MAX_LENGTH = 4;
  const { themeColor } = useThemeMode();
  const classes = useStyles();

  const handleChange = ({ target: { name, value } }) => {
    let temp = { ...userInfo };
    temp[name] = value;
    setUserInfo(temp);
  };
  const redirectToDashboard = () => {
    history.push("/dashboard/default");
  };

  // const {settings} = useSettings();
  // const brightImg = '/assets/images/logos/unnamed.jpeg';
  // const darkImg =
  //   'https://dev.churchpad.com/xMasterPortalLogosLoc/app_pdf_logos/app_250_1606573199_w3sruo__cropped.png';

  React.useEffect(() => {
    if (loginData.responseCode === "00") {
      redirectToDashboard();
    }
  }, [loginData.responseCode, loginData]);

  // check pin validity and password
  React.useEffect(() => {
    if (!ValidatorForm.hasValidationRule("validPin")) {
      ValidatorForm.addValidationRule("validPin", (value) => {
        if (value.length >= 1 && value.length !== 4) {
          return false;
        }
        return true;
      });
    }

    if (!ValidatorForm.hasValidationRule("passwordValid")) {
      ValidatorForm.addValidationRule("passwordValid", (value) => {
        if (value.length >= 1 && value.length < 8) {
          setPasswordError("Mininum 8 characters");
          return false;
        }
        if (value.length > 32) {
          setPasswordError("Maximum 32 characters");
          return false;
        }
        return true;
      });
    }

    return () => {
      if (ValidatorForm.hasValidationRule("passwordValid")) {
        ValidatorForm.removeValidationRule("passwordValid");
      }
      if (ValidatorForm.hasValidationRule("validPin")) {
        ValidatorForm.removeValidationRule("validPin");
      }
    };
  }, [userInfo.pin, userInfo.password]);

  const handleFormSubmit = () => {
    const { email, password, pin } = userInfo;

    if (showMobile === 'phoneNumber' && mobile === "") {
      setMobileError("This field is required");
      return;
    }
    if (showMobile === 'phoneNumber' && !isValidPhoneNumber(mobile)) return;

    dispatch(
      login(
        {
          emailOrPhone: showMobile === 'email' ? email : mobile,
          password,
          orgId: orgInfo.orgId,
        },
        setProductList
      )
    );
  };

  const setProductList = () => {
    // localStorage.setItem("productList", JSON.stringify(productList));
  };

  const handleToggle = () => {
    setUserInfo({ ...initialState });
    setShowMobile(!showMobile);
  };

  const onPinChange = (e) => {
    const pinInput = e.target.value.replace(/\D/g, "").slice(0, 4);
    const letters = /^[A-Za-z]+$/;
    if (e.target.value.match(letters))
      return setPinError({
        ...pinError,
        msg: "Pin must be a number",
        status: true,
      });
    if (pinInput.length < 4)
      setPinError({
        ...pinError,
        msg: "Pin must not be less than 4 numbers",
        status: true,
      });
    if (pinInput.length >= 4)
      setPinError({ ...pinError, msg: "", status: false });
    setUserInfo({ ...userInfo, pin: pinInput });
  };

  const handleVisibility = () => {
    if (!visible) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };
  const checkTwoFA = JSON.parse(localStorage.getItem("2FA"));
  React.useEffect(() => {
    if (checkTwoFA) {
      setTwoFA(true);
    }
  }, [checkTwoFA]);

  const handleSubmit = (data) => {
    setTwoFALoading(true);
    const payload = {
      orgId: orgInfo.orgId,
      userId: checkTwoFA?.userId,
      advancedAccessCode: data,
    };
    dispatch(
      verifyTwoFA(
        payload,
        () => {
          setTwoFALoading(false);
          localStorage.removeItem("2FA");
        },
        () => setTwoFALoading(false)
      )
    );
  };

  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
    >
      <Card className={classes.card}>
        <Grid container>
          <Grid item lg md sm xs>
            <div
              className="flex items-center justify-between px-8 pt-8"
              style={{ columnGap: "4rem" }}
            >
              <img
                src={orgInfo?.logo}
                alt=""
                className={`${classes.logoImg}`}
              />
              <span>
                <h3 style={{ color: `${themeColor}` }}>{orgInfo?.fullName}</h3>
              </span>
            </div>
            <div className="px-8 mt-5">
              <h6 style={{ color: `${themeColor}` }} className=" text-center">
                Admin Portal
              </h6>
              <MatxDivider className="mt-0" text="Or" />

              {/* {!twoFA && (
                <div className="flex mt-4">
                  <Switch
                    onChange={handleToggle}
                    checked={showMobile}
                    color="secondary"
                    size="small"
                  />
                  <p className="caption my-auto ml-2">
                    Sign in with {showMobile ? "Email" : "Mobile"}
                  </p>
                </div>
              )} */}
              <RadioGroup
                  className="pt-3"
                  value={showMobile || ''}
                  name="gender"
                  onChange={(e)=>setShowMobile(e.target.value)}
                  row
                  style={{columnGap: '2rem'}}
                >
                  <FormControlLabel
                    value="email"
                    control={<StyledRadio />}
                    label="Email"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="phoneNumber"
                    control={<StyledRadio />}
                    label="Phone Number"
                    labelPlacement="end"
                  />
                </RadioGroup>
              {loginData?.responseCode === '01' && (
                <div>
                  <p className="text-error my-auto text-center">{loginData?.responseMessage}</p>
                </div>
              )}
            </div>
            <div className="px-8 pb-8 pt-4 h-full relative min-w-288">
              {twoFA ? (
                <div
                  className="login-form login-signin"
                  id="kt_login_signin_form 2fa"
                >
                  <div className="text-center mb-5 mb-lg-10">
                    <div>
                    </div>
                    <h3
                      className="font-size-h1 mb-5"
                      style={{ width: "19rem" }}
                    >
                      {checkTwoFA?.twoFactorAuthentication?.messageHeader}
                    </h3>
                    <p
                      className="text-muted font-weight-bold"
                      style={{ width: "19rem" }}
                    >
                      {checkTwoFA?.twoFactorAuthentication?.messageBody}
                    </p>
                  </div>
                  <div className="">
                    <PinInputComponent setInputValue={handleSubmit} />
                  </div>
                  <Alert
                    variant="outlined"
                    severity="info"
                    className="mt-3"
                    style={{ width: "20rem" }}
                  >
                    {checkTwoFA?.twoFactorAuthentication?.messageFooter}
                  </Alert>
                  <div className="relative w-full  mt-8">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={twoFALoading}
                      type="submit"
                      className="w-full"
                      onClick={() => {
                        setTwoFA(false);
                        localStorage.removeItem("2FA");
                      }}
                    >
                      Back to Sign In
                    </Button>
                    {twoFALoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  {/* <p>it may take a minute to recieve your code</p> */}
                </div>
              ) : (
                <ValidatorForm onSubmit={handleFormSubmit}>
                  {showMobile === 'email' && (
                    <>
                      <TextValidator
                        className="mb-6 w-full"
                        variant="outlined"
                        size="small"
                        label="Email"
                        onChange={handleChange}
                        type="email"
                        name="email"
                        value={userInfo.email}
                        validators={["required", "isEmail"]}
                        errorMessages={[
                          "this field is required",
                          "email is not valid",
                        ]}
                      />
                      <TextValidator
                        className="mb-3 w-full"
                        label="Password"
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                        name="password"
                        type={visible ? "text" : "password"}
                        value={userInfo.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleVisibility}
                                aria-label="Toggle password visibility"
                              >
                                {!visible ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}

                  {showMobile === 'phoneNumber' && (
                    <>
                      <div className="relative">
                        <p style={{marginBottom: '0rem'}}>Phone Number</p>

                        <PhoneInput
                          international={
                            ipData && ipData ? ipData.country_code : null
                          }
                          withCountryCallingCode
                          countryCallingCodeEditable={false}
                          defaultCountry={
                            ipData && ipData ? ipData.country_code : null
                          }
                          placeholder="Mobile"
                          id="loginMobile"
                          value={mobile}
                          onChange={setMobile}
                          className={`mb-5 px-3 mobile`}
                          name="mobile"
                        />
                        {mobile && isValidPhoneNumber(mobile) && (
                          <div className="check"></div>
                        )}
                        {mobile && !isValidPhoneNumber(mobile) && (
                          <span className="x">X</span>
                        )}
                        {mobileError && (
                          <p className="text-error my-auto errorMessage">
                            {mobileError}
                          </p>
                        )}
                      </div>

                      <TextValidator
                        className="mb-3 w-full"
                        label="Password"
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                        name="password"
                        type={visible ? "text" : "password"}
                        value={userInfo.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleVisibility}
                                aria-label="Toggle password visibility"
                              >
                                {!visible ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </>
                  )}

                  <div
                    className="flex justify-between items-center mb-4 mt-4"
                    style={{ columnGap: "2rem" }}
                  >
                    <div className="relative w-full">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={loginData && loginData.loading}
                        type="submit"
                        className="w-full"
                      >
                        Sign In
                      </Button>
                      {loginData && loginData.loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>

                    {/* <Button
                    className="capitalize w-full"
                    onClick={() => history.push('/session/signup')}
                    variant='outlined'
                  >
                    Sign up
                  </Button> */}
                  </div>
                  <Button
                    style={{ color: `${themeColor}` }}
                    onClick={() => {
                      history.push({
                        pathname: "/session/forgot-password",
                        state: { isMobile: '' },
                      });
                      // if (showMobile) {
                      //   return window.location.replace(
                      //     `${orgInfo?.forgotPinUrl}`
                      //   );
                      // } else
                      //   return window.location.replace(
                      //     `${orgInfo?.forgotPasswordUrl}`
                      //   );
                    }}
                  >
                    {"Forgot Password?"}
                  </Button>
                </ValidatorForm>
              )}
            </div>

            {/* 2FA */}
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Login;
