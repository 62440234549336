import { actionTypes } from "../actionTypes";

export const peopleDashboard = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PEOPLE_DASHBOARD_SUCCESS:
      return action.payload;
    case actionTypes.PEOPLE_DASHBOARD_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

//   DepartmentS
export const departmentsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DEPARTMENTS_LIST_SUCCESS:
      return action.payload;
    case actionTypes.DEPARTMENTS_LIST_FAIL:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addDepartment = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_DEPARTMENT_LIST_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_LIST_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteDepartmentGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_DEPARTMENT_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_DEPARTMENT_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const renameDepartmentGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RENAME_DEPARTMENT_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.RENAME_DEPARTMENT_GROUP_FAILURE:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const departmentGroupUsers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_DEPARTMENT_GROUP_USERS_SUCCESS:
      return action.payload;
    case actionTypes.GET_DEPARTMENT_GROUP_USERS_FAILURE:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_DEPARTMENT_GROUP_USERS_SUCCESS_RESET:
      return {};
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteUsersFromDepartmentGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_DEPARTMENT_GROUP_USERS_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_DEPARTMENT_GROUP_USERS_FAILURE:
      return action.payload;
    case actionTypes.DEPARTMENT_USERS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DEPARTMENT_USERS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addRemoveUserAdminAccess = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_REMOVE_USER_ADMIN_ACCESS_SUCCESS:
      return action.payload;
    case actionTypes.ADD_REMOVE_USER_ADMIN_ACCESS_FAIL:
      return action.payload;
    case actionTypes.CREATE_DEPARTMENT_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DEPARTMENT_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchedUserToJoinDept = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_USER_TO_JOIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_USER_TO_JOIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_USER_TO_JOIN_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLEAR_SEARCH_USERS_TO_JOIN:
      return {};
    default:
      return state;
  }
};

// POSITIONS
export const positionsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.POSITIONS_LIST_SUCCESS:
      return action.payload;
    case actionTypes.POSITIONS_LIST_FAIL:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addPosition = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_POSITION_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_POSITION_FAIL:
      return action.payload;
    case actionTypes.CREATE_POSITION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_POSITION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deletePosition = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_POSITION_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_POSITION_FAIL:
      return action.payload;
    case actionTypes.CREATE_POSITION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_POSITION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const renamePosition = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RENAME_POSITION_SUCCESS:
      return action.payload;
    case actionTypes.RENAME_POSITION_FAIL:
      return action.payload;
    case actionTypes.CREATE_POSITION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_POSITION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const positionMembers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_POSITION_USERS_SUCCESS:
      return action.payload;
    case actionTypes.GET_POSITION_USERS_FAIL:
      return action.payload;
    case actionTypes.CREATE_POSITION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_POSITION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// SALUTATION
export const salutationsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SALUTATIONS_LIST_SUCCESS:
      return action.payload;
    case actionTypes.SALUTATIONS_LIST_FAIL:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addSalutation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_SALUTATION_SUCCESS:
      return action.payload;
    case actionTypes.ADD_SALUTATION_FAIL:
      return action.payload;
    case actionTypes.SALUTAION_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SALUTAION_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteSalutation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SALUTATION_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_SALUTATION_FAIL:
      return action.payload;
    case actionTypes.SALUTAION_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SALUTAION_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const renameSalutation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RENAME_SALUTATION_SUCCESS:
      return action.payload;
    case actionTypes.RENAME_SALUTATION_FAIL:
      return action.payload;
    case actionTypes.SALUTAION_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SALUTAION_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const salutationMembers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_SALUTATION_USERS_SUCCESS:
      return action.payload;
    case actionTypes.GET_SALUTATION_USERS_FAIL:
      return action.payload;
    case actionTypes.SALUTAION_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SALUTAION_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// Listing
export const enableDisabledUserAccount = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_USER_ACCOUNT_SUCCESS:
      return action.payload;
    case actionTypes.ENABLE_USER_ACCOUNT_FAIL:
      return action.payload;
    case actionTypes.ENABLE_USER_ACCOUNT_LAODING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ENABLE_USER_ACCOUNT_LAODING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchedSpouse = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SPOUSE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_SPOUSE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_SPOUSE_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLEAR_SEARCHED_SPOUSE:
      return {};
    default:
      return state;
  }
};

export const updateSpouse = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SPOUSE_SUCCESS:
      return action.payload;
    case actionTypes.UPDATE_SPOUSE_FAIL:
      return action.payload;
    case actionTypes.UPDATE_SPOUSE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SPOUSE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const adminChangePwd = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PWD_SUCCESS:
      return action.payload;
    case actionTypes.CHANGE_PWD_FAIL:
      return action.payload;
    case actionTypes.CHANGE_PWD_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHANGE_PWD_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const adminChildManager = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_CHILD_MANAGER_SUCCESS:
      return action.payload;
    case actionTypes.ADMIN_CHILD_MANAGER_FAIL:
      return action.payload;
    case actionTypes.ADMIN_CHILD_MANAGER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADMIN_CHILD_MANAGER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const fetchUserDeptServ = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.USER_DEPT_SERV_SUCCESS:
      return action.payload;
    case actionTypes.USER_DEPT_SERV_FAIL:
      return action.payload;
    // case actionTypes.ADMIN_CHILD_MANAGER_START:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case actionTypes.ADMIN_CHILD_MANAGER_STOP:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    default:
      return state;
  }
};

export const deleteUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_USER__SUCCESS:
      return action.payload;
    case actionTypes.DELETE_USER__FAIL:
      return action.payload;
    case actionTypes.DELETE_USER__START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_USER__STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// groups next-gen
export const getGroups = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUPS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_GROUPS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.GET_GROUPS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_GROUPS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_GROUPS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_GROUPS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_GROUPS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_GROUPS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GROUPS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_GROUPS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_GROUPS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_GROUPS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_GROUPS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_GROUPS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_GROUPS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_GROUPS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const approveUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_USER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.APPROVE_USER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.APPROVE_USER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.APPROVE_USER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const removeUser = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REMOVE_USER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.REMOVE_USER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.REMOVE_USER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REMOVE_USER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getUsersInGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUPS_USERS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_GROUPS_USERS_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.GET_GROUPS_USERS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_GROUPS_USERS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const mergeAccounts = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MERGE_USER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.MERGE_USER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.MERGE_USER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MERGE_USER_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const birthdaysWeddingsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.BIRTHDAY_WEDDINGS_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.BIRTHDAY_WEDDINGS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.BIRTHDAY_WEDDINGS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const importPeople = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.IMPORT_PEOPLE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.IMPORT_PEOPLE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getExportableData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EXPORT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.EXPORT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const exportTheseData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EXPORT_THESE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.EXPORT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updatePhoneNumberAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PHONE_NUMBER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_PHONE_NUMBER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeGroupBanner = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_PHONE_NUMBER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_USER_PHONE_NUMBER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const userContributionsHistory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.USER_CONTRIBUTION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.USER_CONTRIBUTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.USER_CONTRIBUTION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getUserContributionStatement = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.USER_CONTRIBUTION_STATEMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.USER_CONTRIBUTION_STATEMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.USER_CONTRIBUTION_STATEMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addManualContribution = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_MANUAL_CONTRIBUTION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_MANUAL_CONTRIBUTION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const qrCodesAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_QR_CODES_ADMIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_QR_CODES_ADMIN_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_QR_CODES_ADMIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const resetToDefaultAdminTableColView = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_TO_DEFAULT_ADMIN_TABLE_COL_VIEW_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RESET_TO_DEFAULT_ADMIN_TABLE_COL_VIEW_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getAutomatedGreeting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_AUTOMATED_GREETINGS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_AUTOMATED_GREETINGS_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FETCH_AUTOMATED_GREETINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const automatedGreetingsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIST_AUTOMATED_GREETINGS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LIST_AUTOMATED_GREETINGS_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.LIST_AUTOMATED_GREETINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const addAutomatedGreetings = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_AUTOMATED_GREETINGS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_AUTOMATED_GREETINGS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateAutomatedGreeting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_AUTOMATED_GREETINGS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_AUTOMATED_GREETINGS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteAutomatedGreeting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_AUTOMATED_GREETINGS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_AUTOMATED_GREETINGS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const testAutomatedGreeting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TEST_AUTOMATED_GREETINGS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TEST_AUTOMATED_GREETINGS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const groupUsers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_GROUP_SUCCESS:
      return {
        ...action.payload,
        loading: true,
      };
    case actionTypes.GET_USERS_GROUP_FAILURE:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const groupUsersPending = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_GROUP_PENDING_START:
      return {
        ...action.payload,
        loading: true,
      };
    case actionTypes.GET_USERS_GROUP_PENDING_STOP:
      return {
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const checkInAttendanceListingAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CHECKIN_ATT_LIST_START:
      return {
        loading: true,
      };
    case actionTypes.GET_CHECKIN_ATT_LIST_SUCCESS:
      return {
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_CHECKIN_ATT_LIST_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const addCheckInAttendance = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_CHECK_IN_START:
      return {
        loading: true,
      };
    case actionTypes.ADD_CHECK_IN_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const editCheckInAttendance = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_CHECK_IN_START:
      return {
        loading: true,
      };
    case actionTypes.EDIT_CHECK_IN_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteCheckInAttendance = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CHECK_IN_START:
      return {
        loading: true,
      };
    case actionTypes.DELETE_CHECK_IN_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const checkInAttendanceRecent = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_RECENT_ATT_START:
      return {
        loading: true,
      };
    case actionTypes.GET_RECENT_ATT_SUCCESS:
      return {
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_RECENT_ATT_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const checkInAttendeesReporting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ATT_REPORTING_START:
      return {
        loading: true,
      };
    case actionTypes.GET_ATT_REPORTING_SUCCESS:
      return {
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_ATT_REPORTING_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const checkInAttendeesInformation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ATT_INFO_START:
      return {
        loading: true,
      };
    case actionTypes.GET_ATT_INFO_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const mobileAppActionLoading = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MOBILE_APP_SLIDER_LOADING_START:
      return {
        loading: true,
      };
    case actionTypes.MOBILE_APP_SLIDER_LOADING_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteGroupLeader = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_GROUP_USER_START:
      return {
        loading: true,
      };
    case actionTypes.DELETE_GROUP_USER_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};

export const groupCoordinators = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUP_COORDINATORS_START:
      return {
        loading: true,
      };
    case actionTypes.GET_GROUP_COORDINATORS_SUCCESS:
      return {
        loading: false,
        ...action.payload,
      };
    case actionTypes.GET_GROUP_COORDINATORS_STOP:
      return {
        loading: false,
      };
    default:
      return state;
  }
};
