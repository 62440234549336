import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// activate skip waiting for service worker on state change
const update = (registration) => {
  const waitingServiceWorker = registration.waiting;
  if (waitingServiceWorker) {
    waitingServiceWorker.addEventListener("statechange", (event) => {
      if (event.target.state === "activated") {
        window.location.reload();
      }
    });
    waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
  }
};

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorkerRegistration.register({ onUpdate: (reg) => update(reg) });
