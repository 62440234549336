import {actionTypes} from '../actionTypes';

export const updateFollowUpData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_FOLLOW_UP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const newFollowUpFormSubmissionsData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.NEW_FOLLOW_UP_FORM_SUBMISSION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.NEW_FOLLOW_UP_FORM_SUBMISSION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getFollowUpByOrganizationIdData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_FOLLOW_UP_BY_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_FOLLOW_UP_BY_ORGANIZATION_ID_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING_FOLLOWUP:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING_FOLLOWUP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const assignedFollowUpMembersData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ASSIGNED_FOLLOW_UP_MEMBERS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ASSIGNED_FOLLOW_UP_MEMBERS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getActivityLogData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const assignGuestData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ASSIGN_GUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ASSIGN_GUEST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createActivityLogData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CREATE_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateActivityLogData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_ACTIVITY_LOG_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getFollowUpFormDetailsData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_FOLLOW_UP_FORM_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_FOLLOW_UP_FORM_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const followUpDashboard = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FOLLOW_UP_MAIN_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FOLLOW_UP_MAIN_DASHBOARD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const getClosedOutForms = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CLOSED_OUT_FORMS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CLOSED_OUT_FORMS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const followUpReporting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FOLLOWUP_REPORTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FOLLOWUP_REPORTING_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FOLLOWUP_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FOLLOWUP_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const followUpReportingViewData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FOLLOWUP_REPORTING_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FOLLOWUP_REPORTING_DATA_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FOLLOWUP_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FOLLOWUP_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const followUpDynamicForm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FOLLOWUP_DYNAMIC_FORM_FAILURE:
    case actionTypes.FOLLOWUP_DYNAMIC_FORM_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FOLLOWUP_START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FOLLOWUP_STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchFollowUp = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FOLLOWUP_GLOBAL_SEARCH_SUCCESS:
    case actionTypes.FOLLOWUP_GLOBAL_SEARCH_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP:
      return {};
    default:
      return state;
  }
};

export const getForm = (state={}, action) => {
  switch(action.type) {
      case actionTypes.GET_FOLLOWUP_FORM_SUCCESS:
          return {
              ...state,
              ...action.payload
          }
      case actionTypes.GET_FOLLOWUP_FORM_FAILURE:
        return {
            ...state,
            ...action.payload
        } 
      default:
        return state;
    }

}