import { actionTypes } from "../actionTypes";

export const getPricing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPGRADE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPGRADE_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPGRADE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const upgradeOrDowngradeServiceRequest = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPGRADE_DOWNGRADE_SERVICE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPGRADE_DOWNGRADE_SERVICE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const cancelServiceInformation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CANCEL_SERVICE_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CANCEL_SERVICE_INFO_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CANCEL_SERVICE_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const cancelServiceRequest = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CANCEL_SERVICE_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CANCEL_SERVICE_REQUEST_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const freeTrialExtensionInformation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FREE_TRIAL_EXT_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FREE_TRIAL_EXT_INFO_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.FREE_TRIAL_EXT_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const freeTrialExtensionRequest = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FREE_TRIAL_EXT_REQUEST_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FREE_TRIAL_EXT_REQUEST_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};