import { actionTypes } from "../actionTypes";

export const orgGeneralSettings = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgSocialMedia = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgLogo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgMessage = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ORG_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgTextMessaging = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgColors = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const globalSystemSettingsData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_SYSTEM_SETTINGS_SUCCESS:
      return action.payload;
    case actionTypes.GLOBAL_SYSTEM_SETTINGS_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const emailTemplate = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const rolesPermissionList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ROLESPERMISSIONSLIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_ROLESPERMISSIONSLIST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ROLESPERMISSIONSLIST_FAIL:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const usersWithRolesPermission = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_WITH_ROLES_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_USERS_WITH_ROLES:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USERS_WITH_ROLES_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const userRolePerm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_ROLE_PERM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_USER_ROLE_PERM:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USER_ROLE_PERM_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateGlobalSystemSettingsData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GLOBAL_SYSTEM_SETTING_SUCCESS:
      return action.payload;
    case actionTypes.UPDATE_GLOBAL_SYSTEM_SETTING_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateRolesPerm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ROLES_PERM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.UPDATE_ROLES_PERM:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_ROLES_PERM_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteRolePerm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ROLES_PERM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.DELETE_ROLES_PERM:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_ROLES_PERM_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchedUsersRolesPerm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_USERS_ROLES_PERM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_USERS_ROLES_PERM:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_USERS_ROLES_PERM_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLEAR_SEARCH_USERS_ROLES_PERM:
      return {};
    default:
      return state;
  }
};

export const checkUserRoles = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHECK_USER_ROLE_PERM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.CHECK_USER_ROLE_PERM:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHECK_USER_ROLE_PERM_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// Leadership
export const leadershipsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LEADERSHIP_LIST_SUCCESS:
      return action.payload;
    case actionTypes.LEADERSHIP_LIST_FAIL:
      return action.payload;
    case actionTypes.LEADERSHIP_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LEADERSHIP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addUpdateleadership = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_LEADERSHIP_SUCCESS:
      return action.payload;
    case actionTypes.ADD_LEADERSHIP_FAIL:
      return action.payload;
    case actionTypes.LEADERSHIP_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LEADERSHIP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteleadership = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_LEADERSHIP_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_LEADERSHIP_FAIL:
      return action.payload;
    case actionTypes.LEADERSHIP_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LEADERSHIP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeleadership = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_LEADERSHIP_SUCCESS:
      return action.payload;
    case actionTypes.CHANGE_LEADERSHIP_FAIL:
      return action.payload;
    case actionTypes.LEADERSHIP_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LEADERSHIP_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const doSearchUserToJoinLeadership = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLEAR_SEARCH_USERS_TO_JOIN_LEADERSHIP:
      return {};
    default:
      return state;
  }
};

export const sendCommunicationOrg = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_ORG_COMM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_ORG_COMM_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const accessSecurityAuditListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ACCESS_SECURITY_LISTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.ACCESS_SECURITY_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ACCESS_SECURITY_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const searchAccessSecurityAuditByUserId = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ACCESS_SECURITY_SEARCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.ACCESS_SECURITY_SEARCH_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ACCESS_SECURITY_SEARCH_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateCommunicationOrg = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_ORG_COMM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_ORG_COMM_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const mobileAppCustomizationListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MOBILE_APP_CUSTOMIZATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.MOBILE_APP_CUSTOMIZATION_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.START_ORG_COMM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_ORG_COMM_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const celebrationListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CELEBRATION_LISTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.CELEBRATION_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CELEBRATION_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const mobileAppCustomization = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MOBILE_APP_CUSTOMIZATION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MOBILE_APP_CUSTOMIZATION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const messagingCreditsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_MESSAGE_CREDITS_LISTING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_MESSAGE_CREDITS_LISTING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_MESSAGE_CREDITS_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const orgPaymentMethod = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ORG_PAYMENT_METHOD_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ORG_PAYMENT_METHOD_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ORG_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const buyMsgCreditWithExistingToken = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case actionTypes.BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const buyMsgCreditWithNewCardOrCheck = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const msgCreditPurchasedHistory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MSG_CREDIT_PURCHASED_HISTORY_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MSG_CREDIT_PURCHASED_HISTORY_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.MSG_CREDIT_PURCHASED_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const enableOrDisableMsgCreditsAutoRecharge = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateAppWalkThruAssetInformation = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_WALT_THRU_ASSET_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_WALT_THRU_ASSET_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getOrganizationDynamicForms = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const getAllFormSubmission = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DYNAMIC_FORMS_SUBMITTED_LOADING:
      return {
        loading: true,
      };
    case actionTypes.DYNAMIC_FORMS_SUBMITTED_SUCCESS:
    case actionTypes.DYNAMIC_FORMS_SUBMITTED_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const formSubmissionStatus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DYNAMIC_FORM_STATUS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_DYNAMIC_FORM_STATUS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createForm = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_FORMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_FORMS_RESPONSE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getFormById = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_FORMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_FORMS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_FORMS_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    default:
      return state;
  }
};

export const giveMethods = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_METHODS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GIVE_METHODS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_GIVE_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export const viewSubmittedForms = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.VIEW_SUBMITTED_FORMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.VIEW_SUBMITTED_FORMS_RESPONSE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    default:
      return state;
  }
};
