import { actionTypes } from "../actionTypes";

export const orgInvoicesListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_LISTING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVOICE_LISTING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_INVOICE_LISTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgCardCheckStoredTokens = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_CARD_CHECK_STORED_TOKENS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_CARD_CHECK_STORED_TOKENS_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_ORG_CARD_CHECK_STORED_TOKENS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgDeleteCardCheck = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CARD_CHECK_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_CARD_CHECK_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgPayWithExistingCardCheckToken = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PAY_WITH_EXISTING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PAY_WITH_EXISTING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgPayWithNewCardOrCheck = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PAY_WITH_NEW_CARD_CHECK_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PAY_WITH_NEW_CARD_CHECK_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgGetThisInvoicePdf = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_PDF_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_INVOICE_PDF_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const verifyRouting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_ROUTING_NUMBER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.VERIFY_ROUTING_NUMBER_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const giveWithAnExistingCardCheckingAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_WITH_EXISTING_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GIVE_WITH_EXISTING_TOKEN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const giveWithANewCardAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_WITH_NEW_CARD_ADMIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GIVE_WITH_NEW_CARD_ADMIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const giveWithANewCheckingAdmin = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_WITH_NEW_CHECK_ADMIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GIVE_WITH_NEW_CHECK_ADMIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
