import { actionTypes } from "redux/actionTypes";

export const onBoardCustommer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ONBOARD_CUSTOMER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ONBOARD_CUSTOMER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const submitProposal = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_PROPOSAL_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SUBMIT_PROPOSAL_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const globalGiving = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GLOBAL_GIVING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GLOBAL_GIVING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const requestOTPOnboarding = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_OTP_ONBOARDING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REQUEST_OTP_ONBOARDING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
