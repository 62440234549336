import { actionTypes } from "../actionTypes";

export const getSermons = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_SERMONS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMONS_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMONS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_SERMONS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createSermon = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SERMON_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_SERMON_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_SERMON_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_SERMON_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteSermon = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SERMON_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMON_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMON_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SERMON_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateSermon = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERMON_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_SERMON_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_SERMON_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SERMON_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSermonsList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_SERMONS_LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMONS_LIST_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMONS_LIST_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_SERMONS_LIST_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createSermonGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SERMON_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_SERMON_GROUP_FAIL:
      return action.payload;
    case actionTypes.CREATE_SERMON_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_SERMON_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateSermonGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERMON_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.UPDATE_SERMON_GROUP_FAIL:
      return action.payload;
    case actionTypes.UPDATE_SERMON_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SERMON_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSermonGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_SERMON_LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMON_LIST_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMON_LIST_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_SERMONS_LIST_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_ORG_SERMONS_LIST_CLEAR:
      return {
        ...state,
        sermons: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteSermonGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SERMON_GROUP_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMON_GROUP_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMON_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SERMON_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchSermons = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SERMON_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_SERMON_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_SERMON_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_SERMON_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addSermonsToGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_SERMONS_TO_GROUP_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.ADD_SERMONS_TO_GROUP_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.ADD_SERMONS_TO_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_SERMONS_TO_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteSermonsToGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SERMONS_FROM_GROUP_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMONS_FROM_GROUP_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMONS_FROM_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SERMONS_FROM_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSermonsBySeries = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_SERMON_SERIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMON_SERIES_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMON_SERIES_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_SERMON_SERIES_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createSeriesMedia = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ORG_MEDIA_SERIES_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_ORG_MEDIA_SERIES_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_ORG_MEDIA_SERIES_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_ORG_MEDIA_SERIES_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateMediaSeries = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ORG_MEDIA_SERIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_ORG_MEDIA_SERIES_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_ORG_MEDIA_SERIES_LOADING_STOP:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_ORG_MEDIA_SERIES_LOADING_START:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteMediaSeries = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MEDIA_SERIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_SERIES_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_SERIES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MEDIA_SERIES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createSpeaker = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MEDIA_SPEAKER_SUCCESS:
    case actionTypes.CREATE_MEDIA_SPEAKER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_MEDIA_SPEAKER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_MEDIA_SPEAKER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateMediaSpeaker = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MEDIA_SPEAKER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_MEDIA_SPEAKER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_MEDIA_SPEAKER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_MEDIA_SPEAKER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getMediaSpeaker = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_MEDIA_SPEAKER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_SPEAKER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_SPEAKER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_MEDIA_SPEAKER_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteMediaSpeaker = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MEDIA_SPEAKER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_SPEAKER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_SPEAKER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MEDIA_SPEAKER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchSpeakers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_SPEAKER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_SPEAKER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_SPEAKER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_SPEAKER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const liveStreamStatus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIVESTREAM_STATUS_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.LIVESTREAM_STATUS_FAILURE:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_PREACHER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_PREACHER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateSermontPlaylistDisplayOrder = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERMON_PLAYLIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SERMON_PLAYLIST_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

// Media

export const getMedias = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_MEDIA_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_MEDIA_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createMedia = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MEDIA_SUCCESS:
    case actionTypes.CREATE_MEDIA_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.CREATE_MEDIA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_MEDIA_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteMedia = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MEDIA_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MEDIA_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateMedia = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MEDIA_SUCCESS:
    case actionTypes.UPDATE_MEDIA_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_MEDIA_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_MEDIA_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getMediaList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_MEDIAS_LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIAS_LIST_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIAS_LIST_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_MEDIAS_LIST_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createMediaGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_MEDIA_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.CREATE_MEDIA_GROUP_FAIL:
      return action.payload;
    case actionTypes.CREATE_MEDIA_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_MEDIA_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateMediaGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MEDIA_GROUP_SUCCESS:
      return action.payload;
    case actionTypes.UPDATE_MEDIA_GROUP_FAIL:
      return action.payload;
    case actionTypes.UPDATE_MEDIA_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_MEDIA_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getMediaGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_MEDIA_LIST_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_LIST_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_LIST_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_MEDIA_LIST_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.GET_ORG_MEDIA_LIST_CLEAR:
      return {
        ...state,
        sermons: [],
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteMediaGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MEDIA_GROUP_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_GROUP_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MEDIA_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchMedia = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_MEDIA_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_MEDIA_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_MEDIA_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_MEDIA_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addMediaToGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_MEDIA_TO_GROUP_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.ADD_MEDIA_TO_GROUP_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.ADD_MEDIA_TO_GROUP_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ADD_MEDIA_TO_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteMediaFromGroup = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_MEDIA_FROM_GROUP_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_FROM_GROUP_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_MEDIA_FROM_GROUP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_MEDIA_FROM_GROUP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateMediatPlaylistDisplayOrder = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MEDIA_PLAYLIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_MEDIA_PLAYLIST_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getMediaBySeries = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_MEDIA_SERIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_SERIES_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_MEDIA_SERIES_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_MEDIA_SERIES_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const updateSermonSeries = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ORG_SERMON_SERIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_ORG_SERMON_SERIES_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_ORG_SERMON_SERIES_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_ORG_SERMON_SERIES_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteSermonSeries = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SERMONS_SERIES_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMONS_SERIES_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMONS_SERIES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SERMONS_SERIES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createPreacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SERMON_PREACHER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_SERMON_PREACHER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.CREATE_SERMON_PREACHER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_SERMON_PREACHER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateSermonPreacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SERMON_PREACHER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_SERMON_PREACHER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.UPDATE_SERMON_PREACHER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SERMON_PREACHER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getSermonPreacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_SERMON_PREACHER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMON_PREACHER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.GET_ORG_SERMON_PREACHER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_SERMON_PREACHER_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteSermonPreacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SERMON_PREACHER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMON_PREACHER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.DELETE_SERMON_PREACHER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SERMON_PREACHER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchPreachers = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_PREACHER_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_PREACHER_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_PREACHER_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_PREACHER_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const fetchStreams = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_STREAMS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_STREAMS_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_STREAMS_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_STREAMS_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getFeaturedStreams = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_FEATURED_STREAM_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_FEATURED_STREAM_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_FEATURED_STREAM_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_FEATURED_STREAM_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchPlaylist = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_PLAYLIST_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_PLAYLIST_FAIL:
      return { loading: false, ...action.payload };
    case actionTypes.SEARCH_PLAYLIST_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_PLAYLIST_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateFeaturedStream = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FEATURED_STREAM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_FEATURED_STREAM_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
