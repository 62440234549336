import React from "react";
import { makeStyles, Radio } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    "& .MuiSvgIcon-root": {
      height: 17,
      weight: 17,
    },
  },
});

function StyledRadio({ color = "primary", ...props }) {
  const classes = useStyles();

  return (
    <Radio
      style={{ padding: "0 " }}
      className={classes.root}
      color={color}
      {...props}
    />
  );
}

export default StyledRadio;
