import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  RadioGroup,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import churchPadLogo from "assests/Onboard/Churchpad-white-1024x258_2126f0f0f97a24c2e1c47ba5e98c3e33.png";

import "./styles.css";
import CustomTextField from "./CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "components/Button";
import {
  fetchChurchProducts,
  fetchChurchSize,
  onBoardCustommer,
  requestOTPOnboarding,
} from "redux/actions/OnBoarding";
import { parsePhoneNumber } from "libphonenumber-js";
import { isValidPhoneNumber } from "react-phone-number-input";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import PinInput from "react-pin-input";
import StyledRadio from "components/StyledRadio";
import { Gif } from "components/Suspense/GifSuspense";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const otpData = {
  show: false,
  otpNumber: "",
  showPinInput: false,
  msg: "",
};
const OnboardingSignUp = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const loading = useSelector((state) => state.onBoardCustommer?.loading);
  const otpLoading = useSelector(
    (state) => state.requestOTPOnboarding?.loading
  );
  const ipData = useSelector((state) => state.ipData);

  const dispatch = useDispatch();
  const classes = useStyles();
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  const [data, setData] = useState({
    church_phone_number: "",
    admin_phone_number: "",
    church_address: "",
    church_email: "",
    church_website: "",
    church_short_name: "",
  });
  const [products, setProducts] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [mobileErrors, setMobileErrors] = useState({
    church_phone_number: "",
    admin_phone_number: "",
  });
  const [step, setStep] = useState(1);
  const [selected, setSelected] = useState([]);
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [shortNameCheck, setShortNameCheck] = React.useState({
    loading: false,
    error: "",
  });
  const [showOtp, setShowOtp] = useState(otpData);

  useEffect(() => {
    dispatch(fetchChurchSize(updateSizes));
    dispatch(fetchChurchProducts(updateProducts));
    localStorage.clear();

    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places&callback=initAutocomplete`;
      script.async = true;
      document.body.appendChild(script);

      window.initAutocomplete = () => setApiLoaded(true);

      return () => {
        if (script) {
          script.remove();
        }
        delete window.initAutocomplete;
      };
    } else {
      setApiLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (
      step === 3 &&
      apiLoaded &&
      inputRef.current &&
      !autocompleteRef.current
    ) {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current
      );
    }
  }, [step, apiLoaded]);

  const updateSizes = (data) => {
    setSizes(data);
  };

  const updateProducts = (data) => {
    setProducts(data);
  };

  const handleChange = ({ target: { name, value } }) => {
    let temp = { ...data };
    if (name === "church_short_name") {
      const formattedValue = value.replace(/[^A-Za-z]/g, "");
      temp[name] = formattedValue;
    } else temp[name] = value;
    setData(temp);

    if (name === "church_size" && !data.church_size) {
      setSelected(products);
    }
  };

  const handleFormSubmit = (code) => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    const { confirm_password, ...rest } = data;
    const payload = { code, ...rest };
    if (payload.church_phone_number === "") {
      return setMobileErrors({
        ...mobileErrors,
        church_phone_number: "This field is required",
      });
    } else {
      setMobileErrors({
        ...mobileErrors,
        church_phone_number: "",
      });
    }
    if (payload.admin_phone_number === "") {
      return setMobileErrors({
        ...mobileErrors,
        admin_phone_number: "This field is required",
      });
    } else
      setMobileErrors({
        ...mobileErrors,
        admin_phone_number: "",
      });
    if (
      !isValidPhoneNumber(payload?.admin_phone_number) ||
      !isValidPhoneNumber(payload?.church_phone_number)
    )
      return;

    if (data?.church_phone_number) {
      const parsedPhoneNumber = parsePhoneNumber(data?.church_phone_number);
      payload.church_phone_number = parsedPhoneNumber.number;
    }
    if (data?.admin_phone_number) {
      const parsedPhoneNumber = parsePhoneNumber(data?.admin_phone_number);
      payload.admin_phone_number = parsedPhoneNumber.number;
    }
    payload.church_products =
      selected.length >= 7
        ? "all"
        : selected.map((item) => item.name).join(",");
    payload.church_size = payload.church_size.name;
    payload.church_pricing = `${getPrice()}`;
    dispatch(onBoardCustommer(payload, onBoardCustommerCB));
  };

  const onBoardCustommerCB = (data) => {
    window.location.href = `${data.data.orgOnboardingAdminUrl}/cpauth_admin?t=${data.data.userToken}`;
  };

  const handleChangePhone = (value, name) => {
    let temp = { ...data };
    temp[name] = value;
    setData(temp);
  };

  const getPrice = () => {
    let key = selected.length;
    if (selected.length >= 7) {
      return data?.church_size[`fullProductSuiteCost`];
    }

    switch (key) {
      case 1:
        return data?.church_size[`oneProductCost`];
      case 2:
        return data?.church_size[`twoProductCost`];
      case 3:
        return data?.church_size[`threeProductCost`];
      case 4:
        return data?.church_size[`fourProductCost`];
      case 5:
        return data?.church_size[`fiveProductCost`];
      case 6:
        return data?.church_size[`sixProductCost`];
      default:
        return "0.00";
    }
  };

  const continueDisabled = () => {
    return (
      (step === 1 && !data?.church_size) || (step === 1 && !selected.length)
    );
  };

  const continueToOtp = () => {
    setData((prev) => ({ ...prev, church_address: inputRef.current.value }));
    setShowOtp((prev) => ({ ...prev, show: true }));
  };
  const requestOtp = () => {
    dispatch(
      requestOTPOnboarding(
        {
          phone_number: showOtp.otpNumber,
          ip: ipData?.ip,
        },
        otpCB
      )
    );
  };

  const otpCB = (data) => {
    setShowOtp((prev) => ({ ...prev, showPinInput: true, msg: data.message }));
  };

  if (loading) return <Gif />;

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
      <div className="page-wrapper" id="on-boarding">
        <div className="content">
          <section className="top p-3 pb-14">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10} md={10}>
                <a
                  href="https://www.churchpad.com"
                  target="_self"
                  rel="noopener noreferrer"
                >
                  <img src={churchPadLogo} alt="church pad logo" class="logo" />
                </a>
                <p className="center top-heading mb-1">Pricing and Planning</p>
                <p className="top-heading-subtitle center">
                  No Contracts. No Setup Fees. Cancel Anytime.{" "}
                </p>
              </Grid>
            </Grid>
          </section>

          {showOtp.show ? (
            <section>
              <Grid container justifyContent="center">
                <div className="p-10">
                  {showOtp?.showPinInput ? (
                    <div className="flex flex-column col-gap-1 items-center">
                      <h6 className="mb-3 text-13">
                        Pls enter the otp sent to your number
                      </h6>

                      <span className="text-12  mb-5 text-center otp-label">
                        {showOtp.msg}
                      </span>
                      <PinInput
                        length={6}
                        secret
                        secretDelay={500}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: "10px" }}
                        inputFocusStyle={{ borderColor: "#304fff" }}
                        onComplete={(value, index) => {
                          handleFormSubmit(value);
                        }}
                        autoSelect={true}
                      />
                    </div>
                  ) : (
                    <>
                      <p className="text-center mb-4 font-bold">
                        Select a number to Receive your OTP
                      </p>
                      <RadioGroup
                        className="d-flex items-center col-gap-2 mb-10"
                        value={showOtp.otpNumber}
                        onChange={(e) =>
                          setShowOtp((prev) => ({
                            ...prev,
                            otpNumber: e.target.value,
                          }))
                        }
                        row
                        style={{ columnGap: "2rem" }}
                      >
                        {[
                          data.admin_phone_number,
                          data.church_phone_number,
                        ].map((number) => (
                          <Card
                            elevation={5}
                            className="p-2 flex relative items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setShowOtp((prev) => ({
                                ...prev,
                                otpNumber: number,
                              }))
                            }
                          >
                            <FormControlLabel
                              value={number}
                              control={<StyledRadio />}
                              label={number}
                              labelPlacement="end"
                              className="mx-0"
                            />
                          </Card>
                        ))}
                      </RadioGroup>
                      <div className="d-flex items-center col-gap-2 justify-center">
                        <CustomButton
                          variant="outlined"
                          color="primary"
                          type="button"
                          label="Go back"
                          onClick={() => setShowOtp(otpData)}
                          disabled={otpLoading}
                        />
                        <CustomButton
                          variant="contained"
                          color="primary"
                          type="button"
                          label="Request OTP"
                          loading={otpLoading}
                          disabled={!showOtp.otpNumber || otpLoading}
                          onClick={requestOtp}
                        />
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            </section>
          ) : (
            <section className={`forms-section p-3`}>
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={10} md={6}>
                  <ValidatorForm onSubmit={continueToOtp}>
                    <Grid container className="mb-5" justifyContent="center">
                      {step === 1 && (
                        <Grid
                          item
                          xs={12}
                          md={10}
                          lg={8}
                          xl={7}
                          container
                          justifyContent="center"
                        >
                          <Grid item xs={12} sm={7}>
                            <React.Fragment>
                              <h5 className="primary-color mb-3 mt-2">
                                Select the Church Size
                              </h5>
                              <CustomTextField
                                label="Church Size"
                                onChange={handleChange}
                                fullWidth
                                className="w-full mb-5"
                                variant="outlined"
                                size="small"
                                type="email"
                                value={data?.church_size || ""}
                                name="church_size"
                                required
                                children
                                select
                                disabled={!sizes.length}
                              >
                                {sizes?.map((item, ind) => (
                                  <MenuItem value={item} key={ind}>
                                    {item?.label}
                                  </MenuItem>
                                ))}
                              </CustomTextField>
                            </React.Fragment>
                          </Grid>

                          {data?.church_size && step !== 3 && (
                            <StepTwo
                              classes={classes}
                              setSelected={setSelected}
                              selected={selected}
                              products={products}
                              data={data}
                              getPrice={getPrice}
                            />
                          )}
                        </Grid>
                      )}

                      {step === 3 && (
                        <StepThree
                          inputRef={inputRef}
                          mobileErrors={mobileErrors}
                          classes={classes}
                          handleChangePhone={handleChangePhone}
                          setIsTermsAgreed={setIsTermsAgreed}
                          handleChange={handleChange}
                          data={data}
                          setShortNameCheck={setShortNameCheck}
                          shortNameCheck={shortNameCheck}
                        />
                      )}
                    </Grid>
                    {data?.church_size && (
                      <div className="flex col-gap-1">
                        <CustomButton
                          variant="outlined"
                          color="primary"
                          type="button"
                          className="mx-auto"
                          label="Go Back"
                          loading={loading}
                          disabled={step === 1 || loading}
                          onClick={() => setStep(step - 2)}
                        />
                        {step === 3 ? (
                          <CustomButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            className="mx-auto"
                            label="Continue to OTP"
                            loading={loading}
                            disabled={
                              !isTermsAgreed ||
                              loading ||
                              shortNameCheck?.error?.length ||
                              shortNameCheck?.loading
                            }
                          />
                        ) : (
                          <CustomButton
                            variant="contained"
                            color="primary"
                            type="button"
                            className="mx-auto"
                            label="Continue"
                            loading={loading}
                            onClick={() => setStep(step + 2)}
                            disabled={continueDisabled()}
                          />
                        )}
                      </div>
                    )}
                  </ValidatorForm>
                </Grid>
              </Grid>
            </section>
          )}
        </div>

        {data?.church_size && (
          <div className={classes.bottom}>
            <span>
              <h5 className="text-white mb-1">Total</h5>
              <h3 className="text-white text-32">
                {getPrice()}&nbsp;
                <span className="text-16">{data?.church_size.currency}</span>
              </h3>
            </span>

            <div className="d-flex text-white items-center col-gap-1">
              <h5 className="text-left">
                Selected Package: {data?.church_size.label}
              </h5>
            </div>
          </div>
        )}
      </div>
    </GoogleReCaptchaProvider>
  );
};

const useStyles = makeStyles(({ palette, ...theme }) => ({
  productCard: {
    display: "flex",
    alignItems: "center",
    marginBottom: "2px",
    "& > :first-child": {
      flexBasis: "20px",
    },
    "& > :last-child": {
      flex: 1,
    },
  },
  bottom: {
    display: "flex",
    placeContent: "space-evenly",
    placeItems: "center",
    position: "sticky",
    bottom: 0,
    height: "85px",
    width: "100%",
    backgroundColor: "#304fff",
    columnGap: "5px",
    padding: "10px",
  },
  container: {
    paddingTop: "50px",
  },
  img: {
    width: "4rem",
  },
  price: {
    display: "flex",
    alignItems: "baseline",
    flex: "1",
  },
  clear: {
    "& > :first-child": {
      flexBasis: "40px",
    },
  },
  loader: {
    position: "absolute",
    right: "8px",
    top: "7px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  customTooltip: {
    fontSize: "14px", // Set the font size
    whiteSpace: "pre-wrap", // Allow line breaks
  },
}));

export default OnboardingSignUp;
