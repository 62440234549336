import React from "react";
import axios from "api";
import { toast } from "react-toastify";
import { actionTypes } from "../actionTypes";
import { asyncMethod, apiUrl, defaultAction } from "../../utils/constants";
import { checkNodeEnv } from "utils/authHelpers";
import NotificationMesage from "../../components/Suspense/NotificationMessage";
import { axiosMultipartPayload, axiosPayload, orgInfoList } from ".";
import AxiosInstance, { AxiosInstanceCP } from "utils/AxiosConfig";

export const resetEndpointDataAction = {
  type: actionTypes.RESET_ENDPOINT_DATA,
};
export const resetCreditDataAction = { type: actionTypes.RESET_CREDIT_DATA };
export const resetCheckDataAction = { type: actionTypes.RESET_CHECK_DATA };
export const startLoadingAction = { type: actionTypes.START_LOADING };
export const stopLoadingAction = { type: actionTypes.STOP_LOADING };

export const globalSystemSettings = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.globalSystemSettings("live-api"),
    apiUrl.globalSystemSettings("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.GLOBAL_SYSTEM_SETTINGS_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.GLOBAL_SYSTEM_SETTINGS_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateGlobalSystemSettings =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.updateGlobalSystemSettings("live-api"),
      apiUrl.updateGlobalSystemSettings("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        dispatch(orgInfoList({ orgId: payload.orgId }));
        callback();
        return dispatch(
          defaultAction(actionTypes.UPDATE_GLOBAL_SYSTEM_SETTING_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.UPDATE_GLOBAL_SYSTEM_SETTING_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

// Leadership
export const leadershipsListing = (payload) => async (dispatch) => {
  dispatch({
    type: actionTypes.LEADERSHIP_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.leadershipsListing("live-api"),
    apiUrl.leadershipsListing("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      return dispatch(defaultAction(actionTypes.LEADERSHIP_LIST_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.LEADERSHIP_LIST_FAIL, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addUpdateleadership = (payload, callback) => async (dispatch) => {
  dispatch({
    type: actionTypes.LEADERSHIP_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.addUpdateleadership("live-api"),
    apiUrl.addUpdateleadership("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(defaultAction(actionTypes.ADD_LEADERSHIP_SUCCESS, data));
    } else {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.ADD_LEADERSHIP_FAIL, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const deleteleadership = (payload, callback) => async (dispatch) => {
  dispatch({
    type: actionTypes.LEADERSHIP_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.deleteleadership("live-api"),
    apiUrl.deleteleadership("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(
        defaultAction(actionTypes.DELETE_LEADERSHIP_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.DELETE_LEADERSHIP_FAIL, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const changeleadership = (payload, callback) => async (dispatch) => {
  dispatch({
    type: actionTypes.LEADERSHIP_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.changeleadership("live-api"),
    apiUrl.changeleadership("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(
        defaultAction(actionTypes.CHANGE_LEADERSHIP_SUCCESS, data)
      );
    } else {
      dispatch({
        type: actionTypes.LEADERSHIP_LOADING_STOP,
      });
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.CHANGE_LEADERSHIP_FAIL, data));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const doSearchUserToJoinLeadership =
  (payload, cb) => async (dispatch) => {
    // dispatch({type: actionTypes.CLEAR_PEOPLE});
    dispatch({
      type: actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_START,
    });
    const url = checkNodeEnv(
      apiUrl.doSearchUserToJoinLeadership("live-api"),
      apiUrl.doSearchUserToJoinLeadership("test-api")
    );

    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        // toast.success(data.responseMessage);
        cb && cb(data?.responseData);
        return dispatch(
          defaultAction(
            actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_SUCCESS,
            data
          )
        );
      } else {
        dispatch({
          type: actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_STOP,
        });
        // return toast.error(data.responseMessage);
      }
    } catch (error) {
      // console.log(error);
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.SEARCH_USER_TO_JOIN_LEADERSHIP_STOP,
      });
    }
  };

export const sendCommunicationOrg = (payload, callback) => async (dispatch) => {
  dispatch({ type: actionTypes.START_ORG_COMM_LOADING });
  const url = checkNodeEnv(
    apiUrl.sendCommunicationOrg("live-api"),
    apiUrl.sendCommunicationOrg("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch({ type: actionTypes.STOP_ORG_COMM_LOADING });
      callback();
      return toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      // return dispatch(
      //   defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_SUCCESS, data)
      // );
    } else {
      dispatch({ type: actionTypes.STOP_ORG_COMM_LOADING });
      return toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      // return dispatch(
      //   defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_FAILURE, data)
      // );
    }
  } catch (error) {
    console.log(error, "error");
    dispatch({ type: actionTypes.STOP_ORG_COMM_LOADING });
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const accessSecurityAuditListing =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.accessSecurityAuditListing("live-api"),
      apiUrl.accessSecurityAuditListing("test-api")
    );
    dispatch({
      type: actionTypes.ACCESS_SECURITY_LOADING_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback(data);
        dispatch(defaultAction(actionTypes.ACCESS_SECURITY_LOADING_STOP));
        return dispatch(
          defaultAction(actionTypes.ACCESS_SECURITY_LISTING_SUCCESS, data)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.ACCESS_SECURITY_LOADING_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.ACCESS_SECURITY_LOADING_STOP,
      });
    }
  };

export const searchAccessSecurityAuditByUserId =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.searchAccessSecurityAuditByUserId("live-api"),
      apiUrl.searchAccessSecurityAuditByUserId("test-api")
    );
    dispatch({
      type: actionTypes.ACCESS_SECURITY_SEARCH_LOADING_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback(data);
        dispatch(
          defaultAction(actionTypes.ACCESS_SECURITY_SEARCH_LOADING_STOP)
        );
        return dispatch(
          defaultAction(actionTypes.ACCESS_SECURITY_SEARCH_SUCCESS, data)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.ACCESS_SECURITY_SEARCH_LOADING_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.ACCESS_SECURITY_SEARCH_LOADING_STOP,
      });
    }
  };

export const celebrationListing = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.celebrationListing("live-api"),
    apiUrl.celebrationListing("test-api")
  );
  dispatch({
    type: actionTypes.CELEBRATION_LOADING_START,
  });
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      callback && callback();
      dispatch(defaultAction(actionTypes.CELEBRATION_LOADING_STOP));
      return dispatch(
        defaultAction(actionTypes.CELEBRATION_LISTING_SUCCESS, data)
      );
    } else {
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.CELEBRATION_LOADING_STOP));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch({
      type: actionTypes.CELEBRATION_LOADING_STOP,
    });
  }
};

export const updateCommunicationOrg =
  (payload, callback) => async (dispatch) => {
    dispatch({ type: actionTypes.START_ORG_COMM_LOADING });
    const url = checkNodeEnv(
      apiUrl.updateCommunicationOrg("live-api"),
      apiUrl.updateCommunicationOrg("test-api")
    );
    try {
      const response = await axios(axiosMultipartPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch({ type: actionTypes.STOP_ORG_COMM_LOADING });
        callback();
        return toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        // return dispatch(
        //   defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_SUCCESS, data)
        // );
      } else {
        dispatch({ type: actionTypes.STOP_ORG_COMM_LOADING });
        return toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        // return dispatch(
        //   defaultAction(actionTypes.SEND_CHILDREN_COMMUNICATION_FAILURE, data)
        // );
      }
    } catch (error) {
      console.log(error, "error");
      dispatch({ type: actionTypes.STOP_ORG_COMM_LOADING });
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const deleteCom = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.deleteCom("live-api"),
    apiUrl.deleteCom("test-api")
  );
  dispatch({
    type: actionTypes.START_ORG_COMM_LOADING,
  });
  var form_data = new FormData();
  for (let key in payload) {
    form_data.append(key, payload[key]);
  }

  try {
    const response = await axios(axiosMultipartPayload(url, form_data));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback && callback();
      return dispatch(defaultAction(actionTypes.STOP_ORG_COMM_LOADING));
    } else {
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.STOP_ORG_COMM_LOADING));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch({
      type: actionTypes.STOP_ORG_COMM_LOADING,
    });
  }
};

export const mobileAppCustomizationListing = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.mobileAppCustomizationListing("live-api"),
    apiUrl.mobileAppCustomizationListing("test-api")
  );
  dispatch({
    type: actionTypes.START_ORG_COMM_LOADING,
  });
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return dispatch(
        defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_SUCCESS, data)
      );
    } else {
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch({
      type: actionTypes.STOP_ORG_COMM_LOADING,
    });
  }
};
export const resetToDefaultAppAssets =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.resetToDefaultAppAssets("live-api"),
      apiUrl.resetToDefaultAppAssets("test-api")
    );
    dispatch({
      type: actionTypes.START_ORG_COMM_LOADING,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_SUCCESS, data)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.STOP_ORG_COMM_LOADING,
      });
    }
  };

export const changeAppAssetImageOrVideo =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.changeAppAssetImageOrVideo("live-api"),
      apiUrl.changeAppAssetImageOrVideo("test-api")
    );
    dispatch({
      type: actionTypes.MOBILE_APP_CUSTOMIZATION_START,
    });
    try {
      const response = await axios(axiosMultipartPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
    }
  };

export const updateAppAssetInformation =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.updateAppAssetInformation("live-api"),
      apiUrl.updateAppAssetInformation("test-api")
    );
    dispatch({
      type: actionTypes.MOBILE_APP_CUSTOMIZATION_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
    }
  };
export const changeAppAssetPositions =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.changeAppAssetPositions("live-api"),
      apiUrl.changeAppAssetPositions("test-api")
    );
    dispatch({
      type: actionTypes.MOBILE_APP_CUSTOMIZATION_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
    }
  };
export const enableOrDisableAppDashboardModule =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.enableOrDisableAppDashboardModule("live-api"),
      apiUrl.enableOrDisableAppDashboardModule("test-api")
    );
    dispatch({
      type: actionTypes.MOBILE_APP_CUSTOMIZATION_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
    }
  };

export const addNewAppSliderAsset = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.addNewAppSliderAsset("live-api"),
    apiUrl.addNewAppSliderAsset("test-api")
  );
  dispatch({
    type: actionTypes.MOBILE_APP_CUSTOMIZATION_START,
  });
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      callback();
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
    } else {
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch(defaultAction(actionTypes.MOBILE_APP_CUSTOMIZATION_STOP));
  }
};

export const messagingCreditsListing =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.messagingCreditsListing("live-api"),
      apiUrl.messagingCreditsListing("test-api")
    );
    dispatch({
      type: actionTypes.GET_MESSAGE_CREDITS_LISTING_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback();
        return dispatch(
          defaultAction(actionTypes.GET_MESSAGE_CREDITS_LISTING_SUCCESS, data)
        );
      } else {
        return dispatch(
          defaultAction(actionTypes.GET_MESSAGE_CREDITS_LISTING_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.GET_MESSAGE_CREDITS_LISTING_STOP,
      });
    }
  };

export const orgPaymentMethod = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.orgPaymentMethod("live-api"),
    apiUrl.orgPaymentMethod("test-api")
  );
  dispatch({
    type: actionTypes.ORG_PAYMENT_METHOD_START,
  });
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      callback && callback();
      return dispatch(
        defaultAction(actionTypes.ORG_PAYMENT_METHOD_SUCCESS, data)
      );
    } else {
      return dispatch(defaultAction(actionTypes.ORG_PAYMENT_METHOD_STOP));
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch({
      type: actionTypes.ORG_PAYMENT_METHOD_STOP,
    });
  }
};

export const buyMsgCreditWithExistingToken =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.buyMsgCreditWithExistingToken("live-api"),
      apiUrl.buyMsgCreditWithExistingToken("test-api")
    );
    dispatch({
      type: actionTypes.BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_STOP)
        );
      } else {
        return dispatch(
          defaultAction(actionTypes.BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.BUY_MSG_CREDIT_WITH_EXISTING_TOKEN_STOP,
      });
    }
  };

export const buyMsgCreditWithNewCardOrCheck =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.buyMsgCreditWithNewCardOrCheck("live-api"),
      apiUrl.buyMsgCreditWithNewCardOrCheck("test-api")
    );
    dispatch({
      type: actionTypes.BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_STOP)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.BUY_MSG_CREDIT_WITH_NEW_CARD_OR_CHECK_STOP,
      });
    }
  };

export const msgCreditPurchasedHistory =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.msgCreditPurchasedHistory("live-api"),
      apiUrl.msgCreditPurchasedHistory("test-api")
    );
    dispatch({
      type: actionTypes.MSG_CREDIT_PURCHASED_HISTORY_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback();
        return dispatch(
          defaultAction(actionTypes.MSG_CREDIT_PURCHASED_HISTORY_SUCCESS, data)
        );
      } else {
        return dispatch(
          defaultAction(actionTypes.MSG_CREDIT_PURCHASED_HISTORY_STOP)
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.MSG_CREDIT_PURCHASED_HISTORY_STOP,
      });
    }
  };

export const enableOrDisableMsgCreditsAutoRecharge =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.enableOrDisableMsgCreditsAutoRecharge("live-api"),
      apiUrl.enableOrDisableMsgCreditsAutoRecharge("test-api")
    );
    dispatch({
      type: actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(
            actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP
          )
        );
      } else {
        return dispatch(
          defaultAction(
            actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP
          )
        );
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP,
      });
    }
  };

export const updateAppWalkThruAssetInformation =
  (payload, callback) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.updateAppWalkThruAssetInformation("live-api"),
      apiUrl.updateAppWalkThruAssetInformation("test-api")
    );
    dispatch({
      type: actionTypes.UPDATE_WALT_THRU_ASSET_START,
    });
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        callback && callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(defaultAction(actionTypes.UPDATE_WALT_THRU_ASSET_STOP));
      } else {
        return dispatch(defaultAction(actionTypes.UPDATE_WALT_THRU_ASSET_STOP));
      }
    } catch (error) {
      console.log(error, "error");
      toast.warn("Network Error. Please check your connectivity and try again");
      dispatch({
        type: actionTypes.UPDATE_WALT_THRU_ASSET_STOP,
      });
    }
  };

export const deleteAppAsset = (payload, callback) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.deleteAppAsset("live-api"),
    apiUrl.deleteAppAsset("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      callback && callback();
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      // return dispatch(
      //   defaultAction(
      //     actionTypes.ENABLE_OR_DISABLE_MSG_CREDITS_AUTO_RECHARGE_STOP
      //   )
      // );
    } else {
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const getOrganisationDynamicForms = (id) => {
  return async (dispatch) => {
    // dispatch({
    //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
    // });
    const url = apiUrl.getOrganizationForms(id);
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.GET,
        url,
      });
      const data = await response.data;
      if (data) {
        console.log(data, 'data');
        return dispatch(
          defaultAction(
            actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS,
            data
          )
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE)
        );
      }
    } catch (error) {
      console.log(error, "error");
      // dispatch({
      //   type: actionTypes.REORDER_ALBUM_EPISODES_STOP,
      // });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const createOrganisationDynamicForms = (id, payload, cb) => {
  return async (dispatch) => {
    // dispatch({
    //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
    // });
    const url = apiUrl.createOrganizationForms(id);
    try {
      const response = await AxiosInstance({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response.data;
      if (data) {
        cb();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
      }
    } catch (error) {
      console.log(error, "error");
      // dispatch({
      //   type: actionTypes.REORDER_ALBUM_EPISODES_STOP,
      // });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const deleteOrganisationDynamicForms = (id, orgId, cb) => {
  return async (dispatch) => {
    // dispatch({
    //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
    // });
    const url = apiUrl.deleteOrganizationForms(id, orgId);
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.DELETE,
        url,
      });
      const data = await response;
      console.log(data, "api data");
      if (data.status === 200) {
        cb();
        toast.success(
          <NotificationMesage
            responseStatus={'00'}
            message={data.data.message}
          />
        );
        // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={'01'}
            message={data.data.message}
          />
        );
        // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
      }
    } catch (error) {
      console.log(error, "error");
      // dispatch({
      //   type: actionTypes.REORDER_ALBUM_EPISODES_STOP,
      // });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const updateOrganisationDynamicForm = (id, payload, cb) => {
  return async (dispatch) => {
    // dispatch({
    //   type: actionTypes.REORDER_ALBUM_EPISODES_LOADING,
    // });
    const url = apiUrl.updateOrganizationForm(id);
    try {
      const response = await AxiosInstance({
        method: asyncMethod.PUT,
        url,
        data: payload,
      });
      const data = await response;
      console.log(data, "respose");
      if (data.status === 200) {
        cb();
        toast.success(
          <NotificationMesage
            responseStatus={data.data.responseCode}
            message={data.data.responseMessage}
          />
        );
        // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_SUCCESS, data));
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.data.responseCode}
            message={data.data.responseMessage}
          />
        );
        // return dispatch(defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE));
      }
    } catch (error) {
      console.log(error, "error");
      // dispatch({
      //   type: actionTypes.REORDER_ALBUM_EPISODES_STOP,
      // });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const getAllFormSubmission = (id) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.DYNAMIC_FORMS_SUBMITTED_LOADING,
    });
    const url = apiUrl.getAllFormSubmission(id);
    try {
      const response = await AxiosInstance({
        method: asyncMethod.GET,
        url,
      });
      const data = await response;
      if (data.status === 200) {
        // cb()
        return dispatch(
          defaultAction(actionTypes.DYNAMIC_FORMS_SUBMITTED_SUCCESS, data.data)
        );
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.data.responseCode}
            message={data.data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.DYNAMIC_FORMS_GET_ORGANIZATION_FAILURE)
        );
      }
    } catch (error) {
      console.log(error, "error");
      // dispatch({
      //   type: actionTypes.REORDER_ALBUM_EPISODES_STOP,
      // });
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
    }
  };
};

export const formSubmissionStatus = (id, payload, cb) => {
  return async (dispatch) => {
    const url = apiUrl.formSubmissionStatus(id);
    dispatch({
      type: actionTypes.UPDATE_DYNAMIC_FORM_STATUS_LOADING_START,
    });
    try {
      const response = await AxiosInstance({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response;
      if (data.data.responseCode === "00") {
        cb && cb();
        <NotificationMesage
          responseStatus={data.data.responseCode}
          message={data.data.responseMessage}
        />;
      } else {
        toast.error(
          <NotificationMesage
            responseStatus={data.data.responseCode}
            message={data.data.responseMessage}
          />
        );
      }
      return dispatch({
        type: actionTypes.UPDATE_DYNAMIC_FORM_STATUS_LOADING_STOP,
      });
    } catch (error) {
      toast.error(
        <NotificationMesage
          responseStatus={error?.response?.data?.responseCode}
          message={error?.response?.data?.responseMessage}
        />
      );
      return dispatch({
        type: actionTypes.UPDATE_DYNAMIC_FORM_STATUS_LOADING_STOP,
      });
    }
  };
};

export const giveMethods = (payload, cb) => {
  return async (dispatch) => {
    const url = apiUrl.giveMethods();
    dispatch({
      type: actionTypes.GIVE_METHODS_LOADING_START,
    });
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.POST,
        url,
        data: payload,
      });
      const data = await response;
      if (data.data.success) {
        toast.success(
          <NotificationMesage
            responseStatus={"00"}
            message={data.data.message}
          />
        );
        dispatch({
          type: actionTypes.GIVE_METHODS_LOADING_STOP,
        });
        return cb && cb();
      }
    } catch (error) {
      if (!error.response.success) {
        toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      }
      return dispatch({
        type: actionTypes.GIVE_METHODS_LOADING_STOP,
      });
    }
  };
};

export const getGiveMethods = (params, cb) => {
  return async (dispatch) => {
    const url = apiUrl.giveMethods();
    dispatch({
      type: actionTypes.GIVE_METHODS_LOADING_START,
    });
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.GET,
        url,
        params,
      });
      const data = await response;
      if (data.data.success) {
        dispatch({
          type: actionTypes.GET_GIVE_METHODS_SUCCESS,
          payload: data.data.data,
        });
        cb && cb();
      }
    } catch (error) {
      if (!error.response.success) {
        toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      }
      return dispatch({
        type: actionTypes.GIVE_METHODS_LOADING_STOP,
      });
    }
  };
};

export const createDynamicForm = (params, cb) => {
  return async (dispatch) => {
    const url = apiUrl.createForms();
    dispatch({
      type: actionTypes.CREATE_FORMS_REQUEST,
    });
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.POST,
        url,
        params,
      });
      const data = await response;
      if (data.data.success) {
        toast.success(
          <NotificationMesage
            responseStatus={"00"}
            message={data.data.message}
          />)
        dispatch({
          type: actionTypes.CREATE_FORMS_RESPONSE,
          payload: data.data.data,
        });
        cb && cb();
      }
    } catch (error) {
      if (!error.response.success) {
        toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      }
      return dispatch({
        type: actionTypes.CREATE_FORMS_RESPONSE,
      });
    }
  };
};

export const updateDynamicForm = (params, id, cb) => {
  return async (dispatch) => {
    const url = apiUrl.updateForms(id);
    dispatch({
      type: actionTypes.CREATE_FORMS_REQUEST,
    });
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.POST,
        url,
        params,
      });
      const data = await response;
      if (data.data.success) {
        toast.success(
          <NotificationMesage
            responseStatus={"00"}
            message={data.data.message}
          />)
        dispatch({
          type: actionTypes.CREATE_FORMS_RESPONSE,
          payload: data.data.data,
        });
        cb && cb();
      }
    } catch (error) {
      if (!error.response.success) {
        toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      }
      return dispatch({
        type: actionTypes.CREATE_FORMS_RESPONSE,
      });
    }
  };
};

export const getFormById = (payload, cb) => {
  return async (dispatch) => {
    const {id, orgId} = payload
    const url = apiUrl.getFormByIdUrl(id,orgId);
    dispatch({
      type: actionTypes.CREATE_FORMS_REQUEST,
    });
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.GET,
        url,
      });
      const data = await response;
      console.log(data, 'get form data');
      if (data.data.success) {
        console.log(data.data, 'get form data');
        toast.success(
          <NotificationMesage
          responseStatus={"00"}
          message={data.data.message}
          />)
        cb && cb();
        dispatch({
          type: actionTypes.GET_FORMS_RESPONSE,
          payload: data.data.data,
        });
      }
    } catch (error) {
      console.log(error, 'dynamic error');
      // if (!error.response) {
      //   toast.error(
      //     <NotificationMesage
      //       responseStatus={"01"}
      //       message={error?.response?.data?.message}
      //     />
      //   );
      // }
      // return dispatch({
      //   type: actionTypes.CREATE_FORMS_FAILED,
      // });
    }
  };
};

export const viewSubmittedForms = (id, orgId) => {
  return async (dispatch) => {
    const url = apiUrl.submittedFormsUrl(id, orgId);
    dispatch({
      type: actionTypes.VIEW_SUBMITTED_FORMS_REQUEST,
    });
    try {
      const response = await AxiosInstanceCP({
        method: asyncMethod.GET,
        url,
      });
      const data = await response;
      console.log(data, 'get form data');
      if (data.data.success) {
        console.log(data.data, 'get form data');
        toast.success(
          <NotificationMesage
          responseStatus={"00"}
          message={data.data.message}
          />)
        dispatch({
          type: actionTypes.VIEW_SUBMITTED_FORMS_RESPONSE,
          payload: data.data.data,
        });
      }
    } catch (error) {
      console.log(error, 'dynamic error');
      if (error.response) {
        toast.error(
          <NotificationMesage
            responseStatus={"01"}
            message={error?.response?.data?.message}
          />
        );
      }
      return dispatch({
        type: actionTypes.VIEW_SUBMITTED_FORMS_RESPONSE,
        payload: {}
      });
    }
  };
};
