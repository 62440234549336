import { actionTypes } from "../actionTypes";

export const getShop = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_SHOP_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SHOP_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_SHOP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_SHOP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateShop = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SHOP_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.UPDATE_SHOP_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.UPDATE_SHOP_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SHOP_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getTags = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_TAG_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_TAG_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_TAG_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_TAG_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createTag = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_SHOP_TAG_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_SHOP_TAG_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateTag = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SHOP_TAG_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_SHOP_TAG_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteTag = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_SHOP_TAG_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_SHOP_TAG_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getDiscounts = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_DISCOUNT_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_DISCOUNT_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_DISCOUNT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const searchDiscounts = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_DISCOUNT_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.SEARCH_DISCOUNT_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.SEARCH_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_DISCOUNT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createDiscount = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_DISCOUNT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateDiscount = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_DISCOUNT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteDiscount = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_DISCOUNT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_DISCOUNT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getProducts = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_PRODUCTS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_PRODUCTS_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_PRODUCTS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_PRODUCTS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createProduct = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_PRODUCT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const updateProduct = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_PRODUCT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteProduct = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_PRODUCT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
