import React from "react";
import { TextField, withStyles } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextValidator } from "react-material-ui-form-validator";

function CustomTextField(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.istextvalidator ? (
        <TextValidator
          {...props}
          variant="outlined"
          className={`${classes.customTextField} ${props.className}`}
        />
      ) : (
        <TextField
          {...props}
          variant="outlined"
          className={`${classes.customTextField} ${props.className}`}
        />
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  customTextField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#304fff",
      },
      "&:hover fieldset": {
        borderColor: "#304fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#304fff",
      },
      "& .MuiInputBase-input": {
        color: "#304fff",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        caretColor: "#fff",
        borderRadius: "inherit",
        "-webkit-box-shadow": "0 0 0 100px #c9dfef inset",
        "-webkit-text-fill-color": "#304fff",
      },
    },

    "& .MuiFormLabel-root": {
      color: "#304fff",
    },
    "&:hover .MuiFormLabel-root": {
      color: "#304fff",
    },
    "& .Mui-focused .MuiFormLabel-root": {
      color: "#304fff",
    },
    "& .MuiFormLabel-root .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
      color: "red !important",
    },
  },
}));

export default CustomTextField;
