import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { shallowEqual, useSelector } from "react-redux";
import useOnBoarding from "utils/hooks/useOnBoarding";

const useStyles = makeStyles((theme) => ({
  loading: {
    position: "fixed",
    top: "0px",
    left: "0px",
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "",
    zIndex: "10",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: "2",
      backdropFilter: "blur(3px)",
      position: "absolute",
      width: "100%",
      height: "100%",
    },

    "& img": {
      position: "absolute",
      height: "150px",
      width: "auto",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: "3",
      [theme.breakpoints.only("xs")]: {
        height: "100px",
      },
    },
  },
}));

export const Gif = () => {
  const classes = useStyles();
  return (
    <div style={{ padding: "20px" }}>
      <Skeleton
        variant="rect"
        height={"60px"}
        style={{ marginBottom: "20px" }}
      />
      <Skeleton
        variant="rect"
        height={"40px"}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        height={"30px"}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        height={"30px"}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        height={"10px"}
        style={{ marginBottom: "20px" }}
      />
      <Skeleton
        variant="rect"
        height={"10px"}
        style={{ marginBottom: "20px" }}
      />
      <Skeleton variant="rect" height={"400px"} />
      {/* <div className={classes.loading}>
        <div className="backdrop">
          <img src={BigLoader} alt="loader" />
        </div>
      </div> */}
    </div>
  );
};

const GifSuspense = (props) => {
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  const { isOnBoarding, churchName } = useOnBoarding();

  const showChildren = () => {
    return (
      (isOnBoarding && !churchName) ||
      churchName === "mobile-app-proposal" ||
      churchName === "global-giving-ebrochure" ||
      (orgInfo?.orgId && orgInfo?.products && orgInfo?.systemSettings)
    );
  };

  return (
    <Suspense fallback={<Gif />}>{showChildren() && props.children}</Suspense>
  );
};

export default GifSuspense;
