import { actionTypes } from "../actionTypes";

export const createTicket = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TICKET_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TICKET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const updateTicket = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TICKET_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TICKET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const ticketListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TICKET_LISTING_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.TICKET_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TICKET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const ticketInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TICKET_INFO_SUCCESS:
      return { loading: false, ...action.payload };
    case actionTypes.TICKET_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TICKET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteTicket = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TICKET_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TICKET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const closeTicket = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TICKET_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.TICKET_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
