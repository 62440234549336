import { actionTypes } from "../actionTypes";

export const ipData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_IPDATA:
      return action.payload;
    default:
      return state;
  }
};

export const getThemeChangeData = (state = { theme: false }, action) => {
  switch (action.type) {
    case actionTypes.SET_THEME_CHANGE:
      return action.payload;
    default:
      return state;
  }
};

export const orgInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ORG_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const secondOrgInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SECOND_ORG_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const peopleList = (state = { loading: false }, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PEOPLE:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.PEOPLE_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.PEOPLE_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CLEAR_TABLE_PEOPLE:
      return {};
    default:
      return state;
  }
};
export const fetchUserList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CLEAR_PEOPLE:
      return {};

    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const membership = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_MEMBERSHIP:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addMembership = (state = {}, action) => {
  switch (action.type) {
    // case actionTypes.ADD_MEMBERSHIP:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeMembershipName = (state = {}, action) => {
  switch (action.type) {
    // case actionTypes.ADD_MEMBERSHIP:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeMembershipPositionReducer = (state = {}, action) => {
  switch (action.type) {
    // case actionTypes.ADD_MEMBERSHIP:
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteMembershipName = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateAdminProfile = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const addNewMemberState = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const randomPassword = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchedUsers = (state = { loading: false }, action) => {
  switch (action.type) {
    case actionTypes.SEARCHED_USERS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.SEARCH_USER_LOADING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_USER_LOADING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const generosityDashboardData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GENEROSITY_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GENEROSITY_DASHBOARD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const adminRecentContributionsData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADMIN_RECENT_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ADMIN_RECENT_CONTRIBUTIONS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const contributionNamesAndCodesData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CONTRIBUTIONS_NAMES_AND_CODES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_CONTRIBUTIONS_NAMES_AND_CODES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeContributionNamesPositionData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_CONTRIBUTION_NAMES_POSITION_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CHANGE_CONTRIBUTION_NAMES_POSITION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addContributionNameData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_CONTRIBUTION_NAME_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ADD_CONTRIBUTION_NAME_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeContributionNameData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_CONTRIBUTION_NAME_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CHANGE_CONTRIBUTION_NAME_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const availableContributionCodesData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_AVAILABLE_CONTRIBUTION_CODES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_AVAILABLE_CONTRIBUTION_CODES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const deleteContributionNameData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CONTRIBUTION_NAMES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.DELETE_CONTRIBUTION_NAMES_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeTransactionContributionTypeData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_TRANSACTION_CONTRIBUTION_TYPE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CHANGE_TRANSACTION_CONTRIBUTION_TYPE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const contributionsReportingData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CONTRIBUTIONS_REPORTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.CONTRIBUTIONS_REPORTING_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export const generosityModuleSpecificInfoData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GENEROSITY_MODULE_SPECIFIC_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GENEROSITY_MODULE_SPECIFIC_INFO_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const weeklyDatesGeneratorData = (
  state = { startYear: [], endYear: [] },
  action
) => {
  switch (action.type) {
    case actionTypes.START_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS:
      return {
        ...state,
        startYear: action.payload,
      };
    case actionTypes.END_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS:
      return {
        ...state,
        endYear: action.payload,
      };
    case actionTypes.WEEKLY_DATES_GENERATOR_SUCCESS:
      return {
        ...state,
        startYear: action.payload,
        endYear: action.payload,
      };
    case actionTypes.WEEKLY_DATES_GENERATOR_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const loginData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.LOGIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.AUTH_2FA_REQUIRED:
      return {
        ...state,
        required2FAAuth: true,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const signupData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SIGNUP_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const forgotPasswordData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const forgotPinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FORGOT_PIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.FORGOT_PIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const verifyForgotPasswordOrPinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_PAGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_PAGE_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const resetPasswordOrPinData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_OR_PIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_PASSWORD_OR_PIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const orgInfoList = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_INFO_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/* Reducer for User Update */
export const updateUserData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_RECORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPDATE_USER_RECORD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

/* Reducer for User profile */
export const getUserProfileData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const donatedAmountData = (
  state = {
    amount: 0.0,
    errorMsg: "",
    hideAmountInput: false,
    scrollToErrorMsg: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.DONATED_AMOUNT:
      return {
        ...state,
        amount: action.payload,
      };
    case actionTypes.DONATED_AMOUNT_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.payload,
      };
    case actionTypes.SET_HIDE_AMOUNT_INPUT:
      return {
        ...state,
        hideAmountInput: action.payload,
      };
    case actionTypes.SCROLL_TO_AMOUNT_ERROR_MSG:
      return {
        ...state,
        scrollToErrorMsg: action.payload,
      };
    default:
      return state;
  }
};
export const setAmountInputStateData = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const creditCardInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_CREDIT_CARD_OWNER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_GIVE_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_CREDIT_CARD_DETAILS:
      return {
        ...state,
        creditCardDetails: action.payload,
        checkDetails: null,
      };
    case actionTypes.SET_CHECK_DETAILS:
      return {
        ...state,
        checkDetails: action.payload,
        creditCardDetails: null,
      };
    default:
      return state;
  }
};

export const giveWithCardData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GIVE_WITH_CARD_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GIVE_WITH_CARD_FAILURE:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GIVE_WITH_CHECK_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.GIVE_WITH_CHECK_FAILURE:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.RESET_GIVE_RESPONSE:
      return {
        ...state,
        data: {},
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addChildData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_A_CHILD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.ADD_A_CHILD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const showMenuBar = (state = { isMobileShow: false }, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MOBILE_SIDE_BAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const updateUser2FA = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_2FA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_2FA_ERROR:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    default:
      return state;
  }
};

export const uploadMediaData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_MEDIA_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.UPLOAD_MEDIA_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_MEDIA_DATA:
      return {};
    default:
      return state;
  }
};
export const systemTooltipsModalComments = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SYSTEM_TOOLTIP_MODAL_COMMENTS_SUCCESS:
    case actionTypes.SYSTEM_TOOLTIP_MODAL_COMMENTS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.RESET_MEDIA_DATA:
      return {};
    default:
      return state;
  }
};
