const useOnBoarding = () => {
  const isOnBoarding =
  window.location.hostname === "onboarding.churchpad.com" ||
  window.location.hostname === "onboarding-test.churchpad.com";
    const path = window.location.pathname;
  const parts = path.split("/");
  const churchName = parts[1];

  return {
    isOnBoarding,
    churchName,
  };
};

export default useOnBoarding;
