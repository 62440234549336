import React from "react";
import axios from "api";
import { toast } from "react-toastify";
import { actionTypes } from "../actionTypes";
import {
  URL,
  asyncMethod,
  basicAuthPayload,
  apiUrl,
  domainName,
  defaultAction,
} from "../../utils/constants";
import { checkNodeEnv } from "utils/authHelpers";
import { getNotification } from "./NotificationActions";
import NotificationMesage from "../../components/Suspense/NotificationMessage";
import { browserDetails, getAdminUserId } from "../../utils/utils";

const ipDataAction = (payload) => ({ type: actionTypes.GET_IPDATA, payload });
const orgInfoAction = (payload) => ({
  type: actionTypes.FETCH_ORG_INFO,
  payload,
});
export const resetEndpointDataAction = {
  type: actionTypes.RESET_ENDPOINT_DATA,
};
export const resetCreditDataAction = { type: actionTypes.RESET_CREDIT_DATA };
export const resetCheckDataAction = { type: actionTypes.RESET_CHECK_DATA };
export const resetMediaDataAction = { type: actionTypes.RESET_MEDIA_DATA };
export const startLoadingAction = { type: actionTypes.START_LOADING };
export const stopLoadingAction = { type: actionTypes.STOP_LOADING };
const startPageLoadingAction = { type: actionTypes.START_PAGE_LOADING };
const stopPageLoadingAction = { type: actionTypes.STOP_PAGE_LOADING };
const orgInfoListAction = (payload) => ({
  type: actionTypes.ORG_INFO_LIST,
  payload,
});
let browerData = browserDetails();
const user = JSON.parse(localStorage.getItem("user"));

export const getIpData = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(URL.ipDataUrl);
      localStorage.setItem("ipAddress", JSON.stringify(response.data.ip));
      return dispatch(ipDataAction(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchOrgInfo = () => {
  return async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.orgInfo(URL.prodOrgUrl, domainName),
      apiUrl.orgInfo(URL.devOrgUrl, domainName)
    );
    try {
      const response = await axios({
        method: asyncMethod.GET,
        url,
        auth: basicAuthPayload,
      });
      const data = await response.data;
      if (data) {
        const isOnBoarding =
          window.location.hostname === "onboarding.churchpad.com" ||
          window.location.hostname === "onboarding-test.churchpad.com";
        if (isOnBoarding && data.responseData?.isCustomUrlEnabled === "true") {
          return (window.location.href = `${data.responseData.dedicatedUrlAdmin}`);
        }
        localStorage.setItem(
          "productList",
          JSON.stringify(data.responseData.products)
        );
        localStorage.setItem(
          "systemSettings",
          JSON.stringify(data.responseData.systemSettings)
        );
        dispatch(orgInfoList({ orgId: data.responseData.orgId }));
        dispatch(secondOrgList({ orgId: data.responseData.orgId }));
        return dispatch(orgInfoAction(data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

const authAction = (type, payload) => ({ type, payload });
export const axiosPayload = (url, data) => {
  let userData = { ...data, ...browerData };
  return {
    method: asyncMethod.POST,
    headers: { "content-type": "application/json" },
    url,
    data: userData,
    auth: basicAuthPayload,
  };
};

export const axiosPayloadForm = (url, data) => {
  // let userData = { ...data, ...browerData };
  return {
    method: asyncMethod.POST,
    headers: { "content-type": "multipart/form-data" },
    url,
    data,
    auth: basicAuthPayload,
  };
};

export const setThemeChange = (payload) => {
  return (dispatch) => {
    dispatch(authAction(actionTypes.SET_THEME_CHANGE, payload));
  };
};

export const axiosMultipartPayload = (url, data) => ({
  method: asyncMethod.POST,
  headers: { "content-type": "multipart/form-data" },
  url,
  data,
  auth: basicAuthPayload,
});
export const axiosMultipartPayloaWithdHeader = (url, data, header) => ({
  method: asyncMethod.POST,
  headers: { "content-type": "multipart/form-data", "X-AUTH-TOKEN": header },
  url,
  data,
});
export const axiosMultipartWithProgress = (url, data, config) => ({
  method: asyncMethod.POST,
  headers: { "content-type": "multipart/form-data" },
  url,
  data,
  auth: basicAuthPayload,
  onUploadProgress: function (progressEvent) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    // console.log(percentCompleted, 'percentage upload')
    config(percentCompleted);
  },
});

export const login = (payload, cb) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.login("live-api"),
      apiUrl.login("test-api")
    );
    // const jwtAccessTokenUrl = checkNodeEnvNextGen(
    //   apiUrl.jwtAccessToken(nextgenUrl.live),
    //   apiUrl.jwtAccessToken(nextgenUrl.staging)
    // );
    const urlMService = checkNodeEnv(
      apiUrl.mserviceLogin("https://mservice.churchpad.com/"),
      apiUrl.mserviceLogin("https://mservice-test.churchpad.com/")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        let payloadMService = {...payload}
        payloadMService['org_id'] = payload.orgId
        payloadMService['user_id'] = data.responseData.userId
        delete payloadMService.pin
        const responseMservice = await axios(axiosPayload(urlMService, payloadMService));
        const dataMservice = await responseMservice.data;
        if (responseMservice.status === 200) {
          // console.log(dataMservice, 'hjfjhcfvj');
          localStorage.setItem('mservice-token', dataMservice.data.access_token)
        }
        // const nextGenLoginDetails = {
        //   email: payload.email,
        //   organizationId: payload.orgId,
        //   password: payload.password,
        //   userId: data.responseData.userId,
        // };
        // const jwtResponse = await axios({
        //   method: asyncMethod.POST,
        //   url: jwtAccessTokenUrl,
        //   data: nextGenLoginDetails,
        // });
        // const jwtData = await jwtResponse.data;
        localStorage.setItem("nextGenToken", data?.responseData?.jwtToken);
        if (
          data?.responseData?.twoFactorAuthentication?.isRequired === "false"
        ) {
          data.authData =
            payload.email && payload.password
              ? window.btoa(payload.email + ":" + payload.password)
              : window.btoa(payload.mobile + ":" + payload.pin);
          localStorage.setItem("user", JSON.stringify(data));
          // localStorage.setItem("jwtAccessToken", JSON.stringify(jwtData));
          dispatch(stopLoadingAction);
          dispatch(
            getUserProfile({
              orgId: payload?.orgId,
              userId: data?.responseData?.userId,
              adminUserId: data?.responseData?.userId,
            })
          );
          cb();
          return dispatch(authAction(actionTypes.LOGIN_SUCCESS, data)); // check if the dispatch data from the store or localStorage
        } else if (
          data?.responseData?.twoFactorAuthentication?.isRequired === "true"
        ) {
          localStorage.setItem("2FA", JSON.stringify(data.responseData));
          dispatch(stopLoadingAction);
        }
      } else {
        data.authData = {};
        dispatch(stopLoadingAction);
        dispatch(getNotification(data.responseMessage));
        return dispatch(authAction(actionTypes.LOGIN_FAILURE, data));
      }
    } catch (error) {
      dispatch(stopLoadingAction);
      // toast.warn("Network Error. Please check your connectivity and try again");
      console.log(error, "login error");
    }
  };
};

export const userImageUpload = async (payload) => {
  const url = checkNodeEnv(
    apiUrl.userImageUrl("live-api"),
    apiUrl.userImageUrl("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return data;
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const signup = (payload, callback) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.signup("live-api"),
      apiUrl.signup("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        data.authData =
          payload.email && payload.password
            ? window.btoa(payload.email + ":" + payload.password)
            : window.btoa(payload.mobile + ":" + payload.pin);
        localStorage.setItem("user", JSON.stringify(data));
        dispatch(stopLoadingAction);
        toast.success(data.responseMessage);
        callback();
        return dispatch(authAction(actionTypes.SIGNUP_SUCCESS, data)); // check if the dispatch data from the store or localStorage
      } else {
        data.authData = {};
        dispatch(stopLoadingAction);
        toast.error(data.responseMessage);
        return dispatch(authAction(actionTypes.SIGNUP_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const forgotPassword = (payload, cb) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.forgotPassword("live-api"),
      apiUrl.forgotPassword("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      console.log(data, 'data');
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // toast.success(data.responseMessage);
        // cb();
        return dispatch(authAction(actionTypes.FORGOT_PASSWORD_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        dispatch(getNotification(data.responseMessage));
        return dispatch(authAction(actionTypes.FORGOT_PASSWORD_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const forgotPin = (payload, cb) => {
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.forgotPin("live-api"),
      apiUrl.forgotPin("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(data.responseMessage);
        cb();
        return dispatch(authAction(actionTypes.FORGOT_PIN_SUCCESS, data));
      } else {
        dispatch(stopLoadingAction);
        dispatch(getNotification(data.responseMessage));
        return dispatch(authAction(actionTypes.FORGOT_PIN_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const resetPasswordOrPin = (payload, cb) => {
  return async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.resetPassword("live-api"),
      apiUrl.resetPassword("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(data.responseMessage);
        cb();
        return dispatch(
          authAction(actionTypes.RESET_PASSWORD_OR_PIN_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        dispatch(getNotification(data.responseMessage));
        return dispatch(
          authAction(actionTypes.RESET_PASSWORD_OR_PIN_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const verifyForgotPassword = (payload, cb) => {
  return async (dispatch) => {
    dispatch(startPageLoadingAction);
    const url = checkNodeEnv(
      apiUrl.verifyForgotPasswordorPin("live-api"),
      apiUrl.verifyForgotPasswordorPin("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopPageLoadingAction);
        return dispatch(
          authAction(actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_SUCCESS, data)
        );
      } else {
        dispatch(stopPageLoadingAction);
        toast.error(data.responseMessage);
        cb();
        return dispatch(
          authAction(actionTypes.VERIFY_FORGOT_PASSWORD_OR_PIN_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

export const fetchPeople = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  dispatch({
    type: actionTypes.PEOPLE_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.fetchPeople("live-api"),
    apiUrl.fetchPeople("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      cb && cb();
      return dispatch(authAction(actionTypes.FETCH_PEOPLE, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(data.responseMessage);
      dispatch({
        type: actionTypes.PEOPLE_LOADING_STOP,
      });
      return dispatch(authAction(actionTypes.STOP_LOADING, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch({
      type: actionTypes.PEOPLE_LOADING_STOP,
    });
  }
};

export const userProfileUrl = (payload, cb) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.userProfileUrl("live-api"),
    apiUrl.userProfileUrl("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return cb && cb(data);
    }
  } catch (error) {
    // toast.warn("Network Error. Please check your connectivity and try again");
    cb && cb();
  }
};

export const fetchUserProfile = (payload) => async (dispatch) => {
  dispatch({ type: actionTypes.CLEAR_PEOPLE });
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.fetchUserProfile("live-api"),
    apiUrl.fetchUserProfile("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.FETCH_USER_PROFILE, data));
    } else {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.STOP_LOADING, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const getMembership = (payload) => async (dispatch) => {
  dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.getMembership("live-api"),
    apiUrl.getMembership("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.GET_MEMBERSHIP, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(data.responseMessage);
      return dispatch(authAction(actionTypes.STOP_LOADING, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addMembershipType = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.addMembershipType("live-api"),
    apiUrl.addMembershipType("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        getMembership({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      cb();
      return dispatch(authAction(actionTypes.ADD_MEMBERSHIP));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const changeMembershipName = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.changeMembershipName("live-api"),
    apiUrl.changeMembershipName("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        getMembership({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      cb();
      return dispatch(authAction(actionTypes.CHANGE_MEMBERSHIP_NAME));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const deleteMembership = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.deleteMembership("live-api"),
    apiUrl.deleteMembership("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        getMembership({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      cb();
      return dispatch(authAction(actionTypes.DELETE_MEMBERSHIP_NAME));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const changeMembershipPosition = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.changeMembershipPosition("live-api"),
    apiUrl.changeMembershipPosition("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        getMembership({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      cb();
      return dispatch(authAction(actionTypes.CHANGE_MEMBERSHIP_POSITION));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateUserProfileAdmin = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateUserProfileAdmin("live-api"),
    apiUrl.updateUserProfileAdmin("test-api")
  );
  var form_data = new FormData();
  for (let key in payload) {
    if (key === "mobile") {
      payload[key].forEach((item) => form_data.append(key + "[]", item));
    } else form_data.append(key, payload[key]);
  }

  try {
    const response = await axios(axiosMultipartPayload(url, form_data));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        fetchUserProfile({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
          userId: payload.userId,
        })
      );
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_ADMIN_PROFILE));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch(stopLoadingAction);
  }
};

export const addNewMember = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.addNewMember("live-api"),
    apiUrl.addNewMember("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      cb && cb();
      dispatch(
        fetchPeople({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      return dispatch(authAction(actionTypes.ADD_NEW_MEMBER));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const generateRandmPassword = (payload, cb) => async (dispatch) => {
  // dispatch({type: actionTypes.CLEAR_PEOPLE});
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.generateRandmPassword("live-api"),
    apiUrl.generateRandmPassword("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      cb && cb(data.responseData.generatedPassword);
      return dispatch(authAction(actionTypes.GENERATE_RANDOM_PASSWORD));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const generateEnvelopeId = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.generateEnvelopeId("live-api"),
    apiUrl.generateEnvelopeId("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return cb && cb(data.responseData.envelopeId);
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const doSearchUser = (payload, cb) => async (dispatch) => {
  // dispatch({type: actionTypes.CLEAR_PEOPLE});
  // dispatch(startLoadingAction);
  dispatch({
    type: actionTypes.SEARCH_USER_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.doSearchUser("live-api"),
    apiUrl.doSearchUser("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      cb && cb(data);
      return dispatch(authAction(actionTypes.SEARCHED_USERS, data));
    } else {
      // dispatch(stopLoadingAction);
      return dispatch({
        type: actionTypes.SEARCH_USER_LOADING_STOP,
      });
      // return toast.error(data.responseMessage);
    }
  } catch (error) {
    // dispatch(stopLoadingAction);
    dispatch({
      type: actionTypes.SEARCH_USER_LOADING_STOP,
    });
    // console.log(error);
    // toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const doSearchUserAdvance = (payload, cb) => async (dispatch) => {
  // dispatch({type: actionTypes.CLEAR_PEOPLE});
  // dispatch(startLoadingAction);
  dispatch({
    type: actionTypes.SEARCH_USER_LOADING_START,
  });
  const url = checkNodeEnv(
    apiUrl.doSearchUserAdvance("live-api"),
    apiUrl.doSearchUserAdvance("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      cb && cb(data);
      return dispatch(authAction(actionTypes.SEARCHED_USERS, data));
    } else {
      // dispatch(stopLoadingAction);
      return dispatch({
        type: actionTypes.SEARCH_USER_LOADING_STOP,
      });
      // return toast.error(data.responseMessage);
    }
  } catch (error) {
    // dispatch(stopLoadingAction);
    dispatch({
      type: actionTypes.SEARCH_USER_LOADING_STOP,
    });
    // console.log(error);
    // toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateOrgGeneral = (payload, cb) => async (dispatch) => {
  const showLoader = !!payload?.showLoader;
  delete payload?.showLoader;
  showLoader && dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateOrgGeneral("live-api"),
    apiUrl.updateOrgGeneral("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      showLoader && toast.success(data.responseMessage);
      // dispatch(stopLoadingAction);
      dispatch(fetchOrgInfo());
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_GENERAL_SETTINGS));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateOrgSocialMedia = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateOrgSocialMedia("live-api"),
    apiUrl.updateOrgSocialMedia("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      // dispatch(stopLoadingAction);
      dispatch(fetchOrgInfo());
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_SOCIALMEDIA));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateOrgLogo = (payload, cb) => async (dispatch) => {
  // dispatch({type: actionTypes.CLEAR_PEOPLE});
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateOrgLogo("live-api"),
    apiUrl.updateOrgLogo("test-api")
  );
  var form_data = new FormData();
  for (let key in payload) {
    if (key === "logoType") {
      payload[key].forEach((item) => form_data.append(key + "[]", item));
    } else form_data.append(key, payload[key]);
  }
  try {
    const response = await axios(axiosMultipartPayload(url, form_data));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(fetchOrgInfo());
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_LOGO));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
    dispatch(stopLoadingAction);
  }
};

export const getOrgMessage = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.getOrgMessage("live-api"),
    apiUrl.getOrgMessage("test-api")
  );

  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      cb && cb();
      return dispatch(authAction(actionTypes.ORG_MESSAGE, data));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    console.log(error);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateOrgTextmessaging = (payload, cb) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateOrgTextmessaging("live-api"),
    apiUrl.updateOrgTextmessaging("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        getOrgMessage({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_TEXT_MESSAGING));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateOrgColors = (payload, cb) => async (dispatch) => {
  // dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateOrgColors("live-api"),
    apiUrl.updateOrgColors("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(fetchOrgInfo());
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_ORRG_COLOR_SUCCESS));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateEmailTemplate = (payload, cb) => async (dispatch) => {
  // dispatch(resetEndpointDataAction);
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.updateEmailTemplate("live-api"),
    apiUrl.updateEmailTemplate("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      toast.success(data.responseMessage);
      dispatch(stopLoadingAction);
      dispatch(
        getOrgMessage({
          orgId: payload.orgId,
          adminUserId: payload.adminUserId,
        })
      );
      cb && cb();
      return dispatch(authAction(actionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS));
    } else {
      dispatch(stopLoadingAction);
      return toast.error(data.responseMessage);
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

// /* Get organization information List */
export const getOrgInfoList = (orgId) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.orgInfoList("live-api"),
    apiUrl.orgInfoList("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, orgId));
    const data = await response.data;
    if (data) {
      return dispatch(authAction(actionTypes.GET_ORG_INFO_LIST, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const prayerWalls = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.prayerWalls("live-api"),
    apiUrl.prayerWalls("test-api")
  );
  dispatch(startLoadingAction);
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.PRAYERWALLS_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.PRAYERWALLS_FAILURE, data));
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const orgInfoList = (payload) => {
  return async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.orgInfoList("live-api"),
      apiUrl.orgInfoList("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(authAction(actionTypes.GET_ORG_INFO_LIST, data));
        return dispatch(orgInfoListAction(data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
};

// // get user profile
export const getUserProfile =
  (payload, hideLoading = false) =>
  async (dispatch) => {
    if (!hideLoading) dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.getUserProfile("live-api"),
      apiUrl.getUserProfile("test-api")
    );
    // const jwtAccessTokenUrl = apiUrl.jwtAccessToken;
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        // const getToken = JSON.parse(localStorage.getItem('jwtAccessToken'))
        // if (!getToken) {
        //   const getUserId = JSON.parse(localStorage.getItem('user'))
        //   const nextGenLoginDetails = {
        //     "organizationId": payload.orgId,
        //     "userId": getUserId.responseData.userId,
        //     "pin": data?.responseData?.pin,
        //     "telephoneNumber": data?.responseData?.mobile[0]
        //   }
        //   const jwtResponse = await axios({
        //     method: asyncMethod.POST,
        //     url: jwtAccessTokenUrl,
        //     data: nextGenLoginDetails
        //   });
        //   const jwtData = await jwtResponse.data;
        //   localStorage.setItem('jwtAccessToken', JSON.stringify(jwtData));
        // }
        if (!hideLoading) dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.GET_USER_PROFILE_SUCCESS, data));
      } else {
        if (!hideLoading) dispatch(stopLoadingAction);
        return dispatch(authAction(actionTypes.GET_USER_PROFILE_SUCCESS, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const secondOrgList = (payload) => async (dispatch) => {
  // dispatch(resetEndpointDataAction);
  // dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.orgInfo2("live-api"),
    apiUrl.orgInfo2("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      localStorage.setItem(
        "productList",
        JSON.stringify(data.responseData.products)
      );
      localStorage.setItem(
        "systemSettings",
        JSON.stringify(data.responseData.systemSettings)
      );
      localStorage.setItem(
        "supportResourcesSettings",
        JSON.stringify(data.responseData.supportResourcesSettings)
      );
      return dispatch(authAction(actionTypes.FETCH_SECOND_ORG_INFO, data));
    } else {
      dispatch(stopLoadingAction);
      return dispatch(authAction(actionTypes.STOP_LOADING, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const approveDisDeleteFellowship =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.adminApproveDisDeleteFellowship("live-api"),
      apiUrl.adminApproveDisDeleteFellowship("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.PRAYERWALLS_APPROVE_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.PRAYERWALLS_APPROVE_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const fellowshipCategoryTypesWithPositions =
  (payload) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.fellowshipCategoryTypesWithPositions("live-api"),
      apiUrl.fellowshipCategoryTypesWithPositions("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // callback()
        return dispatch(
          defaultAction(
            actionTypes.FELLOWSHIP_CATEGORY_WITH_POSITION_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        return dispatch(
          defaultAction(
            actionTypes.FELLOWSHIP_CATEGORY_WITH_POSITION_FAILURE,
            data
          )
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const generosityDashboard = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.generosityDashboard("live-api"),
    apiUrl.generosityDashboard("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.GENEROSITY_DASHBOARD_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.GENEROSITY_DASHBOARD_FAILURE, data)
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const adminRecentContributions = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.adminRecentContributions("live-api"),
    apiUrl.adminRecentContributions("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.ADMIN_RECENT_CONTRIBUTIONS_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.ADMIN_RECENT_CONTRIBUTIONS_FAILURE, data)
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const getContributionNamesAndCodes =
  (payload, cb) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.contributionNamesAndCodes("live-api"),
      apiUrl.contributionNamesAndCodes("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        // cb();
        return dispatch(
          defaultAction(
            actionTypes.GET_CONTRIBUTIONS_NAMES_AND_CODES_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        return dispatch(
          defaultAction(
            actionTypes.GET_CONTRIBUTIONS_NAMES_AND_CODES_FAILURE,
            data
          )
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const getAvailableContributionCodes = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.availableContributionCodes("live-api"),
    apiUrl.availableContributionCodes("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return dispatch(
        defaultAction(
          actionTypes.GET_AVAILABLE_CONTRIBUTION_CODES_SUCCESS,
          data
        )
      );
    } else {
      return dispatch(
        defaultAction(
          actionTypes.GET_AVAILABLE_CONTRIBUTION_CODES_FAILURE,
          data
        )
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const changeContributionNamesPosition =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeContributionNamesPosition("live-api"),
      apiUrl.changeContributionNamesPosition("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(
            actionTypes.CHANGE_CONTRIBUTION_NAMES_POSITION_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        return dispatch(
          defaultAction(
            actionTypes.CHANGE_CONTRIBUTION_NAMES_POSITION_FAILURE,
            data
          )
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const addContributionName = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.addContributionName("live-api"),
    apiUrl.addContributionName("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(
        defaultAction(actionTypes.ADD_CONTRIBUTION_NAME_SUCCESS, data)
      );
    } else {
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.ADD_CONTRIBUTION_NAME_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error.response, "eror");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const changeContributionName =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeContributionName("live-api"),
      apiUrl.changeContributionName("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.CHANGE_CONTRIBUTION_NAME_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.CHANGE_CONTRIBUTION_NAME_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, "eror");
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const deleteContributionName =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.deleteContributionName("live-api"),
      apiUrl.deleteContributionName("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.DELETE_CONTRIBUTION_NAMES_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.DELETE_CONTRIBUTION_NAMES_FAILURE, data)
        );
      }
    } catch (error) {
      console.log(error, "eroor");
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const changeTransactionContributionType =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeTransactionContributionType("live-api"),
      apiUrl.changeTransactionContributionType("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(
            actionTypes.CHANGE_TRANSACTION_CONTRIBUTION_TYPE_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(
            actionTypes.CHANGE_TRANSACTION_CONTRIBUTION_TYPE_FAILURE,
            data
          )
        );
      }
    } catch (error) {
      console.log(error, "eroor");
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const generosityModuleSpecificInfo = (payload) => async (dispatch) => {
  // dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.generosityModuleSpecificInfo("live-api"),
    apiUrl.generosityModuleSpecificInfo("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.GENEROSITY_MODULE_SPECIFIC_INFO_SUCCESS, data)
      );
    } else {
      // dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.GENEROSITY_MODULE_SPECIFIC_INFO_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "eroor");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const contributionsReporting = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.contributionsReporting("live-api"),
    apiUrl.contributionsReporting("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.CONTRIBUTIONS_REPORTING_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.CONTRIBUTIONS_REPORTING_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "eroor");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const changeFellowshipCategoryPosition =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeFellowshipCategoryPosition("live-api"),
      apiUrl.changeFellowshipCategoryPosition("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        return dispatch(
          defaultAction(actionTypes.CHANGE_FELLOWSHIP_POSITION_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.CHANGE_FELLOWSHIP_POSITION_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const addFellowshipCategory =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.addFellowshipCategory("live-api"),
      apiUrl.addFellowshipCategory("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.ADD_FELLOWSHIP_CATEGORY_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.ADD_FELLOWSHIP_CATEGORY_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const deleteFellowshipCategory =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.deleteFellowshipCategory("live-api"),
      apiUrl.deleteFellowshipCategory("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.DELETE_FELLOWSHIP_CATEGORY_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.DELETE_FELLOWSHIP_CATEGORY_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const changeFellowshipCategoryName =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeFellowshipCategoryName("live-api"),
      apiUrl.changeFellowshipCategoryName("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        callback();
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.EDIT_FELLOWSHIP_CATEGORY_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.EDIT_FELLOWSHIP_CATEGORY_FAILURE, data)
        );
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const fellowshipPrequestDashboard = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.fellowshipPrequestDashboard("live-api"),
    apiUrl.fellowshipPrequestDashboard("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // callback()
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.FELLOWSHIP_PREQUEST_DASHBOARD_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.FELLOWSHIP_PREQUEST_DASHBOARD_FAILURE, data)
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const fellowshipPreportDashboard = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.fellowshipPreportDashboard("live-api"),
    apiUrl.fellowshipPreportDashboard("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // callback()
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.FELLOWSHIP_PREPORT_DASHBOARD_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.FELLOWSHIP_PREPORT_DASHBOARD_FAILURE, data)
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const childrenListing = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.childrenListing("live-api"),
    apiUrl.childrenListing("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // callback()
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.CHILDREN_LISTING_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.CHILDREN_LISTING_FAILURE, data)
      );
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const fetchChildProfile = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.fetchChildProfile("live-api"),
    apiUrl.fetchChildProfile("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    dispatch(classroomCategories(payload));
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // callback()
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(defaultAction(actionTypes.GET_ONE_CHILD_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(defaultAction(actionTypes.GET_ONE_CHILD_FAILURE, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const childDetails = (payload) => {
  return (dispatch) => {
    dispatch(authAction(actionTypes.GET_CHILD, payload));
  };
};

export const adminChildDelete = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.adminChildDeleteOrUpdate("live-api"),
    apiUrl.adminChildDeleteOrUpdate("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      callback();
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.DELETE_A_CHILD_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.DELETE_A_CHILD_FAILURE, data));
    }
  } catch (error) {
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const adminChildUpdate = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.adminChildDeleteOrUpdate("live-api"),
    apiUrl.adminChildDeleteOrUpdate("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      callback();
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.UPDATE_A_CHILD_DATA_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.UPDATE_A_CHILD_DATA_FAILURE, data)
      );
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const classroomCategories = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.classroomCategories("live-api"),
    apiUrl.classroomCategories("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // callback()
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.GET_ALL_CLASSROOM_CATEGORIES_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(
        defaultAction(actionTypes.GET_ALL_CLASSROOM_CATEGORIES_FAILURE, data)
      );
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addClassroomCategory = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.addClassroomCategory("live-api"),
    apiUrl.addClassroomCategory("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(
        defaultAction(actionTypes.ADD_CLASSROOM_CATEGORIES_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(
        defaultAction(actionTypes.ADD_CLASSROOM_CATEGORIES_FAILURE, data)
      );
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const changeClassroomCategoryPosition =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeClassroomCategoryPosition("live-api"),
      apiUrl.changeClassroomCategoryPosition("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(
            actionTypes.CHANGE_CLASSROOM_CATEGORIES_POSITION_SUCCESS,
            data
          )
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(
            actionTypes.CHANGE_CLASSROOM_CATEGORIES_POSITION_FAILURE,
            data
          )
        );
      }
    } catch (error) {
      // console.log(error, 'error');
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const deleteClassroomCategory =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.deleteClassroomCategory("live-api"),
      apiUrl.deleteClassroomCategory("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.DELETE_CLASSROOM_CATEGORIES_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.DELETE_CLASSROOM_CATEGORIES_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'error');
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const changeClassroomCategory =
  (payload, callback) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.changeClassroomCategory("live-api"),
      apiUrl.changeClassroomCategory("test-api")
    );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        toast.success(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        callback();
        return dispatch(
          defaultAction(actionTypes.EDIT_CLASSROOM_CATEGORIES_SUCCESS, data)
        );
      } else {
        dispatch(stopLoadingAction);
        toast.error(
          <NotificationMesage
            responseStatus={data.responseCode}
            message={data.responseMessage}
          />
        );
        return dispatch(
          defaultAction(actionTypes.EDIT_CLASSROOM_CATEGORIES_FAILURE, data)
        );
      }
    } catch (error) {
      // console.log(error, 'error');
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

export const teachersListing = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.teachersListing("live-api"),
    apiUrl.teachersListing("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      // callback()
      return dispatch(defaultAction(actionTypes.TEACHER_LISTING_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(defaultAction(actionTypes.TEACHER_LISTING_FAILURE, data));
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const searchTeacher = (payload) => async (dispatch) => {
  // dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.searchTeacher("live-api"),
    apiUrl.searchTeacher("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      // dispatch(stopLoadingAction);
      // toast.success(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      // callback()
      return dispatch(defaultAction(actionTypes.SEARCH_TEACHER_SUCCESS, data));
    } else {
      // dispatch(stopLoadingAction);
      // toast.error(<NotificationMesage responseStatus={data.responseCode} message={data.responseMessage} />)
      return dispatch(defaultAction(actionTypes.SEARCH_TEACHER_FAILURE, data));
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const deleteTeacher = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.deleteTeacher("live-api"),
    apiUrl.deleteTeacher("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(defaultAction(actionTypes.DELETE_TEACHER_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.DELETE_TEACHER_FAILURE, data));
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const availableClassroomThisTeacher = (payload) => async (dispatch) => {
  // dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.availableClassroomThisTeacher("live-api"),
    apiUrl.availableClassroomThisTeacher("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      return dispatch(
        defaultAction(actionTypes.AVAILABLE_CLASSROOM_CATEGORIES_SUCCESS, data)
      );
    } else {
      return dispatch(
        defaultAction(actionTypes.AVAILABLE_CLASSROOM_CATEGORIES_FAILURE, data)
      );
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const addTeacher = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.addTeacher("live-api"),
    apiUrl.addTeacher("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(defaultAction(actionTypes.ADD_TEACHER_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.ADD_TEACHER_FAILURE, data));
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const editTeacher = (payload, callback) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.editTeacher("live-api"),
    apiUrl.editTeacher("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      callback();
      return dispatch(defaultAction(actionTypes.EDIT_TEACHER_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      return dispatch(defaultAction(actionTypes.EDIT_TEACHER_FAILURE, data));
    }
  } catch (error) {
    // console.log(error, 'error');
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const weeklyDatesGenerator = (payload) => async (dispatch) => {
  const url = checkNodeEnv(
    apiUrl.weeklyDatesGenerator("live-api"),
    apiUrl.weeklyDatesGenerator("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response?.data;
    if (data.responseCode === "00") {
      if (payload.type === "startYear") {
        return dispatch(
          defaultAction(
            actionTypes.START_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS,
            data.responseData.ReturnedRecords
          )
        );
      } else if (payload.type === "endYear")
        return dispatch(
          defaultAction(
            actionTypes.END_YEAR_WEEKLY_DATES_GENERATOR_SUCCESS,
            data.responseData.ReturnedRecords
          )
        );
      else
        return dispatch(
          defaultAction(
            actionTypes.WEEKLY_DATES_GENERATOR_SUCCESS,
            data.responseData.ReturnedRecords
          )
        );
    } else {
      return dispatch(
        defaultAction(actionTypes.WEEKLY_DATES_GENERATOR_FAILURE, data)
      );
    }
  } catch (error) {
    console.log(error, "error");
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const clearMedia = () => (dispatch) => {
  dispatch(resetMediaDataAction);
};

export const uploadMedia = (payload, cb, i) => async (dispatch) => {
  // console.log(payload, "payload");
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.uploadMediaCdn("live-api"),
    apiUrl.uploadMediaCdn("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response?.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      cb && cb(data, i);
      return dispatch(
        defaultAction(actionTypes.UPLOAD_MEDIA_SUCCESS, data.responseData)
      );
    } else {
      dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.UPLOAD_MEDIA_FAILURE, data));
    }
  } catch (error) {
    console.log(error, "error");
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const formVideoUpload =
  (payload, cb, i, userToken) => async (dispatch) => {
    dispatch(startLoadingAction);
    const url = apiUrl.videoUploadDynamicForms();
    try {
      const response = await axios(
        axiosMultipartPayloaWithdHeader(url, payload, userToken)
      );
      const data = await response?.data;
      if (data.responseCode === "00") {
        dispatch(stopLoadingAction);
        cb && cb(data, i);
        return dispatch(
          defaultAction(actionTypes.UPLOAD_MEDIA_SUCCESS, data.responseData)
        );
      } else {
        dispatch(stopLoadingAction);
        return dispatch(defaultAction(actionTypes.UPLOAD_MEDIA_FAILURE, data));
      }
    } catch (error) {
      console.log(error, "error");
      dispatch(stopLoadingAction);
      toast.warn("Network Error. Please check your connectivity and try again");
    }
  };
export const systemTooltipsModalComments = (payload) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.systemTooltipsModalComments("live-api"),
    apiUrl.systemTooltipsModalComments("test-api")
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response?.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.SYSTEM_TOOLTIP_MODAL_COMMENTS_SUCCESS, data)
      );
    } else {
      dispatch(stopLoadingAction);
      return dispatch(
        defaultAction(actionTypes.SYSTEM_TOOLTIP_MODAL_COMMENTS_FAILURE, data)
      );
    }
  } catch (error) {
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};
export const deleteMedia = (payload, cb, i) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.deleteMediaCdn("live-api"),
    apiUrl.deleteMediaCdn("test-api")
  );
  try {
    const response = await axios(axiosMultipartPayload(url, payload));
    const data = await response?.data;
    if (data.responseCode === "00") {
      dispatch(stopLoadingAction);
      cb && cb(data, i);
      return dispatch(
        defaultAction(actionTypes.UPLOAD_MEDIA_SUCCESS, data.responseData)
      );
    } else {
      dispatch(stopLoadingAction);
      return dispatch(defaultAction(actionTypes.UPLOAD_MEDIA_FAILURE, data));
    }
  } catch (error) {
    console.log(error, "error");
    dispatch(stopLoadingAction);
    toast.warn("Network Error. Please check your connectivity and try again");
  }
};

export const updateUserRecordsListPerPage =
  (payload, cb) => async (dispatch) => {
    const url = checkNodeEnv(
      apiUrl.updateUserRecordsListPerPage("live-api"),
      apiUrl.updateUserRecordsListPerPage("test-api")
    );

    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      if (data.responseCode === "00") {
        return cb && cb();
      } else {
        return toast.error(data.responseMessage);
      }
    } catch (error) {
      console.log(error);
      // toast.warn("Network Error. Please check your connectivity and try again");
    }
  };

// function updateRows(numberOfRows) {
export const updateRows = (numberOfRows, orgInfo) => async (dispatch) => {
  const payload = {
    orgId: orgInfo?.orgId,
    adminUserId: getAdminUserId(),
    userId: getAdminUserId(),
    newRecordsListPerPage: `${numberOfRows}`,
  };

  dispatch(
    updateUserRecordsListPerPage(payload, function () {
      dispatch(
        getUserProfile(
          {
            orgId: orgInfo?.orgId,
            userId: getAdminUserId(),
            adminUserId: getAdminUserId(),
          },
          true
        )
      );
    })
  );
};

export const loginByToken = (payload, cb) => {
  // console.log(payload, "payload");
  return async (dispatch) => {
    dispatch(resetEndpointDataAction);
    dispatch(startLoadingAction);
    const url = checkNodeEnv(
      apiUrl.loginByToken("live-api"),
      apiUrl.loginByToken("test-api")
    );
    // const jwtAccessTokenUrl = checkNodeEnvNextGen(
    //   apiUrl.jwtAccessToken(nextgenUrl.live),
    //   apiUrl.jwtAccessToken(nextgenUrl.staging)
    // );
    try {
      const response = await axios(axiosPayload(url, payload));
      const data = await response.data;
      // console.log(data, 'dta');
      if (data.responseCode === "00") {
        // const nextGenLoginDetails = {
        //   email: payload.email,
        //   organizationId: payload.orgId,
        //   password: payload.password,
        //   userId: data.responseData.userId,
        // };
        // const jwtResponse = await axios({
        //   method: asyncMethod.POST,
        //   url: jwtAccessTokenUrl,
        //   data: nextGenLoginDetails,
        // });
        // const jwtData = await jwtResponse.data;
        if (
          data?.responseData?.twoFactorAuthentication?.isRequired === "false"
        ) {
          localStorage.removeItem("user");
          data.authData =
            payload.email && payload.password
              ? window.btoa(payload.email + ":" + payload.password)
              : window.btoa(payload.mobile + ":" + payload.pin);
          localStorage.setItem("user", JSON.stringify(data));
          // localStorage.setItem("jwtAccessToken", JSON.stringify(jwtData));
          dispatch(stopLoadingAction);
          dispatch(
            getUserProfile({
              orgId: payload?.orgId,
              userId: data?.responseData?.userId,
              adminUserId: data?.responseData?.userId,
            })
          );
          cb();
          return dispatch(authAction(actionTypes.LOGIN_SUCCESS, data)); // check if the dispatch data from the store or localStorage
        } else if (
          data?.responseData?.twoFactorAuthentication?.isRequired === "true"
        ) {
          localStorage.setItem("2FA", JSON.stringify(data.responseData));
          dispatch(stopLoadingAction);
        }
      } else {
        data.authData = {};
        dispatch(stopLoadingAction);
        dispatch(getNotification(data.responseMessage));
        return dispatch(authAction(actionTypes.LOGIN_FAILURE, data));
      }
    } catch (error) {
      toast.warn("Network Error. Please check your connectivity and try again");
      console.log(error, "login error");
    }
  };
};
