import axios from 'api';
import { toast } from 'react-toastify';
import { checkNodeEnv } from 'utils/authHelpers';
import { apiUrl, defaultAction } from 'utils/constants';
import { axiosPayload, getUserProfile } from '.';
import { actionTypes } from '../actionTypes';
import NotificationMesage from '../../components/Suspense/NotificationMessage';

export const resetEndpointDataAction = {
  type: actionTypes.RESET_ENDPOINT_DATA,
};
export const resetCreditDataAction = { type: actionTypes.RESET_CREDIT_DATA };
export const resetCheckDataAction = { type: actionTypes.RESET_CHECK_DATA };
export const startLoadingAction = { type: actionTypes.START_LOADING };
export const stopLoadingAction = { type: actionTypes.STOP_LOADING };

export const verifyTwoFA = (payload, cb, cb2) => async (dispatch) => {
  dispatch(startLoadingAction);
  const url = checkNodeEnv(
    apiUrl.verifyTwoFA('live-api'),
    apiUrl.verifyTwoFA('test-api')
  );
  try {
    const response = await axios(axiosPayload(url, payload));
    const data = await response.data;
    // console.log(data, 'data');
    if (data.responseCode === '00') {
      console.log(data);
      data.authData =
        payload.email && payload.password
          ? window.btoa(payload.email + ':' + payload.password)
          : window.btoa(payload.mobile + ':' + payload.pin);
      dispatch(stopLoadingAction);
      toast.success(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      localStorage.setItem('user', JSON.stringify(data));
      dispatch(stopLoadingAction);
      dispatch(
        getUserProfile({
          orgId: payload?.orgId,
          userId: data?.responseData?.userId,
          adminUserId: data?.responseData?.userId,
        })
      );
      cb && cb();
      return dispatch(defaultAction(actionTypes.LOGIN_SUCCESS, data));
    } else {
      dispatch(stopLoadingAction);
      toast.error(
        <NotificationMesage
          responseStatus={data.responseCode}
          message={data.responseMessage}
        />
      );
      cb2 && cb2();
      //   return dispatch(defaultAction(actionTypes.VERIFY_TWO_FA_FAIL, data));
    }
  } catch (error) {
    console.log(error, 'error');
    // toast.warn('Network Error. Please check your connectivity and try again');
  }
};
