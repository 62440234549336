import React, { useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useThemeMode } from "../../utils/utils";
import clsx from "clsx";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { forgotPassword, forgotPin, resetEndpointDataAction } from "redux/actions";
import CustomButton from "components/Button";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  cardHolder: {
    background: "#1A2038",
  },
  card: {
    maxWidth: 800,
    borderRadius: 12,
    margin: "1rem",
  },
  logoImg: {
    width: "5rem",
    [theme.breakpoints.down("sm")]: {
      width: "4rem",
    },
  },
}));

const ForgotPassword = ({ location }) => {
  const [state, setState] = useState({});
  const classes = useStyles();
  const ipData = useSelector((state) => state.ipData);
  const forgotPasswordData = useSelector(
    (state) => state.forgotPasswordData,
    shallowEqual
  );
  const forgotPinData = useSelector(
    (state) => state.forgotPinData,
    shallowEqual
  );
  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );
  const { themeColor } = useThemeMode();
  const { message } = useSelector((state) => state.notifications, shallowEqual);
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = location.state && location.state.isMobile;

  React.useEffect(() => {
    if (!location.state) history.push("/");
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const path = isMobile === 'phoneNumber' ? "reset_pin" : "reset_password";
  const resetUrl = orgInfo?.dedicatedUrlAdmin + path;

  const redirectToSignin = () => {
    history.push("/");
    dispatch(resetEndpointDataAction);
  };

  let { email } = state;

  const handleFormSubmit = (event) => {
    if (isMobile === 'phoneNumber' && mobile === "") {
      setMobileError("This field is required");
      return;
    }
    if (isMobile === 'phoneNumber'  && !isValidPhoneNumber(mobile)) return;
    // if (!isMobile) {
      dispatch(
        forgotPassword(
          {
            emailOrPhone: isMobile === 'phoneNumber' ? mobile : email,
            orgId: orgInfo.orgId,
            resetUrl,
          },
          // redirectToSignin
        )
      );
    // } else {
    //   dispatch(
    //     forgotPin(
    //       {
    //         mobile,
    //         orgId: orgInfo.orgId,
    //         resetUrl,
    //       },
    //       // redirectToSignin
    //     )
    //   );
    // }
  };

  // Error message for phone
  React.useEffect(() => {
    if (mobile && !isValidPhoneNumber(mobile)) {
      setMobileError("Number is not valid");
    } else setMobileError("");
  }, [mobile]);

  console.log(isMobile, 'isMobile');

  return (
    <div
      className={clsx(
        "flex justify-center items-center  min-h-full-screen",
        classes.cardHolder
      )}
    >
      <Card className={classes.card} style={{ width: "22rem" }}>
        <Grid container>
          <Grid item xs>
            <div
              className="px-8 pt-8 flex items-center justify-between"
              style={{ columnGap: "4rem" }}
            >
              <img
                src={orgInfo?.logo}
                alt=""
                className={`${classes.logoImg}`}
              />
              <span>
                <h3 style={{ color: `${themeColor}` }}>Forgot</h3>
                <h3 style={{ color: `${themeColor}` }}>
                  {"Password"}
                </h3>
              </span>
            </div>
            <div className="p-8 h-full bg-light-gray relative">
              {forgotPasswordData?.responseCode !== '00' && <span>
                <p className=" mb-5 text-center">
                  Enter your email and we'll send you a link to reset it. Or enter your phone number and we'll text you a temporary password.
                </p>
              </span>}
              {forgotPasswordData?.responseCode === '01' && <h5 className="text-error my-auto mb-3 text-center pb-2">{forgotPasswordData?.responseMessage}</h5>}
              {forgotPasswordData?.responseCode === '00' && <SuccessForgotPasswordPage successMessage={forgotPasswordData?.responseMessage} onBack={() => redirectToSignin()} />}
              {/* {forgotPasswordData?.responseCode !== '00'&& <hr />} */}
              {forgotPasswordData?.responseCode !== '00' && <ValidatorForm onSubmit={handleFormSubmit}>
                {isMobile === 'phoneNumber' ? (
                  <div className="relative">
                    <PhoneInput
                      international
                      withCountryCallingCode
                      countryCallingCodeEditable={false}
                      defaultCountry={ipData ? ipData.country_code : null}
                      placeholder="Mobile"
                      id="loginMobile"
                      value={mobile}
                      onChange={setMobile}
                      className={`mb-5 px-3 mobile`}
                      name="mobile"
                    />
                    {mobile && isValidPhoneNumber(mobile) && (
                      <div className="check"></div>
                    )}
                    {mobile && !isValidPhoneNumber(mobile) && (
                      <span className="x">X</span>
                    )}
                    {mobileError && (
                      <p className="text-error my-auto errorMessage">
                        {mobileError}
                      </p>
                    )}
                  </div>
                ) : (
                  <TextValidator
                    className="mb-6 w-full"
                    variant="outlined"
                    label="Email or Phone number"
                    onChange={handleChange}
                    type="text"
                    name="email"
                    size="small"
                    value={email || ""}
                    validators={["required"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid",
                    ]}
                  />
                )}
                {/* {message && (
                  <p className="text-error my-auto mb-3">{message}</p>
                )} */}
                <div
                  className="flex items-center justify-between"
                  style={{ columnGap: "2rem" }}
                >
                  <CustomButton
                    label="Send Request"
                    className="w-full"
                    variant="contained"
                    color="primary"
                    loading={
                      isMobile
                        ? forgotPinData && forgotPinData.loading
                        : forgotPasswordData && forgotPasswordData.loading
                    }
                    disabled={
                      isMobile
                        ? forgotPinData && forgotPinData.loading
                        : forgotPasswordData && forgotPasswordData.loading
                    }
                    type="submit"
                  />
                  <Link to="/" className="w-full">
                    <CustomButton
                      variant="outlined"
                      label="Sign in"
                      className="capitalize w-full"
                    />
                  </Link>
                </div>
              </ValidatorForm>}
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default ForgotPassword;

const SuccessForgotPasswordPage = ({successMessage, onBack}) => {
  return (
    <div> 
      <h6 className='text-center' style={{textWrap: 'balance'}}>{successMessage}</h6>
      <div className="d-flex justify-content-center align-items-center my-5">
            <div className=" align-items-center w-full">
              <Link className="text-dark-50 text-hover-primary" to="/" onClick={onBack}>
                <CustomButton variant="outlined" className="capitalize w-full" label='Back to Login' />
              </Link>
            </div>
          </div>
    </div>
  )
}
