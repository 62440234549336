export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_ALL_NOTIFICATION = 'DELETE_ALL_NOTIFICATION';

export const getNotification = (data) => (dispatch) => {
  dispatch({
    type: GET_NOTIFICATION,
    payload: data,
  });
  setTimeout(() => {
    dispatch({
      type: DELETE_NOTIFICATION,
    });
  }, 5000);
};

