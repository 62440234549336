import React from "react";
import { Grid, Card, Icon, Fab, Tooltip, Button } from "@material-ui/core";

const StatCards1 = ({
  width = "100%",
  background,
  handleClick,
  cursor = true,
  item,
  handleIconClick,
  showSub = false,
}) => {
  const styles = {
    price: {
      display: "flex",
      alignItems: "center",
      flex: "1",
    },
    viewMore: {
      // transition: "all .5s cubic-bezier(0,1,0,1)",
      overflow: "hidden",
      transition: "all 0.5s",
    },
  };

  const { color, description, icon, label } = item;
  return (
    <Card
      elevation={3}
      className={`p-4 ${cursor ? "cursor-pointer" : "cursor-default"}`}
      style={{ width: `${width}` }}
      onClick={() => handleClick(item)}
    >
      <React.Fragment>
        <div className="flex items-center mb-3">
          <Fab size="medium" style={!background && { background: "none" }}>
            <Icon className="" style={{ color: `${color}` }}>
              {icon}
            </Icon>
          </Fab>
          <div className="d-flex justify-between" style={styles.price}>
            <h5 className="font-medium m-0 ml-3">{label}</h5>
          </div>
        </div>
        <p>{description}</p>
        <Button onClick={handleIconClick} type="text">
          {showSub ? "Show Less" : "Show More"}
        </Button>
        {showSub && (
          <ul style={styles.viewMore} className="pl-5">
            {item.subLabel.map((label) => (
              <li>{label}</li>
            ))}
          </ul>
        )}
      </React.Fragment>
    </Card>
  );
};

export default StatCards1;
