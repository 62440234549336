import { useEffect } from "react";

const useDynamicTitle = (isOnBoardingLandingPage) => {
  useEffect(() => {
    if (isOnBoardingLandingPage) {
      document.title = "ChurchPad onboarding";
    } else {
      document.title = "ChurchPad Admin";
    }
  }, [isOnBoardingLandingPage]);
};

export default useDynamicTitle;
