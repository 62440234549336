import React from 'react';
import useSettings from 'utils/hooks/useSettings';
import GifSuspense from 'components/Suspense/GifSuspense';
import {MatxLayouts} from './index';

const MatxLayout = (props) => {
  const {settings} = useSettings();
  const Layout = MatxLayouts[settings.activeLayout];

  return (
    <GifSuspense>
      <Layout {...props} />
    </GifSuspense>
  );
};

export default MatxLayout;
