const isOnBoarding =
  window.location.hostname === "onboarding.churchpad.com" ||
  window.location.hostname === "onboarding-test.churchpad.com";

export const checkNodeEnv = (prodUrl, devUrl) => {
  // console.log(process.env.REACT_APP_ENV, 'env')
  if (process.env.REACT_APP_ENV === "local") {
    return devUrl;
  } else if (process.env.REACT_APP_ENV === "staging") {
    return devUrl;
  } else if (process.env.REACT_APP_ENV === "production") {
    // console.log(process.env.REACT_APP_ENV, 'env')
    // console.log(prodUrl, 'url')
    return prodUrl;
  }
  return prodUrl;
};
export const checkNodeEnvNextGen = (prodUrl, devUrl) => {
  // console.log(process.env.REACT_APP_ENV, 'env')
  if (process.env.REACT_APP_ENV === "local") {
    return devUrl;
  } else if (process.env.REACT_APP_ENV === "staging") {
    return devUrl;
  } else if (process.env.REACT_APP_ENV === "production") {
    // console.log(process.env.REACT_APP_ENV, 'env')
    // console.log(prodUrl, 'url')
    return prodUrl;
  }
  return prodUrl;
};
export const checkNodeEnvOnboarding = (prodUrl, devUrl) => {
  if (!isOnBoarding) return devUrl;
  return prodUrl;
};

export const authHeader = () => {
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.authData) {
    return { Authorization: "Basic " + user.authData };
  } else {
    return {};
  }
};

export const logout = (isOnBoarding, churchName) => {
  let keysToRemove = [
    "user",
    "productList",
    "jwtAccessToken",
    "supportResourcesSettings",
    "peopleListingTableColumns",
    "lastActiveTime",
    "nextGenToken",
    'mservice-token'
  ];
  keysToRemove.forEach((k) => localStorage.removeItem(k));
  window.location.href = isOnBoarding ? `/${churchName}/` : "/";
};

export const getUserDetails = () => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.responseData) {
    return user.responseData;
  } else {
    return {};
  }
};
