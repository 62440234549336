import {actionTypes} from '../actionTypes';


export const prayerWalls = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.PRAYERWALLS_SUCCESS:
        return action.payload;
      case actionTypes.PRAYERWALLS_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

export const prayerWallsApproveOrDelete = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.PRAYERWALLS_APPROVE_SUCCESS:
        return action.payload;
      case actionTypes.PRAYERWALLS_APPROVE_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };
  
export const fellowshipCategoryTypesWithPositions = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.FELLOWSHIP_CATEGORY_WITH_POSITION_SUCCESS:
        return action.payload;
      case actionTypes.FELLOWSHIP_CATEGORY_WITH_POSITION_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };
export const changeFellowshipCategoryPosition = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.FELLOWSHIP_CATEGORY_WITH_POSITION_SUCCESS:
        return action.payload;
      case actionTypes.FELLOWSHIP_CATEGORY_WITH_POSITION_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

export const addFellowshipCategory = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.ADD_FELLOWSHIP_CATEGORY_SUCCESS:
        return action.payload;
      case actionTypes.ADD_FELLOWSHIP_CATEGORY_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

export const deleteFellowshipCategory = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.DELETE_FELLOWSHIP_CATEGORY_SUCCESS:
        return action.payload;
      case actionTypes.DELETE_FELLOWSHIP_CATEGORY_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

export const changeFellowshipCategoryName = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.EDIT_FELLOWSHIP_CATEGORY_SUCCESS:
        return action.payload;
      case actionTypes.DELETE_FELLOWSHIP_CATEGORY_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };

  export const fellowshipPrequestDashboard = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.FELLOWSHIP_PREQUEST_DASHBOARD_SUCCESS:
        return action.payload;
      case actionTypes.FELLOWSHIP_PREQUEST_DASHBOARD_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };
  export const fellowshipPreportDashboard = (state = {}, action) => {
    switch (action.type) {
      case actionTypes.FELLOWSHIP_PREPORT_DASHBOARD_SUCCESS:
        return action.payload;
      case actionTypes.FELLOWSHIP_PREPORT_DASHBOARD_FAILURE:
        return action.payload;
      case actionTypes.START_LOADING:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.STOP_LOADING:
        return {
          ...state,
          loading: false,
        };
      default:
        return state;
    }
  };