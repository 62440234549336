import { actionTypes } from "../actionTypes";

export const childrenListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_LISTING_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const childDetails = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CHILD:
      return action.payload;
    default:
      return state;
  }
};

export const fetchChildProfile = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ONE_CHILD_SUCCESS:
      return action.payload;
    case actionTypes.GET_ONE_CHILD_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const adminChildDelete = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_A_CHILD_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_A_CHILD_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const adminChildUpdate = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_A_CHILD_DATA_SUCCESS:
      return action.payload;
    case actionTypes.UPDATE_A_CHILD_DATA_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const classroomCategories = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CLASSROOM_CATEGORIES_SUCCESS:
      return action.payload;
    case actionTypes.GET_ALL_CLASSROOM_CATEGORIES_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addClassroomCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_CLASSROOM_CATEGORIES_SUCCESS:
      return action.payload;
    case actionTypes.ADD_CLASSROOM_CATEGORIES_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeClassroomCategoryPosition = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_CLASSROOM_CATEGORIES_POSITION_SUCCESS:
      return action.payload;
    case actionTypes.CHANGE_CLASSROOM_CATEGORIES_POSITION_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteClassroomCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CLASSROOM_CATEGORIES_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_CLASSROOM_CATEGORIES_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const changeClassroomCategory = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_CLASSROOM_CATEGORIES_SUCCESS:
      return action.payload;
    case actionTypes.EDIT_CLASSROOM_CATEGORIES_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const teachersListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.TEACHER_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.TEACHER_LISTING_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchTeacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_TEACHER_SUCCESS:
      return action.payload;
    case actionTypes.SEARCH_TEACHER_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteTeacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_TEACHER_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_TEACHER_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const availableClassroomThisTeacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.AVAILABLE_CLASSROOM_CATEGORIES_SUCCESS:
      return action.payload;
    case actionTypes.AVAILABLE_CLASSROOM_CATEGORIES_FAILURE:
      return action.payload;
    // case actionTypes.START_LOADING:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case actionTypes.STOP_LOADING:
    //   return {
    //     ...state,
    //     loading: false,
    //   };
    default:
      return state;
  }
};

export const addTeacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_TEACHER_SUCCESS:
      return action.payload;
    case actionTypes.ADD_TEACHER_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const editTeacher = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_TEACHER_SUCCESS:
      return action.payload;
    case actionTypes.EDIT_TEACHER_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const childrenListingGrid = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_GRID_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_GRID_LISTING_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const childrenGridAndListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_GRID_AND_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_GRID_AND_LISTING_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const childrenCheckinCheckout = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_CHECKIN_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_CHECKIN_FAILURE:
      return action.payload;
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getChildCheckedInToken = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_SUCCESS:
      return action.payload;
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_FAILURE:
      return action.payload;
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const sendCommunicationChildren = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEND_CHILDREN_COMMUNICATION_SUCCESS:
      return action.payload;
    case actionTypes.SEND_CHILDREN_COMMUNICATION_FAILURE:
      return action.payload;
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const sendCommunicationParentsProcess = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEND_CHILDREN_COMMUNICATION_PROCESS_SUCCESS:
      return action.payload;
    case actionTypes.SEND_CHILDREN_COMMUNICATION_PROCESS_FAILURE:
      return action.payload;
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_CHILD_CHECKED_IN_TOKEN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const mediaVaultListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MEDIA_VAULT_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.MEDIA_VAULT_LISTING_FAILURE:
      return action.payload;
    case actionTypes.MEDIA_VAULT_LISTING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MEDIA_VAULT_LISTING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteMediaVault = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MEDIA_VAULT_IMAGE_DELETE_SUCCESS:
      return action.payload;
    case actionTypes.MEDIA_VALUT_IMAGE_DELETE_FAILURE:
      return action.payload;
    case actionTypes.MEDIA_VAULT_LISTING_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MEDIA_VAULT_LISTING_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const uploadMediaVault = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.MEDIA_VALUT_UPLOAD_SUCCESS:
      return action.payload;
    case actionTypes.MEDIA_VALUT_UPLOAD_FAILURE:
      return action.payload;
    case actionTypes.MEDIA_VALUT_UPLOAD_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.MEDIA_VALUT_UPLOAD_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const childrenDashboard = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_DASHBOARD_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_DASHBOARD_FAILURE:
      return action.payload;
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchParentGuardianToCheckIn = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_PARENTS_TO_CHECKIN_SUCCESS:
      return action.payload;
    case actionTypes.SEARCH_PARENTS_TO_CHECKIN_FAILURE:
      return action.payload;
    case actionTypes.CHECKIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHECKIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const getChildrenWithCheckInStatus = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CHILDREN_WITH_CHECKIN_STATUS_SUCCESS:
      return action.payload;
    case actionTypes.GET_CHILDREN_WITH_CHECKIN_STATUS_FAILURE:
      return action.payload;
    case actionTypes.CHECKIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHECKIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const checkInTheseChildren = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHECKIN_CHILDREN_SUCCESS:
      return action.payload;
    case actionTypes.CHECKIN_CHILDREN_FAILURE:
      return action.payload;
    case actionTypes.CHECKIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHECKIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const listNotesOfChild = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIST_NOTE_OF_CHILD_SUCCESS:
      return action.payload;
    case actionTypes.LIST_NOTE_OF_CHILD_FAILURE:
      return action.payload;
    case actionTypes.CHECKIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHECKIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const addNoteToChild = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_CHILD_NOTE_SUCCESS:
      return action.payload;
    case actionTypes.ADD_CHILD_NOTE_FAILURE:
      return action.payload;
    case actionTypes.CHECKIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHECKIN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const listCommentsOfThisChildNote = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_COMMENT_FOR_A_CHILD_SUCCESS:
      return action.payload;
    case actionTypes.GET_COMMENT_FOR_A_CHILD_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const addCommentToThisChildNote = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_COMMENT_TO_CHILD_NOTE_SUCCESS:
      return action.payload;
    case actionTypes.ADD_COMMENT_TO_CHILD_NOTE_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const editNoteOfChild = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_CHILD_NOTE_SUCCESS:
      return action.payload;
    case actionTypes.EDIT_CHILD_NOTE_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteNoteOfChild = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_CHILD_NOTE_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_CHILD_NOTE_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const deleteCommentOfThisChildNote = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_COMMENT_TO_CHILD_NOTE_SUCCESS:
      return action.payload;
    case actionTypes.DELETE_COMMENT_TO_CHILD_NOTE_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const editCommentOfThisChildNote = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EDIT_COMMENT_TO_CHILD_NOTE_SUCCESS:
      return action.payload;
    case actionTypes.EDIT_COMMENT_TO_CHILD_NOTE_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const listCheckInsOfChild = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.LIST_CHECKIN_OF_CHILD_SUCCESS:
      return action.payload;
    case actionTypes.LIST_CHECKIN_OF_CHILD_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const childrenCheckInsListing = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_CHECKIN_LISTING_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_CHECKIN_LISTING_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const childrenCheckinsReporting = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_CHECKIN_REPORTING_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_CHECKIN_REPORTING_FAILURE:
      return action.payload;
    case actionTypes.COMMENT_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.COMMENT_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CHILDREN_CHECKIN_REPORTING_RESET:
      return {};
    default:
      return state;
  }
};

export const childrenModuleSpecificInfo = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CHILDREN_MODULE_SUCCESS:
      return action.payload;
    case actionTypes.CHILDREN_MODULE_FAILURE:
      return action.payload;
    default:
      return state;
  }
};


export const importChildren = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.IMPORT_CHILDREN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.IMPORT_CHILDREN_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const foodAllergy = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_FOODALLERGY:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.RESET_ENDPOINT_DATA:
      return {};
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export const foodAllergyListChildren = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FOOD_ALLERGY_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.RESET_ENDPOINT_DATA_ALLERGY:
      return {};
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const foodAllergyLoading = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};