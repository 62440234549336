import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import MatxCssVars from "./MatxCssVars";
import useSettings from "utils/hooks/useSettings";
import { shallowEqual, useSelector } from "react-redux";
import themeOptions from "_Matx/MatxTheme/themeOptions";
import { createTheme } from '@material-ui/core/styles'
import { merge } from "lodash";

const textLight = {
  primary: "rgba(52, 49, 76, 1)",
  secondary: "rgba(52, 49, 76, 0.54)",
  disabled: "rgba(52, 49, 76, 0.38)",
  hint: "rgba(52, 49, 76, 0.38)",
};
const errorColor = {
  main: "#FF3D57",
};

const MatxTheme = ({ children }) => {
  const { settings, updateSettings } = useSettings();
  const [selectedTheme, setSelectedTheme] = React.useState({});

  const orgInfo = useSelector((state) => state.orgInfo, shallowEqual);
  const preferLightTheme =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: light)").matches;
  const newTheme = JSON.parse(localStorage.getItem("currentTheme"));

  const dark = {
    palette: {
      type: "dark",
      primary: {
        main: orgInfo?.responseData
          ? orgInfo?.responseData?.colorThemes[1].primary
          : "#4c0808",
        contrastText: "#ffffff",
      },
      secondary: {
        main: orgInfo?.responseData
          ? orgInfo?.responseData?.colorThemes[1].secondary
          : "#ff9e43",
        contrastText: textLight.primary,
      },

      error: errorColor,
      background: {
        paper: "#222A45",
        default: "#1a2038",
      },
    },
  };

  const light = {
    palette: {
      type: "light",
      primary: {
        main: orgInfo?.responseData
          ? orgInfo?.responseData?.colorThemes[0].primary
          : "#304FFF",
        contrastText: "#ffffff",
      },
      secondary: {
        main: orgInfo?.responseData
          ? orgInfo?.responseData?.colorThemes[0].secondary
          : "#FFAF38",
        contrastText: textLight.primary,
      },
      error: errorColor,
    },
  };

  React.useEffect(() => {
    if (orgInfo?.responseData) {
      if (preferLightTheme && !newTheme) {
        setSelectedTheme(light);
      }

      if (!preferLightTheme && !newTheme) {
        setSelectedTheme(dark);
      }

      if (newTheme && newTheme) {
        if (newTheme && newTheme === "lightMode") {
          setSelectedTheme(light);
        } else if (newTheme && newTheme === "darkMode") {
          setSelectedTheme(dark);
        }
      }
    }
  }, [orgInfo, newTheme]);

  let testTheme = createTheme(merge({}, themeOptions, selectedTheme));

  return (
    <ThemeProvider theme={testTheme}>
      <CssBaseline />
      <MatxCssVars> {children} </MatxCssVars>
    </ThemeProvider>
  );
};

export default MatxTheme;
