import { combineReducers } from "redux";
import NotificationReducer from "./NotificationReducer";

import {
  getShop,
  getTags,
  updateTag,
  createTag,
  deleteTag,
  getDiscounts,
  createDiscount,
  updateDiscount,
  deleteDiscount,
  searchDiscounts,
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
} from "./EcommerceReducer";
import {
  importContributionsAnalyze,
  importContributionsColumns,
  importContributionsProcess,
  contributionsReportingByDonor,
  actionContributionStatementPdf,
  contributionStatementsAccounting,
  contributionStatementMultiple,
  membersExpiringCardsListing,
} from "./Accounting";

import {
  createAlbum,
  getAlbums,
  deleteAlbum,
  updateAlbum,
  createEpisode,
  deleteEpisode,
  updateEpisode,
  getAlbum,
  uploadFile,
  createAlbumCategory,
  getAlbumCategories,
  deleteAlbumCategories,
  updateAlbumCategories,
  getAlbumCategory,
  searchAlbums,
  reorderAlbumsInCategory,
  searchCategories,
} from "./Podcast";

import {
  ipData,
  orgInfo,
  loginData,
  signupData,
  forgotPasswordData,
  forgotPinData,
  verifyForgotPasswordOrPinData,
  resetPasswordOrPinData,
  orgInfoList,
  updateUserData,
  getUserProfileData,
  secondOrgInfo,
  peopleList,
  fetchUserList,
  membership,
  addMembership,
  changeMembershipName,
  deleteMembershipName,
  changeMembershipPositionReducer,
  updateAdminProfile,
  addNewMemberState,
  randomPassword,
  searchedUsers,
  generosityDashboardData,
  adminRecentContributionsData,
  contributionNamesAndCodesData,
  changeContributionNamesPositionData,
  deleteContributionNameData,
  availableContributionCodesData,
  addContributionNameData,
  changeContributionNameData,
  generosityModuleSpecificInfoData,
  changeTransactionContributionTypeData,
  contributionsReportingData,
  weeklyDatesGeneratorData,
  uploadMediaData,
  systemTooltipsModalComments,
} from ".";

import {
  prayerWalls,
  prayerWallsApproveOrDelete,
  fellowshipCategoryTypesWithPositions,
  changeFellowshipCategoryPosition,
  addFellowshipCategory,
  deleteFellowshipCategory,
  changeFellowshipCategoryName,
  fellowshipPrequestDashboard,
  fellowshipPreportDashboard,
} from "./PrayerwallsReducer";

import {
  orgGeneralSettings,
  orgLogo,
  orgSocialMedia,
  orgMessage,
  orgTextMessaging,
  orgColors,
  emailTemplate,
  globalSystemSettingsData,
  rolesPermissionList,
  usersWithRolesPermission,
  userRolePerm,
  updateRolesPerm,
  updateGlobalSystemSettingsData,
  deleteRolePerm,
  searchedUsersRolesPerm,
  checkUserRoles,
  leadershipsListing,
  addUpdateleadership,
  deleteleadership,
  changeleadership,
  doSearchUserToJoinLeadership,
  sendCommunicationOrg,
  accessSecurityAuditListing,
  searchAccessSecurityAuditByUserId,
  celebrationListing,
  updateCommunicationOrg,
  mobileAppCustomizationListing,
  mobileAppCustomization,
  messagingCreditsListing,
  orgPaymentMethod,
  buyMsgCreditWithExistingToken,
  buyMsgCreditWithNewCardOrCheck,
  msgCreditPurchasedHistory,
  enableOrDisableMsgCreditsAutoRecharge,
  getOrganizationDynamicForms,
  getAllFormSubmission,
  formSubmissionStatus,
  updateAppWalkThruAssetInformation,
  giveMethods,
  getFormById,
  viewSubmittedForms,
} from "./OrganizationReducer";

import {
  childrenListing,
  childDetails,
  fetchChildProfile,
  adminChildDelete,
  adminChildUpdate,
  classroomCategories,
  addClassroomCategory,
  changeClassroomCategoryPosition,
  deleteClassroomCategory,
  changeClassroomCategory,
  teachersListing,
  searchTeacher,
  deleteTeacher,
  availableClassroomThisTeacher,
  addTeacher,
  editTeacher,
  childrenListingGrid,
  childrenModuleSpecificInfo,
  getChildCheckedInToken,
  childrenCheckinCheckout,
  sendCommunicationChildren,
  mediaVaultListing,
  deleteMediaVault,
  uploadMediaVault,
  childrenDashboard,
  sendCommunicationParentsProcess,
  searchParentGuardianToCheckIn,
  getChildrenWithCheckInStatus,
  checkInTheseChildren,
  listNotesOfChild,
  listCommentsOfThisChildNote,
  addCommentToThisChildNote,
  editCommentOfThisChildNote,
  deleteCommentOfThisChildNote,
  editNoteOfChild,
  deleteNoteOfChild,
  listCheckInsOfChild,
  childrenCheckInsListing,
  childrenCheckinsReporting,
  childrenGridAndListing,
  importChildren,
  foodAllergy,
  foodAllergyLoading,
  foodAllergyListChildren,
} from "./Children";

import {
  peopleDashboard,
  departmentsListing,
  addDepartment,
  deleteDepartmentGroup,
  renameDepartmentGroup,
  departmentGroupUsers,
  deleteUsersFromDepartmentGroup,
  positionsListing,
  searchedUserToJoinDept,
  addRemoveUserAdminAccess,
  addPosition,
  deletePosition,
  renamePosition,
  positionMembers,
  salutationsListing,
  addSalutation,
  deleteSalutation,
  renameSalutation,
  salutationMembers,
  enableDisabledUserAccount,
  searchedSpouse,
  updateSpouse,
  adminChangePwd,
  adminChildManager,
  fetchUserDeptServ,
  deleteUser,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  approveUser,
  removeUser,
  getUsersInGroup,
  mergeAccounts,
  birthdaysWeddingsListing,
  importPeople,
  getExportableData,
  exportTheseData,
  updatePhoneNumberAdmin,
  changeGroupBanner,
  userContributionsHistory,
  getUserContributionStatement,
  addManualContribution,
  qrCodesAdmin,
  resetToDefaultAdminTableColView,
  getAutomatedGreeting,
  addAutomatedGreetings,
  automatedGreetingsListing,
  updateAutomatedGreeting,
  deleteAutomatedGreeting,
  testAutomatedGreeting,
  groupUsers,
  groupUsersPending,
  checkInAttendanceListingAdmin,
  addCheckInAttendance,
  editCheckInAttendance,
  deleteCheckInAttendance,
  checkInAttendeesInformation,
  checkInAttendeesReporting,
  checkInAttendanceRecent,
  mobileAppActionLoading,
  deleteGroupLeader,
  groupCoordinators
} from "./People.js";

import {
  getSermons,
  updateSermon,
  deleteSermon,
  createSermon,
  getSermonsList,
  updateSermonGroup,
  createSermonGroup,
  getSermonGroup,
  searchSermons,
  addSermonsToGroup,
  deleteSermonsToGroup,
  getSermonsBySeries,
  updateSermonSeries,
  deleteSermonSeries,
  createPreacher,
  updateSermonPreacher,
  getSermonPreacher,
  deleteSermonPreacher,
  searchPreachers,
  liveStreamStatus,
  updateSermontPlaylistDisplayOrder,
  createMedia,
  getMedias,
  deleteMedia,
  updateMedia,
  getMediaList,
  createMediaGroup,
  updateMediaGroup,
  getMediaGroup,
  deleteMediaGroup,
  searchMedia,
  addMediaToGroup,
  deleteMediaFromGroup,
  updateMediatPlaylistDisplayOrder,
  getMediaBySeries,
  createSeriesMedia,
  updateMediaSeries,
  deleteMediaSeries,
  createSpeaker,
  updateMediaSpeaker,
  getMediaSpeaker,
  searchSpeakers,
  fetchStreams,
  getFeaturedStreams,
  updateFeaturedStream,
  searchPlaylist,
} from "./Sermons";

import {
  contactGroupsListing,
  addContactGroup,
  deleteContactGroup,
  renameContactGroup,
  addUsersToContactGroupAnalyze,
  addUsersToContactGroupProcess,
  contactGroupUsers,
  updateContactGroupUser,
  deleteUsersFromContactGroup,
  smartKeywordsListing,
  deleteSmartKeyword,
  addNewSmartKeyword,
  editSmartKeyword,
  smartKeywordConsumers,
  fetchCommunicationGroupsByCategory,
  uCMessageLogs,
  unifiedCommunicationSendEngineAnalyze,
  unifiedCommunicationSendEngineProcess,
  doNotTextCallEmailListingticketListing,
  doNotCallTextLoading,
  searchDoNotTextCallEmail,
  uCScheduledMessages,
  uCMessageLogsGetDetails,
  uCScheduledMessageGetDetails,
} from "./UnifiedCommunication";

import {
  newFollowUpFormSubmissionsData,
  getFollowUpByOrganizationIdData,
  updateFollowUpData,
  assignedFollowUpMembersData,
  getActivityLogData,
  assignGuestData,
  createActivityLogData,
  updateActivityLogData,
  getFollowUpFormDetailsData,
  followUpDashboard,
  getClosedOutForms,
  followUpReporting,
  followUpReportingViewData,
  followUpDynamicForm,
  searchFollowUp,
  getForm,
} from "./FollowUp";

import {
  getEventData,
  createEventData,
  searchAddresses,
  deleteAddresses,
  getAddresses,
  createAddress,
  updateAddresses,
  searchAddress,
  organisationAddress,
  listOfEvent,
  eventRegistration,
} from "./Event";

import {
  createTicket,
  updateTicket,
  ticketListing,
  ticketInfo,
  deleteTicket,
  closeTicket,
} from "./Support";

import {
  orgInvoicesListing,
  orgCardCheckStoredTokens,
  verifyRouting,
  giveWithAnExistingCardCheckingAdmin,
  giveWithANewCheckingAdmin,
  giveWithANewCardAdmin,
} from "./invoiceReducer";

import { getDistricts, getZones, getHouseFellowship } from "./HouseFellowship";

import {
  getPricing,
  cancelServiceRequest,
  cancelServiceInformation,
  upgradeOrDowngradeServiceRequest,
  freeTrialExtensionInformation,
  freeTrialExtensionRequest,
} from "./Pricing";

import { onBoardCustommer, submitProposal, globalGiving, requestOTPOnboarding } from "./onBoarding";

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  ipData,
  orgInfo,
  loginData,
  signupData,
  forgotPasswordData,
  forgotPinData,
  verifyForgotPasswordOrPinData,
  resetPasswordOrPinData,
  orgInfoList,
  updateUserData,
  getUserProfileData,
  secondOrgInfo,
  peopleList,
  fetchUserList,
  membership,
  addMembership,
  changeMembershipName,
  deleteMembershipName,
  changeMembershipPositionReducer,
  updateAdminProfile,
  addNewMemberState,
  randomPassword,
  searchedUsers,
  prayerWalls,
  prayerWallsApproveOrDelete,
  fellowshipCategoryTypesWithPositions,
  changeFellowshipCategoryPosition,
  addFellowshipCategory,
  deleteFellowshipCategory,
  changeFellowshipCategoryName,
  orgGeneralSettings,
  orgLogo,
  orgSocialMedia,
  generosityDashboardData,
  adminRecentContributionsData,
  fellowshipPrequestDashboard,
  fellowshipPreportDashboard,
  orgMessage,
  orgTextMessaging,
  childrenListing,
  orgColors,
  contributionNamesAndCodesData,
  changeContributionNamesPositionData,
  childDetails,
  fetchChildProfile,
  adminChildDelete,
  adminChildUpdate,
  deleteContributionNameData,
  availableContributionCodesData,
  addContributionNameData,
  changeContributionNameData,
  classroomCategories,
  addClassroomCategory,
  changeClassroomCategoryPosition,
  deleteClassroomCategory,
  changeClassroomCategory,
  emailTemplate,
  teachersListing,
  searchTeacher,
  deleteTeacher,
  availableClassroomThisTeacher,
  addTeacher,
  editTeacher,
  globalSystemSettingsData,
  rolesPermissionList,
  usersWithRolesPermission,
  userRolePerm,
  updateRolesPerm,
  updateGlobalSystemSettingsData,
  childrenListingGrid,
  childrenModuleSpecificInfo,
  getChildCheckedInToken,
  childrenCheckinCheckout,
  deleteRolePerm,
  searchedUsersRolesPerm,
  checkUserRoles,
  generosityModuleSpecificInfoData,
  changeTransactionContributionTypeData,
  sendCommunicationChildren,
  mediaVaultListing,
  deleteMediaVault,
  uploadMediaVault,
  contributionsReportingData,
  weeklyDatesGeneratorData,
  childrenDashboard,
  peopleDashboard,
  contactGroupsListing,
  addContactGroup,
  deleteContactGroup,
  renameContactGroup,
  addUsersToContactGroupProcess,
  addUsersToContactGroupAnalyze,
  contactGroupUsers,
  updateContactGroupUser,
  deleteUsersFromContactGroup,
  smartKeywordsListing,
  deleteSmartKeyword,
  departmentsListing,
  addDepartment,
  deleteDepartmentGroup,
  renameDepartmentGroup,
  departmentGroupUsers,
  deleteUsersFromDepartmentGroup,
  positionsListing,
  searchedUserToJoinDept,
  addNewSmartKeyword,
  editSmartKeyword,
  smartKeywordConsumers,
  addRemoveUserAdminAccess,
  addPosition,
  deletePosition,
  renamePosition,
  positionMembers,
  sendCommunicationParentsProcess,
  salutationsListing,
  addSalutation,
  deleteSalutation,
  renameSalutation,
  salutationMembers,
  leadershipsListing,
  addUpdateleadership,
  deleteleadership,
  changeleadership,
  doSearchUserToJoinLeadership,
  searchParentGuardianToCheckIn,
  getChildrenWithCheckInStatus,
  checkInTheseChildren,
  fetchCommunicationGroupsByCategory,
  getFollowUpByOrganizationIdData,
  uploadMediaData,
  updateFollowUpData,
  newFollowUpFormSubmissionsData,
  assignedFollowUpMembersData,
  getActivityLogData,
  assignGuestData,
  createActivityLogData,
  enableDisabledUserAccount,
  searchedSpouse,
  updateSpouse,
  adminChangePwd,
  adminChildManager,
  fetchUserDeptServ,
  updateActivityLogData,
  uCMessageLogs,
  deleteUser,
  getSermons,
  updateSermon,
  deleteSermon,
  createSermon,
  getSermonsList,
  updateSermonGroup,
  createSermonGroup,
  getSermonGroup,
  searchSermons,
  addSermonsToGroup,
  deleteSermonsToGroup,
  getEventData,
  getFollowUpFormDetailsData,
  getSermonsBySeries,
  updateSermonSeries,
  deleteSermonSeries,
  unifiedCommunicationSendEngineAnalyze,
  createEventData,
  createPreacher,
  updateSermonPreacher,
  getSermonPreacher,
  deleteSermonPreacher,
  searchPreachers,
  unifiedCommunicationSendEngineProcess,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  approveUser,
  removeUser,
  getUsersInGroup,
  listNotesOfChild,
  listCommentsOfThisChildNote,
  addCommentToThisChildNote,
  editCommentOfThisChildNote,
  editNoteOfChild,
  deleteNoteOfChild,
  deleteCommentOfThisChildNote,
  mergeAccounts,
  getShop,
  listCheckInsOfChild,
  childrenCheckInsListing,
  childrenCheckinsReporting,
  getTags,
  updateTag,
  createTag,
  deleteTag,
  sendCommunicationOrg,
  followUpDashboard,
  getDiscounts,
  createDiscount,
  updateDiscount,
  deleteDiscount,
  searchDiscounts,
  getProducts,
  createProduct,
  updateProduct,
  deleteProduct,
  searchAddresses,
  deleteAddresses,
  getAddresses,
  createAddress,
  updateAddresses,
  searchAddress,
  organisationAddress,
  getClosedOutForms,
  createTicket,
  updateTicket,
  ticketListing,
  ticketInfo,
  deleteTicket,
  closeTicket,
  doNotTextCallEmailListingticketListing,
  doNotCallTextLoading,
  searchDoNotTextCallEmail,
  accessSecurityAuditListing,
  searchAccessSecurityAuditByUserId,
  getDistricts,
  getZones,
  getHouseFellowship,
  celebrationListing,
  updateCommunicationOrg,
  birthdaysWeddingsListing,
  importPeople,
  followUpReporting,
  getExportableData,
  exportTheseData,
  updatePhoneNumberAdmin,
  changeGroupBanner,
  mobileAppCustomizationListing,
  mobileAppCustomization,
  userContributionsHistory,
  getUserContributionStatement,
  followUpReportingViewData,
  addManualContribution,
  qrCodesAdmin,
  messagingCreditsListing,
  orgPaymentMethod,
  buyMsgCreditWithExistingToken,
  buyMsgCreditWithNewCardOrCheck,
  msgCreditPurchasedHistory,
  enableOrDisableMsgCreditsAutoRecharge,
  resetToDefaultAdminTableColView,
  importContributionsAnalyze,
  importContributionsColumns,
  importContributionsProcess,
  liveStreamStatus,
  eventRegistration,
  listOfEvent,
  getPricing,
  systemTooltipsModalComments,
  updateSermontPlaylistDisplayOrder,
  createMedia,
  getMedias,
  deleteMedia,
  updateMedia,
  getMediaList,
  createMediaGroup,
  updateMediaGroup,
  getMediaGroup,
  deleteMediaGroup,
  searchMedia,
  addMediaToGroup,
  deleteMediaFromGroup,
  updateMediatPlaylistDisplayOrder,
  getMediaBySeries,
  createSeriesMedia,
  updateMediaSeries,
  deleteMediaSeries,
  createSpeaker,
  updateMediaSpeaker,
  getMediaSpeaker,
  searchSpeakers,
  orgInvoicesListing,
  orgCardCheckStoredTokens,
  followUpDynamicForm,
  cancelServiceRequest,
  cancelServiceInformation,
  upgradeOrDowngradeServiceRequest,
  freeTrialExtensionInformation,
  freeTrialExtensionRequest,
  getAutomatedGreeting,
  addAutomatedGreetings,
  automatedGreetingsListing,
  updateAutomatedGreeting,
  deleteAutomatedGreeting,
  testAutomatedGreeting,
  groupUsers,
  groupUsersPending,
  uCScheduledMessages,
  uCMessageLogsGetDetails,
  uCScheduledMessageGetDetails,
  contributionsReportingByDonor,
  actionContributionStatementPdf,
  contributionStatementsAccounting,
  childrenGridAndListing,
  createAlbum,
  getAlbums,
  deleteAlbum,
  updateAlbum,
  createEpisode,
  deleteEpisode,
  updateEpisode,
  getAlbum,
  uploadFile,
  createAlbumCategory,
  getAlbumCategories,
  deleteAlbumCategories,
  updateAlbumCategories,
  getAlbumCategory,
  searchAlbums,
  reorderAlbumsInCategory,
  contributionStatementMultiple,
  importChildren,
  getOrganizationDynamicForms,
  getAllFormSubmission,
  searchFollowUp,
  formSubmissionStatus,
  checkInAttendanceListingAdmin,
  addCheckInAttendance,
  editCheckInAttendance,
  deleteCheckInAttendance,
  checkInAttendeesInformation,
  checkInAttendeesReporting,
  checkInAttendanceRecent,
  foodAllergy,
  foodAllergyLoading,
  mobileAppActionLoading,
  verifyRouting,
  onBoardCustommer,
  getForm,
  foodAllergyListChildren,
  giveWithAnExistingCardCheckingAdmin,
  giveWithANewCheckingAdmin,
  giveWithANewCardAdmin,
  membersExpiringCardsListing,
  submitProposal,
  fetchStreams,
  getFeaturedStreams,
  updateFeaturedStream,
  searchPlaylist,
  searchCategories,
  updateAppWalkThruAssetInformation,
  deleteGroupLeader,
  giveMethods,
  globalGiving,
  groupCoordinators,
  getFormById,
  viewSubmittedForms,
  requestOTPOnboarding
});

export default RootReducer;
