import {actionTypes} from '../actionTypes';

export const getDistricts =(state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_DISTRICTS_DATA_SUCCESS:
          return {
            ...state,
            ...action.payload,
          };
        case actionTypes.GET_DISTRICTS_DATA_FAILURE:
          return {
            ...state,
            ...action.payload,
          };
        case actionTypes.START_EVENT:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.STOP_EVENT:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
}
export const getZones =(state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_ZONES_DATA_SUCCESS:
          return {
            ...state,
            ...action.payload,
          };
        case actionTypes.GET_ZONES_DATA_FAILURE:
          return {
            ...state,
            ...action.payload,
          };
        case actionTypes.START_EVENT:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.STOP_EVENT:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
}

export const getHouseFellowship =(state = {}, action) => {
    switch (action.type) {
        case actionTypes.GET_HOUSEFELLOWSHIP_DATA_SUCCESS:
          return {
            ...state,
            ...action.payload,
          };
        case actionTypes.GET_HOUSEFELLOWSHIP_DATA_FAILURE:
          return {
            ...state,
            ...action.payload,
          };
        case actionTypes.START_EVENT:
          return {
            ...state,
            loading: true,
          };
        case actionTypes.STOP_EVENT:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
}