import React, { useCallback } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import MatxLayout from "_Matx/MatxLayout/MatxLayout";
import Login from "Pages/auth/login";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  // getOrgInfoList,
  getUserProfile,
  systemTooltipsModalComments,
} from "redux/actions";
import { globalSystemSettings } from "redux/actions/organizationActions";
import Register from "Pages/auth/register/Register";
import ForgotPassword from "Pages/auth/ForgotPassword";
import NotFound from "Pages/auth/NotFound";
import Reset from "Pages/auth/Reset";
import { Gif } from "components/Suspense/GifSuspense";
import { toast } from "react-toastify";
import LoginByToken from "Pages/auth/LoginByToken";
import useOnBoarding from "utils/hooks/useOnBoarding";

const user = JSON.parse(localStorage.getItem("user"));
const PrivateRoutes = ({ component: Component, permission, ...rest }) => {
  const { responseData } = useSelector(
    (state) => state.getUserProfileData,
    shallowEqual
  );
  const userRoles = responseData?.userRolesAndPermissions;
  const { accountType, billingStatus: { isLockDown: billingLockDown } = {} } =
    useSelector((state) => state?.orgInfo.responseData, shallowEqual) || {};
  const { pathname } = useLocation();
  const dashboardPath = "/dashboard/default";
  const isBilling = pathname.includes("billing");

  const isDisabled =
    (accountType?.isLockDown === "true" && accountType.isHide === "false") ||
    billingLockDown === "true";

  const canAccesspath = useCallback(
    (role) => {
      if (userRoles) {
        if (userRoles?.isSuperAdmin === "true" || role === "open") return true;
        if (userRoles[role]?.length < 1)
          toast.error("You don't have permission to access this page");
        return userRoles[role]?.length > 0;
      }
    },
    [userRoles]
  );

  const isLochedDown = useCallback(() => {
    if (pathname === dashboardPath || isBilling) return true;
    if (isDisabled) {
      toast.error("Account is Suspended. Please contact your administrator");
      return false;
    }
    return true;
  }, [isDisabled, pathname]);

  if (!JSON.parse(localStorage.getItem("user"))) {
    return <Redirect to="/" />;
  }

  if (!userRoles) return <Gif />;

  return (
    <MatxLayout>
      <Route
        {...rest}
        render={(props) =>
          userRoles && canAccesspath(permission) && isLochedDown() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/dashboard/default" />
          )
        }
      />
    </MatxLayout>
  );
};

const PublicRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !localStorage.getItem("user") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard/default" />
        )
      }
    />
  );
};

const Routes = () => {
  const dispatch = useDispatch();

  const orgInfo = useSelector(
    (state) => state.orgInfo.responseData,
    shallowEqual
  );

  React.useEffect(() => {
    if (user?.responseData) {
      dispatch(
        getUserProfile({
          orgId: orgInfo?.orgId,
          userId: user?.responseData?.userId,
          adminUserId: user?.responseData?.userId,
        })
      );
    }
  }, [dispatch, orgInfo]);

  React.useEffect(() => {
    // dispatch(getOrgInfoList({ orgId: orgInfo?.orgId }));
    dispatch(
      globalSystemSettings({
        orgId: orgInfo?.orgId,
        adminUserId: user?.responseData?.userId,
      })
    );
    changeFavicon();
    dispatch(systemTooltipsModalComments({ orgId: orgInfo?.orgId }));
  }, []);

  const changeFavicon = () => {
    const favicon = document.getElementById("favicon");
    favicon.href = orgInfo?.favIcons[4].url;
  };

  const { churchName, isOnBoarding } = useOnBoarding();

  function getBasename() {
    if (isOnBoarding && churchName) return `/${churchName}`;
    return "";
  }

  return (
    <Router basename={getBasename()}>
      <Switch>
        <PublicRoutes path="/" exact component={Login} />
        {/* <PublicRoutes path="/" exact component={OnboardingSignUp} /> */}
        <PublicRoutes path="/session/signup" exact component={Register} />
        <PublicRoutes
          path="/session/forgot-password"
          exact
          component={ForgotPassword}
        />
        <PublicRoutes path="/reset_password" exact component={Reset} />
        <PublicRoutes path="/reset_pin" exact component={Reset} />
        <PublicRoutes path="/cpauth_admin" exact component={LoginByToken} />
        <PrivateRoutes
          path="/dashboard/default"
          exact
          component={React.lazy(() => import("Pages/Dashboard"))}
          permission="open"
        />
        <PrivateRoutes
          path="/product/pricing"
          exact
          component={React.lazy(() => import("Pages/BuyProducts/BuyProducts"))}
          permission="open"
        />
        <PrivateRoutes
          path="/product/people/listing"
          exact
          component={React.lazy(() =>
            import("Pages/People/Listing/PeopleDashboard")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/listing/view-people"
          exact
          component={React.lazy(() =>
            import("Pages/People/Listing/ViewPeople")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/merge-accounts"
          exact
          component={React.lazy(() =>
            import("Pages/People/Listing/MergeAccounts")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/membershiptypes"
          exact
          component={React.lazy(() =>
            import("Pages/People/Groups/MembershipGroup")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/groups"
          exact
          component={React.lazy(() =>
            import("Pages/People/GroupsNxtGen/Groups")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/group-users"
          exact
          component={React.lazy(() =>
            import("Pages/People/GroupsNxtGen/GroupUsers")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/settings"
          exact
          permission="people"
          component={React.lazy(() => import("Pages/People/Settings"))}
        />
        <PrivateRoutes
          path="/product/people/dashboard"
          exact
          component={React.lazy(() =>
            import("Pages/People/Dashboard/PeopleDashboard")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/serviceunits&positions"
          exact
          component={React.lazy(() =>
            import("Pages/People/Departments&Positions/index")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/titles&salutations"
          exact
          component={React.lazy(() =>
            import("Pages/People/Salutation/Salutation")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/organization/general"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/General/GeneralSettings")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/merchantaccount"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/MerchantAccount/MerchantAccountForms")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/socialmedia"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/SocialMedia/SocialMedia")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/brand&logo"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/Brand&Logos/BrandAndLogos")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/messaging"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/OrgMessaging/Messaging")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/settings"
          exact
          component={React.lazy(
            () => import("Pages/Organization/Settings/Settings")
            // import('Pages/Organization/Settings/settings')
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/roles&permissions"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/Roles&Permissions/RolesPermissions")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/roles&permissions/view-administrator"
          exact
          component={React.lazy(() =>
            import(
              "Pages/Organization/Roles&Permissions/Administrators/ViewAdministrator"
            )
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/roles&permissions/currentroles&permissions"
          exact
          component={React.lazy(() =>
            import(
              "Pages/Organization/Roles&Permissions/AddRolesToUser/UserCurrentRoles"
            )
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/leadership&dirservice"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/Leadership/Leadership")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/qrcodes"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/QRcodes/index")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/credits"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/OrgMessaging/BuyCredit/index")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/buy-credits"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/OrgMessaging/BuyCredit/ProcessPayment")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/organization/credits/purchase-history"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/OrgMessaging/BuyCredit/PurchaseHistory")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/product/the-word/sermons"
          exact
          permission="livestreamSermons"
          component={React.lazy(() => import("Pages/LiveStream/Sermons/index"))}
        />
        <PrivateRoutes
          path="/product/the-word/view-sermon-group"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Sermons/SermonsGroup/ViewSermonGroup")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/reorder-playlist"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Sermons/SermonsGroup/ReorderPlaylist")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/view-series"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Sermons/SermonSeries/ViewSeries")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/settings"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Settings/Settings")
          )}
          permission="livestreamSermons"
        />

        <PrivateRoutes
          path="/product/the-word/media"
          exact
          permission="livestreamSermons"
          component={React.lazy(() => import("Pages/LiveStream/Media/index"))}
        />
        <PrivateRoutes
          path="/product/the-word/view-media-group"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Media/SermonsGroup/ViewSermonGroup")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/view-media-series"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Media/SermonSeries/ViewSeries")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/view-series-media"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Media/SermonSeries/ViewSeries")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/reorder-playlist-media"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Media/SermonsGroup/ReorderPlaylist")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/ecommerce/categories"
          exact
          component={React.lazy(() => import("Pages/ECommerce/Category/Shop"))}
          permission="eCommerce"
        />
        <PrivateRoutes
          permission="eCommerce"
          path="/product/ecommerce/tags"
          exact
          component={React.lazy(() => import("Pages/ECommerce/Tags/Tags"))}
        />
        <PrivateRoutes
          path="/product/ecommerce/discount"
          exact
          component={React.lazy(() =>
            import("Pages/ECommerce/Discounts/Discount")
          )}
          permission="eCommerce"
        />
        <PrivateRoutes
          path="/product/ecommerce/products"
          exact
          permission="eCommerce"
          component={React.lazy(() => import("Pages/ECommerce/Products/Shop"))}
        />
        <PrivateRoutes
          path="/product/ecommerce/settings"
          exact
          permission="eCommerce"
          component={React.lazy(() => import("Pages/ECommerce/Settings"))}
        />
        <PrivateRoutes
          permission="fellowshipPrequest"
          path="/product/fellowship-prequest/listing"
          exact
          component={React.lazy(() => import("Pages/Prayerwalls"))}
        />
        <PrivateRoutes
          path="/product/fellowship-preport/listing"
          exact
          component={React.lazy(() => import("Pages/Testimony"))}
          permission="fellowshipPreport"
        />
        <PrivateRoutes
          permission="fellowshipPrequest"
          path="/product/fellowship-prequest/categories"
          exact
          component={React.lazy(() => import("Pages/PrayerCategoryPage"))}
        />
        <PrivateRoutes
          path="/product/fellowship-prequest/settings"
          exact
          component={React.lazy(() =>
            import("Pages/PrayerRequest/Settings/Settings")
          )}
          permission="fellowshipPrequest"
        />
        <PrivateRoutes
          permission="fellowshipPreport"
          path="/product/fellowship-preport/categories"
          exact
          component={React.lazy(() => import("Pages/PrayerCategoryPage"))}
        />
        <PrivateRoutes
          permission="generosity"
          path="/product/generosity/dashboard"
          exact
          component={React.lazy(() => import("Pages/Accounting/Dashboard"))}
        />
        <PrivateRoutes
          path="/product/generosity/contributions"
          permission="generosity"
          exact
          component={React.lazy(() => import("Pages/Accounting/Income"))}
        />
        <PrivateRoutes
          path="/product/generosity/contributions/import"
          permission="generosity"
          exact
          component={React.lazy(() =>
            import(
              "Pages/Accounting/ImportExportContribution/ImportContribution"
            )
          )}
        />
        <PrivateRoutes
          path="/product/generosity/contributions/import/process"
          permission="generosity"
          exact
          component={React.lazy(() =>
            import(
              "Pages/Accounting/ImportExportContribution/ImportContributionProcess"
            )
          )}
        />
        <PrivateRoutes
          path="/product/generosity/contributioncodes"
          exact
          component={React.lazy(() =>
            import("Pages/Accounting/ContributionCodes")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/product/generosity/settings"
          exact
          component={React.lazy(() =>
            import("Pages/Accounting/Settings/Settings")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/product/generosity/reportingbynumbers"
          exact
          component={React.lazy(() =>
            import("Pages/Accounting/Reporting/Reporting")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/product/generosity/reportingbydonors"
          exact
          component={React.lazy(() =>
            import("Pages/Accounting/Reporting/ReportingByDonor")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/product/generosity/contributionstatements"
          exact
          component={React.lazy(() =>
            import("Pages/Accounting/ContributionStatement")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/product/fellowship-prequest/dashboard"
          exact
          component={React.lazy(() =>
            import("Pages/PrayerRequest/PrayerRequest")
          )}
          permission="fellowshipPrequest"
        />
        <PrivateRoutes
          path="/product/fellowship-preport/dashboard"
          exact
          component={React.lazy(() =>
            import("Pages/PraiseReport/TestimonyDashboard")
          )}
          permission="fellowshipPreport"
        />
        <PrivateRoutes
          path="/product/fellowship-preport/settings"
          exact
          component={React.lazy(() =>
            import("Pages/PraiseReport/Settings/Setting")
          )}
          permission="fellowshipPreport"
        />
        <PrivateRoutes
          path="/product/children/listing"
          exact
          component={React.lazy(() => import("Pages/Children/ChildrenListing"))}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/import/create"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Import/ImportPage1")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/import/process"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Import/ImportPageTwo")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/view-child"
          exact
          component={React.lazy(() => import("Pages/Children/ViewChild"))}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/classrooms"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Classroom/Classroom")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/teachers"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Teachers/TeachersListing")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/settings"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Settings/Settings")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/grid"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Grid/GridListing")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/children/communication/success"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Communication/SuccessPage")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/unified-communication/mediavault"
          exact
          component={React.lazy(() => import("Pages/MediaVault/MediaVault"))}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/children/dashboard"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Dashboard/Dashboard")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/unified-communication/contactgroups"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/ContactGroup/ContactGroup")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/unified-communication/smartkeywords"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/SmartKeyWords/SmartKeyword")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/children/check-instation"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Checkin/CheckinStation")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/checkIn/children"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Checkin/CheckinChildren")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/unified-communication/sms"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/SMS/Sms")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/unified-communication/mms"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/MMS/MMS")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/unified-communication/voice"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/Voice/Voice")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/follow-up/settings"
          exact
          component={React.lazy(() =>
            import("Pages/FollowUp/Settings/Settings2")
          )}
          permission="followUp"
        />
        <PrivateRoutes
          permission="followUp"
          path="/product/follow-up/listing"
          exact
          component={React.lazy(() => import("Pages/FollowUp/Listing/Listing"))}
        />
        <PrivateRoutes
          path="/product/calendar-events/eventaddresses"
          exact
          component={React.lazy(() =>
            import("Pages/Events/Addresses/Addresses")
          )}
          permission="calendarEvents"
        />
        <PrivateRoutes
          path="/product/unified-communication/emails"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/Email/Email")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/unified-communication/pushnotifications"
          exact
          component={React.lazy(() =>
            import(
              "Pages/UnifiedCommunications/PushNotification/PushNotification"
            )
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/cron"
          exact
          component={React.lazy(() => import("Pages/CronComponent"))}
          permission="open"
        />
        <PrivateRoutes
          path="/product/calendar-events/listing"
          exact
          component={React.lazy(() => import("Pages/Events/Listing/Listing"))}
          permission="calendarEvents"
        />
        <PrivateRoutes
          path="/product/children/view/note"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Notes/ViewChildNote")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/organization/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/UserGuide/UserGuide")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/billing/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/Invoice/Userguide/Userguide")
          )}
          permission="billing"
        />
        <PrivateRoutes
          path="/dynamic-forms/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/DynamicForms/UserGuide/UserGuide")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/generosity/adminguide"
          exact
          component={React.lazy(() => import("Pages/Accounting/Userguide"))}
          permission="generosity"
        />
        <PrivateRoutes
          path="/product/people/adminguide"
          exact
          component={React.lazy(() => import("Pages/People/Userguide"))}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/birthdays&weddings"
          exact
          component={React.lazy(() => import("Pages/People/BirthdayWeddings"))}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/check-inattendance"
          exact
          component={React.lazy(() => import("Pages/People/CheckIn"))}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/import-page-1"
          exact
          component={React.lazy(() =>
            import("Pages/People/ImportExport/Import")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/import-page-2"
          exact
          component={React.lazy(() =>
            import("Pages/People/ImportExport/ImportPage2")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/export"
          exact
          component={React.lazy(() => import("Pages/People/Export/Export"))}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/export-page-2"
          exact
          component={React.lazy(() =>
            import("Pages/People/Export/ExportPage2")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/children/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/Children/UserGuide/UserGuide")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/children/foodallergiestypes"
          exact
          component={React.lazy(() => import("Pages/Children/FoodAllergies"))}
          permission="children"
        />
        <PrivateRoutes
          path="/product/unified-communication/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/UserGuide/UserGuide")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/calendar-events/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/Events/UserGuide/UserGuide")
          )}
          permission="calendarEvents"
        />
        <PrivateRoutes
          path="/product/the-word/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/UserGuide/Userguide")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/fellowship-prequest/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/PrayerRequest/UserGuide/UserGuide")
          )}
          permission="fellowshipPrequest"
        />
        <PrivateRoutes
          path="/product/fellowship-preport/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/PraiseReport/UserGuide/UserGuide")
          )}
          permission="fellowshipPreport"
        />
        <PrivateRoutes
          path="/product/ecommerce/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/ECommerce/UserGuide/UserGuide")
          )}
          permission="ecommerce"
        />
        <PrivateRoutes
          path="/product/children/reporting"
          exact
          component={React.lazy(() =>
            import("Pages/Children/Reporting/Reporting")
          )}
          permission="children"
        />
        <PrivateRoutes
          path="/product/follow-up/dashboard"
          exact
          component={React.lazy(() =>
            import("Pages/FollowUp/MainDashboard/MainDashboard")
          )}
          permission="followUp"
        />
        <PrivateRoutes
          path="/tickets"
          exact
          component={React.lazy(() => import("Pages/Support/Ticket/Ticket"))}
          permission="open"
        />
        <PrivateRoutes
          path="/tickets/active/:id"
          exact
          component={React.lazy(() =>
            import("Pages/Support/Ticket/ActiveTicket")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/tickets/closed/:id"
          exact
          component={React.lazy(() =>
            import("Pages/Support/Ticket/ActiveTicket")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/unified-communication/donottext-call-email"
          exact
          component={React.lazy(() =>
            import("Pages/UnifiedCommunications/DoNot/DoNotCallText")
          )}
          permission="unifiedCommunications"
        />
        <PrivateRoutes
          path="/product/people/housefellowship"
          exact
          component={React.lazy(() =>
            import("Pages/People/HouseFellowship/HouseFellowship")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/organization/access&securityaudit"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/AccessSecurity/index")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/product/follow-up/adminguide"
          exact
          component={React.lazy(() =>
            import("Pages/FollowUp/UserGuide/UserGuide")
          )}
          permission="followUp"
        />
        <PrivateRoutes
          path="/product/follow-up/view-log/:id?"
          exact
          component={React.lazy(() =>
            import("Pages/FollowUp/Listing/ActivityLog/ViewActivityLog")
          )}
          permission="followUp"
        />
        <PrivateRoutes
          path="/product/people/groups&serviceunits"
          exact
          component={React.lazy(() =>
            import("Pages/People/GroupsAndServiceUnit/index")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/product/people/positions&salutations"
          exact
          component={React.lazy(() =>
            import("Pages/People/PositionAndSalutation")
          )}
          permission="people"
        />
        <PrivateRoutes
          path="/organization/mobileappcustomization"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/MobileAppCustomization")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/mobileappcustomization/change/:name"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/MobileAppCustomization/ChangePosition")
          )}
          permission="organization"
        />
        <PrivateRoutes
          path="/product/follow-up/viewDetails"
          exact
          component={React.lazy(() =>
            import("Pages/FollowUp/Listing/Reporting/ViewReportingDetails")
          )}
          permission="followUp"
        />
        <PrivateRoutes
          path="/product/the-word/livestreamstatus"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/LiveStreamStatus/LiveStreamStatus")
          )}
          permission="livestreamSermons"
        />
        <PrivateRoutes
          path="/product/the-word/audio&podcasts"
          exact
          component={React.lazy(() => import("Pages/LiveStream/Podcast"))}
          permission="open"
        />
        <PrivateRoutes
          path="/product/the-word/audio&podcasts/view-album-category"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Podcast/Categories/SingleCategory")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/the-word/audio&podcasts/viewAlbum"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Podcast/Albums/ViewAlbum")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/the-word/audio&podcasts/reorder-categories"
          exact
          component={React.lazy(() =>
            import("Pages/LiveStream/Podcast/Categories/Reorder")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/calendar-events/registrations"
          exact
          component={React.lazy(() =>
            import("Pages/Events/EventRegistration/index")
          )}
          permission="calendarEvents"
        />
        <PrivateRoutes
          path="/billing/invoices"
          exact
          component={React.lazy(() =>
            import("Pages/InvoiceBilling/Invoices/InvoiceListing")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/billing/invoices/makepayment"
          exact
          component={React.lazy(() =>
            import("Pages/InvoiceBilling/Invoices/PayWithNewOrExistingCard")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/billing/cardsonfile"
          exact
          component={React.lazy(() =>
            import("Pages/InvoiceBilling/Cards on File/index")
          )}
          permission="generosity"
        />
        <PrivateRoutes
          path="/organization/dynamicforms"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/DynamicForms/OrganisationForms")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/organization/dynamicforms/create"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/DynamicForms/CreateForms")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/generosity/membersexpiringcards"
          exact
          component={React.lazy(() =>
            import("Pages/Accounting/MemberExpiryCards")
          )}
          permission="open"
        />
        <PrivateRoutes
          path="/product/generosity/otherwaystogive"
          exact
          component={React.lazy(() => import("Pages/Accounting/GiveMethods"))}
          permission="people"
        />
        <PrivateRoutes
          path="/organization/forms/view_forms/:id"
          exact
          component={React.lazy(() =>
            import("Pages/Organization/DynamicForms/ViewSubmittedForms")
          )}
          permission="organization"
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
