import React from "react";
import { Provider } from "react-redux";
import { SettingsProvider } from "utils/context/SettingsContext";
import MatxTheme from "_Matx/MatxTheme/MatxTheme";
import GifSuspense from "components/Suspense/GifSuspense";
import GlobalCss from "styles/GlobalCss";
import Routes from "Route/RootRoutes";
import { Store } from "redux/Store";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fetchOrgInfo, getIpData } from "redux/actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import useOnBoarding from "utils/hooks/useOnBoarding";
import OnboardingSignUp from "Pages/Onboarding";
import useDynamicTitle from "utils/hooks/useDynamicTitle";
import { setupAxiosInterceptors } from "utils/AxiosConfig";
import MobileProposalRoutes from "Pages/MobileAppProposal/Routes";
import GlobalGivingEbrochureRoutes from "Pages/GlobalGiving/Routes";

const App = () => {
  const { isOnBoarding, churchName } = useOnBoarding();
  const isOnBoardingLandingPage = isOnBoarding && !churchName;
  useDynamicTitle(isOnBoardingLandingPage);

  const isNotAdminApp =
    (isOnBoarding && !churchName) ||
    (isOnBoarding && churchName === "mobile-app-proposal") ||
    (isOnBoarding && churchName === "global-giving-ebrochure");

  React.useEffect(() => {
    Store.dispatch(getIpData());
    if (isNotAdminApp) {
      return;
    } else {
      Store.dispatch(fetchOrgInfo());
    }
  }, [isOnBoarding, churchName]);

  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'staging') {
      if ((window.location.protocol !== "https:")){
        window.location.protocol = "https";
      }
    }
  }, [])

  Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
  };

  const changeFavicon = () => {
    if (isNotAdminApp) return;
    const data = Store.getState()?.orgInfo?.responseData;
    const iconsData = data?.favIcons?.map((item) => {
      return {
        src: item.url,
        sizes: item.size,
        type: item.type,
      };
    });
    const mainfestData = {
      short_name: data?.shortName,
      name: data?.fullName,
      icons: iconsData,
      start_url: data?.dedicatedUrl,
      display: "standalone",
      theme_color: "#000000",
      background_color: "#ffffff",
    };
    const favicon = document.getElementById("favicon");
    const mainfest = document.getElementById("mainfest_");
    const appleTouch = document.getElementById("apple_touch");
    mainfest.setAttribute("href", JSON.stringify(mainfestData));
    favicon.setAttribute("href", data?.favIcons[4]?.url);
    appleTouch.setAttribute("href", data?.favIcons[4]?.url);
  };

  const routesToShow = () => {
    switch (true) {
      case isOnBoarding && churchName === "mobile-app-proposal":
        return <MobileProposalRoutes />;
      case isOnBoarding && churchName === "global-giving-ebrochure":
        return <GlobalGivingEbrochureRoutes />;
      case isOnBoarding && !churchName:
        return <OnboardingSignUp />;
      default:
        return <Routes />;
    }
  };

  setupAxiosInterceptors();

  return (
    <>
      <Provider store={Store}>
        <SettingsProvider>
          <MatxTheme>
            <GlobalCss />
            <GifSuspense>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnHover
                />
                {changeFavicon()}
                {routesToShow()}
              </MuiPickersUtilsProvider>
            </GifSuspense>
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </>
  );
};

export default App;
