import {actionTypes} from '../actionTypes';

export const getEventData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_EVENT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const searchAddress = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ADDRESS_EVENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SEARCH_ADDRESS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export const organisationAddress = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORGANISATION_ADDRESS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.GET_ORGANISATION_ADDRESS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const createEventData = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_EVENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CREATE_EVENT_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.START_EVENT:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_EVENT:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};


export const getAddresses = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_ORG_ADDRESSES_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ADDRESSES_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.GET_ORG_ADDRESSES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ORG_ADDRESSES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const createAddress = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.CREATE_ORG_ADDRESSES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_ORG_ADDRESSES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateAddresses = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ORG_ADDRESSES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.UPDATE_ORG_ADDRESSES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const deleteAddresses = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ORG_ADDRESSES_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DELETE_ORG_ADDRESSES_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const searchAddresses = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ADDRESS_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.SEARCH_ADDRESS_FAIL:
      return { ...state, loading: false, ...action.payload };
    case actionTypes.SEARCH_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SEARCH_ADDRESS_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const listOfEvent = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EVENT_LIST_SUCCESS || actionTypes.EVENT_LIST_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.EVENT_REGISTRATION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EVENT_REGISTRATION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export const eventRegistration = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.EVENT_REGISTRATION_SUCCESS || actionTypes.EVENT_REGISTRATION_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.EVENT_REGISTRATION_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.EVENT_REGISTRATION_STOP:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
