import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import MobileAppProposal from ".";
import MobileAppProposalSuccess from "./SuccessPage";

const MobileProposalRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact component={MobileAppProposal} path={"/mobile-app-proposal"} />
        <Route
          component={MobileAppProposalSuccess}
          path={"/mobile-app-proposal/success"}
          exact
        />
        <Redirect to="/mobile-app-proposal" />
      </Switch>
    </Router>
  );
};

export default MobileProposalRoutes;
